import React, {useState, useEffect, useRef} from 'react';
import _ from 'underscore';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from 'libs/Button'
import Input from 'libs/Input'
import IsFavorite from './IsFavorite'
import {
    Link, Outlet
} from "react-router-dom";
import CustomStorage from "./Suggest/CustomStorage";
import TagSelector from "./Suggest/TagSelector";
import Smart from 'libs/Smart';
import {ReactSortable} from "react-sortablejs";
import NFN from "../NFN";

const USER_SPRINT_STATUSES = ['',
    {name: 'Ожидание старта', key: 'pending'},
    {name: 'Актив', key: 'active'},
    {name: 'Начался', key: 'started'},
    {
        name: 'Сабмит',
        key: 'submitted'
    }, {name: 'Закрыто', key: 'closed'}];


let names = {
    good: {name: 'Хорошо', cl: 'label-success'},
    very_good: {name: 'Оч. хорошо', cl: 'label-success'},
    bad: {name: 'Норм', cl: 'label-warning'},
    norm: {name: 'Плохо', cl: 'label-danger'},
    '': {name: 'Новый', cl: 'label-info'},
    'all': {name: 'Все', cl: 'label-info'}
}

function Layout2(_props) {
    let {props, httpSaveFn, statuses, opts} = _props || {}

    opts = opts || {}

    let {isCourse, fieldKey = 'questions'} = opts;
    let {localItem} = props;
    let {filter = {}} = localItem || {};

    let [cd, setCd] = useState(0)
    let [users, setUsers] = useState([])
    let [plainQ2, setPlainQ2] = useState([])
    let [check1, setCheck1] = useState(filter.check1 || {})
    let [check2, setCheck2] = useState(filter.check2 || {})
    let [filter1, setFilter1] = useState(null)
    let [filter2, setFilter2] = useState(null)
    let [questions, setQuestions] = useState([])
    let [questionsObj, setQuestionsObj] = useState({})
    let [questions1, setQuestions1] = useState([])
    let [questions2, setQuestions2] = useState([])
    let [idsObj, setIdsObj] = useState({})
    let [historyObj, setHistoryObj] = useState({})

    // console.log('filter999filter999', localItem)
    useEffect(() => {
        let {filter} = localItem || {};
        if (!filter) {
            return;
        }
        setFilter1(filter.filter1 || {})
        setFilter2(filter.filter2 || {})
    }, [localItem ? localItem._id : null])


    useEffect(() => {
        if (!localItem || !localItem.user) {
            return;
        }
        if (isCourse) {
            return;
        }

        global.http.get('/get-user-history', {_id: localItem.user})
            .then(r => {
                historyObj = {};
                _.each(r, ({question, status}) => {
                    historyObj[question] = {status, ...names[status] || {}}
                })
                setHistoryObj(historyObj)
            })
    }, [(localItem || {}).user])

    useEffect(() => {
        global.http.get('/all-questions', {})
            .then(items => {
                questionsObj = {};
                _.each(items, (item, ind) => {
                    questionsObj[item._id] = item;
                })

                setQuestionsObj(questionsObj)
                setQuestions(items)
                onSetQuestions1(items)
                setQ2ByOpts(questionsObj)
                trySetSmart(items)
                // let questions2 = ((localItem || {})[fieldKey] || []).map(it => questionsObj[it]);
                // setQuestions2(questions2);
              //console.log("qqqqq OPTS CHANGEDDDDDDDD LOAD ALL-q", opts, plainQ2, questionsObj);

            })

        global.http.get('/all-users', {})
            .then(items => {
                setUsers(items.map(it => {
                    return {_id: it._id, label: `${it.username} ${it.first_name || ''} #${it._id}`}
                }))
            })

    }, [])

    useEffect(() => {
        trySetSmart(questions)
    }, [localItem ? localItem._id : null, (localItem || {})[fieldKey]])

    function trySetSmart (questions) {
        if (!opts.questions2 && localItem && questions.length) {
            let questions2 = ((localItem || {})[fieldKey] || []).map(it => questionsObj[it]);
            setQ2(questions2, true)
        }
    }


    function setQ2ByOpts(obj) {

      //console.log("qqqqq opts", opts.questions2, obj);
        if (opts.questions2 && opts.questions2.length) {
            let _questionsObj = questionsObj || obj;
            let q2 = (opts.questions2 || []).map(it => _questionsObj[it]).filter(it => it);
            console.log("qqqqq xxxxxxxxxxxxxxxx", q2, opts.questions2, _questionsObj);
            setQ2(q2, true)
        }
    }


    // useEffect(() => {
    //     (opts.questions2 || []).length  && setPlainQ2(opts.questions2 || [])
    //     // console.log("qqqqq OPTS CHANGEDDDDDDDD", opts.questions2, questionsObj);
    //     // _.size(questionsObj) && setQ2ByOpts(historyObj)
    // }, [((opts || {}).questions2 || []).length])
    console.log('*........ ## FILTER  44444', plainQ2);

    function onSetQuestions1(items = questions) {
        setQuestions1(items)
    }


    function setQ2(q2, withoutSet) {
        let ids = {}
        _.each(q2, it => {

            if (!it || !it._id) {
                return;
            }
            ids[it._id] = true;
        })
        setIdsObj(ids)
        let sortedQuestions = [...q2];//sortQuestions([...q2], filter2);
        setQuestions2(sortedQuestions);
        console.log("qqqqq !!!!!!!!!!!! sortedQuestions xxxxxxxxx", q2, sortedQuestions);

        let qq = sortedQuestions.filter(it => it);
        let obj = {
            ...localItem,
            filter: {filter1, filter2},
            [fieldKey]: qq.map(it => it._id),

        };
        console.log("qqqqq withththththththththththhtththht", withoutSet);

        if (!withoutSet) {
            let {onChangeQ2} = opts
            onChangeQ2 && onChangeQ2(obj[fieldKey], qq)
            props.autoSaveFn && props.autoSaveFn(obj)
        } else {

            console.log("qqqqq objbjbjbj", opts.questions2, q2, sortedQuestions, obj[fieldKey], {fieldKey});
        }

    }

    function addQuizFn2() {
        console.log("qqqqq add quiz",);
    }


    function move1To2(newQuestions) {
        console.log("qqqqq move 1 to 2", newQuestions);
        setQ2(_.uniq([...questions2, ...newQuestions], it => it._id))
    }

    function move2To1(newQuestions) {
        let obj = {};
        _.each(newQuestions, (item, ind) => {
            obj[item._id] = true
        })

        questions2 = questions2.filter(it => {
            return !obj[it._id]
        })
        setQuestions1(_.uniq([...questions1, ...newQuestions], it => it._id))
        setQ2(questions2)

    }

    // function sortQuestions(questions, filter) {
    //     return questions;
    // }
    // function onChange() {
    //     console.log('on  global Sort Questions Change')
    //     let sortedQuestions = sortQuestions([...questions2], filter2);
    //
    //     let obj = {
    //         ...localItem,
    //         questions: sortedQuestions.map(it => it._id),
    //         filter: {filter1, filter2}
    //     };
    //     props.onGlobalChange && props.onGlobalChange(obj)
    // }


    questions1 = (questions1 || []).filter(it => !idsObj[it._id])
    console.log("propsppppppppppppppppppppp", questions2)

    let UserPreviewComponent = _props.UserPreviewComponent
    return <div className={'row'}>
        {UserPreviewComponent && <div className="col-sm-12">
            <UserPreviewComponent item={localItem}></UserPreviewComponent>
        </div>}
            {!isCourse && <>
                <div className="col-sm-12" style={{marginTop: '15px', marginRight: '15px'}}>
                    <Smart
                        obj={localItem}
                        items={[{type: 'group', key: 'status', list: statuses || USER_SPRINT_STATUSES, size: 12}]}
                        onChange={(v, v1) => {
                            localItem.status = v1
                            props.onGlobalChange && props.onGlobalChange(localItem)
                        }
                        }
                    ></Smart>
                </div>
                <div className="col-sm-3 " style={{marginTop: '10px'}}>
                    {users && users.length && <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={users}
                        value={(users || []).filter(it => {
                            return localItem && localItem.user && (localItem.user == (it || {})._id)
                        })[0]}
                        sx={{width: 300}}
                        renderInput={(params) => <TextField {...params} label={global.nameFn('User')}/>}
                        onChange={(v1, v) => {
                            localItem.user = (v || {})._id || 0;
                            props.onGlobalChange && props.onGlobalChange(localItem)
                            setCd(new Date())
                        }
                        }
                    />}

                </div>

                <div className="col-sm-12 " style={{marginLeft: '0', marginTop: '10px'}}>
                    <Smart
                        obj={localItem}
                        items={
                            [

                                {size: 3, type: 'input', name: 'Name', key: 'name'},
                                {size: 3, type: 'input', name: 'Minutes', key: 'minutesStr'},
                                {size: 3, type: 'input', name: 'Max Attempts To Submit', key: 'availableSubmitCount'},
                                {
                                    size: 1, Component: ({item}) => {
                                        return <div style={{marginTop: '20px'}}><IsFavorite size={24} item={localItem}
                                                                                            onChange={(r) => {
                                                                                                console.log("qqqqq change favorite", r);
                                                                                                localItem.isFavorite = r;
                                                                                                props.onGlobalChange && props.onGlobalChange(localItem)

                                                                                            }}></IsFavorite></div>
                                    }
                                },
                                {
                                    Component: ({item}) => {
                                        return <>
                                            <a style={{marginTop: '20px', display: 'inline-block'}}
                                               onClick={() => {
                                                   global.http.post(opts.cloneUrl || '/user-sprint',
                                                       {
                                                           ...localItem,
                                                           isFavorite: false,
                                                           isFromClone: true,
                                                           _id: null,
                                                           user: null,
                                                           status: 'active'
                                                       })
                                                       .then(r => {
                                                           window.location.pathname = (opts.redirectUrl || '/user-sprint') + '/' + r._id
                                                       })
                                               }}
                                            ><NFN>Clone That</NFN> <NFN>{opts.name || 'Sprint'}</NFN></a>
                                        </>
                                    }, size: 4
                                },
                                // {
                                //     Component: ({item}) => {
                                //         return <>
                                //             <a style={{marginTop: '20px', display: 'inline-block'}}
                                //                onClick={() => {
                                //                    global.http.post(opts.cloneUrl || '/user-sprint',
                                //                        {
                                //                            ...localItem,
                                //                            isFavorite: false,
                                //                            isFromClone: true,
                                //                            _id: null,
                                //                            user: null,
                                //                            status: 'active'
                                //                        })
                                //                        .then(r => {
                                //                            window.location.pathname = (opts.redirectUrl || '/user-sprint') + '/' + r._id
                                //                        })
                                //                }}
                                //             >Move That {opts.name || 'Sprint'} To Template</a>
                                //         </>
                                //     }, size: 4
                                // },

                            ]
                        }
                        onChange={(v, v1) => {
                            console.log("qqqqq ON GLOBAL CHANGE", v, v1);
                            props.onGlobalChange && props.onGlobalChange(localItem)
                        }
                        }
                    ></Smart>

                </div>
            </>}



        <div className="col-sm-12">
            <hr/>
        </div>
        {!!questions.length && <>
            <div className="col-sm-6">
                <FilterAndQuestions
                    questions={questions1}
                    name={global?.nameFn('Select Questions')}
                    filter={filter1}
                    check={check1}
                    questionsObj={questionsObj}
                    historyObj={historyObj}
                    moveFn={move1To2}
                    setQuestions={setQuestions1}
                    setFilter={setFilter1}
                    setCheck={setCheck1}
                    isCourse={isCourse}
                    sortFn={setQuestions1}

                ></FilterAndQuestions>
            </div>
            <div className="col-sm-6">
                <FilterAndQuestions
                    questions={questions2}
                    name={global?.nameFn('Select Questions')}
                    filter={filter2}
                    check={check2}
                    questionsObj={questionsObj}
                    historyObj={historyObj}
                    moveFn={move2To1}
                    setQuestions={setQuestions2}
                    setFilter={setFilter2}
                    setCheck={setCheck2}
                    addQuizFn={addQuizFn2}
                    isCourse={isCourse}
                    isResultQ2={true}
                    sortFn={(items) => {
                        setQ2(items)
                        console.log('items44444', items.map(it => it._id))
                    }}
                ></FilterAndQuestions>
            </div>
        </>}
    </div>
}

function setAll(status, fn, questions) {

    if (!status) {
        return fn && fn({})
    }
    let item = {};
    _.each(questions, (it, ind) => {
        item[it._id] = true
    })
    console.log("qqqqq item44444", item);
    fn && fn({...item})

}

function getSize(obj, questions) {
    let count = 0;
    obj = obj || {};
    _.each(questions, it => {
        if (!it) {
            return;
        }
        count += (obj[it._id] ? 1 : 0)
    })
    return count;
}

let STATUSES = ['all', '', 'bad', 'norm', 'good', 'very_good'];

function FilterAndQuestions(props) {
    let [id, setId] = useState('')
    let {
        questions,
        addQuizFn,
        sortFn,
        filter,
        isCourse,
        check,
        setCheck,
        setFilter,
        moveFn,
        historyObj,
        setQuestions,
        questionsObj,
        isResultQ2
    } = props;
    filter = filter || {};
    questions = questions || [];
    let pubName = CustomStorage.pubName;
    let qqCache;
    let ref = useRef(null)

    // isResultQ2 && console.log('qqqqqa objjjj', questions.map(it => it._id))
    useEffect(() => {
        onChangeTags(filter)
    }, [])

    useEffect(() => {
        onChangeTags(filter)
    }, [historyObj])


    function onChangeTags(filter) {
        function checkSearch(it) {
            if (!it) {
                return true;
            }
            if (!reg) {
                return true;
            }
            return reg.test(it.name) || reg.test(it._id.toString())
        }

        function checkDuplicate(it, oldValue) {
            if (oldValue) {
                return oldValue;
            }

            if (!isDuplicated) {
                return true;
            }
            if (isDuplicated == -1) {
                return !it.originalDuplicateId;
            }
            if (isDuplicated == 1) {
                return it.originalDuplicateId;
            }
        }

        function checkType(it, oldValue) {
            if (oldValue) {
                return oldValue;
            }

            if (!type) {
                return true;
            }

            return (it.type || 'question') === type
        }

        function checkApprove(it, oldValue) {
            if (oldValue) {
                return oldValue;
            }

            if (!isApprove) {
                return true;
            }
            if (isApprove == -1) {
                return !it.isForModeratorApprove;
            }
            if (isApprove == 1) {
                return it.isForModeratorApprove;
            }
        }


        let statuses = filter.statuses;
        let isDuplicated = filter.isDuplicated;
        let isApprove = filter.isApprove;
        let type = filter.type;

        setFilter({...filter})
        let tagsObj = {};
        let reg = filter.search ? new RegExp(filter.search, 'gi') : null
        let tags = filter.hashTags || [];

        _.each(tags, it => {
            let _ids = Storage.getAllChildIds(it);
            _.each(_ids, (childId, ind) => {
                tagsObj[childId] = true
            })
            tagsObj[it] = true
        })

        let statusesCount = 0;
        _.each(statuses, (item, ind) => {
            if (item) {
                statusesCount++
            }
        })
        if (statuses.all) {
            statusesCount--;
        }
        let isTags = Boolean(tags.length);

        let item = {};
        let total = 0;
        _.each(questions, (it, ind) => {

            it.sortingPriority = 0;
            let hist = (historyObj || {})[it._id] || {}
            hist.status = hist.status || ''
            if (statusesCount && !statuses[hist.status]) {
                return
            }


            if (!isTags) {
                // checkDuplicate(it, item[it._id]), checkApprove(it, item[it._id]), checkType(it, item[it._id]),
                // {type, isApprove, isDuplicated});
                it.isSearch = checkSearch(it);
                item[it._id] = it.isSearch && checkDuplicate(it, item[it._id]) && checkApprove(it, item[it._id]) && checkType(it, item[it._id])
                return;
            }

            _.each(it.hashTags, tag => {
                total += tag;
                if (tagsObj[tag]) {
                    it.isSearch = checkSearch(it);
                    let rr = it.isSearch && checkDuplicate(it, item[it._id]) && checkApprove(it, item[it._id]) && checkType(it, item[it._id]);
                    item[it._id] = item[it._id] || rr
                }
            })
        })

        questions = isResultQ2 ? questions : _.sortBy(questions, (it) => {
            let v = it.interviewsCount || .5;
            return item[it._id] ? -v : 101 - Math.min(100, v);
        })
        setQuestions([...questions])
        setCheck({...item})
    }

    historyObj = historyObj || {};

    let onEnter = (cb) =>{
        global.http.get('/theme-question/' + id).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r);
            moveFn([r])
            // setId('')
            cb && cb()
        })
    }

    return <div>
        <NFN>Select Questions</NFN> (x{questions.length || 0})
        <div>
            <TagSelector
                props={{
                    localItem: filter,
                    onChange: (value, key) => {
                        filter.hashTags = value;
                        onChangeTags && onChangeTags(filter)
                    }
                }}
            ></TagSelector>
            <div className="ib" style={{marginTop: '10px', marginBottom: '20px'}}>
                <button className={'btn btn-xs btn-default'} style={{marginRight: '15px'}} onClick={() => {
                    filter.selectAll = !filter.selectAll;
                    // filter.hashTags = [];
                    setAll(filter.selectAll, setCheck, questions)
                }}> <NFN>Select All</NFN>
                </button>
                {/*<button className={'btn btn-xs btn-default'} style={{marginRight: '15px'}} onClick={() => {*/}
                {/*    onChangeTags && onChangeTags(filter)*/}
                {/*}}> Select Tags</button>*/}
                <div className="ib">
                    {STATUSES.map((status, ind) => {
                        let it = names[status]
                        it.status = status;
                        filter.statuses = filter.statuses || {}
                        return (<button
                            className={"btn btn-xs btn-default " + (filter.statuses[it.status] ? "active" : '')}
                            key={'status' + it.status} onClick={() => {

                            if (it.status === 'all') {
                                filter.statuses = {'all': filter.statuses[it.status]};
                            } else {
                                filter.statuses.all = false;
                            }
                            filter.statuses[it.status] = !filter.statuses[it.status]
                            onChangeTags && onChangeTags(filter)
                        }}>
                            {it.name}
                        </button>)
                    })}
                </div>
                <div className="ib" style={{marginLeft: '15px'}}>
                    {[{name: 'Все', value: 0}, {name: 'Дубль', value: 1}, {
                        name: 'Оригин',
                        value: -1
                    }].map((it, ind) => {
                        return (<button
                            className={"btn btn-xs btn-default " + (filter.isDuplicated == it.value ? "active" : '')}
                            key={'duplicate' + it.value} onClick={() => {
                            filter.isDuplicated = it.value;
                            onChangeTags && onChangeTags(filter)
                        }}>
                            {it.name}
                        </button>)
                    })}
                </div>


                <Smart
                    obj={filter}
                    defSize={6}
                    items={[
                        // {name: 'Type', key: 'isDuplicated', type: 'group', list:[{name: 'Все', value: '0'}, {name: 'Дубль', value: 1}, {name: 'Оригин', value: -1}], size: 4},
                        {
                            name: 'Type',
                            key: 'isApprove',
                            type: 'group',
                            list: [{name: 'Все', key: 0}, {name: 'Модерация', key: 1}, {name: 'Аппрув', key: -1}]
                        },
                        {name: 'Type', key: 'type', type: 'group', list: ['', 'question', 'task', 'js-task']}
                    ]}
                    onChange={(v) => {
                        onChangeTags && onChangeTags(v)
                    }}
                ></Smart>


            </div>
        </div>
        <button className={'btn btn-sm btn-primary'} onClick={() => {
            let _questions = _.filter(questions, it => check[it._id])
            moveFn(_questions)
        }
        }>Переместить ({getSize(check, questions)} из {questions.length})
        </button>
        <div className="ib">
            <Input woTitle={true} woLabel={true}
                   onEnter={() => {
                       onEnter()
                   }}
                   placeholder="ID для перемещения" value={id} onChange={v => setId(v)}></Input>
            <Button onClick={(cb) => {
               onEnter(cb)

            }}>Переместить по Id</Button>
        </div>
        <div className="ib pull-right   " style={{marginTop: '-20px'}}>
            <Input placeholder={'Поиск ...'} value={filter.search} onChange={(search) => {
                console.log("qqqqq reffff", ref.current);
                filter = {...filter, search}
                setFilter(filter)

                clearTimeout(ref.current)
                ref.current = setTimeout(() => {
                    onChangeTags && onChangeTags(filter)
                }, 400)

            }}/>

        </div>

        <hr/>

        {/* {questions.map((it, ind) => {
            let hist = (historyObj[it._id] || names['']);
            return (<div key={ind}
                         className={(it.isForModeratorApprove ? 'error ' : '') + (it.originalDuplicateId ? 'o5 error' : '')}>
                    <span className="ib pull-left" style={{marginRight: '5px'}}>

                        <Link to={'/theme-question/' + it._id} target={"_blank"}><i className="fa fa-pencil"></i></Link>
                        <span
                            style={{padding: '3px'}}
                            onClick={() => {
                                check[it._id] = !check[it._id]
                                setCheck({...check})
                            }}
                        >
                            {check[it._id] ? '[ * ] ' : '[  ] '}
                            </span>
                        <span className={'label ' + hist.cl}>{hist.name}</span>
                    </span>
                <span className="ib" onClick={(scb) => {
                    moveFn([it])

                }}>{it._id} {pubName(it.name)} </span>
            </div>)
        })} */}

        <div style={{padding: '0 10px', maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden'}}>
            <ReactSortable
                // handle={".drag-handle"}
                animation={200}
                list={questions || []}
                onEnd={(evt, list) => {
                    console.log('onEnd', qqCache)
                    sortFn && sortFn(qqCache)
                }}

                setList={(_value) => {
                    // sortFn(_value)
                    qqCache = _value
                    // console.log('setList', _value)
                }}>

                {questions.filter((it, ind) => ind < 100).map((it, _ind) => {
                        if (!it || !it._id) {
                            return <div key={_ind}>EMPTY</div>
                        }
                        let hist = ((historyObj || {})[it._id] || names['']);
                        return (<div key={_ind}
                                     className={'row sort-item sort-questions rel ' + (it.isForModeratorApprove ? 'error ' : '') + (it.originalDuplicateId ? 'o5 error' : '')}
                        >
                            <div className='ib' style={{width: '30px'}}>
                                <div className={'drag-handle'} style={{display: 'inline-block'}} onClick={(e) => {
                                    e.stopPropagation();
                                    return true;
                                }}></div>
                            </div>
                            <span className="ib pull-left" style={{marginRight: '5px'}}>
             
                                     <Link to={'/theme-question/' + it._id} target={"_blank"}><i
                                         className="fa fa-pencil"></i></Link>
                                     <span
                                         style={{padding: '3px'}}
                                         onClick={() => {
                                             check[it._id] = !check[it._id]
                                             setCheck({...check})
                                         }}
                                     >
                                         {check[it._id] ? '[ * ] ' : '[  ] '}
                                         </span>
                                {!isCourse && <span className={'label ' + hist.cl}>{hist.name}</span>}
                                 </span>
                            <span className="ib" onClick={(scb) => {
                                moveFn([it])

                            }}>{it._id} [x{it.interviewsCount || 0}] {pubName(it.name)} </span>
                        </div>)
                        // let tags = (item.hashTags || []).length;
                        // let themeQuestionId = item.themeQuestionId;
                        // let isError = !tags || !themeQuestionId;
                        // item.answerLevel = item.answerLevel || '5'
                        // return <div key={'value_wrap' + '_' + _ind}
                        //             className={'row sort-item sort-questions rel'}>
                        //             <div className={'drag-handle'} style={{display: 'inline-block'}} onClick={(e) => {
                        //                 e.stopPropagation();
                        //                 return true;
                        //             }}>DragMe</div>
                        //     <div>
                        //     {item.name || '-'}
                        //     </div>
                        // </div>
                    }
                )}

            </ReactSortable>
            {/* Sort */}
        </div>

    </div>
}
function Layout3 () {
    return <div>asdf</div>
}



// export default Layout3
export default Layout2
export {USER_SPRINT_STATUSES}