import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from './Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from 'react-router-dom'

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={'headerCont'}>
      <Box sx={{ flexGrow: 1 }}>
        {/*<AppBar position="static">*/}
          <Toolbar>
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  edge="start"*/}
            {/*  color="inherit"*/}
            {/*  aria-label="menu"*/}
            {/*  sx={{ mr: 2 }}*/}
            {/*>*/}
            {/*  <MenuIcon />*/}
            {/*</IconButton>*/}
              <Link className="ib" to={'/'} style={{paddingRight: '20px', float: 'left', marginTop: '-5px'}}>
                  <img src="/logos/academy/logoShort.png" alt="" width={79}/>
              </Link>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={'top-links'}>
                {(global.CONFIG.header || []).map((it, ind) => {
                    return (<Link to={it.url} key={ind}>
                        {it.name}
                    </Link>)
                })}
            </Typography>

              <div className={'menuProfile'} onClick={handleClick}>{global.user.get_public_name() || 'Пользователь'}
                  <div className="ib caretProfile">
                      <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                           focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon">
                          <path d="M7 10l5 5 5-5z"></path>
                      </svg>
                  </div>
              </div>
              <Popover
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <Menu ></Menu>

            </Popover>
          </Toolbar>
        {/*</AppBar>*/}
      </Box>

    </div>
  );
}
