import React, { useState } from 'react';
import _ from 'underscore';
import Storage from 'libs/Storage/Storage';
import { names, config, fns, functions } from './PlanningConfig';
import './Planing.css'
import {
  Link, Outlet
} from "react-router-dom";

function TrItem({ opts, params, onClick }) {
  let { name, key, fn, childs, } = opts;
  let { periods, getValue, _onChange, setAvg } = params;
  return <tr>
    <td onClick={() => {
      onClick && onClick()
    }}>
      <small>{name} {childs && childs.length && <i className="fa fa-caret-down"></i>} </small></td>
    {periods.map((period, ind) => {
      return <td key={ind}>
        <input
          type="number"
          value={getValue(key, period, fn)} onChange={_onChange(key, period, { fn })}></input>
      </td>
    })}
    <td>
      <div className='plan-icons'>
        <i onClick={() => setAvg(key, '-')}>-</i>
        <i onClick={() => setAvg(key, '/')}>/</i>
        <i onClick={() => setAvg(key, '//')}>//</i>
        <i onClick={() => setAvg(key, '=')}>=</i>
      </div>
    </td>
  </tr>
}
function Planing(props) {
  let [values, setValues] = useState(Storage.get('values'))
  let [status, setStatus] = useState('closed')
  let [tab, setTab] = useState('sum')
  let [cd, setCd] = useState(0)
  let periods = [6, 7, 8, 9, 10, 11, 12];

  let tabs = ['all', 'sum', 'contracts', 'hire']
  function getValue(key, period) {
    return ((values || {})[key] || {})[period] || ''
  }

  function setAvg(key, type) {
    values = values || {}
    values[key] = values[key] || {};
    let vv = values[key]

    let start = +vv[periods.at(0)] || 0;
    let end = +vv[periods.at(-1)] || 0;

    let delta = end - start;
    let leng = periods.length - 1;

    _.each(periods, (period, i) => {
      values[key][period] = Math.round(type === '=' ? start
        : type === '-' ? start + i * delta / leng
          : type === '//' ? start + delta * Math.pow(i / leng, 2)
            : start + delta * Math.pow(((leng - i) / leng), 2))

    })
    values[key] = vv;
    _setValues(values);
  }

  function _setValues(values) {
    setValues(values);
    setCd(new Date());
    Storage.set('values', values);
  }

  function _onChange(key, period, { fn }) {
    return function (e) {
      let value = e.target.value;
      values = values || {};
      values[key] = values[key] || {}
      values[key][period] = value;
    //console.log('on change', key, period, fn, functions[fn])
      if (fn && functions[fn]) {
        values = functions[fn](values, periods, key, value)
      }

      _setValues(values)

    }
  }

//console.log("names", names)
  function filterNames(_names) {
    let obj = {};
    _.each(names, (name) => {
      obj[name.key] = name;
      // _.each(name.childs, __name => {
      //   obj[__name.key] = __name;
      // })
    })

  //console.log('obj', obj)
    
    return _names.map((key) => obj[key]).filter(it => it)
  }

  // 'revenue_fix',
  // 'onboarding', 
  // 'zp',
  let _names = tab === 'contracts' ? filterNames([
    'revenue',
    'profit',
    'devs',
    'load',
    'contract_time',

    // 'all_contracts_ooo',
    'all_contracts_any',
    'all_contracts_agent',
    'all_contracts_intern',
    'all_contracts_jun',
    'all_contracts_jun+',
    'all_contracts_middle',
    'all_contracts_mentor',
    'all_contracts_mtl',
    'all_contracts_tl',
    'rate',

    'korp',
    'relax_days',
    'config'
  ]) : tab === 'hire' ? filterNames([
    // 'revenue', 'profit',
    'devs',
    'hire',
    'count_screening',
    'pass_screening',
    'weeks_screening',
    'fire',
    'migrate_to',
  ]) : tab === 'all' ? filterNames([ 'revenue', 'profit',
    'devs',
    'hire',
    'count_screening',
    'pass_screening',
    'weeks_screening',
    'load',
    'contract_time',
    'fire',
    'migrate_to',
    'all_contracts_any',
    'all_contracts_agent',
    'all_contracts_intern',
    'all_contracts_jun',
    'all_contracts_jun+',
    'all_contracts_middle',
    'all_contracts_mentor',
    'all_contracts_mtl',
    'all_contracts_tl',
    'rate',

    'korp',
    'relax_days',
    'config'
  ]) : filterNames([ 'revenue', 'profit',
    'devs',
    'hire',
    'load',
    'contract_time',
    'korp',
    // 'relax_days',
    'config'
  ])


//console.log('_names', _names)
  return <div className="row plan-wrap">
    <div className="col-sm-12">
      {(tabs || []).map((tab, ind) => {
        return (<span className={'tab-item'} key={ind} onClick={() => {setTab(tab)}}>
          {tab}
        </span>)
      })}
    </div>
    <div className="col-sm-9">

      <table className='planing-page'>

        <tr>
          <td style={{ width: '120px' }} onClick={() => {
            setStatus(status === 'closed' ? 'open' : 'closed')
          }}>
            <i className="fa fa-caret-down"></i>
          </td>
          {periods.map((period, ind) => {
            return <td key={ind}>
              {period}
            </td>
          })}
          <td></td>
        </tr>



        {_names.map((it, ind) => {
          let { isOpen, childs } = it;
          return <><TrItem opts={it}
            params={{ periods, getValue, _onChange, setAvg }}
            onClick={() => {
              it.isOpen = !it.isOpen;
              setStatus('default')
              _setValues(values);
            //console.log('clickckckkc', it.isOpen)
            }}></TrItem>
            {status !== 'closed' && (status === 'open' || isOpen) && <tbody>
              {(childs || []).map((it2) => {
                return <TrItem
                  params={{ periods, getValue, _onChange, setAvg }}
                  opts={it2}></TrItem>
              })}
            </tbody>}

          </>
        })}
      </table>
    </div >
    <div className="col-sm-3">
      {/* Information */}
      <div className="row">
        <div className="planing-page">
          {config.map(({ name, key, fn }, ind) => {
            return <div key={ind} className="col-sm-6">

              <small>{name}</small>
              <input
                type="number"
                value={getValue(key, 'all')} onChange={_onChange(key, 'all', { fn })}></input>
            </div>
          })}
        </div>
      </div>
    </div>

  </div>
}


export default Planing
