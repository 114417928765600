import React, {useEffect, useState} from 'react';
import QHAdmin from "./QHAdmin";
import Textarea from "../../libs/Textarea";
import Smart from "../../libs/Smart";

function Layout2(props) {
    let [info, setInfo] = useState({});

    console.log('*........ ## Auto Interview ', props);

    let {interview = {}} = props || {};

    useEffect(() => {
        loadInterviewDetails();
    }, [interview?._id])

    let loadInterviewDetails = () => {
        interview._id && global.http.get('/load-interview-details', {_id: interview._id}).then(r => {
            let obj = r.reduce((acc, it) => {
                return {...acc, [it.quiz]: it}
            }, {})
            console.log("qqqqq set infofofofofo", obj, r);
            setInfo(obj)
        })
    }
    let onSetInfo = (_id, obj) => {
        let stat = info[_id] || {}

        setInfo({...info, [_id]: {...info[_id] || {}, ...obj || {}}})
        global.http.get('/set-quiz-answer-details', {_id: stat._id, answerDetails: obj.answerDetails || {}})

    }
    // let v = useActionData();

    return <div>
        Интервью #{interview._id}
        {(interview.quizes || []).map((it, ind) => {
            let stat = (info || {})[it] || {}
            let answerDetails = stat.answerDetails || {}
            if (!stat || !stat._id) {
                return <div>-</div>
            }
            return (<div key={ind}>
                <hr/>

                <QHAdmin item={stat}></QHAdmin>

            </div>)
        })}


    </div>
}


export const QHScore = (props) => {

    let [comment, setComment] = useState(props.comment || '')
    let [details, setDetails] = useState(props.details || {})
    let [rate, setRate] = useState(props.rate || 0)
    useEffect(() => {
        setRate(props.rate)
    }, [props.rate, props?.item?._id])
    useEffect(() => {
        setDetails(props.details)
    }, [props.details, props?.item?._id])

    let onSetDetails = (v) => {
        if (disabled) {
            return;
        }
        let _v = {...details || {}, ...v || {}}
        onChange && onChange(_v)
        setDetails(_v)

    }
    let {onChange, item, disabled} = props;

    return <>
        <div className={disabled ? 'disabled' : ''}>
            {([1, 2, 3, 4, 5] || []).map((it, ind) => {
                return (
                    <span key={ind} className={'shortTag ' + (details?.rate == it ? 'selected' : '')}
                          onClick={() => {
                              onSetDetails({rate: it})
                          }}>
                        {it}
                    </span>)
            })}
            {/*<Textarea value={details?.comment} onChange={(v) => {*/}
            {/*    onSetDetails({comment: v})*/}
            {/*}}></Textarea>*/}
            {rate && <>
                <DirectlyTextArea
                    woTitle={true}
                    woLabel={true}
                    item={item}
                    _id={item?._id}
                    placeholder={'Ваш комментарий'}
                    value={details?.growComment}
                    onChange={(v) => {
                        onSetDetails({growComment: v})
                    }}
                ></DirectlyTextArea>
            </>}
        </div>
    </>
}

// const TextareaMemo = React.memo(function (props) {
//     console.log("qqqqq rerenderrrrrrrrr", props, props.value);
//
//     return <DirectlyTextArea {...props}></DirectlyTextArea>
// }, (v1, v2) => {
//     //console.log('SMART MEMEO', v1.memo, v2.memo, v2.name)
//     return v1._id == v2._id
// })

function DirectlyTextArea (props) {

    let [value, setValue] = useState('')
    let [_id, setId] = useState('')
    let [cd, setCd] = useState(0)

    useEffect(() => {
        console.log("qqqqq TRY CHANGTE ", props._id, props.value, props);
        let _cd = new Date().getTime();
        if (props._id != _id || (_cd - cd < 500)) {
            setValue(props.value)
            setId(props._id)
            setCd(_cd)
        }
    }, [props._id, props.value])

    return <>
        <div>

        </div>
        <Textarea
            {...props}
            value={value}
            onChange={v => {
                setValue(v)
                props.onChange && props.onChange(v)
            }}
        ></Textarea></>
}


export default Layout2

