import React, {useEffect, useState} from 'react';
import {QHScore} from "./AutoInterview";
import './QHAdmin.css';
import SmartPlayer from "./SmartPlayer";
import DebugLogs from "./DebugLogs";
import NFN from "../../NFN";

const DEEP_SIZE = 5;

function QHAdmin(props) {
    console.log('*........ ## ROOT RENDER', props);
    let {item = {}, woDeep, deep} = props;
    let [dbItem, setDBItem] = useState(props.item || {})


    useEffect(() => {
        item && setDBItem(item)
    }, [item])

    let onSetDBItem = (item) => {
        setDBItem(item);
        props.item.adminDetails = item.adminDetails
        global.http.get('/send-fb', {quizHistoryId: item._id, adminDetails: item.adminDetails}).then(r => {
            setDBItem({...item, ...r.quizHistory})
            console.log("qqqqq SEND UNIQUE FEEDBACK function is created",);
        })
        console.log("qqqqq SEND HTTP REQUEST!!!!!!!", item);
    }

    let getClassName = (rate) => {
        if (!rate) {
            return 'audio-grey'
        }
        if (rate < 3) {
            return 'audio-red'
        }
        if (rate == '5') {
            return 'audio-green'
        }
        return 'audio-yellow'
    }

    let {
        _id,
        name,
        answerDetails,
        adminReviewOnReviewDetails = {},
        reviewDetails = {},
        adminDetails,
        hash,
        user,
        quiz,
        question
    } = dbItem || {}
    let text = dbItem?.recognition?.recognizedText || '-'
    // let v = useActionData();
    return <div>
        <div onClick={() => {
            console.log("qqqqq CLICK ITEMMMMMMMMMMMM", `#${_id} pfb#${dbItem.parentFB} quiz#${dbItem.quiz}`);
            // console.log("qqqqq on clickckckckckckc",);
            // myPlayer({path: `/${user}/${hash}.wav`})
        }}>
            <SmartPlayer
                user={user}
                hash={hash}
                woPlayIcon={true}
                autoPlay={props.autoPlay}
            >
                <DebugLogs>
                    #{_id} pfb#{dbItem.parentFB} quiz#{dbItem.quiz}
                </DebugLogs>
                <div title={`#${_id} pfb#${dbItem.parentFB} quiz#${dbItem.quiz}`}>
                    {!props.deep && <>
                        <div></div>
                    </>}

                    <div className="h100">
                        {props.deep && <><span className="fa fa-play-circle pull-left mr-5"
                                               style={{marginTop: '3px'}}></span></>}
                        {!props.deep && <span className={'ib pull-left'}>
                        <span className="fa fa-play-circle pull-left mr-5" style={{marginTop: '3px'}}></span>
                            {getTitleInfoName(dbItem)}</span>}
                    </div>

                    <div>
                        <div className={'recognized-text'}>
                            <strong
                                title={'Продолжительность и с.в.с = символоов в секунду'}>{Math.round(dbItem?.recognition?.recognizedDuration || 0)}с
                                / {+(dbItem?.recognition?.recognizedSpeed || 0).toFixed(1)} с.в.с</strong> {text}
                        </div>
                    </div>
                </div>
            </SmartPlayer>
        </div>


        <div className={'w100 tl tags'}>
            <small className={getClassName(answerDetails?.rate)}><NFN>Answer</NFN>: {answerDetails?.rate}</small>
            <small style={{marginLeft: '10px'}}
                   className={getClassName(reviewDetails?.rate)}><NFN>Review</NFN>: {reviewDetails?.rate}</small><small
            style={{marginLeft: '10px'}}
            className={getClassName(reviewDetails?.rate)}><NFN>RoR</NFN>: {adminReviewOnReviewDetails?.rate}</small>
        </div>

        <div></div>
        <QHScore
            details={adminDetails || {}}
            item={dbItem}
            rate={adminDetails?.rate}
            onChange={(opts) => {
                let {rate} = opts;
                console.log("qqqqq on change Rate", rate);
                onSetDBItem({...dbItem, adminDetails: {isAdmin: true, ...adminDetails || {}, ...opts}})
            }}></QHScore>
        {/*<small>Review #{reviewDetails?.maxHistId} {props.deep}</small>*/}
        {!woDeep && reviewDetails?.maxHistId && reviewDetails?.maxHistId != dbItem._id && (props.deep || 0) < DEEP_SIZE &&
            <div className={'ansSep'}
            >
                <QHAdminId histId={reviewDetails?.maxHistId} deep={(props.deep || 0) + 1}></QHAdminId>
                {/*<QHScore item={dbItem} rate={reviewDetails?.rate} disabled={true} onChange={(v) => {*/}
                {/*}}></QHScore>*/}
            </div>}

    </div>
}


export function getTitleInfoName(stat) {
    let titleInfo = stat?.titleInfo || {}
    return titleInfo.title || titleInfo.smallTitle || titleInfo.desc || stat?.name || ''
}

export const QHAdminId = (props) => {
    let [dbItem, setDBItem] = useState({})
    useEffect(() => {
        props.histId && global.http.get('/quiz-history/' + props.histId).then(r => {
            setDBItem(r)
        })
    }, [props.histId])

    props.deep == -1 && console.log("qqqqq dbItemdbItemdbItem", dbItem);
    return <>
        {dbItem && <QHAdmin item={dbItem} deep={props.deep}></QHAdmin>}
    </>
}

export const QHParentsAdminId = (props) => {
    let [items, setItems] = useState([])
    useEffect(() => {
        props.histId && global.http.get('/get-parents-fbaq', {_id: props.histId}).then(r => {
            // console.log("qqqqq itemsssss", r);
            setItems(r.items)
        })
    }, [props.histId])

    return <>
        {(items || []).map((it, ind) => {
            return (<div key={ind} className={'ansSep parentFB'}>
                Parent #{ind + 1}
                <QHAdmin item={it} woDeep={true}></QHAdmin>
            </div>)
        })}
    </>
}

export default QHAdmin
