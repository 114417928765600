import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../libs/Smart";
import './CourseModule.css';
import PreviewCourseModuleById from "./PreviewCourseModuleById";

function CourseModule({props}) {
    console.log('*........ ## CourseModule', props);
    let {item} = props;
    let selectedModule = props.item;
    let {onGlobalChange} = window;


    console.log("qqqqq item", item);
    // let v = useActionData();
    return <div>
        <Smart
            obj={item}
            defSize={12}
            items={[
                {
                    size: 4,
                    childs: [

                        {
                            size: 12, path: 'CourseModule/QuizInsideCourse',
                        }
                    ],
                },
                {
                    size: 8, path: 'CourseModule/PreviewCourseModuleById'
                },
                {
                    size: 12, path: 'CourseModule/PullQuestions'
                },
            ]}
            onChange={(v) => {
                onGlobalChange && onGlobalChange(v)
                // item.modules[selectedModuleInd] = v;
                // onGlobalChange && onGlobalChange(item);
            }
            }
        ></Smart>
    </div>
}

export default CourseModule


// {
//     size: 7, key: 'blocks',
//     sortable: true,
//     notFoundText: ' ',
//     addName: '+ Блок ',
//     each: [
//         {
//             defSize: 12,
//             defClass: 'courseEditWrap',
//             childs: [
//                 {
//                     size: 6,
//                     type: 'textarea',
//                     minRows: 1,
//                     key: 'title',
//                     name: 'Title',
//                     // isVisible: (obj) => {
//                     //     return obj.type == 'title'
//                     // }
//                 },
//
//                 {
//                     size: 2,
//                     type: 'number',
//                     minRows: 1,
//                     name: 'QuestionRunId',
//                     key: 'questionRunId',
//                     // isVisible: (obj) => {
//                     //     return obj.type == 'miniquiz'
//                     // }
{/*                }, {*/
}
{/*                    size: 4,*/
}
{/*                    type: 'textarea',*/
}
{/*                    minRows: 1,*/
}
{/*                    name: 'QuestionRunName',*/
}

//                     key: 'questionRunName',
//                     // isVisible: (obj) => {
//                     //     return obj.type == 'miniquiz'
//                     // }
//                 },
//                 {
//                     type: 'md',
//                     key: 'name',
//                     // isVisible: (obj) => {
//                     //     return (obj.type || 'md') == 'md'
//                     // }
//                 },
//                 {
//                     name: 'facts', key: 'facts', size: 6,
//                     notFoundText: ' ',
//                     addName: '++ Добавить факт ++',
//                     sortable: true,
//                     showTopAdd: true,
//
//                     defClass: 'video-list-parent',
//                     each: [
//                         {key: 'name', name: 'name', type: 'textarea', minRows: 1, size: 6},
//                         {
//                             key: 'videos',
//                             defClass: 'video-list',
//                             size: 6,
//                             sortable: false,
//                             notFoundText: ' ',
//                             addName: '+ Видео',
//
//                             each: [
//                                 {key: 'name', name: 'name', type: 'input', minRows: 1, size: 8},
//                                 {key: 'href', name: 'href', type: 'input', minRows: 1, size: 4},
//                             ]
//                         },
//                         {key: 'desc', name: 'desc', type: 'md', defClass: 'md-desc', preview: 'edit', size: 12},
//                         {type: 'HR', size: 12},
//
//                     ]
//                 },
//                 {
//                     name: 'useCases', key: 'useCases', size: 6,
//                     notFoundText: ' ',
//                     addName: '++ Добавить UseCase ++',
//                     sortable: true,
//                     showTopAdd: true,
//                     defClass: 'video-list-parent',
//                     each: [
//                         {key: 'name', name: 'name', type: 'textarea', minRows: 1, size: 6},
//                         {
//                             key: 'videos',
//                             defClass: 'video-list',
//                             size: 6,
//                             sortable: false,
//                             notFoundText: ' ',
//                             addName: '+ Видео',
//
//                             each: [
//                                 {key: 'name', name: 'name', type: 'input', minRows: 1, size: 8},
//                                 {key: 'href', name: 'href', type: 'input', minRows: 1, size: 4},
//                             ]
//                         },
//                         {key: 'desc', name: 'desc', type: 'md',  preview: 'edit', defClass: 'md-desc', size: 12},
//                         {type: 'HR', size: 12},
//
//                     ]
//                 },
//                 {size: 12, type: 'HR'}
//
//             ]
//         },
//
//     ]
// },
