import React, {useEffect, useState} from 'react';
import Train from "./Train";
import QuestionDetails from "../Suggest/QuestionDetails";
import {QuestionDetailsNew} from "../Suggest/PreviewCourseModule";


function Layout2(props) {
    let [history, setHistory] = useState(null)
    let [trainHist, setTrainHist] = useState({})

    useEffect(() => {
        // loadHistory();
        loadInterviewHistory()
    }, [])
    let feedbackHistoryId = 1192;


    let hist0 = (history || [])[0]
    function loadInterviewHistory() {
        setTimeout(() => {

            setHistory([
                {
                    // "_id": 1038,
                    // "quiz": 1038,
                    // "question": 1224,
                    // "answerType": "audio",
                    // "name": "sdafasdfasdfasdf",
                    // "time": 180
                    _id: 1002,
                    answerType: "audio",
                    name: 'aaaaa22222222',
                    time: 200,
                    question: 1224,
                    opts: {subQuestion: 1007, isExam: true},
                    historyDetails: [
                        {
                            name: 'В чем суть хок компонент',
                            answer: 'xxxxxxxxxxxxx222222',
                            hash: 11112244444,
                        }
                    ]

                }
            ])
        }, 500)

    }


    function loadHistory() {
        global.http.get('/load-my-quiz-history-details', {_id: feedbackHistoryId}).then(r => {
            r.time = r.totalTime;
            setHistory([r])
        })
    }

    function sendFeedback() {
        global.http.get('/send-user-quiz-feedback', {_id: hist0._id, hash: trainHist?.data?.hash}).then(r => {
            console.log("qqqqq csaveddddddd", r);
        })
    }
    console.log("qqqqq history", history);

    // let v = useActionData();
    return <div>
        {history?._id}
        <button onClick={() => {
            sendFeedback()
        }}> Теперь все хорошо, отправить на проверку!
        </button>
        <Train
            isExam={true}
            // url={'/exam-load'}
            getItems={() => {
                return [{
                    opts: {
                        autoInterviewId: 999,
                        quiz: 999,
                        question: 999
                    },
                    item: {
                        name: 'А ты что сам думаешь?'
                    }
                }]
            }}
            Result={Result2}
            onChange={(v) => {
                setTrainHist(v)
            }}
        >
        </Train>
    </div>
}

function Result2(props) {
    return <>
        <hr/>
        Result pge 2</>
}
function Result(props) {
    let [activeInd, setActiveInd] = useState(0)
    let {items, history, timers} = props;
    let activeItem = items[activeInd] || {};
    console.log("qqqqq propssss RESULT PAGE", props, activeItem);

    function getGoodCount() {
        let count = 0;
        _.each(history, (item, ind) => {
            if (item.isCorrect) {
                count++
            }
            if (item.rate == 5) {
                count++
            }
        })

        return count;


    }


    return <div>
        <div style={{fontSize: '24px', marginBottom: '10px'}}>Результаты</div>

        <div>Отвечено на 5: {getGoodCount()}</div>
        <div>Всего вопросов: {items.length}</div>
        <Button onClick={(cb) => {
            cb && cb()
            window.reloadTrain && window.reloadTrain()
        }}>Перезапустить тренировку</Button>
        <hr/>
        <div className="row">
            <div className="col-sm-4">
                {(items || []).map((it, ind) => {
                    return (<div key={ind} onClick={() => {
                        setActiveInd(ind)
                    }}>
                        {it.name}
                    </div>)
                })}
            </div>
            <div className="col-sm-8">
                <QuestionDetailsNew
                    answerSubType={'results'}
                    withoutShow={true} showName={true}
                    // question={activeItem}
                    questionId={activeItem.question}
                ></QuestionDetailsNew>

                {/*<QuestionDetails*/}
                {/*    */}
                {/*    questionId={activeItem.question}></QuestionDetails>*/}
            </div>
        </div>


    </div>
}


export default Layout2
