import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import QuizPreview from "./QuizPreview";
import './RenderQuizResults.css';
import {isCorrectQuizFn} from "./RunQuiz";
import {Feedback} from "../Stats/CoursesStats";

function splitIntoChunks(arr, chunkSize) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
}

export function isDebugIds() {
    let isDebugIds = Storage.get('isDebugIds')
    return !!(isDebugIds);
}

export const isDebugLogsFn = isDebugIds;

export function isKuratorCorrectQuiz(exam, quiz) {
    let quizId = (quiz || {})._id || quiz;
    return ((exam.kuratorRates || {})['quiz_' + quizId] || {}).rate >= 4
}

function RenderQuizResults(props) {

    // let [quiz, setQuiz] = useState({})
    let [cd, setCd] = useState(0);
    let [selectedInd, setSelectedInd] = useState(1)
    let [dbQuizes, setDbQuizes] = useState({})
    let {exam, history, onChangeExam} = props;
    let examHist = ((history['-1'] || {}).quizHistory || {}).history || {};
    let chunkSize = 4;
    let quiz = {...(exam.quizQuestionsPlain || [])[selectedInd] || {}}
    quiz.answerType = quiz.answerType || 'quiz'
    let hist = examHist[quiz._id] || {}
    // let dbHist = (dbQuizes || {})[quiz._id] || {}

    useEffect(() => {
        // global.http.get('/load-exam-hists', {exam: exam._id}).then(r => {
        //     console.log("qqqqq rrrrr", r);
        //
        //     setDbQuizes(r.reduce((acc, it) => {
        //         let _id = it.quiz;
        //         return {...acc, [_id]: acc[_id] || it}
        //     }), {})
        // })
    }, [])

    let isDebugLogs = isDebugLogsFn()
    console.log("qqqqq histhist", dbQuizes, hist, quiz._id);
    return <>
        {!!exam && !!exam.quizQuestionsPlain && !!exam.quizQuestionsPlain.length && <div className={'quizResults'}>
            {/* История тестов. Выполнено правильно {(exam.scoreDetails || {}).quizPerc || 0}% */}
            {/* <hr/> */}
            <hr/>

            {/*{(exam.quizQuestionsPlain || []).map((it, ind) => {*/}
            {/*    return (<div key={ind}>*/}
            {/*        !! :{it._id}*/}
            {/*    </div>)*/}
            {/*})}*/}


            <div className="row">
                <div className="col-sm-4 fbList2">
                    {/*{splitIntoChunks(exam.quizQuestionsPlain || [], chunkSize).map((items, ind) => {*/}
                    {/*    return <div className={'row'}>*/}
                    {/*        <div className="col-sm-12" style={{marginTop: '10px'}}></div>*/}
                    {(exam.quizQuestionsPlain || []).map((quiz, ind) => {
                        let history = (examHist[quiz._id] || {})

                        let is999 = ((examHist[quiz._id] || {})?.chosen || {})[999]
                        let isCorrect = isKuratorCorrectQuiz(exam, quiz) || history.isCorrect || isCorrectQuizFn(history.chosen, quiz.variations)
                        let userRate = (props.exam.userRates || [])[`quiz_` + quiz._id];

                        let isAudio = isAnyAudio(quiz)
                        let isCode = isCodeFN(quiz)
                        let isUploaded =  history?.recognizedDuration
                        let isAnswered = isAudio ? isUploaded : !is999 && Boolean(_.size((examHist[quiz._id] || {}).chosen) || history.hash);

                        let isOk = isAudio ? isUploaded : false;
                        let kuratorRate = ((exam.kuratorRates || {})[`quiz_${quiz._id}`] || {}).rate;

                        return (<div key={ind}
                                     onClick={() => {
                                         setSelectedInd(ind)
                                         myPlayer({path: `/audio/${exam.user}/${history.hash}.wav`})
                                         console.log("qqqqq quizzzzzzzzzzzzz", isAudio, quiz?.answerType, quiz);
                                     }}
                                     style={{padding: '5px 20px'}}
                                     className={(ind === selectedInd ? 'activeList' : '') + ' listItem ellipses'}>
                            <div>
                                {isOk && <div className={"label " + (kuratorRate ? "label-default" : 'label-danger')}>{kuratorRate || 'НЕОБХОДИМО ОЦЕНИТЬ'}</div>}

                                {isAudio && !isUploaded && <>
                                <div className="label label-info">НЕ ЗАГРУЖЕНО</div>
                                </>}
                                {isAudio && <>
                                    <div className="label label-primary">{isCode ? 'КОД' : 'АУДИО'}</div>
                                </>}
                                {!isAnswered && <>
                                    <div className="label label-info">Не отвечен</div>
                                </>}
                                {!isAudio && isAnswered && <>
                                    {isCorrect && <div className="label label-success">{'Правильно'}</div>}
                                    {!isCorrect && <div className="label label-danger">Не правильно</div>}
                                </>}

                                <div className="tagDetails ib">
                                    <div className="ib" title={'Скорость'}>
                                        {history.recognizedSpeed}
                                    </div>
                                    <div className="ib" title={'Оценка'}>
                                        {history.rate}
                                    </div>
                                    <div className="ib" title={'Продолжительность'}>
                                        {history.duration}
                                    </div>
                                </div>
                            </div>
                            <div className="ib w100">
                                <div className="ellipse w100">{getQuizAnyName(quiz)}</div>
                            </div>
                            {userRate && <div>
                                Rate: {userRate.rate} CodeRate: {userRate.codeRate}
                            </div>}
                        </div>)
                    })}
                </div>
                {/*})}*/}
                {/*</div>*/}
                <div className="col-sm-8">

                    {quiz.answerType !== 'quiz' && <Feedback
                        it={{adminFeedback: (exam.kuratorRates || {})[`quiz_${quiz._id}`]}}
                        url={'/rate-exam'}
                        isCode={false}
                        woNames={true}
                        filter={{hash: hist.hash, user: exam.user, examId: exam._id, quizId: quiz._id}}
                        onChangeExam={onChangeExam}
                    ></Feedback>}
                    <QuizPreview quiz={quiz}
                                 skipBottomOpenText={true}
                                 exam={exam}
                                 // dbHist={dbHist}
                                 history={{...hist, isSubmit: true,}}
                                 onSubmit={(chosen) => {
                                 }}
                    ></QuizPreview>
                    <hr/>
                </div>
            </div>

        </div>}
    </>
}

function isAnyAudio (quiz) {
    let {answerType} = quiz || {};
    return /audio|code/gi.test(answerType || '')
}
function isCodeFN (quiz) {
    let {answerType} = quiz || {};
    return /code/gi.test(answerType || '')
}


export function getQuizAnyName(quiz) {
    return getQuizName(quiz) || quiz?.specialTitle || quiz?.specialName
}

export function getQuizName (item) {

    let {answerType = 'quiz'} = item;
    if (answerType === 'quiz') {
        return item.name;
    } else if (answerType === 'audio') {
        return item.audioName
    } else if (answerType === 'code') {
        return item.codeName
    } else {
        return 'Unknown Name!!'
    }
}

export default RenderQuizResults
