import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import PreviewCourseModule from "../Suggest/PreviewCourseModule";
import NFN from "../../NFN";


function PreviewCourseModuleById({props}) {
    let [data, setData] = useState({});
    let [selectedBlockInd, setSelectedBlockInd] = useState(0)
    let moduleId = (props.item || {})._id;


    useEffect(() => {

        global.http.get('/load-course-module/' + moduleId)
            .then(r => {
                setData({...r.item, questions: r.questions})
            })
    }, [props.item])

    let {questions} = data || {};

    return <div>
        <small><NFN>Preview Course Module Wrapper</NFN> {moduleId}</small>
        <div className="fbList2">

            {(questions || []).map((it, ind) => {
                return <div key={ind}
                            onClick={() => setSelectedBlockInd(ind)}
                            style={{
                                background: ind == selectedBlockInd ? 'rgba(0, 255, 0, .15)' : 'transparent',
                                padding: '2px 5px'
                            }}
                >
                    <a><Link className="fa fa-pencil mr-5" to={'/theme-question/' + it._id}></Link>{it.name}</a>
                </div>
            })}
        </div>
        <div className="fbList2">
            <PreviewCourseModule

                qHistory={{}}
                questions={(data || {}).questions}
                courseUserId={0}
                isAdmin={true}
                moduleId={data._id}
                selectedBlockInd={selectedBlockInd}

                onChangeQHistory={(r) => {
                }
                }
                onChangeMHistory={(r) => {
                }
                }
                onOpenNextModule={() => {
                }
                }
                // questions={selectedModule.questions.map(_id => dbQuestionsObj[_id])}

            ></PreviewCourseModule>
        </div>
    </div>
}

export default PreviewCourseModuleById
