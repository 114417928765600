import React, {useState} from 'react';

function Layout2(props) {
//console.log('*........ ## ROOT RENDER', props);
  let go = () => {
  //console.log("qqqqq  agogogogogo", );
    global.http.get('/interview-question?search=&per_page=1000&page=1&sort=_id&direction=desc&&sub_id=&filters%5B0%5D=name&filters%5B1%5D=type&filters%5B2%5D=additionalQuestions&filters%5B3%5D=themeQuestionId&filters%5B4%5D=themePlain').then(r => {
    //console.log("qqqqq crrrrrr", r);
    })
  }
  // let v = useActionData();
  return <div>
    DashboardPage
  </div>
}

export default Layout2
