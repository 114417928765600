import React, {useEffect, useState} from 'react';
import tm from './TrainMethods';
import './Train.css';
import Input from "../../libs/Input";

function Inp(props) {
    let {field, filter, setFilter} = props;
    filter = filter || {}
    return <>
        {filter[field]}
        {field}
        <input key={'filter' + field} value={filter[field]}
               onChange={v => setFilter({...filter, [field]: v.target.value})}></input>
    </>
}


function Layout2(props) {
  //console.log('*........ ## ROOT RENDER', props);
    let __train_details = Storage.get('__train_details') || {}
    let [filter, setFilter] = useState(__train_details.filter || {})
    let [info, setInfo] = useState(__train_details.info || {})
    let [res, setRes] = useState({})
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        reloadAll()
    }, [])
    useEffect(() => {
        reloadAll()
    }, [info.statsUser])

    useEffect(() => {
        Storage.set('__train_details', {info, filter})
    }, [filter, info])

    async function reloadAll(cb) {
        setLoading(true)
        Promise.all(
            [
                tm.getLastAudio(info.audio),
                tm.getLastFeedbacks(info.feeedback),
                tm.getLastAQH(info.aqh),
                tm.getLastAQ(info.aq),
                tm.getUserStats({user: info.statsUser}),
            ]
        ).then(([audio, feedbacks, aqh,
                                   aq, stats]) => {
            let info = {audio, feedbacks, aqh, aq, stats}
            setRes(info)
            console.log("qqqqq lst audio", info);
            cb && cb()
            setLoading(false)
        })
    }


    function getLastAudio() {
        // tm.getLastAudio(info.total).then()
        return global.http.get('/load-hist', {}).then(r => {

        })

    }

    function _Inp({field}) {
        return <Inp field={field} filter={filter} setFilter={setFilter}></Inp>
    }


    function isSearch(it, key) {
        let value = (filter || {})[key];
        if (!value) {
            return true;
        }
        return it._id.toString().indexOf(value) > -1

    }
    async function emit (key) {
        let obj = {
            async crtQuizHistory() {


            },
            async updQuizHistory() {
                let _info = {
                    user: info.user,
                    opts: {
                        autoInterview: info.autoInterviewId,
                        quiz: info.quizId,
                        parentFB: info.parentFB || '',
                        parentAQ: info.parentAQ || '',
                    },
                    "data": {
                        correctAnswer: 'correctAnswer',
                        selectedAnswer: 'correctAnswer!!!',
                        "hash": info.hash,
                        sessionHash: info.sessionHash,
                        "recognizedText": "--",
                        "recognizedSpeed": "2.80",
                        "recognizedDuration": 0.9,
                        "recognizedTextSize": 2,
                        "attempt": 1,
                        "duration": 0,
                        "rate": info.rate,
                        "growTags": [],
                        "growComment": ""
                    },
                    "timer": {
                        "time": 200,
                        "totalTime": 200,
                        "deltaPerc": 0.5,
                        "perc": 100
                    },
                };
                await tm.updateQuizHistory(_info)
                await tm.updateQuizHistory2(_info)
            },
            async giveFeedback() {
                await tm.sendFB({
                    quizHistoryId: info.quizHistoryId,
                    user: info.quizUser,
                    adminDetails: {
                        rate: info.quizRate,
                        isAdmin: !!(+info.isAdmin),
                        growComment: info.quizName
                    },
                })

            },
            async giveAQ() {
                await tm.sendAQ({
                    quizHistoryId: info.quizHistoryId,
                    user: info.quizUser,
                    name: info.quizName,
                    adminDetails: {
                        isAdmin: !!(+info.isAdmin),

                    }

                })

            },
        }

        let v = obj[key] && await obj[key]();
        console.log("qqqqq vvvv", v);
        reloadAll();
    }




    console.log("qqqqq cinfofofofof", info);
    let REPLACER = 2;
    // let v = useActionData();
    return <div>
        <div style={{opacity:  (loading ? .5 : 1)}}>
            Train Page integration
            <div></div>
            <Button color={4} size={'xs'} onClick={reloadAll}>Reload All</Button>

        </div>
        <div className={"row "} >
            <div className="col-sm-3">
                {(['quizId', 'parentFB', 'parentAQ', 'hash', 'sessionHash',  'user', 'rate', 'autoInterviewId'] || []).map((it, ind) => {
                    return (<div key={ind}>
                        <Input
                            type={'number'}
                            placeholder={it}
                            value={info[it]} onChange={(v) => setInfo({...info, [it]: v})}></Input>
                    </div>)
                })}
            </div>

            <div className="col-sm-3">
                {(['quizHistoryId', 'quizName', 'quizRate', 'quizUser', 'isAdmin'] || []).map((it, ind) => {
                    return (<div key={ind}>
                        <Input
                            type={'number'}
                            placeholder={it}
                            value={info[it]} onChange={(v) => setInfo({...info, [it]: v})}></Input>
                    </div>)
                })}
            </div>
            <div className="col-sm-6">
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{marginTop: '15px'}}></div>
                        {/*{(['crtQuizHistory', 'updQuizHistory'] || []).map((it, ind) => {*/}
                        {(['updQuizHistory'] || []).map((it, ind) => {
                            return (<div key={ind}>
                                <button onClick={() => {emit(it)}}>
                                    {it}
                                </button>
                            </div>)
                        })}
                        <div><hr/></div>
                        {(['giveFeedback', 'giveAQ'] || []).map((it, ind) => {
                            return (<div key={ind}>
                                <button onClick={() => {emit(it)}}>
                                    {it}
                                </button>
                            </div>)
                        })}
                    </div>
                    <div className="col-sm-6">
                        {(['statsUser'] || []).map((it, ind) => {
                            return (<div key={ind}>
                                <Input
                                    type={'number'}
                                    placeholder={it}
                                    value={info[it]} onChange={(v) => setInfo({...info, [it]: v})}></Input>
                            </div>)
                        })}
                    </div>
                </div>
                <hr/>
                <div></div>
                <pre style={{maxHeight: '500px'}}>
                    {JSON.stringify(res.stats, null, 2)}
                </pre>
            </div>
            <div className="col-sm-12">
                <hr/>
                <Button color={4} size={'xs'} onClick={reloadAll}>Reload All</Button>
                <Button color={4} size={'xs'} onClick={(cb) => {
                    cb && cb();
                    setFilter({})
                }}>Reset Filters</Button>
            </div>


            <div className="col-sm-3">
                <div>Audio List:</div>
                <Inp field={'filterAudio'} filter={filter} setFilter={setFilter}/>
                {(res.audio || []).map((it, ind) => {
                    let _isSearch = isSearch(it, 'filterAudio')
                    if (!_isSearch) {
                        return <div key={ind}>NOT FOUND {it._id}</div>
                    }
                    return (<div key={ind}>
                        {(['_id', 'rate', 'parentAQ', 'parentFB', 'parentsAQ', 'parentsFB'] || []).map((key, ind) => {
                            return (<div key={ind}>
                                <small>{key}:<b> {it[key]}</b></small>
                            </div>)
                        })}

                        <pre className={'trainPre'}>{JSON.stringify(it, null, REPLACER)}</pre>
                        <hr/>
                    </div>)
                })}


            </div>
            <div className="col-sm-3">
                <div>Feedbacks List:</div>
                <Inp field={'filterFb'} filter={filter} setFilter={setFilter}/>

                {(res.feedbacks || []).map((it, ind) => {
                    let _isSearch = isSearch(it, 'filterFb')
                    if (!_isSearch) {
                        return <div key={ind}>NOT FOUND {it._id}</div>
                    }
                    return (<div key={ind}>
                        {it._id}
                        {it.name}
                        <pre className={'trainPre'}>{JSON.stringify(it, null, REPLACER)}</pre>
                        <hr/>
                    </div>)
                })}
            </div>
            <div className="col-sm-3">
                <div>AQH list:</div>
                <Inp field={'filterAQ'} filter={filter} setFilter={setFilter}/>

                {(res.aqh || []).map((it, ind) => {
                    let _isSearch = isSearch(it, 'filterAQ')
                    if (!_isSearch) {
                        return <div key={ind}>NOT FOUND {it._id}</div>
                    }
                    return (<div key={ind}>
                        {it._id}
                        <div></div>

                        {it.name}
                        <pre className={'trainPre'}>{JSON.stringify(it, null, REPLACER)}</pre>
                        <hr/>
                    </div>)
                })}
            </div>
            <div className="col-sm-3">
                <div>AQ list:</div>
                <Inp field={'filterAQH'} filter={filter} setFilter={setFilter}/>

                {(res.aq || []).map((it, ind) => {
                    let _isSearch = isSearch(it, 'filterAQH')
                    if (!_isSearch) {
                        return <div key={ind}>NOT FOUND {it._id}</div>
                    }
                    return (<div key={ind}>
                        {it._id}
                        <div></div>
                        {it.name}
                        <pre className={'trainPre'}>{JSON.stringify(it, null, REPLACER)}</pre>
                        <hr/>
                    </div>)
                })}
            </div>
        </div>
    </div>
}


export default Layout2
