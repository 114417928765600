import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import ContributorsList from './Suggest/ContributorsList'
import {userGrades} from './Suggest/ContributorsList'

import {
    Link, Outlet
} from "react-router-dom";
import CustomStorage from './Suggest/CustomStorage';
import Table from "../libs/Table";
import Smart from "../libs/Smart";
// import StarRating from './StarRating/App.js';
let user = global.user;
let QuestionTabs = [
    {
        name: 'Name', key: 'name', component: ({item}) => {
            return <div>{CustomStorage.pubName(item.name)}</div>
        }
    },
    {
        name: 'CScore', key: 'contributorsScore', component: ({item}) => {
            return <small>x{item.contributorsScore || 0}
                <div></div>
                <ContributorsList item={item} size={20} limit={5}></ContributorsList>
            </small>
        }
    },
    {name: 'Lock?', key: 'lockData.cd', type: 'date'},
    {name: 'comment', key: 'comment',},
    {name: 'IntCount', key: 'interviewsCount'},
    {name: 'Полнота ответа', key: 'answerProgressCount'},
    {name: 'Type', key: 'type'},
    {name: 'Difficulty', key: 'difficulty'},
    {name: 'SuggestCount', key: 'suggestCount'},
    {name: 'Facts', key: 'factsLength'},
    {name: 'UseCases / LC solutions', key: 'useCasesLength'},
    {name: 'quizCount', key: 'quizCount'},
    {name: '_id', key: '_id'},

];

function parseListForApprove(r) {
    let userId = user.get_id();
    let cd = new Date().getTime();

    function timeAgo(it) {
        let item = (it.contributors || []).filter(it => it.user == userId)[0]
        if (!item) {
            return {contrDelta: -1, contrStr: '---'}
        }

        let delta = Math.round((cd - item.cd) / (24 * 3600 * 1000))
        return {
            contrDelta: delta, contrStr: delta < 1 ? '< 1d' : delta + 'd'
        }
    }

    function pub(questions) {
        return [...questions.map(it => {
            return {...it, ...timeAgo(it)}
        })]
    }

    r.questions = pub(r.questions)
    r.questions2 = pub(r.questions2).sort((a, b) => {
        return a.contrDelta > b.contrDelta ? 1 : a.contrDelta == b.contrDelta ? 0 : -1
    })
    return r;
}

function Layout2(props) {
    function ago(mins) {
        return new Date().getTime() - mins * 1000 * 60;
    }
    let contribList = [{name: 'Все', key: 'all'}, {name: 'Пусто', key: {$exists: false}}, {
        name: '5-',
        key: {$lte: 5}
    }, {name: '500-', key: {$lte: 500}}, {name: '500+', key: {$gte: 500}}];
    let answerProgress = [{name: 'Все', key: 'all'}, {
        name: '1+',
        key: {$gte: 1}
    }, {name: '1', key: '1'}, {name: '2', key: '2'}, ];
    let suggestList = [{name: 'Все', key: 'all'}, {
        name: '1+',
        key: {$gte: 1}
    }, {name: '1', key: '1'}, {name: '2', key: '2'}, ];
    let interviewsList = [{name: 'Все', key: 'all'}, {name: '1+', key: {$gte: 1}}, {name: '3+', key: {$gte: 3}}];
    let quizList = [{name: 'Все', key: 'all'}, {name: '3+', key: {$gte: 3}}, {name: '7+', key: {$gte: 3}}];
    let lockDataList = [{name: 'Все', key: 'all'}, {name: '15+ мин', key: '15'}, {name : '60+ мин', key: '60'}, {name : '60- мин', key: '-60'}];
    let [data, setData] = useState(null);
    let [query, setQuery] = useState({
        contributorsScore: contribList[1].key,
        answerProgressCount: {$gte: 1},
        suggestCount: 'all',
        interviewsCount: {$gte: 1},
        quizCount: 'all',
        'lockData': lockDataList[0].key,

    });
    let [cd, setCd] = useState(0);

    useEffect(() => {
        loadData(query)
    }, [])

    function loadData(query, ms) {
        _.each(query, (item, ind) => {
            if (item === '' || item === 'all') {
                delete query[ind]
            }
        })


      //console.log("qqqqqqccxvzxcvzxcvzxcv", query)
        global.http.get('/list-for-approve', {customQuery: query}).then((r) => {
            let vv = {...parseListForApprove(r)};
            setData(vv)
            if (ms) {
                setTimeout(() => setCd(new Date().getTime()), ms)
            }
        })
    }


    if (!data) {
        return <></>
    }

    console.log("dataatatat", data)
    // let v = useActionData();
    let alreadyIn = {};
    let _data = user.get_info();
    let cData = (_data || {}).customData || {}

    return <div>
        <div className='row'>

            {/*<StarRating></StarRating>*/}
            <div className="col-sm-12">
                {/*<pre>{JSON.stringify({..._data.customData, hrData: null}, null, 4)}</pre>*/}
                <div>Выбранные теги: {cData.isApprovedAll ? 'Все' : <>
                    <div className={'ib'}>{(cData.approvedTags || []).join(", ")}</div>
                </>}</div>     <div>Игнорирование тегов: <>
                    <div className={'ib'}>{(cData.ignoreTags || []).join(", ")}</div>
                </></div>
                <div>Ваш грейд: <>
                    <div className={'ib'}>{userGrades[cData.userGrade]}</div>
                </></div>
                <Link to={'/users/' + _data._id}>Редактировать свои теги</Link>

                <hr/>
            </div>
            <div className="col-sm-12 remCol">
                {/*{name: 'Lock?', key: 'lockData.cd', type: 'date'},*/}
                {/*{name: 'Полнота ответа', key: 'answerProgressCount'},*/}
                {/*{name: 'Type', key: 'type'},*/}
                {/*{name: 'Difficulty', key: 'difficulty'},*/}
                {/*{name: 'SuggestCount', key: 'suggestCount'},*/}
                {/*{name: 'Facts', key: 'factsLength'},*/}
                {/*{name: 'UseCases / LC solutions', key: 'useCasesLength'},*/}
                {/*{name: 'InterviewsCount', key: 'interviewsCount'},*/}
                {/*{name: 'quizCount', key: 'quizCount'},*/}
                {/*{name: '_id', key: '_id'},*/}

                <Smart
                    obj={query}
                    items={[

                        {label: 'Контрибьютеры', type: 'group', key: 'contributorsScore', list: contribList},
                        {label: 'Полнота ответа', type: 'group', key: 'answerProgressCount', list: answerProgress},
                        {label: 'Саджест каунт', type: 'group', key: 'suggestCount', list: suggestList},
                        {label: 'Интервью каунт', type: 'group', key: 'interviewsCount', list: interviewsList},
                        {label: 'Квиз каунт', type: 'group', key: 'quizCount', list: quizList},
                        {label: 'Лок Время', type: 'group', key: 'lockData', list: lockDataList},

                    ]}
                    onChange={(v) => {
                        console.log("qqqqq vvvvvvvvvvvvvvvvvvvvvvvvvv", v);
                        setQuery({...v})
                        loadData(v, 10)
                    }
                    }
                ></Smart>
                <hr/>

            </div>
            <div className='col-sm-12 mt10'>
                <strong>Самые актуальные</strong>
                <div className="mt10">
                    <RenderList items={data.questions}></RenderList>
                </div>
                {/*{data.questions.map((it, ind) => <QPreview alreadyIn={alreadyIn} it={it} ind={ind}></QPreview>)}*/}
            </div>
            <div className='col-sm-12'>
                <strong>Дополнительные</strong>
                <div className="mt10">
                    <RenderList items={data.questions2}></RenderList>
                </div>

            </div>
            <div className='col-sm-12'>
                <strong> Некоторые мои контрибьюты</strong>
                <div className="mt10">
                    <RenderList items={data.questionsMy}></RenderList>
                </div>
            </div>
        </div>
    </div>
}

function RenderList(props) {
    let {items} = props;
    console.log("RENDER LIST", items)
    return <Table
        woHash={true}
        opts={{
            per_page: 5,
            tabs: QuestionTabs,
            onClickItem: (item) => {
                global.navigate && global.navigate('/theme-question/' + item._id)
                console.log("clickckckckc", item)
            }
        }}
        items={items}
    ></Table>
}


function QPreview({it, ind, alreadyIn}) {
    let userId = user.get_id();

    function getTime() {
        // let item = it.contributors.filter(it => it.userId ==) '---'
        return it.contrStr;
    }

    if (alreadyIn[it._id]) {
        return null;
    }
    alreadyIn[it._id] = true;

    return <div key={ind} style={{borderBottom: '1px solid #00000010'}}><Link
        style={{display: 'block', marginTop: '10px'}}
        className="row" to={'/theme-question/' + it._id}>


        <div className='col-sm-12'>
            {it.title || CustomStorage.pubName(it.name)}
        </div>

        <div className='col-sm-12' style={{padding: '10px'}}>
            <ContributorsList size={20} item={it} limit={5}></ContributorsList>

        </div>
        <div className='col-sm-12'>
            <div className='qWrap'>
                {it.contrDelta < 0 && <small className='label label-success'
                                             style={{marginRight: '5px', textDecoration: 'underline'}}>new</small>}
                <small className='label label-default' title={`Кол-во контента`}>x{it.answerProgressCount}</small>
                {it.contrDelta >= 0 && <small className='label label-default'>Дата: {getTime()}</small>}
                <small className='label label-default'>#{it._id}</small>
            </div>
        </div>


    </Link>
    </div>

}

export {QuestionTabs}
export default Layout2
