import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import UserSelector from "../libs/UserSelector";
import Editor from "@monaco-editor/react";
import NFN from "../NFN";


function Layout2({props}) {
    let [user, setUser] = useState(0);
    let [data, setData] = useState({loading: true});

    let {localItem} = props;
  //console.log('*........ ## ROOT RENDER Assign Template', props);
    // Component: ({item, localItem}) => {
    //
    function getData() {
        if (!user) {
            global.notify.error('Выберите польователя')
        }
        return {userId: user || 0, templateId: localItem._id}

    }

    function setRes(r) {
        setData(r)

    }


    return <div>
        <UserSelector
            value={user}
            onChange={(v) => {
              //console.log("qqqqq vvv", v);
                setUser(v)
            }
            }
        ></UserSelector>


        <div className={'btns-wrap'}>
        <button className='btn btn-sm btn-success' onClick={() => {
            global.http.get('/turm-on-user-hr-template', getData()).then(setRes)
        }}>Добавить текущий
        </button>
        <button className='btn btn-sm btn-success' onClick={() => {
            global.http.get('/turm-off-user-hr-template', getData()).then(setRes)
        }}>Удалить текущий
        </button>
        <button className='btn btn-sm btn-default' onClick={() => {
            global.http.get('/turm-off-all-except-user-hr-template', getData()).then(setRes)
        }}>Удалить все, но добавить текущий
        </button>
        <button className='btn btn-sm btn-default' onClick={() => {
            global.http.get('/turm-off-all-user-hr-template', getData()).then(setRes)
        }}>Удалить все
        </button>
        <div>
            <div><NFN>userId</NFN>: <Link to={'/users/' + user}>{user}</Link></div>
            <div><NFN>templateId</NFN>: {localItem._id}</div>
        </div>
        </div>
        <hr/>

        <Editor
            options={{domReadOnly: true}}
            height="500px"

            language={'json'}
            value={JSON.stringify(data, null, 4)}
        >

        </Editor>
    </div>

}

export default Layout2
