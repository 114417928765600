import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../libs/Button";
import {PartnerSelector, UserSaleSel} from "./Interview/Interview";
import Smart from "../libs/Smart";
import Input from "../libs/Input";
import {DayPicker} from "./Stats/UserStatsOld";
import UserNewSel from "./Suggest/UserNewSel";


function Layout2(props) {
    let [items, setItems] = useState([]);
    let [item, setItem] = useState({});
    let [selectedInd, setSelectedInd] = useState(0);
    let [loading, setLoading] = useState(false);
    let [filter, setFilter] = useState({});
    let [search, setSearch] = useState('');


    useEffect(() => {
        loadItems()
    }, [])

    function loadItems() {
        setLoading(true)
        global.http.get("/my-consideration", filter).then(r => {
          //console.log("rrrrr", r)
            setItems(r.items)
        })
    }

    function onSetItem(item) {
        setItem({...item})
        global.http.put("/my-consideration", item).then(r => {
          //console.log("rrrrr Updated", r)
        })
    }


    item = (items || [])[selectedInd] || {}

  //console.log('*........ ## ROOT RENDER', props);

    return <div className='row'>
        <div className='col-sm-4'>
            Filters
            <small>Выбор даты От до </small>
            <PartnerSelector item={filter} field={{key: 'partner'}}/>
            <PartnerSelector item={filter} field={{key: 'client'}}/>
            <UserNewSel item={filter} field={{key: 'client'}}/>
            <Input type="text" value={filter.search} onChange={(search) => {
                setFilter({...filter, search})
            }} title={'Search ...'}/>
            {items.map((it, ind) => {
                return <div
                    className={ind === selectedInd ? 'active' : ''}
                    onClick={() => {
                        setSelectedInd(0)
                    }}
                    key={ind}>{it.name || '-'} #{it._id} {it.status}</div>
            })}
        </div>
        <div className='col-sm-8'>
            Info
            <Smart obj={item} items={considerationEdit} onChange={(v) => {
              //console.log("qqqqq vvvvv", v);
                onSetItem(v)
            }}></Smart>

        </div>

    </div>
}

let considerationEdit = [
    {
        name: 'Статус',
        size: 2,
        key: 'status',
        type: 'select',
        items: ['waiting', 'assign_interview', 'reject']
    },
    {name: 'Напомнить проверить результат', size: 2, key: 'remindCd', type: 'date'},
    {name: 'Дата отправки', size: 2, key: 'cd', type: 'date'},
    {
        isVisible: (item) => {
            return item.status === 'reject'
        },
        name: 'Причина',
        size: 2,
        key: 'reason',
        type: 'select',
        items: ['continue_with_other', 'bad_skills', 'closed_job', 'no_response']
    },
    {name: 'Коммент', size: 4, key: 'comment', type: 'textarea'},
    {
        isVisible: (item) => item.status === 'assign_interview',
        Component: () => {
            let interview = {};

            return <div>
                <Button color={3}>Добавить интервью</Button>
                <div>
                    <DayPicker title={'Дата интервью'}></DayPicker>
                    <Link to={'/interview/' + interview._id}>Edit interview</Link>
                </div>
            </div>
        },
        size: 12,
    },

    {type: "HR", size: 12},
    {name: 'partner', size: 3, key: 'partner', Component: PartnerSelector},
    {name: 'client', size: 3, key: 'client', Component: PartnerSelector},
    {name: 'sale', size: 3, key: 'sale', Component: UserSaleSel},
    {name: 'user', size: 3, key: 'user', Component: UserSaleSel},
    {
        size: 6, childs: [
            {name: 'cv', size: 6, key: 'cv'},
            {name: 'channel', size: 6, key: 'channel'},

            {name: 'jobDesc', size: 6, key: 'jobDesc', type: 'textarea'},

        ]
    },
    {type: 'HR', size: 12,},
    {
        name: 'history', size: 12, key: 'history',
        addName: '+ History',
        each: [
            {name: 'Суть', type: 'textarea', size: 3},
            {name: 'Переписка', type: 'textarea', size: 5},
            {name: 'Дата', type: 'date', size: 2},
            {name: 'След Дата', type: 'nextDate', size: 2},
        ]
    },

    {type: 'HR', size: 12,},
]
export {considerationEdit}
export default Layout2
