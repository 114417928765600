
function isEqualFN(data1, data2) {
    return JSON.stringify(data1) === JSON.stringify(data2)
}

function diffLetters(obj1, obj2) {
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    return [...allKeys].reduce((acc, key) => {
        if (/total|totalLetters|special|lineTabs/gi.test(key)) {
            return acc;
        }
        let diff = Math.abs((obj1[key] || 0) - (obj2[key] || 0));
        if (!diff) {
            return acc;
        }
        return { ...acc, diffTotal: acc.diffTotal + diff, [key]: diff };
    }, { diffTotal: 0});
}

function generateLetters(str) {
    let txt = (str || '').toLowerCase().replace(/\\n|\\s|\\t/gi, '@');
    // console.log('txt', txt)
    return txt.split('').reduce((acc, it) => {
        let isLetter = /^[a-zA-Zа-яА-Я\@]+$/.test(it)
        let key = isLetter ? it === '@' ? 'lineTabs' : it : 'special';
        return {
            ...acc
            , totalLetters: acc.totalLetters + (isLetter ? 1 : 0)
            , total: acc.total + 1
            , [key]: (acc[key] || 0) + 1
        }
    }

        , { total: 0, totalLetters: 0, special: 0, lineTabs: 0 });
}

function getDiff({versionLTS, versionLTP}) {
    let dataLTS = (versionLTS || {}).data || {};
    let dataLTP = (versionLTP || {}).data || {};
    // console.log("dataLTS", dataLTS, versionLTS)

    let letters1 = generateLetters(JSON.stringify(dataLTS));
    let letters2 = generateLetters(JSON.stringify(dataLTP));
    // console.log("letters", {letters1, letters2})
    let { diffTotal } = diffLetters(letters1, letters2);
    let isEqual = isEqualFN(dataLTS, dataLTP)
    let perc = Math.round(100 * diffTotal / Math.max(1, letters1.totalLetters, letters2.totalLetters));
    let changeKey = (perc < 10 && diffTotal < 10) ? 'patch' : (perc < 25 && diffTotal < 150) ? 'subVersion' : 'version'

    return {changeKey, diffTotal, perc, isEqual};
}

export default getDiff