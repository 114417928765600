import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import Input from "../../libs/Input";
import {toOdb} from '../Stats'
import PlanFact from "./PlanFact";

function generatePeriods({type, startCd, endCd, dayDiff = 1}, scb) {
    scb && scb();
    type = type || 'weekly'
    startCd = new Date(startCd)
    endCd = new Date(endCd)

    let periods = [];

    let difference;
    if (type === 'weekly') {
        // startCd.setDay(1)
        startCd.setDate(startCd.getDate() - startCd.getDay() + 1 + (+dayDiff - 1));
        difference = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
    } else if (type === 'monthly') {
        startCd.setDate(dayDiff);
        difference = 0; // We'll handle this in the loop since months have different numbers of days
    } else {
        console.error("Invalid type:", type);
        return;
    }

    while (startCd < endCd) {
        let _start = new Date(startCd).getTime()

        if (type === 'monthly') {
            startCd.setMonth(startCd.getMonth() + 1); // Increment by one month
        } else {
            startCd = new Date(startCd.getTime() + difference); // Increment by the difference
        }
        periods.push({
            odb: toOdb(_start),
            period: type,
            start: _start, end: new Date(startCd).getTime()
        }); // Add the current date to the array

    }
    console.log("qqqqq startcd4444", type, {startCd, endCd});
    return periods;

}

function Layout2({props}) {
    let {item} = props;
    console.log('*........ ## GENERATE PROGNOZ FACT', props, item);




    function pubDate(cd) {
        return new Date(cd).toString().substring(4, 10)
    }

    item.planPeriods = generatePeriods({
        startCd: item.startCd, endCd: item.endCd,
        type: item.planPeriod
    })
    item.prognozPeriods = generatePeriods({
        startCd: item.prognozStartCd,
        endCd: item.prognozEndCd,
        type: item.prognozPeriod, dayDiff: item.prognozDay
    })

    return <div>
        <hr/>
        <div></div>
        <Button color={3} onClick={(scb) => {
            item.planPeriods = generatePeriods({type: item.planPeriod}, scb)
            item.prognozPeriods = generatePeriods({type: item.prognozPeriod, dayDiff: item.prognozDay}, scb)
            global.onGlobalChange(item)
        }}>ReGenerate</Button>
        <hr/>
        <div className="row">
            <div className="col-sm-3">
                План
                {(item.planPeriods || []).map((it, ind) => {
                    return (<div key={ind}>
                        #{ind + 1}. {pubDate(it.start)} - {pubDate(it.end)}
                    </div>)
                })}
            </div>
            <div className="col-sm-6">
                Прогнозы
                {(item.prognozPeriods || []).map((it, ind) => {
                    return (<div key={ind}>
                        #{ind + 1}. {pubDate(it.start)} - {pubDate(it.end)}

                    </div>)
                })}
            </div>
            <div className="col-sm-12">
                <PreviewResults
                    pf={item}
                    prognozPeriods={item.prognozPeriods}
                    periods={item.planPeriods}
                    fields={item.fields}
                    planPeriod={item.planPeriod}
                    prognozPeriod={item.prognozPeriod}
                ></PreviewResults>


            </div>
        </div>
    </div>
}

function pubDate(it, type) {
    let {start, end} = it;
    type = type || 'weekly'

    function _week(cd) {
        return new Date(cd).toString().substring(4, 10)
    }

    function _month(cd) {
        return new Date(cd).toString().substring(4, 7)
    }


    return type === 'weekly' ? `${_week(start)} - ${_week(end)}` : `${_month(start)}`
}

function PreviewResults(props) {
    let [prognozItems, setPrognozItems] = useState({})
    let {
        pf,
        planPeriod,
        prognozPeriod,
        periods, prognozPeriods, fields
    } = props;

    prognozPeriods ??= [];
    let start = (prognozPeriods[0] || {}).odb;
    let end = (prognozPeriods.at(-1) || {}).odb;
    useEffect(() => {
        global.http.get('/load-prognozes', {
            pfKeyId: pf._id,
            pfSubKey: pf.pfSubKey,
            start,
            end
        }).then(items => {
            console.log("qqqqq load prognozesssssss", items);
            setPrognozItems(items.reduce((acc, it) => {
                return {...acc, [it.odb]: it}
            }, {}))
        })
    }, [start, end])



    return <>
        <table className={'table table-responsive table-bordered table-striped'} border={1}>
            <thead>
            <tr>
                <th>
                </th>
                <th>
                </th>
                {(periods || []).map((it, ind) => {
                    return (<th key={ind}>
                        #{ind + 1}. {pubDate(it, planPeriod)}
                    </th>)
                })}
                <th>Тотал / Среднее</th>
            </tr>
            <tr>
                <th colSpan={2}>
                    План
                </th>
                {(periods || []).map((it, ind) => {
                    return (<th key={ind}>
                        <PlanFact {...it}
                                  pfKeyId={pf._id}
                                  pfKey={pf.pfKey}
                                  pfSubKey={pf.pfSubKey}
                        ></PlanFact>
                    </th>)
                })}
                <th></th>
7            </tr>
            <tr>
                <th colSpan={2}>
                    Факт
                </th>
                {(periods || []).map((it, ind) => {
                    return (<th key={ind}>
                        <PlanFact {...it}
                                  pfKeyId={pf._id}
                                  pfKey={pf.pfKey}
                                  pfSubKey={pf.pfSubKey}
                                  type={'fact'}
                        ></PlanFact>
                    </th>)
                })}
                <th></th>
            </tr>
            <tr>
                <th colSpan={2}>
                    Прогноз
                </th>
                {(periods || []).map((it, ind) => {
                    return (<th key={ind}>
                        <PlanFact {...it}
                                  pfKeyId={pf._id}
                                  pfKey={pf.pfKey}
                                  pfSubKey={pf.pfSubKey}
                                  type={'prognoz'}
                        ></PlanFact>
                    </th>)
                })}
                <th></th>
            </tr> <tr>
                <th colSpan={2}>
                    Пред Прогноз
                </th>
                {(periods || []).map((it, ind) => {
                    return (<th key={ind}>
                        <PlanFact {...it}
                                  pfKeyId={pf._id}
                                  pfKey={pf.pfKey}
                                  pfSubKey={pf.pfSubKey}
                                  type={'prognoz1'}
                        ></PlanFact>
                    </th>)
                })}
                <th></th>
            </tr>
            </thead>
            {(prognozPeriods || []).map((prognoz, ind) => {

                return (<tr key={ind}>
                    <td colSpan={1}>
                        {pubDate(prognoz, prognozPeriod)}
                    </td>
                    <td>
                        Прогноз {pf._id}
                        <div></div>
                        <button className={'btn btn-xs btn-success'}>Отправить</button>
                    </td>
                    {(periods || []).map((it, ind) => {
                        prognozItems[prognoz.odb] ??= {}
                        prognozItems[prognoz.odb].values ??= {}
                        prognozItems[prognoz.odb].values[it.odb] ??= {}

                        return (<td key={ind}>
                            <div className="row np">
                                <div className="col-sm-12 np">
                                    {it.odb}
                                    <Input value={prognozItems[prognoz.odb].values[it.odb].value} onChange={(v) => {
                                        prognozItems[prognoz.odb].values[it.odb].value = v;
                                        setPrognozItems({...prognozItems})

                                        global.http.put('/update-prognoz', {
                                            pfKeyId: pf._id,
                                            pfSubKey: pf.pfSubKey,
                                            odb: prognoz.odb,
                                            values: prognozItems[prognoz.odb].values
                                        }).then(r => {
                                                console.log("qqqqq rrrrrr", r);
                                        })
                                    }}></Input>
                                </div>
                                {/*<div className="col-sm-4 np tc">*/}
                                {/*    24*/}
                                {/*</div>*/}
                            </div>
                        </td>)
                    })}
                    <td></td>
                </tr>)
            })}

        </table>
    </>
}

export {generatePeriods, pubDate};

export default Layout2

