import React, {useEffect, useState} from "react";
import _ from 'underscore';

let intervalId;

export function CountDownSecs(props) {
    let [count, setCount] = useState(props.count)

    useEffect(() => {
        if (!props.count || !props.active) {
            return clearInterval(intervalId)
        }
        clearInterval(intervalId)

        setCount(props.count)
        intervalId = setInterval(() => {
            console.log("qqqqq trigger", count);

            setCount((prevSeconds) => {
                console.log("qqqqq prev trigger secondssssss", {intervalId, prevSeconds});
                if (prevSeconds < 0) {
                    return clearInterval(intervalId)
                }
                if (prevSeconds == 0) {

                    console.log("qqqqq prev trigger secondssssss STOP!!!!!! ", prevSeconds);
                    props.onChange && props.onChange(0, 0)
                    clearInterval(intervalId)
                    props.onStop && props.onStop()

                    return;
                }
                let sec = prevSeconds - 1;
                props.onChange && props.onChange(sec, 100 * sec / (props.totalTime || props.time))
                return sec;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [props.iteration, props.active])
    return props.hideValue ? null : <>{count}</>
}
