import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";
import Button from "../../libs/Button";
import Smart from "../../libs/Smart";
import {QuizEditFields, QuizAdminInsideQuestion} from "../Suggest/SuggestionItem";
import QuizPreview from "../Suggest/QuizPreview";
import CustomStorate from "../Suggest/CustomStorage";
import NFN from "../../NFN";


function QuizItemInsideCourse({props}) {
    let [open, setOpen] = useState(false)
    let [items, setItems] = useState([]);
    let [quizObj, setQuizObj] = useState({});
    let [dbQuestionsObj, setDbQuestionsObj] = useState({});
    let [allQuizes, setAllQuizes] = useState([]);

    let {item, onChange, onGlobalChange} = props;

    function generateAllQuizes(quizObj) {
        const allQuizes = Object.values(quizObj).flat();
        setAllQuizes(allQuizes)
    }

    function onAddBlock(key, q) {
        item.blocks[key]({
            title: q.name,
            name: q.answer,
            useCases: q.useCases,
            facts: q.facts
        })
        // props.onChange && props.onChange(v, 'questions')
        onChange(item.blocks, 'blocks')
    }


    useEffect(() => {
        item && item._id && global.http.get('/pull-questions', {_id: item._id}).then(dbQuestions => {
            let dbQuestionsObj = dbQuestions.reduce((acc, it) => {
                return {...acc, [it._id]: it}
            }, {})
            setDbQuestionsObj(dbQuestionsObj);
        })
        console.log("qqqqq need reload DB questions",);
    }, [(item.questions || []).join(',')])

    return <>
        <div className="row" style={{marginTop: '20px'}}>
            <div className="col-xs-12">
                <div className="fbList2">
                    <div><NFN>List OF QUSETIONS</NFN>:</div>
                    <hr/>
                    {(item.questions || []).map((it, ind) => {
                        let dbQ = dbQuestionsObj[it] || {};

                        return (<div key={ind} className={'w100 ib'}>
                            {/*<button className={'btn btn-xs btn-default'} onClick={() => {*/}
                            {/*    onAddBlock('unshift', dbQ)*/}
                            {/*}}>To Top*/}
                            {/*</button>*/}
                            {/*<button className={'btn btn-xs btn-default'} onClick={() => {*/}
                            {/*    onAddBlock('push', dbQ)*/}
                            {/*}}>Io Bot*/}
                            {/*</button>*/}
                            <a
                                onClick={() => {
                                    window.open('/theme-question/' + it, '_blank')
                                }}
                                href={'/theme-question/' + it} target={"_blank"} className="ib"
                                style={{marginRight: '5px'}}>{CustomStorate.pubName(dbQ.name)}</a>

                            <div>
                                <QuizAdminInsideQuestion
                                    onChangeItems={(items) => {
                                        quizObj[it] = items;
                                        setQuizObj({...quizObj});
                                        generateAllQuizes(quizObj)
                                        console.log("qqqqq quiz changed", items, quizObj);
                                    }}
                                    title={`${global?.nameFn('QUIZES')}: `} questionId={it}></QuizAdminInsideQuestion>
                            </div>
                            <div className="sep"></div>
                        </div>)
                    })}
                </div>
            </div>
            <div className="col-xs-6">
                {/*Активных квизов: {allQuizes.filter(it => !(item.quizBans || {})[it._id]).length} из {allQuizes.length}*/}
                {/*<div style={{maxHeight: '500px', overflow: 'auto'}}>*/}
                {/*{(allQuizes || []).map((it, ind) => {*/}
                {/*    let isBan = (item.quizBans || {})[it._id]*/}
                {/*    return (<div key={ind} className={isBan ? 'ho2': ''} style={{borderTop: '1px solid lightgrey', padding: '3px'}}>*/}
                {/*        <div className="pull-right">*/}
                {/*            <button className={'btn btn-xs ' + (isBan ? 'btn-danger btn-active' : 'btn-default')} onClick={() => {*/}
                {/*                item.quizBans ??= {};*/}
                {/*                item.quizBans[it._id] = !item.quizBans[it._id];*/}
                {/*                onGlobalChange && onGlobalChange(item)*/}
                {/*            }}>Ban</button>*/}
                {/*        </div>*/}
                {/*        <b>{it.name} (qId = {it.question})</b>*/}
                {/*        <div style={{width: '100%'}}>*/}
                {/*            {(it.variations || []).map((it2, ind) => {*/}
                {/*                return (<div key={ind} className={'ib ' + (it2.isCorrect ? 'correctV' : '')} style={{*/}
                {/*                    padding: '1px',*/}
                {/*                    paddingLeft: '30px',*/}
                {/*                    whiteSpace: 'break-all',*/}
                {/*                    width: '100%'*/}
                {/*                }}>*/}
                {/*                    {it2.name}*/}
                {/*                </div>)*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </div>)*/}
                {/*})}*/}
                {/*</div>*/}


            </div>
        </div>

        <br/>
    </>
}


export default QuizItemInsideCourse
