import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";
import UserSprint from "../UserSprint";
import NFN from "../../NFN";


function PullQuestions({props}) {
    console.log('*........ ## PUll Qusetions :: RENDER', props);
    let [open, setOpen] = useState(false)
    let {item} = props;
    item ??= {};

    let _props = {};
    // let v = useActionData();
    return <div>

        <a onClick={() => {
            setOpen(!open)
        }}>
            <NFN>Config Questions with Order</NFN> (x{(item.questions || []).length})</a>
        <UserSprint
          {..._props}
          props={_props}
          opts={{
              name: 'Exam',
              isCourse: true,
              cloneUrl: '/exam',
              redirectUrl: '/exams',
              questions2: item.questions || [],
              onChangeQ2: (v, plainV) => {
                  console.log("qqqqq on change 51241211111", v);
                  props.onChange && props.onChange(v, 'questions')
              }
          }}></UserSprint>
        {/*<MyModal*/}
        {/*    size={'full'}*/}
        {/*    isOpen={open}*/}
        {/*    onClose={() => setOpen(false)}*/}
        {/*>*/}
        {/*    {item._id && <UserSprint*/}
        {/*        {..._props}*/}
        {/*        props={_props}*/}
        {/*        opts={{*/}
        {/*            name: 'Exam',*/}
        {/*            isCourse: true,*/}
        {/*            cloneUrl: '/exam',*/}
        {/*            redirectUrl: '/exams',*/}
        {/*            questions2: item.questions || [],*/}
        {/*            onChangeQ2: (v, plainV) => {*/}
        {/*                console.log("qqqqq on change 51241211111", v);*/}
        {/*                props.onChange && props.onChange(v, 'questions')*/}
        {/*            }*/}
        {/*        }}></UserSprint>}*/}
        {/*</MyModal>*/}
    </div>
}

export default PullQuestions
