import React, {useState, useEffect, useCallback} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart';

import {
    Link, Outlet
} from "react-router-dom";
import './SuggestionItem.css';
import QuestionDetails from './QuestionDetails'
// import Editor from '@monaco-editor/react';
import JSDetails from './JSDetails'
import DuplicatePreview from "./DuplicatePreview";
import MyModal from "../../libs/MyModal";
import QuizPreview from "./QuizPreview";
import Button from "../../libs/Button";
import CustomStorage from './CustomStorage';
import LazyDiffEditor from "../LazyEditor/LazyDiffEditor";
import QuizEditor from './QuizEditor';
import LazyEditor from "../LazyEditor/LazyEditor";
import Input from "../../libs/Input";
import NFN from "../../NFN";
// readOnly: true,preview: 'edit',
let isAdmin = global.env.isAdmin;
let eqTimer, eqObj = {};

let unactiveFn = (key) => {
    return (item) => {
        console.log("qqqqq aaaa", item);
        return item[key] === 'unactive' ? 'unactive-quiz' : ''
    }

}

//replace in API as WELL
let isValidQuiz = (item) => {

    //replace on front admin & API validation too
    let variations = (item.variations || []).filter(it => it.name);
    let isValidProblem = item.status == 'unactive';
    item.answerType = item.answerType || 'quiz';

    let answerType = item.answerType;

    if (answerType == 'quiz') {
        isValidProblem = isValidProblem || (variations.length !== (item.variations || []).length)
        isValidProblem = isValidProblem || (variations.length < 3)
        isValidProblem = isValidProblem || (_.filter(variations, it => it.isCorrect).length != 1)

    }
    if (answerType == 'audio') {
        isValidProblem = isValidProblem || (!item.specialName && !item.audioName);
    }
    if (answerType == 'code') {
        let files = item.files || []
        isValidProblem = isValidProblem || (!item.specialName && !item.codeName);
        isValidProblem = isValidProblem || !files.length
    }

    item.isValid = !isValidProblem
    console.log("qqqqq item is valid", item.isValid);
    //replace on front admin & API validation too
    return item.isValid;
}

let QuizEditFields = [
    {
        size: 12,
        classFn: (item, v, a) => {
            // let item = props.parentObj;
            return !isValidQuiz(item) ? 'error-quiz p15-0' : 'p15-0';

        },
        childs: [
            {
                name: 'Статус',
                key: 'answerType',
                size: 3,
                defClass: 'col-lg',
                type: 'group',
                list: ['', 'quiz', 'audio', 'code', 'run']
            },
            {
                name: 'Статус',
                key: 'status',
                size: 3,
                defClass: 'col-lg',
                type: 'group',
                list: ['', 'active', 'unactive']
            }, {
                name: 'Сложность',
                key: 'difficulty',
                size: 3,
                defClass: 'col-lg',
                type: 'group',
                list: ['', 'jun', 'mid', 'senior']
            },

            {
                isVisible: (item) => item.specialType == 'general',
                size: 12, Component({item}) {
                    return <>
                        <hr/>
                        <div style={{maxHeight: '100px', overflowY: 'auto'}}>
                            <div><NFN>Special Title</NFN>: <NFN>{item.specialTitle}</NFN></div>
                            <div><NFN>Special Name</NFN>: <NFN>{item.specialName}</NFN></div>
                            <div><NFN>Special Type</NFN>: <NFN>{item.specialType}</NFN></div>
                            <div><NFN>Special QType</NFN>: <NFN>{item.specialQuestionType}</NFN></div>
                        </div>
                    </>

                }
            },
            {
                size: 12,
                classFn: unactiveFn('status'),
                childs: [
                    {
                        size: 12,
                        // classFn: unactiveFn('statusAudio'),
                        isVisible: (item) => item.answerType == 'audio',

                        childs: [
                            {size: 12, type: 'HR'},
                            // {
                            //     name: 'Статус',
                            //     key: 'statusAudio',
                            //     size: 12,
                            //     type: 'group',
                            //     list: ['', 'active', 'unactive']
                            // },

                            {name: 'Вопрос для аудио надиктовки', key: 'audioName', size: 12, type: 'md'},
                            {
                                name: 'Время в секундах для аудио ответа (заполняйте если супер уверены, что дефолтное время - это плохо)',
                                key: 'durationAudio',
                                max: 100,
                                size: 12,
                                type: 'number'
                            },

                        ]
                    },
                    {
                        size: 12,
                        isVisible: (item) => item.answerType == 'quiz',

                        // classFn: unactiveFn('statusQuiz'),
                        childs: [
                            {size: 12, type: 'HR'},
                            // {
                            //     name: 'Статус',
                            //     key: 'statusQuiz',
                            //     size: 12,
                            //     type: 'group',
                            //     list: ['', 'active', 'unactive']
                            // },

                            {name: 'Вопрос для квиза', key: 'name', size: 12, type: 'md'},
                            // {size: 8, type: 'hr'},
                            // {name: 'questionId', key: 'question', size: 4, type: 'input'},
                            {
                                size: 12,
                                key: 'variations',
                                sortable: true,
                                notFoundText: 'Добавьте вариант ответа',
                                addName: '+ Вариант ответа',
                                each: [
                                    {name: 'isCorrect', key: 'isCorrect', type: 'checkbox', size: 1},
                                    {name: ' ', key: 'name', type: 'textarea', defClass: 'mt20', minRows: 1, size: 11},
                                ]
                            },
                            {
                                name: 'Время в секундах для ответа (заполняйте если супер уверены, что дефолтное время - это плохо)',
                                key: 'durationQuiz',
                                max: 100,
                                size: 12,
                                type: 'number'
                            },
                        ]
                    },
                    {
                        size: 12,
                        isVisible: (item) => item.answerType == 'code',

                        // classFn: unactiveFn('statusCode'),
                        childs: [
                            {size: 12, type: 'HR'},
                            // {name: 'Статус', key: 'statusCode', size: 12, type: 'group', list: ['', 'active', 'unactive']},
                            {name: 'Вопрос для шорт кодинга', key: 'codeName', size: 12, type: 'md'},
                            {
                                size: 12,
                                key: 'files',
                                Component: FilesEditComp
                            },
                            {
                                Component: () => <>
                                    <hr/>
                                    Список единственно правильных ответов (опционально - если хотим выбирать из
                                    списка)</>,
                                size: 12
                            },
                            {
                                key: 'correctAnswers',
                                size: 12,
                                addName: '++ Добавить правильный вариант ++',
                                notFoundText: 'Еще не добавлены = значит программа не будет искать по правильным вариантам и будет доступна только мануальная проверка',
                                each: [
                                    {
                                        size: 11,
                                        name: 'Код ответ',
                                        key: 'files',
                                        Component: CorrectAnswers,
                                    }
                                ]
                            },
                            {
                                name: 'Время в секундах для ответа (заполняйте если супер уверены, что дефолтное время - это плохо)',
                                key: 'durationCode',
                                max: 100,
                                size: 12,
                                type: 'number'
                            },
                        ]
                    }, {
                        size: 12,
                        isVisible: (item) => item.answerType == 'run',

                        // classFn: unactiveFn('statusCode'),
                        childs: [
                            {size: 12, type: 'HR'},
                            {
                                size: 12,
                                path: 'Suggest/JSDetailsQuiz'
                            }
                        ]
                    },
                    {
                        size: 12,
                        Component({item, onChange}) {
                            let [isOpen, setIsOpen] = useState(false)
                            let _id = item.question;
                            return <><Link to={'/theme-question/' + _id}>Вопрос: {_id}</Link>
                                <small className={'ml-10'} onClick={() => {
                                    setIsOpen(!isOpen)
                                }}><a><NFN>Assign Another Question</NFN></a>
                                </small>
                                {isOpen && <>
                                    <Input type="text" value={item.question} onChange={(v) => {
                                        item.question = v;
                                        onChange(item)
                                    }}/>
                                </>}
                            </>
                        }
                    }
                ]
            },
        ]
    }
]

export function FilesEditComp(props) {
    let {item, field} = props;
    let [selectedInd, setSelectedInd] = useState(0)
    item.files = item.files || []

    let {key} = field
    let selItem = (item.files || [])[selectedInd] || {}
    selItem.lng = getLng(selItem.name)
    // item.files = item.files || [{}]
    console.log("qqqqq FIFIFIFIIFIFIFIF pppppppppppppppppppp", props);

    return <div className={'row'}>
        <div className="col-sm-12">
            <hr/>
            Стартовые значения:
        </div>
        <div className="col-sm-3">
            <Smart
                obj={item}
                items={[{
                    key,
                    size: 12,
                    addName: '++ Добавить файл ++',
                    sortable: true,

                    each: [{
                        size: 12,
                        type: 'input', key: 'name',
                        onClick(props) {
                            let {ind} = props;
                            setSelectedInd(ind)
                            console.log("qqqqq aaaaaa", props);
                        },
                    },
                        {
                            size: 12, Component() {
                                return <div style={{marginTop: '-30px'}}></div>
                            }
                        },
                        {
                            size: 5,
                            type: 'checkbox', key: 'isTopVisible', label: 'Top Vis'
                        }, {
                            size: 7,
                            type: 'checkbox', key: 'isSmartCompare', label: 'AI Compare'
                        }, {
                            size: 12,
                            type: 'checkbox', key: 'isPrevCompare', label: 'Prev Compare'
                        }]
                },]}
                onChange={(v) => {
                    console.log("qqqqq vvv", v);
                    onGlobalChange(v)

                }}
            ></Smart>
        </div>
        <div className="col-sm-9">
            {(item.files || []).map((it, ind) => {
                return (<div key={ind} className={'ib ' + getActiveClass(ind, selectedInd)} style={{marginRight: '5px'}}
                             onClick={() => {
                                 setSelectedInd(ind)
                             }}
                >
                    {it.name || '--'}
                </div>)
            })}
            <LazyEditor
                height={300}
                value={selItem.code || ''}
                language={selItem.lng}
                onChange={(code) => {
                    item.files[selectedInd].code = code;
                    global.onGlobalChange(item)
                    console.log("qqqqq vvvvv", code);
                }}
            ></LazyEditor>
        </div>

    </div>
}

export function CorrectAnswers(props) {
    let {item, field, ind} = props;
    let [selectedInd, setSelectedInd] = useState(0)
    item.files = item.files || []

    let {key} = field
    item.correctAnswers = item.correctAnswers || [];
    item.correctAnswers[ind] = item.correctAnswers[ind] || {}
    item.correctAnswers[ind].files = item.correctAnswers[ind].files || []
    item.correctAnswers[ind].files[selectedInd] = item.correctAnswers[ind].files[selectedInd] || {}

    let files = item.files || []
    let file = files[ind] || {}
    let selItem = item.correctAnswers[ind].files[selectedInd] || {}
    selItem.lng = getLng(file.name)
    // item.files = item.files || [{}]
    console.log("qqqqq FIFIFIFIIFIFIFIF pppppppppppppppppppp", props);

    return <div className={'row'}>
        <div className="col-sm-12">
            {(item.files || []).map((it, ind) => {
                if (it.isPrevCompare) {
                    return null;
                }
                return (<div key={ind} className={'ib ' + getActiveClass(ind, selectedInd)} style={{marginRight: '5px'}}
                             onClick={() => {
                                 setSelectedInd(ind)
                             }}
                >
                    {it.name || '--'}
                </div>)
            })}
            <LazyEditor
                height={150}
                value={selItem.code || ''}
                language={selItem.lng}
                onChange={(code) => {

                    item.correctAnswers[ind].files[selectedInd].code = code;
                    global.onGlobalChange(item)
                    console.log("qqqqq vvvvv", code);
                }}
            ></LazyEditor>
        </div>

    </div>
}

export function getActiveClass(ind1, ind2, className = 'active') {
    return ind1 == ind2 ? (' ' + className) : ''
}

// let editQuestion = [
//     {name: 'Короткое название', key: 'title', type: 'input', size: 12},
//     {name: 'Вопрос', key: 'name', type: 'md', size: 12,},
//     {name: 'Теги', path: 'Suggest/TagSelector', size: 6},
// ]
let editQuestion = [
    {
        memoFn(field, props) {
            // console.log('memo key888', props)
            return 'quizAdmin_' + (props.obj || {}).questionId
        },
        size: 12,
        Component: QuizAdminInsideQuestion
    },
    {
        isVisible: () => isAdmin,
        Component: ({item}) => {
            let [open, setOpen] = useState(false)

            if (!item || !item.LCDetails) return <></>
            return <><a style={{padding: '10px 20px 10px 0', display: 'inline-block'}} href={item.LCDetails.url}
                        target={"_blank"}>{item.LCDetails.url}</a>
                <a className={'ib '} style={{paddingTop: '10px'}} onClick={() => {
                    setOpen(!open)
                }}>Toggle Details</a>
                {open && <pre>{JSON.stringify(item.LCDetails, null, 4)}</pre>}
            </>
        }, size: 12
    },
    // {
    //     Component: ({item}) => {
    //         let [open, setOpen] = useState(false)
    //
    //         if (!item || !item.LCDetails) return <></>
    //         return <><a  style={{padding: '10px 20px 10px 0', display: 'inline-block'}} href={item.LCDetails.url} target={"_blank"}>{item.LCDetails.url}</a>
    //             <a className={'ib '} style={{paddingTop: '10px'}} onClick={() => {setOpen(!open)}}>Toggle Details</a>
    //             {open && <pre>{JSON.stringify(item.LCDetails, null, 4)}</pre>}
    //         </>
    //     }, size: 12
    // },
    {
        Component: ({item, localItem}) => {
            return <div>{item.originalDuplicateId
                ? <>ЭТО ДУБЛИКАТ
                    <div></div>
                    <Link to={'/theme-question/' + item.originalDuplicateId}>Оригинал:
                        #{item.originalDuplicateId}</Link>
                    <hr/>
                </>
                : ''}</div>
        }, size: 12
    },
    {
        isVisible: () => isAdmin,
        name: 'Type',
        key: 'type',
        type: 'group',
        list: ['', 'question', 'task', 'js-task'],
        size: 3
    },
    {
        name: 'Сложность',
        key: 'difficulty',
        type: 'group',
        list: ['', 'easy', 'medium', 'hard'],
        size: 3
    },


    {
        size: 6, Component: ({item, onChange, autoSaveFn}) => {
            // console.log("qqqqq item", item);
            return <>
                <button className={'btn btn-xs btn-default ' + (item.isForModeratorApprove ? '' : 'active')}
                        onClick={() => {
                            autoSaveFn({...item, isForModeratorApprove: false})
                        }}>Проверено
                </button>
                <button className={'btn btn-xs  ' + (item.isForModeratorApprove ? 'active btn-danger' : ' btn-default')}
                        onClick={() => {
                            autoSaveFn({...item, isForModeratorApprove: true})
                        }}>На модерации
                </button>
                <Link to={'/find-duplicates'} className={'btn btn-xs btn-default'}>Пометить дубликатом</Link>
            </>
        }
    },
    // {
    //     type: 'checkbox',
    //     key: 'woAuto',
    //     name: 'Не создавать автоквиз',
    //     size: 6
    // },


    // {size: 12, isVisible: () => isAdmin, Component: JSDetails},
    // { size: 12, isVisible: () => !isAdmin, type: 'HR' },

    {

        Component: ({item}) => {
            // console.log("qqqqq isForModeratorApprove", item);
            let _modal;
            let {MyModal} = global;
            return <div className='pull-right'>
                <button className='btn btn-xs btn-default' onClick={() => {
                    console.log('Open Preiview')
                    _modal.show()
                }}>Превью
                </button>
                {/* <button className='btn btn-xs btn-default' onClick={() => {
                    generateSuggestion(item._id || CustomStorage.getId())
                }}>Предложить контент
                </button> */}
                <MyModal
                    title={item._id || 'Question Preview'}
                    size={'full'}
                    ref={(el) => _modal = el}
                >
                    <QuestionDetails woSuggestions={true} showSolution={true} question={item}
                                     showName={true}></QuestionDetails>
                </MyModal>
            </div>
        },
        size: 12
    },

    // { name: 'Коммент', key: 'comment', type: 'textarea', minRows: 1, size: 12 },
    {name: 'Короткое название', key: 'title', type: 'input', size: 12},
    {name: 'Вопрос', key: 'name', type: 'md', size: 12,},
    {name: 'Теги', path: 'Suggest/TagSelector', size: 6},
    {
        key: 'videos',
        size: 6,
        sortable: true,
        notFoundText: ' ',
        addName: '+ Видео',
        each: [
            {key: 'name', name: 'Имя', type: 'input', minRows: 1, size: 8},
            {key: 'href', name: 'Ссылка', type: 'input', minRows: 1, size: 4},
        ]
    },
    {name: 'Ответ', key: 'answer', type: 'md', defClass: 'md-answer', size: 12},
    {type: 'HR', size: 12},
    {
        size: 6, Component: ({item}) => {
            return <div className={'title-use-case'}>Интересные факты ({(item.facts || []).length})</div>
        },
    },

    {
        size: 6, Component: ({item}) => {
            return <div className={'title-use-case'}>ЮзКейсы / Решения задач ({(item.useCases || []).length})</div>
        },
    },

    {
        name: 'Интересные Факты', key: 'facts', size: 6,
        notFoundText: 'Добавьте факт',
        addName: '++ Добавить факт ++',
        sortable: true,
        showTopAdd: true,

        defClass: 'video-list-parent',
        each: [
            {key: 'name', name: 'name', type: 'textarea', minRows: 1, size: 6},
            {
                key: 'videos',
                defClass: 'video-list',
                size: 6,
                sortable: false,
                notFoundText: ' ',
                addName: '+ Видео',

                each: [
                    {key: 'name', name: 'Имя', type: 'input', minRows: 1, size: 8},
                    {key: 'href', name: 'Ссылка', type: 'input', minRows: 1, size: 4},
                ]
            },
            {key: 'desc', name: 'Описание', type: 'md', defClass: 'md-desc', size: 12},
            {type: 'HR', size: 12},

        ]
    },

    {
        name: 'ЮзКейсы', key: 'useCases', size: 6,
        notFoundText: 'Добавьте ЮзКейс',
        addName: '++ Добавить ЮзКейс ++',
        sortable: true,
        showTopAdd: true,
        defClass: 'video-list-parent',
        each: [
            {key: 'name', name: 'Имя', type: 'textarea', minRows: 1, size: 6},
            {
                key: 'videos',
                defClass: 'video-list',
                size: 6,
                sortable: false,
                notFoundText: ' ',
                addName: '+ Видео',

                each: [
                    {key: 'name', name: 'Имя', type: 'input', minRows: 1, size: 8},
                    {key: 'href', name: 'Ссылка', type: 'input', minRows: 1, size: 4},
                ]
            },
            {key: 'desc', name: 'Описание', type: 'md', defClass: 'md-desc', size: 12},
            {type: 'HR', size: 12},

        ]
    },
    {type: 'HR', size: 12},
    {
        name: 'Подсказки', key: 'hints', size: 12,
        notFoundText: 'Добавьте Подсказку',
        addName: '++ Добавить Подсказку ++',
        sortable: true,
        showTopAdd: true,
        defClass: 'video-list-parent',
        each: [
            {key: 'desc', name: 'Описание', type: 'md', defClass: 'md-desc', size: 12},

        ]
    },

];


function setReadOnly(editQuestion) {
    return [...editQuestion].map((_it, ind) => {
        let it = {..._it};
        if (it.type === 'md') {
            it.readOnly = true;
            it.preview = 'edit';
            it.commands = [];
            it.name = '';//it.name === 'desc' ? '' : it.name;
            it.defClass = 'preview-only-md'
        }

        if (/input|textarea/gi.test(it.type)) {
            it.type = 'text'
        }

        if (it.childs) {
            it.chidls = setReadOnly(it.childs)
        }
        if (it.each) {
            it.sortable = false;
            it.showTopAdd = false;
            it.woAdd = true;
            it.each = setReadOnly(it.each)
        }

        return it;
    })
}

let editQuestionReadOnly = setReadOnly(editQuestion);

function Editor2(props) {
    const [height, setHeight] = useState(props.height || 270);


    return (

        <LazyDiffEditor
            // height="600"
            language="javascript"
            height={height}
            // editorDidMount={handleEditorDidMount}
            original={props.v1 || ''}
            value={props.v2 || ''}
            options={{
                ignoreTrimWhitespace: true,
                diffAlgorithm: 'advanced',
                automaticLayout: true,
                renderSideBySide: false,
                experimental: {
                    collapseUnchangedRegions: true,
                }
            }}
            onChange={props.onChange}
        />
    );
}

function SuggestionItem({props}) {
    let {localItem} = props;
    console.log("qqqqq localItme444444444", localItem);
    let isFinilized = !isAdmin && localItem.status === 'approved';
    // let config =

    let [config, setConfig] = useState(getConfig());
    let [filter, setFilter] = useState({});
    let {keyDiff, key2, type1} = filter;

    useEffect(() => {
            let config = getConfig()
            setFilter(config.start)
            setConfig(config)
        }
        , [localItem._id])

    function getConfig() {
        return isAdmin
            ? {
                start: {
                    keyDiff: 'original',
                    key2: 'admin',
                    type1: 'diff',
                },
                statuses: [{
                    key: 'edit',
                    name: 'Редактирую'
                }, {
                    name: 'Отправлено',
                    key: 'sent'
                }, {
                    name: 'Проверено',
                    key: 'approved'
                }, {
                    name: 'Отменено',
                    key: 'canceled'
                }],
                sel1: [
                    {name: 'Оригинал', key: 'original'},
                    {name: 'Предлаг', key: 'suggest'},
                    {name: 'Aдмин', key: 'admin'},
                    {name: 'Последняя', key: 'latest'}
                ],
                sel2: [
                    {name: 'Изменения', key: 'diff'},
                    {name: 'Сорс', key: 'source'},
                    {name: 'Превью', key: 'preview'},
                ]
            }
            : {
                start: {
                    keyDiff: 'original',
                    key2: 'suggest',
                    type1: isFinilized ? 'diff' : 'preview',
                },
                statuses: [{
                    key: 'edit',
                    name: 'Редактирую'
                }, {
                    name: 'Отправлено',
                    key: 'sent'
                }, {
                    name: 'Отменено',
                    key: 'canceled'
                }],
                // statuses: statuses.filter(({key}) => key !== 'Проверено'),
                sel1: [
                    // { name: 'Original', key: 'original' },
                    // { name: 'Suggestion', key: 'current' },
                    // { name: 'Admin', key: 'admin' },
                    // { name: 'Latest', key: 'latest' }
                ],
                sel2: [
                    // {name: 'Изменения', key: 'diff'},
                    // { name: 'Source', key: 'source' },
                    {name: 'Превью', key: 'preview'},
                ]
            }
    }

    console.log('rerender$$$$$$$$$', localItem)
    window.localItem = localItem;
    return <div className="row">


        <div className="col-sm-12">
            <Smart
                obj={localItem}
                items={[
                    // {name: 'User', key: 'user', type: 'Number'},
                    // {name: 'Type', key: 'user', },
                    {
                        config,
                        isFinilized,
                        autoSaveFn: props.autoSaveFn,
                        size: 12, Component: StatusAndReason
                    },
                    isAdmin && {
                        size: 12,
                        Component: ({item}) => {
                            return <>
                                <div style={{marginTop: '15px'}}>
                                    <Link to={'/theme-question/' + item.question}><NFN>Question</NFN> #{item.question}</Link>
                                    <Link to={'/users/' + item.user}> <NFN>User</NFN> #{item.user}</Link>
                                </div>
                            </>
                        }
                    },
                    {isVisible: () => isAdmin, type: 'HR', size: 12},
                ]}

            ></Smart>
        </div>
        {localItem.duplicateData && <>
            <div className={'col-sm-12'}>
                {!isAdmin && <div>Вы отправили дубликат</div>}
                {isAdmin && <DuplicatePreviewWrap {...props}></DuplicatePreviewWrap>}
            </div>
        </>}
        {!localItem.duplicateData && <>
            {isAdmin && <div className="col-sm-4">
                {config.sel1.map((it, ind) => {
                    return <button key={ind}
                                   onClick={() => {
                                       setFilter({...filter, keyDiff: it.key})
                                   }}
                                   className={'btn btn-xs btn-default ' + (it.key === keyDiff ? 'active' : '')}>
                        {it.name}</button>
                })}
                <div className="ib" style={{marginLeft: '10px'}}>
                    {config.sel2.map((it, ind) => {
                        return <button key={ind}
                                       onClick={() => {
                                           setFilter({...filter, type1: it.key})
                                       }}
                                       className={'btn btn-xs btn-default ' + (it.key === filter.type1 ? 'active' : '')}>{it.name}</button>
                    })}
                </div>

                {type1 === 'preview' && <div className="diff-it">
                    <QuestionDetails woSuggestions={true} showName={true}
                                     question={(props.localItem || {})[key2] || {}}></QuestionDetails>
                </div>}
                {type1 === 'diff' && <DiffSmart key1={keyDiff} key2={key2} {...props} />}
                {type1 === 'source' && <div className="preview-read-only-wrap diff-it"><EditSmart {...props} {...{
                    key2: keyDiff,
                    fields: editQuestionReadOnly
                }} /></div>}
            </div>}
            <div className={isAdmin ? "col-sm-8" : 'col-sm-12'}>
                {isFinilized && <div className="preview-read-only-wrap diff-it"><EditSmart {...props} {...{
                    key2: key2,
                    fields: editQuestionReadOnly
                }} /></div>}
                {!isFinilized && <EditSmart {...{key2, fields: editQuestion}} {...props} />}
            </div>
        </>}
    </div>
}

function deepClone(v) {
    return JSON.parse(JSON.stringify(v))
}

function DuplicatePreviewWrap(props) {
    let [question1, setQuestion1] = useState({})
    let [question2, setQuestion2] = useState({})
    let {localItem, autoSaveFn} = props;
    let details = (localItem || {}).duplicateData || {}

    console.log("qqqqq localImte", props);
    return <div>
        {/*<Link to={`/find-duplicates?suggestion=${localItem._id}&question1=${details.originalId}&question2=${details.duplicateId}`}>Edit Links</Link>*/}
        <DuplicatePreview
            question1={localItem.populatedOriginal}
            question2={localItem.populatedDuplicated}
            onClose={() => {
                localItem.status = 'approved'
                console.log("qqqqq close", props, autoSaveFn);

                autoSaveFn(localItem)

            }}></DuplicatePreview>
    </div>

}

function LikeDislike({
                         localItem, onChange, isEqualFn, obj1, obj2, key1, key2, field, fn, name
                     }) {
    obj1 ??= {};
    obj2 ??= {};
    console.log('JS DETAILS::', obj1, obj2)
    let v1 = fn ? fn(obj1[field]) : obj1[field];
    let v2 = fn ? fn(obj2[field]) : obj2[field];
    v1 = v1 || ''
    v2 = v2 || ''
    let lines = Math.max(v1.length / 50, v2.length / 50, (v1 || '').toString().split('\n').length, (v2 || '').toString().split('\n').length)
    let PER_LINE = 20;
    if (isEqualFn) {
        isEqualFn(v1 == v2, field)
    }

    function pub(v) {
        return typeof v === 'object' ? JSON.stringify(v, null, 4) : v;
    }

    v1 = pub(v1)
    v2 = pub(v2)

    console.log("v1' v2", v1.toString(), String(v2))
    return <div className="diff-it" key={field + '__' + v2}>

        <small>{name} {(v1 == v2) && <span>:: Без изм.</span>} </small>
        {v1 !== v2 && <>
            <button className="btn btn-xs btn-default"
                    onClick={() => {
                        console.log("aaaaa!!!!!!!!!!!!!!", {key1, key2, field, localItem})
                        localItem[key2] = localItem[key2] || {};
                        localItem[key2][field] = deepClone((localItem[key1] || {})[field] || '')
                        onChange && onChange(localItem[key2], key2)
                    }}
            >Применить Оригинал
            </button>
        </>}

        {v1 !== v2 && <Editor2 height={lines > 8 ? 270 : lines > 3 ? 80 : 80} v1={v1 || ''} v2={v2 || ''}
                               onChange={(...args) => {
                                   localItem[key2] = localItem[key2] || {};
                                   localItem[key2][field] = args[0];
                                   onChange && onChange(localItem[key2], key2)
                               }}
        />}
    </div>
}

function DiffSmart(props) {

    console.log("propssssss444", props)

    function pubList(items) {
        return (items || []).map(it => {
            return `${it.name || ''}:: \n${it.desc} \n${videos(it.videos)}`
        }).join('\n')
    }

    function stringifyShort(data) {

        data ??= {}
        let names = {
            timeout: 'Таймаут на запуск',

            testCasesStr: 'Тест кейсы',
            pubCasesStr: 'Пуб тест кейсы',
            starterFiles: 'Стартер Файлы',
            starter: 'Стартер код',
            returnType: 'Тип ретерн ф-ии',
            originalSolutionStatus: 'Дефолт солюшн статус',
            hideRunStatus: 'Скрыть ран результаты',

            'files.name': 'Файлы',
            'fields.name': 'Поля',
            fnName: 'Файл нейм',
            correctSolution: 'Правильное решение',
            solutionFiles: 'Файлы решения',
            codeType: 'Тип задачи',
            // pubCasesStr: 'Пуб тест кейсы',
        };
        let str = ['timeout', 'testCasesStr', 'pubCasesStr', 'starterFiles', 'starter',
            'solutionFiles', 'returnType', 'originalSolutionStatus', 'hideRunStatus',
            'files.name', 'fields.name',
            'fnName', 'correctSolution', 'solutionFiles', 'codeType'].map(key => {
            let isArrName = key.indexOf('.name') > -1;
            key = isArrName ? key.replace('.name', '') : key;
            let namesArr = (data[key] || []);
            // console.log('namesArr', isArrName, namesArr, key, data[key])
            let rr = (isArrName ? (namesArr.map(it => it.name).join('\n')) : JSON.stringify(data[key], null, 2));
            console.log("rrrrrrrr", rr)
            if (rr === '{}' || rr === 'undefined' || rr === undefined) {
                rr = ''
            }
            return (names[key] || key) + ':: ' + rr
        }).join('\n\n')
        // console.log("data JS DETAILS", str)

        return str;
        // return new Date().getTime().toString();
        // return JSON.stringify(data, null, 2)
    }

    let {localItem, key1, key2} = props;
    let obj1 = localItem[key1] || {}
    let obj2 = localItem[key2] || {}

    function tagify(arr) {
        let categories = Storage.getCategories();
        return (arr || []).map(it => {
            return (categories[it] || {}).title || ('#' + it)
        }).join('\n')
    }

    function videos(arr) {
        return (arr || []).map(it => {
            return 'video: ' + it.name + ' :: ' + it.href
        }).join('\n')
    }


    let _obj = {
        obj1: obj1,
        obj2: obj2,
        key1: key1,
        key2: key2,
        localItem,
        onChange: props.onChange,
        isEqualFn: !props.onChangeTotalEqual ? null : (value, field) => {
            eqObj[field] = value;
            clearTimeout(eqTimer)
            eqTimer = setTimeout(() => {
                // console.log("eeeeeeee")
                let isEqual = true;
                _.each(eqObj, v => {
                    if (!v) {
                        isEqual = false;
                    }
                })
                props.onChangeTotalEqual && props.onChangeTotalEqual(isEqual, _.size(eqObj), eqObj)

            }, 100)
        }
    }
    return <div className='diffWrapper'>
        <>
            <button className="btn btn-xs btn-default"
                    onClick={() => {
                        localItem[key2] = deepClone((localItem[key1] || {}))
                        props.onChange && props.onChange(localItem[key2], key2)
                    }}
            >Применить оригинал для Всех
            </button>
        </>
        <LikeDislike
            name={global.nameFn('Question Type')}
            field={'type'}
            {..._obj}
        ></LikeDislike>
        <LikeDislike
            name={global.nameFn('Title')}
            field={'title'}
            {..._obj}
        ></LikeDislike>
        <LikeDislike
            name={global.nameFn('Difficulty')}
            field={'difficulty'}
            {..._obj}
        ></LikeDislike>
        <LikeDislike
            name={global.nameFn('Name')}
            field={'name'}
            {..._obj}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('Tags')}
            field={'hashTags'}
            fn={tagify}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('Videos')}
            field={'videos'}
            fn={videos}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('Answer')}
            field={'answer'}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('UseCases')}
            field={'useCases'}
            fn={pubList}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('Facts')}
            field={'facts'}
            fn={pubList}
        ></LikeDislike>
        <LikeDislike
            {..._obj}

            name={global.nameFn('Hints')}
            field={'hints'}
            fn={pubList}
        ></LikeDislike>

        {/* <LikeDislike
            name={'quizes'}
            field={'quizes'}
            fn={stringifyShort}
        ></LikeDislike> */}
        <LikeDislike
            {..._obj}

            name={global.nameFn('jsDetails')}
            field={'jsDetails'}
            fn={stringifyShort}
        ></LikeDislike>
    </div>
}

function EditSmart(props) {
    let {onChange, fields, localItem = {}, key2, onGlobalChange} = props || {};
    let obj = localItem[key2] || {}
    return <Smart
        obj={obj}
        items={fields}
        onChange={(v) => {
            console.log("qqqqq vvvvvv", v, key2, localItem[key2]);
            localItem[key2] = v;
            onChange && onChange(v, key2)
            onGlobalChange && onGlobalChange(localItem)
        }
        }
    ></Smart>
}

function generateSuggestion(questionId) {
    let _id = (questionId || {})._id || questionId;
    console.log('generate suggestion', _id)
    global.http.get('/generate-suggestion', {question: _id})
        .then(r => {
            global.navigate('/suggestions/' + r._id)
        })
}

function QuizAdminInsideQuestion(props) {
    console.log("propspropspropsprops", props)
    let {item = {}} = props;
    let [count, setCount] = useState(-1)
    let [open, setOpen] = useState(false)
    let [items, setItems] = useState([]);
    let _id = props.questionId || item.questionId || item._id || (global.localItem || {}).question;
    let questionId = _id;
    useEffect(() => {
        questionId && global.http.get('/load-quizes-by-question', {question: questionId})
            .then(items => {
                setCount(items.length)
            })
    }, [questionId])

    console.log("xxxxxxxxxxxxxxxx memo key888 ")

    return <div className='row'>
        <div className='col-xs-12'>

            <div className='pull-right'>
                {/* <small>{new Date().getTime()}</small> */}
                {global.env.isAdmin && <button className='btn btn-xs btn-primary' onClick={() => {
                    setOpen(true)
                }}>{props.title || 'Конфигуратор квизов'} {count < 0 ? `loading ... #${_id}` : `[x${count}]`}
                </button>}
                {item._id && global.env.isAdmin &&
                    <Link className='btn btn-xs btn-primary' to={'/theme-question/' + item._id} onClick={() => {
                    }}>Open Version Editor #{item._id}
                    </Link>}
            </div>
        </div>
        <div className='col-xs-12'>
            <hr/>
            {open && <MyModal
                isOpen={true}
                size={'full'}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <QuizEditor questionId={_id}
                            setCount={(count) => {
                                setCount(count)
                            }}
                ></QuizEditor>
            </MyModal>}
        </div>
    </div>
}

function StatusAndReason(props) {
    let {isFinilized, config, autoSaveFn} = props.field || {};
    console.log('propsprops!!!!props', props)
    let {localItem} = props;
    if (isFinilized) {
        return <strong>Статус: Проверено Администратором</strong>
    }
    return <Smart
        obj={localItem}
        items={[
            {name: 'Type', key: 'status', type: 'group', list: config.statuses, size: 12},
            {
                isVisible: (item) => {
                    return item.status === 'canceled' && isAdmin
                }, size: 12, key: 'reason', type: 'textarea', label: 'Причина'
            },
            {
                isVisible: (item) => {
                    return item.status === 'canceled' && !isAdmin
                }, size: 12, Component: () => {
                    return <div><small>Причина</small>
                        <pre>{localItem.reason || '-'}</pre>
                    </div>
                }
            },
        ]}
        onChange={(v) => {
            autoSaveFn && autoSaveFn({...v})
        }}
    ></Smart>
}

export function getLng(fileName) {
    let ext = (fileName || '').trim('').split('.').pop();
    console.log("qqqqq ext", ext);
    let extensions = {
        'js': 'javascript',
        'jsx': 'javascript',
        'ts': 'javascript',
        'htm': 'html',
        'html': 'html',
        'xhtml': 'html',
        'md': 'md',
        'tsx': 'javascript',
        'css': 'css',
        'scss': 'scss',
        'less': 'less',
        'ja': 'java',
        'py': 'python',
        'txt': 'text',
    }
    return extensions[ext] || 'md'
}

export default SuggestionItem
export {editQuestion, DiffSmart, QuizAdminInsideQuestion, QuizEditFields, generateSuggestion};
