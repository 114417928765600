import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Select from 'libs/Select'
import Smart from 'libs/Smart'
import Textarea from 'libs/Textarea'
import Input from 'libs/Input'
import MDEditorComp from './Suggest/MDEditorComp'
import TagSelector from './Suggest/TagSelector'
import Questions from './QuestionsSelection'
import {ReactSortable} from "react-sortablejs";
import Storage from "./Storage";


class Sort extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        let {
            field = {},
            activeInd,
            localItem,
        } = this.props;

        let onChange = () => {
            localItem.questions = value;
            // global.onChangeCount(new Date().getTime());
            this.setState({cd: new Date()})
        }

        let value = localItem.questions || [];
        this.arr = value;

        function onAdd(cmd) {
            global.http.post('/interview-question', {interview: localItem._id})
                .then(r => {
                    value[cmd](r);
                    onChange();
                })
        }


        let {ind = 0} = this.state;
        let item = value[ind];

        return <div>


            <div className="col-sm-4">
                <button className={'btn btn-xs btn-default btn-push-add'}
                        onClick={(e) => {
                            onAdd('unshift')
                        }}> + Новый вопрос
                </button>
                {/* {localItem.problemQuestions} */}
                <ReactSortable
                    handle={".drag-handle"}
                    animation={200}
                    list={value || []}
                    onEnd={(evt) => {
                        value = this.arr;
                        onChange()
                    }}

                    setList={(_value) => {
                        value = _value;
                        this.arr = _value;
                        // onChange()
                    }}>

                    {value.map((item, _ind) => {
                            let tags = item.hashTags.length;
                            let themeQuestionId = item.themeQuestionId;
                            let isError = !tags || !themeQuestionId;
                            item.answerLevel = item.answerLevel || '5'
                            return <div key={'value_wrap' + '_' + _ind}
                                        onClick={() => this.setState({item, ind: _ind})}
                                        className={'row sort-item sort-questions '
                                            + (_ind === ind ? 'active' : '')
                                            + (isError ? ' error-wrap' : '')}>
                                <div className={'ib col-sm-12 draggable-cont'}>
                                    <div className={'arr-item ' + field.defClass} onClick={() => {
                                    }}>
                                        <div className={'drag-handle'} onClick={(e) => {
                                            e.stopPropagation();
                                            return true;
                                        }}>
                                            <i className={'fa fa-bars'}></i>
                                        </div>
                                        <div>
                                            {item.name}
                                        </div>
                                        <i className="fa fa-close" onClick={(e) => {
                                            let remItem = value[_ind];
                                          //console.log("qqqqq remImte", remItem, _ind, value,);
                                            value = _.filter(value, (it, ind2) => {
                                                return ind2 !== _ind;
                                            });
                                            onChange();
                                            this.setState({ind: 0})
                                            e.stopPropagation();
                                            e.preventDefault();
                                            return true;
                                        }}></i>
                                    </div>
                                </div>
                            </div>
                        }
                    )}

                </ReactSortable>
                <button className={'btn btn-xs btn-default btn-push-add'}
                        onClick={(e) => {
                            onAdd('push')
                        }}> + Новый вопрос
                </button>
            </div>
            {item && <div className="col-sm-8">
                <div className="col-sm-12">
                    <Smart items={
                        [
                            {name: 'Type', key: 'type', type: 'group', list: ['', 'question', 'task', 'js-task']}
                        ]
                    }
                           obj={item}
                           onChange={(vv) => {
                               item.type = vv.type;
                               onChange(item)
                           }
                           }
                    ></Smart>
                </div>
                <div className="col-sm-12">

                    <div style={{width: '100%'}}>
                        <small className="pull-left">Редактировать вопрос
                            {item._id && <a href={'/interview-question/' + item._id} className="fa fa-pencil"></a>}
                            {item.date && <a href={'/interview-question/' + item.date}>{item.date}</a>}
                        </small>
                    </div>


                    <MDEditorComp value={item.name} onChange={(v) => {
                        item.name = v;
                        onChange(item)
                    }}></MDEditorComp>

                </div>
                <div className="col-sm-12">
                    <Textarea minRows="1" placeholder="Уточняющие вопросы"
                              value={item.additionalQuestions}
                              onChange={(e) => {
                                  item.additionalQuestions = e;
                                  onChange(item)
                              }
                              }/>
                </div>
                <div className="col-sm-7">
                    {item.answerLevel !== '5' && <Textarea value={item.detailedAnswer} label=""
                                                           placeholder="Проработанный ответ (если не 5) "
                                                           onChange={(e) => {
                                                               item.detailedAnswer = e;
                                                               onChange()
                                                           }
                                                           }/>}
                    <TagSelector
                        props={{
                            localItem: item,
                            onChange: (value, key) => {
                                item[key] = value;
                                onChange(item)
                            }
                        }}

                    ></TagSelector>
                    {(!item.hashTags || !item.hashTags.length) && <small>** {item.themePlain}</small>}

                </div>
                <div className="col-sm-3">
                    <Input placeholder="Время" value={item.timePlain} onChange={(v) => {
                        item.timePlain = v;
                        onChange(item)
                    }}></Input>
                </div>

                <div className="col-sm-2 padd0">
                    <small>Уровень</small>
                    <Select
                        defClass={''}
                        items={['1', '2', '3', '4', '5']}
                        value={item.answerLevel} onChange={v => {
                        item.answerLevel = v;
                        onChange(item);
                    }}></Select>
                </div>

                <div className="col-sm-12">
                    <hr/>
                    <Questions
                        themeQuestionId={item.themeQuestionId}
                        forceLoad={this.state.forceLoad}
                        setForceLoad={() => {
                            this.setState({forceLoad: new Date().getTime()})
                        }
                        }
                        hashTags={item.hashTags} onChange={(themeQuestionId) => {
                        item.themeQuestionId = themeQuestionId;
                        onChange(item)
                        if (!themeQuestionId) {
                            Storage.createThemeQuestion({
                                hashTags: item.hashTags,
                                name: item.name
                            }, (question) => {
                                item.themeQuestionId = question._id;
                                onChange(item)
                                this.setState({forceLoad: new Date().getTime()})
                            })
                        } else {

                        }

                    }
                    }></Questions>
                    {/* <hr/> */}

                </div>


            </div>}
            {!item && <div className="col-sm-8 tc">
                Выберите вопрос
            </div>}

        </div>

        // </div >
    }
}

function Interview({props}) {
    const [count, setCount] = useState(0);
  //console.log('*.....Interview Render', props);
    let {item, onChange} = props

    global.onChangeCount = setCount;

    return <div>

        <div className="row interview-item">
            <div className="col-sm-2">
                <Smart
                    items={[
                        {name: 'name', key: 'name', type: 'input'},
                        {name: 'date', key: 'date', type: 'date'},

                        {name: 'type', key: 'type', type: 'select', items: ['', 'HR', 'tech']},
                        {
                            name: 'status',
                            key: 'status',
                            type: 'select',
                            items: ['waiting', 'next_stage', 'offer', 'bad']
                        },
                        {
                            size: 12, Component: ({localItem}) => {
                                function text(it) {
                                    return it ? it.innerHTML : it;
                                }

                                return <div className="row contenteditable-input">
                                    <div className="col-sm-6">
                                        <small onClick={() => {
                                            global.document.querySelector('#table1').innerHTML = ``
                                        }}>Вставьте блок с вопросами</small>
                                        <div contentEditable={true} id="table1" onInput={(e) => {
                                            let el = e.currentTarget.querySelector('table')
                                            if (!el) {
                                                return;
                                            }
                                            let categories = Storage.getCategoriesPlain();
                                            let rows = el.querySelectorAll('tbody tr')
                                            let questions = [];

                                            rows.forEach((it, ind) => {
                                                let [question, theme, additionalQuestions, time, level, answer] = it.querySelectorAll('td');
                                                let name = text(question);
                                                let themePlain = text(theme);

                                                ((name || '').trim() !== 'Вопрос') && questions.push({
                                                    name,
                                                    themePlain,
                                                    hashTags: _.filter(categories, it => {
                                                        // console.log("qqqqq category", it);
                                                        return it.title === themePlain
                                                    }).map(it => it._id),
                                                    additionalQuestions: text(additionalQuestions),
                                                    answerLevel: text(level),
                                                    timePlain: text(time),
                                                    detailedAnswer: text(level),
                                                })


                                            })
                                            localItem.questions = questions;
                                            global.onChangeCount(new Date().getTime());
                                            // console.log("qqqqq vvv!!!!", questions);
                                        }
                                        }>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <small onClick={() => {
                                            global.document.querySelector('#table2').innerHTML = ''
                                        }}>Блок Шапки</small>
                                        <div contentEditable={true} id="table2" onInput={(e) => {
                                          //console.log("qqqqq vvвввv", e.currentTarget.innerHTML);
                                            let el = e.currentTarget.querySelector('table')
                                            if (!el) {
                                                return;
                                            }
                                            let row = el.querySelectorAll('tbody tr')[1].querySelectorAll('td');

                                            // let localItem = {}

                                            localItem.name = text(row[0])
                                            localItem.client = text(row[1])
                                            localItem.position = text(row[2])
                                            localItem.partner = text(row[3])
                                            localItem.video = row[4].querySelector('[href]').getAttribute('href')
                                            localItem.stack = text(row[5])

                                            global.onChangeCount(new Date().getTime());
                                            // global.UpdateRootFn();
                                          //console.log("qqqqq localItem", localItem);
                                        }
                                        }>
                                        </div>
                                    </div>
                                </div>
                            }
                        },
                        {type: 'HR', size: 12},
                        {name: 'video', key: 'video', type: 'input'},
                        {name: 'client', key: 'client', type: 'input'},
                        {name: 'position', key: 'position', type: 'input'},
                        {name: 'partner', key: 'partner', type: 'input'},
                        {name: 'stack', key: 'stack', type: 'input'},
                        {name: 'feedback', key: 'feedback', type: 'textarea'},
                        {name: 'growPoints', key: 'growPoints', type: 'textarea'},

                        // { type: 'HR', size: 12 },
                        // {
                        //     size: 12,
                        //     autoSave: true,
                        //     Component: Sort
                        //  }

                    ]}
                    defSize={6}
                    obj={item}
                    onChange={(v, _id) => {
                      //console.log("qqqqqon Change", v, _id);
                        setCount(new Date().getTime())
                    }
                    }></Smart>
            </div>
            <div className="col-sm-10">

                <Smart
                    items={[

                        {
                            size: 12,
                            autoSave: true,
                            Component: Sort
                        }

                    ]}
                    obj={item}
                    onChange={(v, _id) => {
                      //console.log("qqqqqon Change", v, _id);
                        setCount(new Date().getTime())
                    }
                    }></Smart>
            </div>
        </div>
    </div>
}


export default Interview
