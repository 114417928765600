import React, {useEffect, useRef, useState} from 'react';
import _ from 'underscore';
import './Train.css'
import {
    Link, Outlet
} from "react-router-dom";
import AudioShort from "./AudioShort/AudioShort";

import QuizPreview from "../Suggest/QuizPreview";
import {CountDownSecs} from "./CoundDownSec";
import {isErr} from "./DisableScreenWhenTrain";

let lastToggle = new Date().getTime();

function getHash() {
    return Math.round(new Date().getTime() / 1000)
}

let sessionHash = getHash();

function Layout2(props) {
    let [process, setProcess] = useState(false);
    let [isResults, setIsResults] = useState(false);
    let [timers, setTimers] = useState({});
    let [history, setHistory] = useState({});
    let [items, setItems] = useState(props.items || []);
    let [activeInd, setActiveInd] = useState(0);
    let [timeoutIteration, setTimeoutIteration] = useState(0);


    window.onStopTrain = () => {
        navigate('/')
    }

    window.reloadTrain = () => {
        console.log("qqqqq __ TRAIN RELOAD",);

        resetHistory()
        loadTrains({})
    }

    useEffect(() => {
        console.log("qqqqq propspspspspsitems", props.items);
        props.items && setItems(props.items)
    }, [props.items])

    useEffect(() => {
        let {err} = isErr();

        if (err) {
            return;
        }
        // console.log("qqqqq [[ USE EFFECT ]]", );
        loadTrains({})
        resetHistory()

        window.startTrain && window.startTrain()
        return () => {
            // console.log("qqqqq [[ UNMOUNT ]]", );
            window.stopTrain && window.stopTrain()
        }
    }, [])

    async function loadTrains(data, cb) {
        setIsResults(false)

        if (props.items) {
            return;
        }
        if (props.getItems) {
            let items = await props.getItems()
            cb && cb(items)
            setItems([...items])
            console.log("qqqqq get ITTT", items);
            return;
        }

        let res = await http.get(props.url || '/quiz-load', {...props.loadDetails || {}, total: 3})//fakeData
        console.log("qqqqq API LOAD ITEMS", res);
        cb && cb(res)
        setItems([...res]);

        return res;
    }

    function resetHistory(forceReset) {
        setProcess(false)
        setTimers({})
        setHistory({})
        setActiveInd(-1)
        setTimeout(() => {
            setActiveInd(0)
            console.log("qqqqq __ reload train", 0, -1);

        })
    }


    let extendHistory = (activeInd, data) => {
        setHistory({...history || {}, [activeInd]: {...history[activeInd] || {}, ...data}})
    }


    let onSelectActive = (ind) => {
        setActiveInd(ind)
        lastToggle = new Date().getTime();
    }


    function onNext() {
        let newInd = ++activeInd % items.length;
        if (newInd == 0) {
            console.log("qqqqq Need to go to final",);
            return onFinal()
        }
        setActiveInd(newInd)
    }

    function onFinal() {
        setIsResults(true)
        setActiveInd(0)
    }

    function pubItemForShortHTTP(item) {
        return item
    }

    function onSubmit(data) {
        setProcess(false)
        extendHistory(activeInd, {...data, isProcess: false})

        let info = {
            item: pubItemForShortHTTP(item),
            data: pubData(data),
            timer,
            opts: activeItem.opts,
        }

        global.http.post(props.submitUrl || '/save-quiz-history', info)

        console.log("qqqqq on [[ SUBMIT ]] task gtm", data, item, info);
    }

    function onChange(data) {


        let info = {
            item: pubItemForShortHTTP(item),
            data: pubData(data),
            timer,
            opts: activeItem.opts || {}
        }
        global.http.post(props.changeUrl || '/set-quiz-history-fbaq', info)
        console.log("qqqqq on << CHANGE HISTORY ", JSON.stringify(info, null, 4));
        console.log("qqqqq on << CHANGE HISTORY SHHSHHH", data.sessionHash, data.hash, data.attempt);
        props.onChange && props.onChange(info)
    }

    function pubData(data) {
        return data;
    }


    function onStart(data) {
        console.log("qqqqq on [[ START ]] task gtm", data);
        lastToggle = new Date().getTime();
        setTimeoutIteration(++timeoutIteration)
        setProcess(true)
        extendHistory(activeInd, {isProcess: true})
    }

    function onReStart() {
        onStart()
        setTimers({...timers, [activeInd]: getStartTime()})
    }

    function onStopTimer() {
        extendHistory(activeInd, {isProcess: false})
    }

    function getStartTime() {
        let time = item.time || 60;
        return {time, totalTime: time, deltaPerc: 100 / time, perc: 100};
    }

    let {woNext, isExam, maxAttemptsCount} = props || {}
    let activeEl = useRef();

    let activeItem = items[activeInd] || {};
    let item = activeItem.item || {};
    let {answerType} = item;
    let hist = history[activeInd] || {};

    timers[activeInd] = timers[activeInd] || getStartTime()
    let timer = timers[activeInd];
    let isFast = new Date().getTime() - lastToggle < 500

    let isActive = hist.isProcess && !hist.isSubmit;
    let isErrRec = timer.perc < 30 && timer.time > -1;


    if (isResults) {
        let Result = props.Result;
        return <Result history={history} timers={timers} items={items} opts={props.opts}></Result>
    }
    console.log("qqqqq qq get IT __ TRAIN RENDER", item, activeInd, woNext, items, isActive, hist);

    return <div>
        {item._id}
        {!woNext && (items || []).map((it, ind) => {
            let hist = history[ind] || {};
            // correctDot
            // incorrectDot
            return (<div
                style={{opacity: process ? .4 : 1}}
                onClick={() => {
                    onSelectActive(ind)
                }}
                key={ind}
                className={'dotsPreview ' + (activeInd == ind ? ' active' : '') + (hist.isSubmit ? (hist.isCorrect ? ' correctDot' : ' incorrectDot') : '')}>
            </div>)
        })}
        <div className={'trainContent'}>

            {/*<div>PERC: {timer.time} / {timer.totalTime}</div>*/}
            <div className={'timerWrap'}>
                <div className={"timerProgress "
                    + (isFast ? ' fastTimer' : '')
                    + (isErrRec ? ' timerError' : '')}
                     style={{width: (timer.perc) + '%'}}></div>
            </div>
            {/*{timer.time}*/}
            {answerType === 'audio' && <AudioShort
                ref={activeEl}
                woNext={woNext}
                isExam={isExam}
                activeInd={activeInd}
                // onStart={(v) => {
                //     console.log("qqqqq on Change AUIDO SHORT STARTTTTTT", v, hist);
                //     onStart()
                // }}
                isErrRec={isErrRec}
                time={timer.time}
                onAttempt={(attempt, hash) => {
                    console.log("qqqqq attempt", attempt);
                    // if (attempt == 1) {
                    //     sessionHash = hash;
                    // }
                }}
                onReStart={(v) => {
                    console.log("qqqqq on Change AUIDO SHORT REEEEEESTARTTTTTT", v, hist);
                    onReStart()
                }}
                onStop={(v) => {
                    console.log("qqqqq on Change AUIDO SHORT STOPPPPPPPPPP@@@@@@@@@@@@@@@", v, hist);
                    onStopTimer()
                }}
                onChange={(data, attempts) => {
                    if (!data?.attempt) {
                        return;
                    }
                    console.log("qqqqq << on Change AUIDO SHORT:: on CHange", data, attempts);
                    onChange(data)
                }}
                onNext={() => {
                    console.log("qqqqq cON NEXTTTTT",);
                    onNext()
                }}
                onSubmit={(v) => {
                    console.log("qqqqq on << SUBMITTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT Change AUIDO SHORT", v);
                    onSubmit({
                        isSubmit: true,
                    })

                }}
                title={item.name}
            ></AudioShort>}
            {answerType === 'quiz' && <div>
                <QuizPreview
                    isGreyForCorrect={hist.isTimeout}
                    quiz={{...item, _id: activeItem?.opts?.quiz}}
                    isExam={false}
                    activeInd={activeInd}
                    history={hist}
                    quizOpenNextIfCorrectMs={props.quizOpenNextIfCorrectMs}
                    quizOpenNextIfIncorrectMs={props.quizOpenNextIfIncorrectMs}
                    forceHistory={true}
                    ref={activeEl}
                    onStart={() => {
                        onStart()
                    }}
                    onNext={() => {
                        onNext()
                    }}
                    onSubmit={(chosen, opts) => {
                        console.log("qqqqq optsoptsoptsopts", opts);

                        onSubmit({
                            chosen,
                            isSubmit: true,
                            isTimeout: opts?.isTimeout,
                            isCorrect: opts?.isCorrect,
                            correctAnswer: opts?.correctAnswer,
                            selectedAnswer: opts?.selectedAnswer,
                        })
                    }}
                ></QuizPreview>
            </div>}

            <CountDownSecs
                onStop={() => {
                    console.log("qqqqq AUIDO SHORT!! COUNTDOWN SUBMITTTTT STOPPPPPP", hist, history[activeInd], history, activeEl.current);
                    if (hist.isSubmit) return
                    setTimers({...timers, [activeInd]: {...timers[activeInd], time: -1, perc: 0}})
                    activeEl.current?.timeOut && activeEl.current?.timeOut();
                }}
                hideValue={true}
                totalTime={timer.totalTime}
                count={timer.time}
                iteration={timeoutIteration}
                active={isActive}
                onChange={(time, perc) => {
                    console.log("qqqqq on trigger timer Change", time, perc);
                    setTimers({...timers, [activeInd]: {...timers[activeInd], time, perc}})
                }}
            ></CountDownSecs>
        </div>


    </div>
}


let fakeData = [

    {
        answerType: 'quiz',
        name: 'В чем суть квизов?',
        question: 1224,
        quiz: 999,
        time: 1,
        variations: [
            {name: 'v1', isCorrect: true},
            {name: 'v2', isCorrect: false},
            {name: 'v3', isCorrect: false},
        ]
    },
    {
        answerType: 'quiz', name: 'В чем суть квизов222?',
        question: 1034,
        quiz: 997,

        time: 1,
        variations: [
            {name: 'v1', isCorrect: false},
            {name: 'v2', isCorrect: true},
            {name: 'v3', isCorrect: false},
        ]
    },

]
export default Layout2
