import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import UserFilterSelector from "../Suggest/UserNewSel";
import {Daily, DayPicker} from "./UserStatsOld";
import {toOdb} from "../Stats";
import CalendarView from "./CalendarView";
import Perc from "../Suggest/Perc";
import CircleIndicator from "./CircleIndicator";
import MyModal from "../../libs/MyModal";
import CoursesStats from "./CoursesStats";
import MainAdmin from "../TrainMethods/MainAdmin";
import {hashHistory} from 'react-router'
import NFN from "../../NFN";

function TimePicker(props) {
    let {value, onChange} = props;

    return <div className={'no-select'}>
        <div className="ib">
            <DayPicker title="Старт" value={value.start} onChange={(v) => {
                value.start = v;
                onChange({...value})
            }
            }></DayPicker>
        </div>
        <div className="ib">
            <DayPicker title="Конец" value={value.end} onChange={(cd) => {
                value.end = cd;
                onChange({...value})
            }
            }></DayPicker>
        </div>
    </div>

}


function Layout2(props) {
    let cd = new Date().getTime();
    let _days = 1000 * 24 * 3600;
    let tabs = ['stat', 'courses']


    let [userDetails, setUserDetails] = useState({})
    let [tab, setTab] = useState(getHash('tab') || 'stat')
    let [user, setUser] = useState(getHash('user', true) || 1002)
    let [data, setData] = useState({})
    let [selected, setSelected] = useState({})
    let [stats, setStats] = useState({})
    let [interviewStats, setInterviewStats] = useState({})
    let [interviews, setInterviews] = useState([])
    let [educationCd, setEducationCd] = useState({start: cd - _days * 10, end: cd})
    let [calendarCd, setCalendarCd] = useState({start: cd - _days * 30, end: cd + _days * 14})
    let [interviewOpen, setInterviewsOpen] = useState(false)

    console.log("qqqqq user333", {user, tab});
    function getHash (_key, isNum) {
        let hash = window.location.hash;
        hash = hash.replace('#', '')
        let arr = hash.split('&')
        let res = ''
        _.each(arr, (item, ind) => {
            let [key, value] = item.split('=')
            if (key == _key) {
                res = isNum ? +value : value;
            }

        })
        return res;

    }


    useEffect(() => {
        console.log("qqqqq ",);
        global.http.get('/get-all-stats2', {
            users: [user],
            start: toOdb(educationCd.start),
            end: toOdb(educationCd.end)
        }).then(r => {
            setData(r)
            setStats(r.stats.reduce((acc, it) => {
                acc[it.user] = {...acc[it.user] || {}, [it.odb]: it}
                return {...acc}
            }, {}))
        })

    }, [user, educationCd.start, educationCd.end])

    let setHash = () => {
        let str = [user ? `user=${user}` : ``, tab ? `tab=${tab}` : ``].filter(it => it).join("&")
        window.history.replaceState(undefined, undefined, window.location.pathname + ('#' + str))
    }
    useEffect(() => {
        setHash()

    }, [user, tab])
    useEffect(() => {
        console.log("qqqqq ",);
        global.http.get('/get-interview-stats', {
            users: [user],
            start: toOdb(calendarCd.start),
            end: toOdb(calendarCd.end)
        }).then(({arr, userDetailsArr}) => {

            let clName = {
                'pending': 'pending',
                '': 'pending',
                'waiting': 'pending',
                'offer': 'good',
                'next_stage': 'next_stage',
                'declined': 'bad',
            }

            setUserDetails(userDetailsArr.reduce((acc, it) => {
                return {...acc, [it._id]: it}
            }, {}))
            setInterviews(arr);
            setInterviewStats(arr.reduce((acc, it) => {
                let odb = toOdb(it.date)
                let newArr = [...(acc[it.user] || {})[odb] || [], it]
                acc[it.user] = {...acc[it.user] || {}, [odb]: newArr}
                acc.total.count++
                acc.total[clName[it.status || '']]++
                return {...acc}
            }, {total: {count: 0, offer: 0, good: 0, next_stage: 0, bad: 0, pending: 0}}))
        })
    }, [user, calendarCd.start, calendarCd.end])

    let total = interviewStats.total || {};
    let salesOpenTime = new Date((((userDetails || {})[user] || {}).customData || {}).salesOpenTime || 9999999999999999).getTime()

    console.log("qqqqq salesOpenTime", {user, tab});
    return <div>
        <UserFilterSelector
            value={user}
            userId={user}
            onChange={(_user) => {
            setUser(_user._id)
            setUserDetails(_user)
        }}></UserFilterSelector>
        <a onClick={() => {
            alert('Данный функционал в разработке')
        }}>Конфигуратор группы юзеров</a>
        <div>
            {(tabs || []).map((it, ind) => {
                return (<span key={ind}
                              className={(tab == it ? 'active' : '')}
                              style={{display: 'inline-block', padding: '5px 10px', cursor: 'pointer'}} onClick={() => {
                    setTab(it)
                }}>
                    <NFN>{it}</NFN>
                </span>)
            })}


        </div>

        {!user && <div className={'w100 tc'} style={{padding: '20px'}}>Выберите пользователя</div>}

        {!!user && tab === 'courses' && <>
            <hr/>
            <MainAdmin user={user}></MainAdmin>
        </>}
        {!!user && tab === 'stat' && <>
            {!user && <div className={'w100 tc'} style={{padding: '20px'}}>Выберите пользователя</div>}
            {!!user && <div className="row">


                <div className="col-sm-12">
                    <hr/>
                </div>
                <div className="col-sm-4">
                    <TimePicker value={educationCd} onChange={setEducationCd}></TimePicker>
                    <hr/>
                    <small>
                        Активность по дням
                    </small>

                    <CalendarView
                        start={educationCd.start} end={educationCd.end} Component={({date, datePub}) => {
                        let isSt = (stats[user] || {})[date];
                        let st = (stats[user] || {})[date] || {}
                        let total = st.total || {};
                        let courses = st.courses || [];
                        return <div className={isSt ? 'stOk' : 'stEmpty'} onClick={() => {
                            setSelected({odb: date, type: 'interview', data: st})
                        }
                        }>
                            <small>{datePub}</small>
                            <Perc value={total.coursePerc} minWidth={1}></Perc>
                            <div></div>
                            <small>
                                <CircleIndicator title={'По времени пришел / ушел'} value={total.totalWorkSitHours}
                                                 borders={[6, 8]}></CircleIndicator>
                                <CircleIndicator title={'Сколько именно работал (без простоя)'}
                                                 value={(total.totalMinutes / 60).toFixed(1)}
                                                 borders={[3, 5]}></CircleIndicator>
                                <CircleIndicator title={'Среднее кол-во рабочих минут'}
                                                 value={(total.total / total.totalMinutes).toFixed(1)}
                                                 borders={[10, 20]}></CircleIndicator>
                            </small>
                        </div>
                    }
                    }></CalendarView>
                    <div>
                        <hr/>
                        <TimePicker value={calendarCd} onChange={setCalendarCd}></TimePicker>

                        <hr/>
                        <div>Дата открытия заявки: <>{salesOpenTime ? toOdb(salesOpenTime) : 'Заявка еще не открыта'}</>
                        </div>
                        <small>
                            <div className="statsList">Всего: {total.count}

                            </div>
                            <div className="statsList">Оффер: {total.offer}
                                <Perc
                                    minWidth={0}
                                    color={'#1DB198'}
                                    value={100 * total.offer / (total.count || 1)}></Perc>
                            </div>
                            <div className="statsList">След этап: {total.next_stage}
                                <Perc minWidth={0}
                                      color={'#7c7ce0'}

                                      value={100 * total.next_stage / (total.count || 1)}></Perc>

                            </div>
                            <div className="statsList">Слили: {total.bad}
                                <Perc minWidth={0}
                                      color={'#f23939'}

                                      value={100 * total.bad / (total.count || 1)}></Perc>

                            </div>
                            <div className="statsList">Ждем: {total.pending}
                                <Perc minWidth={0}
                                      color={'#555'}

                                      value={100 * total.pending / (total.count || 1)}></Perc>

                            </div>
                            <div></div>
                        </small>
                        <CalendarView start={calendarCd.start} end={calendarCd.end} Component={({date, datePub}) => {
                            let st = (interviewStats[user] || {})[date] || []
                            return <div>
                                <small
                                    className={(st && st.length ? 'stOk ' : 'stEmpty ') + (salesOpenTime && salesOpenTime > new Date(date).getTime() ? 'salesOpen' : 'salesPassive')}
                                    style={{padding: '3px'}}>{datePub}</small>
                                {(st || []).map((it, ind) => {
                                    let status = it.status
                                    let clName = {
                                        'pending': 'circleEmpty',
                                        '': 'circleEmpty',
                                        'waiting': 'circleEmpty',
                                        'offer': 'circleGreen',
                                        'next_stage': 'circleBlue',
                                        'declined': 'circleRed',
                                    }

                                    return (<div key={ind}>
                                        <CircleIndicator title={'Результат собеседования'}
                                                         clName={clName[status || '']}
                                        ></CircleIndicator>
                                    </div>)
                                })}


                            </div>
                        }
                        }></CalendarView>
                        <a onClick={() => {
                            setInterviewsOpen(true)
                        }}>Детали по интервью x{interviews.length}</a>

                        <MyModal size={'full'} isOpen={interviewOpen} onClose={() => setInterviewsOpen(false)}>
                            <table className={'animChild mt-10 table table-bordered table-striped '}>
                                <thead>
                                <tr>
                                    <th>Название</th>
                                    <th>Дата</th>
                                    <th>Тип</th>
                                    <th>Статус</th>
                                    <th>Пользватель</th>
                                    <th>Ссылка</th>
                                </tr>
                                </thead>
                                {(interviews || []).map((it, ind) => {
                                    console.log("qqqqq itenridvjsajsdvjasjdvjasd", it);
                                    return (<tbody key={ind}>
                                    <tr>
                                        <td>{it.name}</td>
                                        <td>{toOdb(it.date)}</td>
                                        <td>{it.type}</td>
                                        <td>{it.status}</td>
                                        <td><Link to={'/users/' + it.user}>Пользователь #{it.user}</Link></td>
                                        <td><Link to={'/interviews/' + it._id}>Интервью #{it._id}</Link></td>
                                    </tr>
                                    </tbody>)
                                })}
                            </table>

                        </MyModal>


                    </div>
                </div>
                <div className="col-sm-8">
                    <SelectedInformation selected={selected} userId={user}></SelectedInformation>
                </div>

            </div>}
        </>}

    </div>
}

function SelectedInformation(props) {
    let {selected, userId} = props;
    let [open, setOpen] = useState(false)

    let odb = selected.odb;
    if (!userId) {
        return <div>Выберите юзера</div>
    }
    if (!odb) {
        return <div>Выберите дату</div>
    }

    let data = selected.data;
    let total = data.total || {};
    console.log("qqqqq selelcted333", selected, data);

    return <div>
        Выбранная дата: {selected.odb}
        <div>
            Время работы: {total.totalWorkSitHours}ч = {total.startTime} - {total.endTime}
        </div>
        <div>
            Всего активное время: {(total.totalMinutes / 60).toFixed(1)}ч
        </div>
        <div>
            Средняя активность: {(total.total / (total.totalMinutes)).toFixed(0)} активных сек. в минуту
        </div>
        <hr/>
        {Boolean((data.courses || []).length) && <div>
            <div style={{padding: '5px'}}>
                Курсы {total.coursePerc}% <a onClick={() => setOpen(!open)}>детали</a>
            </div>
            {open && <>{(data.courses || []).map((it, ind) => {
                return (<Link to={'/course/' + it.course} key={ind} className={'ib'}
                              style={{width: '200px', padding: '5px', overflow: 'hidden'}}>
                    {it.name}
                    <Perc value={it.perc}/>
                </Link>)
            })}
            </>}
        </div>}
        <hr/>

        <Daily userId={userId} odb={odb}></Daily>
    </div>

}

export {TimePicker, CalendarView}

export default Layout2
