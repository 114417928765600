import React, { useState } from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";

function _getValue(values) {
  return function (key, period) {
    return +(((values || {})[key] || {})[period]) || 0;
  }
}

function getPeriodAfter(periods, index, weeks) {
  return periods[Math.round(index + weeks / 4)]
}

function _setValue(values) {
  return function (value, key, period) {
    values = values || {};
    values[key] = values[key] || {};
    values[key][period] = value || '0';

    return values;
  }
}
let functions = {
  // { name: 'Всего Джун', key: 'contracts_ooo_jun' },
  // { name: 'Новых Джун', key: 'new_contracts_ooo_jun' },
  // { name: 'Слитых Джун', key: 'closed_contracts_ooo_jun' },

  hire(values, periods) {
    let getValue = _getValue(values);
    let setValue = _setValue(values);

    let types = ['agent', 'djun', 'middle', 'mtl', 'tl', 'mentor', 'intern'];

    _.each(periods, (period, periodInd) => {
      _.each(types, (type, ind) => {
        let key2 = 'count_screening_' + type;
        setValue('0', key2, period)
      })
    })
    _.each(periods, (period, periodInd) => {
      // let value = 0;
      // let _value = 0;

      _.each(types, (type, ind) => {

        let key_count = 'count_screening_' + type//type.replace(/hire_/gi, 'count_screening_')
        let key_perc = 'pass_screening_' + type //type.replace(/hire_/gi, 'pass_screening_')
        let key_weeks = 'weeks_screening_' + type//type.replace(/hire_/gi, 'weeks_screening_')
        let key_fire = 'fire_' + type;//type.replace(/hire_/gi, 'fire_')
        let devs_key = 'devs_' + type;

        let perc = (getValue(key_perc, period) || getValue('pass_screening', period)) / 100;
        let weeks = (getValue(key_weeks, period) || getValue('weeks_screening', period));
        let newPeriod = getPeriodAfter(periods, periodInd, weeks);

        let v0 = getValue('hire_' + type, period);
        let v1 = Math.round(v0 * perc);


        setValue(v1, key_count, newPeriod)
      })

      // calcSum('count_screening')
      // calcSum('devs')
      // calcSum('hire')
    })

    function getStartValue(key) {
      return getValue(key, 'start') || getValue(key, periods[0])
    }

    let current_devs = { devs: getStartValue('devs') }
    _.each(types, (type, ind) => {
      let key = 'devs_' + type;
      current_devs[key] = getStartValue(key)
    })

    _.each(periods, (period, periodInd) => {
      _.each(types, (type, ind) => {
        let key = 'devs_' + type;
        let curDevs = current_devs[key];
        // let hire = getValue('hire_' + type, period)
        let fire = getValue('fire_' + type, period)
        let count_screening = getValue('count_screening_' + type, period);
        let migrate_to = getValue('migrate_to_' + type, period);

        let devs = curDevs + count_screening - fire + migrate_to;

      //console.log('migrate_to_', migrate_to)
        setValue(devs, key, period)
        current_devs[key] = devs;
      })
    })


    let keys = ['count_screening', 'fire', 'devs', 'hire', 'migrate_to'];
    _.each(keys, (_key, ind) => {
      _.each(periods, (period, periodInd) => {
        let value = 0;
        _.each(types, (type, ind) => {
          value += getValue(_key + '_' + type, period) || 0;
        })
        setValue(value, _key, period)
      })
    })



    return values;
  },
  calcContracts(values, periods) {
    let getValue = _getValue(values);
    let setValue = _setValue(values);
    let types = ['agent', 'ooo_jun', 'ooo_mid']
    // let types = ['ooo_mid']
    values = values || {};

    _.each(types, (type) => {
      _.each(periods, (period, ind) => {
        if (ind > 0) {
          let prevPeriod = periods[ind - 1];

          let key1 = 'new_contracts_' + type;
          let key2 = 'closed_contracts_' + type;
          let key0 = 'contracts_' + type;
          let v1 = getValue(key1, prevPeriod);
          let v2 = getValue(key2, prevPeriod);
          let v0 = getValue(key0, prevPeriod);
          global.values = values;

          setValue(v0 + v1 - v2, key0, period)
        }
      })
    })
    return values;
  }
}

let fns = {
  // 'contracts': 'calcContracts',
  // 'new_contracts': 'calcContracts',
  // 'old_contracts': 'calcContracts',
}

function generateContracts(name, key) {
  return {
    name, key, childs: [
      { name: 'Aгент', key: key + '_agent' },
      { name: 'Интерн', key: key + '_intern' },
      { name: 'Джун', key: key + '_djun' },
      { name: 'Джун+', key: key + '_djun+' },
      { name: 'Миддл', key: key + '_middl' },
      { name: 'Ментор', key: key + '_mentor' },
      { name: 'МТЛ', key: key + '_mtl' },
      { name: 'ТЛ', key: key + '_tl' },
    ]
  }
};
function generateNewContracts(name, key) {
  return {
    name: 'Контрактов ' + name, key: 'all_contracts_' + key, childs: [
      { name: 'Всего', key: 'all_contracts_' + key },
      { name: 'Новых', key: 'new_contracts_' + key },
      { name: 'Слитых', key: 'closed_contracts_' + key },
    ]
  }
};

function generateDevs(name, key) {
  return {
    name, key, fn: 'hire', childs: [
      { name: 'Агент', key: key + '_agent', fn: 'hire' },
      { name: 'Интерн', key: key + '_intern', fn: 'hire' },
      { name: 'Джун', key: key + '_djun', fn: 'hire' },
      { name: 'Джун+', key: key + '_djun+', fn: 'hire' },
      { name: 'Миддл', key: key + '_middle', fn: 'hire' },
      { name: 'Ментор', key: key + '_mentor', fn: 'hire' },
      { name: 'МТЛ', key: key + '_mtl', fn: 'hire' },
      { name: 'ТЛ', key: key + '_tl', fn: 'hire' },
    ]
  }
}
let config = [
  { name: 'Время сотрудника' },
  { name: 'Недель обучения', key: 'weeks_learning' },
  { name: 'Загрузка' },
  { name: 'Средняя ЗП' },
  { name: 'Средний рейт' },
  { name: 'Корп налог' },
]
let names = [
  { name: 'Оборот', key: 'revenue' },
  // { name: 'Оборот на основе кол-ва', key: 'oborot2' },
  { name: 'Прибыль', key: 'profit' },
  { name: 'Рентабельность', key: 'rentab' },
  // { name: 'Себестоимость', key: 'revenue' },
  generateDevs('Девов', 'devs'),
  generateDevs('Найм', 'hire'),
  generateDevs('Прошли скрининг', 'count_screening'),
  generateDevs('K прохода скрининга %', 'pass_screening'),
  generateDevs('Недель обучения до скрининга', 'weeks_screening'),
  generateDevs('Текучка', 'fire'),
  generateDevs('Смена грейда', 'migrate_to'),

  generateNewContracts('Всего', 'any'),
  generateNewContracts('Агент', 'agent'),
  generateNewContracts('Интерн', 'intern'),
  generateNewContracts('Джун', 'jun'),
  generateNewContracts('Джун+', 'jun+'),
  generateNewContracts('Миддл', 'middle'),
  generateNewContracts('Ментор', 'mentor'),
  generateNewContracts('МТЛ', 'mtl'),
  generateNewContracts('ТЛ', 'tl'),
  {
    name: 'Контрактов', key: 'all_contracts_ooo', childs: [
      { name: 'Всего Джун', key: 'contracts_ooo_jun', fn: 'calcContracts' },
      { name: 'Новых Джун', key: 'new_contracts_ooo_jun', fn: 'calcContracts' },
      { name: 'Слитых Джун', key: 'closed_contracts_ooo_jun', fn: 'calcContracts' },
      // { name: 'Развитие', key: 'contracts_extended' },
      { name: 'Всего Миддл', key: 'contracts_ooo_mid', fn: 'calcContracts' },
      { name: 'Новых Миддл', key: 'new_contracts_ooo_mid', fn: 'calcContracts' },
      { name: 'Слитых Миддл', key: 'closed_contracts_ooo_mid', fn: 'calcContracts' },
      { name: 'Всего Агент', key: 'contracts_agent', fn: 'calcContracts' },
      { name: 'Новых Агент', key: 'new_contracts_agent', fn: 'calcContracts' },
      { name: 'Слитых Агент', key: 'closed_contracts_agent', fn: 'calcContracts' },

    ]
  },
  {
    name: 'Фикс Выручка', key: 'revenue_fix', childs: [
      { name: 'Сумма актов', key: 'revenue_fix_acts' },
      { name: 'Сумма оценок', key: 'estimated' },
    ]
  },
  {
    name: 'Онбординг Тайм', key: 'onboarding', childs: [
      { name: 'Агент до заявки', key: 'onboarding_screen_agent' },
      { name: 'ООО до заявки', key: 'onboarding_screen_ooo' },
      { name: 'Посадка ООО', key: 'onboarding_job_search' },
      { name: 'Посадка ООО миддл', key: 'onboarding_job_search_middle' },
      { name: 'Посадка Агент', key: 'onboarding_job_search_agent' },
    ]
  },
  generateContracts('Среднее время контракта', 'contract_time'),
  generateContracts('Загрузка', 'load'),
  generateDevs('Средняя ЗП', 'zp'),
  generateDevs('Средний рейт', 'rate'),

  {
    name: 'Корп налог', key: 'korp', childs: [
      { name: 'Aгент', key: 'korp_agent' },
      { name: 'ООО', key: 'korp_ooo' },
    ]
  },


  { name: 'Отпуска', key: 'relax_days' },
  generateDevs('Удовлетворенность сотрудников?', 'nps_dev'),
  {
    name: 'Config',
    childs: [
      { name: 'Дней', key: 'days', },
      // { name: '% удаленщиков', key: 'remote_perc', },
    ]
  }

]


export { fns, config, names, functions }
