global.env.nameFn = (name) => {
    let obj = {
        pending: 'Ожидает',
        submitted: 'Подтвержден',
        started: 'Начался',

        HR: 'ХР',
        closed: 'Закрытая',
        urgency: 'Срочность',
        norm: 'Обычная',
        urgent: 'Срочная',
        answered: 'Отвечено',
        opened: 'Открытая',
        open: 'Открытая',
        create: 'Создать',
        save: 'Сохранить',
        name: 'Название',
        csore: 'Апрувы',
        lock: 'Занят',
        CScore: 'Скоринг',
        'lock?': 'Занят?',
        'comment': 'Коммент',
        'IntCount': 'Кол-во',
        'type': 'Тип',
        'difficulty': 'Сложность',
        'SuggestCount': 'Кол-во предложений',
        'Facts': 'Кол-во фактов',
        'UseCases / LC solutions': 'Кол-во решений',
        'quizCount': 'Кол-во квизов',
        '_id': 'ИД',
        'problemQuestions': 'Проблемные вопросы',
        'questions': 'Вопросы',
        'question': 'Вопрос',
        'client': 'Клиент',
        'status': 'Статус',
        'date': 'Дата',
        cd: 'Дата',
        'js-task': 'Код задача',
        'Sale manager': 'Саилз',
        'Partner': 'Партнер',
        'New HashTags': 'Новые хэштеги',
        'HashTags': 'Хэштеги',
        'Interview Questions': 'Вопросы интервью',
        'ThemeQuestions': 'Тематич. вопросы',
        'ThemeQuestion': 'Тематич. вопрос',
        'Interviews': 'Интервью',
        'InterviewQuestions': 'Интервью вопросы',
        "interviewQuestion": 'Вопрос с интервью',
        "Interview": 'Интервью',
        '1d': '1 день',
        '1w': '1 нед',
        '2w': '2 нед',
        '1m': '1 мес',
        'Start': 'Старт',
        'End': 'Конец',
        'stat': 'Статистика',
        'main': 'Главная',
        'courses': 'Курсы',
        'progress': 'Прогресс',
        'Courses & Questions': 'Курсы и вопросы',
        'UserId': 'Пользователь',
        'Preview Page': 'Превью',
        'Reset Stats': 'Перезагрузка статистики',
        'tag': 'Тэг',
        'surname': 'Фамилия',
        'username': 'Логин',
        'userGrade': 'Грейд',
        'roles': 'Роли',
        'approvedTags': 'Аппрув Тэги',
        'ignoreTags': 'Игнор Тэги',
        'isApprovedAll': 'Все теги?',
        'active': 'Актив',
        'unactive': 'Не актив',
        'removed': 'Удален',
        'plain_password': 'Пароль',
        'active_search': 'На поиске',
        'pause_search': 'Пауза поиска',
        'on_project': 'На проекте',
        'endDate': 'Дата конец',
        'Get DebugLogs': 'Получить дебаг логи',
        'Generate': 'Генерация',
        "Regenerate User's History": 'Пересобрать историю юзера',
        "Clear CourseHistory to Zero": 'Очистить историю',
        "Ignore Tags": 'Игнор Тэги',
        "Approve Tags": 'Аппрув Тэги',
        "Approve All Tags?": 'Аппрув всех тэгов',
        "Removed / Finished Courses": 'Удаленные или закончившиеся курсы',
        "+ Add item": '+ Запись',
        "isCV?": 'Карьерный трек?',
        "User": 'Пользователь',
        "quizExamSize": 'Кол-во квизов в экзамене',
        "examSize": 'Размер экзамена',
        "Questions To Find": 'Воопрос на поиск',
        "Questions List": 'Список вопросов',
        "Tags Questions List": 'Вопросы по тегу',
        "Add Bad Word": 'Добавить игнор слова',
        "Favorite": 'Избранное',
        "NotFavorite": 'Не Избранное',
        "inviteId": 'Инвайт',
        "Time": 'Время',
        "Edit Module": 'Редактирование модуля',
        "Preview Course Module Wrapper ": 'Превью курс модуля',
        "setTQ": 'Установить тематич. вопрос',
        "duplicate": 'Дубликат',
        "specialType": 'Спец. тип',
        "isValid": 'Валидировано?',
        "isFavorite": 'Избранное?',
        "true": 'Да',
        "false": 'Нет',
        "general": 'Общий',
        "audio": 'Аудио',
        "quiz": 'Квиз',
        "QUIZE": 'Квиз',
        "QUIZES": 'Квизы',
        "code": 'Код',
        "Special Title": 'Спец. название',
        "Special Name": 'Спец. имя',
        "Special Type": 'Спец. тип',
        "Special QType": 'Спец. тип вопроса',
        "Assign Another Question": 'Подключить другой вопрос',
        "easy": 'Легко',
        "medium": 'Средне',
        "hard": 'Сложно',
        "jun": 'Джун',
        "mid": 'Миддл',
        "List OF QUSETIONS": 'Список вопросов',
        "senior": 'Сеньор',
        "Select Questions": 'Выберите вопросы',
        "Config Questions with Order": 'Конфиг вопросов с сортировкой',
        "jsDetails": 'Код детали',
        "Hints": 'Подсказки',
        "UseCases": 'Практич. примеры',
        "videos": 'Видео',
        "answer": 'Ответ',
        "Tags": 'Тэг',
        "Title": 'Название',
        "Question Type": 'Тип вопроса',
        "Timeout (to wait async)": 'Таймаут ожидания',
        "originalSolutionStatus": 'Статус ориг решения',
        "hideRunStatus": 'Скрыть статус',
        "CodeType": 'Тип задачи',
        "Pull Letcode": 'Подтянуть леткод',
        "sourceId": 'Сорс ИД',
        "source": 'Сорс',
        "isLTS": 'ЛТС?',
        "VersionStr": 'Версия',
        "ForkVersion": 'Форк Версия',
        "suggests": 'Предложения',
        "contributorsScore": 'Оценка контрибьютеров',
        "contributors": 'Контрибьютеры',
        "refactor": 'Рефактор',
        "problem": 'Проблема',
        "discussion": 'Обсуждение',
        "perc": '%',
        "quizPerc": '% в квизах',
        "startCd": 'Время начала',
        "submitCd": 'Время окончания',
        "SourceName": 'Название источника',
        "It's Current item": 'Эте текущий элемент',
        "Count": 'Кол-во',
        "exam": 'Экзамен',
        "module": 'Модуль',
        "course": 'Курс',
        "tasks": 'Задач',
        "total": 'Всего',
        "current": 'Текущий',
        "Max Attempts To Submit": 'Макс. кол-во сабмита',
        "Minutes": 'Минут',
        "FULL EXAM DETAILS": 'ДЕТАЛИ ЭКЗАМЕНА',
        "Clone That": 'Скопировать текущий',
        "List": 'Список',
        "Test Results": 'Результаты экзамена',
        "RoR": '2-е ревью',
        "review": 'Ревью',
        "desc": 'Описание',
        "sprintId": 'Спринт ИД',
        "courseId": 'Курс ИД',
        "examId": 'Экзамен ИД',
        "userId": 'Пользователь ИД',
        "templateId": 'Шаблон ИД',
        "Select All": 'Выбрать Все',
        "Submit Full Test": 'Сабмит весь экзамен',
        "LTS version": 'ЛТС версия',
        "SUGGESTIONS": 'Предложения контента',
        "": '',

    }

    let arr = [
        ...[{name: '-', value: ''}, {name: 'Ответ не понятен', value: 'unclear_question'},
            {value: 'not_deep_answer', name: 'Не глубокий ответ'}, {
                value: 'not_actual_question',
                name: 'Вопрос не актуален'
            }
        ], ...[
            {
                value: "edit",
                name: "Редактирую",
            },
            {
                name: "Отправлено",
                value: "sent",
            },
            {
                name: "Проверено",
                value: "approved",
            },
            {
                name: "Отменено",
                value: "canceled",
            },
        ], ...[
            {name: "ХР", value: "HR"},
            {name: "Тех", value: "tech"},
        ], ...[
            {name: "Ожидает старта", value: "waiting"},
            {name: "Офер", value: "offer"},
            {name: "След фаза", value: "next_stage"},
            {name: "Не прошли", value: "bad"},
        ], ...[{name: 'Вопрос', value: 'question'},
            {name: 'Задача', value: 'task'},
            {name: 'JS Задача', value: 'js-task'},
            {name: 'Пусто', value: {$exists: false}}
        ], ...[
            {name: 'Все', value: 'all'},

        ], ...[{name: 'Ожидает', value: 'waiting'},
            {name: 'Оффер', value: 'offer'},
            {name: 'След этам', value: 'next_stage'},
            {name: 'Отказ', value: 'reject'},
        ], ...[]
    ]

    function toLower(v) {
        try {
            return (v || '').trim('').toLowerCase()
        } catch (e) {
            return v;
        }

    }

    _.each(obj, (item, ind) => {
        obj[toLower(ind)] = item
    })

    arr.forEach(it => {
        obj[toLower(it?.value)] = it.name;
    })


    let _name = toLower(name)
    return obj[_name] || name
}

global.nameFn = global.env.nameFn;