import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart';
import {editQuestion} from './Suggest/SuggestionItem';
import QuizEditor from './Suggest/QuizEditor';
import JSDetails from './Suggest/JSDetails';
import {
    Link, Outlet
} from "react-router-dom";
import {DiffSmart} from './Suggest/SuggestionItem'
import getDiff from './DiffMethods';
import Storage from 'libs/Storage/Storage'
import ContributorsList from './Suggest/ContributorsList'
import LazyEditor from './LazyEditor/LazyEditor';
import Textarea from 'libs/Textarea';
import QuestionDetails from './Suggest/QuestionDetails';
import CustomStorage from './Suggest/CustomStorage';
import Button from 'libs/Button';

// function MyComponent() {
let QKey = 'Текущая версия вопроса'
let ClearKey = 'Clear'
let CustomKey = 'Локальная версия (браузер)'
let eqTimer = 0;

function deepClone(data) {
    return JSON.parse(JSON.stringify(data))
}

function setLastIds(_id) {
    let arr = getLastIds('woMap')
    arr.unshift({_id, cd: new Date().getTime()})
    arr = _.uniq(arr, it => it._id);
    Storage.set('nextHistory', arr)
//console.log('Set historyarrr', arr)
}

function getLastIds(woMap) {
    let arr = Storage.get('nextHistory') || []
    let cd = new Date().getTime() - 2 * 60 * 1000;
    return arr
        .filter((it, ind) => ind < 100 && it.cd > cd)
        .map(it => (woMap ? it : it._id));
}


let versionDetails;

function getLastUserAction(version) {
    let {decliners = [], contributors = []} = version || {};
    let userId = global.user.get_id();

    function findMaxCD(user, array1, array2) {
        const combinedArray = [...array1 || [], ...array2 || []];
        const userElements = combinedArray.filter(element => element.user == user);
        if (userElements.length === 0) return null;
        const maxCD = Math.max(...userElements.map(element => element.cd));
        return userElements.find(element => element.cd == maxCD);
    }

    let v = findMaxCD(userId, decliners, contributors)

    return v ? v.action || 'contribute' : '';
}

function VersionList(props) {
    let {selected, onChange, allVersions, allVersionsObj, loadVersion} = props
    return <> {(allVersions || []).map(it => {
        let vv = allVersionsObj[it.versionStr];
        let type = getLastUserAction(vv)

        return <div
            className={'versionV ' + (it.versionStr == selected ? 'activeV' : '')}
            onClick={() => {
                loadVersion(it.versionStr, (versionLTS) => {
                    onChange(versionLTS, it.versionStr)
                })
            }}
            style={it.isLTS ? {fontWeight: 'bold'} : {}}
            key={it.versionStr}>{it.isLTS ? '* ' : ''}{it.versionStr}
            <div className='pull-right' key={'vv_' + it.versionStr}>
                <ContributorsList size={15} item={allVersionsObj[it.versionStr]}></ContributorsList>

                <div className={'logoContrib '} data-action={type}></div>
            </div>
        </div>
    })}</>
}

let cd = new Date().getTime();

function timer(isSet) {
    cd = isSet ? new Date().getTime() : cd;
    return new Date().getTime() - cd;
}

function Layout2(props) {

//console.log("timer 1.0", timer(true))
    let _id = props._id || global.CustomStorage.getId();
    let [data, setData] = useState(null);
    let [open, setOpen] = useState(true);
    let [equalScore, setEqualScore] = useState(false);
    let [customEdit, setCustomEdit] = useState(null);
    let [selectedV, setSelectedV] = useState({v1: '0.0.0', v2: '0.0.0'})
    let [lock, setLock] = useState(false);


    window.data = data;
    useEffect(() => {
        setLastIds(_id);
        global.http.get('/approve-question', {_id}).then(r => {
            let LTSVersion = (r.allVersions.filter(it => it.isLTS)[0] || {}).versionStr;
            // let isAnyNot0Version =
            r.allVersions = [...r.allVersions, (LTSVersion && LTSVersion.versionStr !== '0.0.0' ? null : {versionStr: QKey}), {versionStr: CustomKey}].filter(it => it)
            let v2 = (r.allVersions.filter(it => it.versionStr === '0.0.0')[0] || {}).versionStr || LTSVersion || QKey;
            let {DItem} = getDD(_id);
            let isDItem = Boolean(DItem) && DItem.version == LTSVersion;

            v2 = isDItem ? CustomKey : v2
            if (v2 === QKey && !r.versionLTS) {
                r.versionLTP = r.qData
            } else if (v2 === CustomKey) {
                r.versionLTP = DItem.data;
            } else {
                r.versionLTP = r.versionLTP || deepClone(r.versionLTS);
            }
            r.allVersionsObj = r.allVersions.reduce((acc, it) => {
                return {...acc, [it.versionStr]: it}
            }, {QKey: r.qData})


            setEqualScore(false)
            //console.log('isDItem', isDItem, DItem)
            setSelectedV({
                ...selectedV
                , v1: LTSVersion
                , v2
            })
            setData({
                ...r,
                versionLTP: {...r.versionLTP || {}},
                [CustomKey]: (DItem || {}).data || null
            })
            setLock((r.locker || {}).error)
            //  if (e.code === 'locked') {
            // //console.log('eeeee', e)
            //   setLock(true)
            //   setData({qData: })
            // }
            // setLock(false)
            // global.resetMemo('editor')

        })
        // .catch(e => {

        // })
    }, [])
    if (!data) {
        return <></>
    }

    function loadVersion(versionStr, cb) {
        if (versionStr === QKey) {
            return global.http.get('/load-version-q', {sourceId: _id, versionStr}).then((r) => {
                //console.log("load LTS-QQQQQQQ", r)
                cb && cb(r.version)
            })
        }
        if (versionStr === CustomKey) {
            // console.log('zzzzz', eeee)
            let {DItem} = getDD(_id);
            //console.log('DItem', DItem)

            return cb && cb((DItem || {}).data || customEdit || deepClone(data.versionLTP))
        }
        if (versionStr === ClearKey) {
            return cb && cb({})
        }
        global.http.get('/load-version', {sourceId: _id, versionStr}).then((r) => {
            // console.log("load LTS", r)
            cb && cb(r.version)
        })
    }

    function increaseVersion(vl, changeKey) {
        let v = {}
        if (changeKey === 'subVersion') {
            v.patch = 0;
            v.subVersion = (vl.subVersion || 0) + 1;
            v.version = vl.version || 1;
        } else if (changeKey === 'version') {
            v.patch = 0;
            v.subVersion = 0;
            v.version = (vl.version || 0) + 1;
        } else {
            v.patch = (vl.patch || 0) + 1;
            v.subVersion = vl.subVersion || 0;
            v.version = vl.version || 1;
        }
        v.versionStr = [v.version, v.subVersion, v.patch].join('.')
        return v;
    }

    function getNewVersion() {
        // selectedV.v2 === '0.0.0' ? getNewVersion() : selectedV.v2
        // let diff = getDiff({ versionLTP: data.versionLTP, versionLTS: data.versionL })
        let isForceSelected = ['0.0.0', QKey, ClearKey, CustomKey].indexOf(selectedV.v2) < 0;
        // let vl = data.versionL || {};
        // let changeKey = diff.changeKey;
        // let v = increaseVersion(vl, changeKey)
        // function get(key) {
        //   return v[key]
        // }

        // versionDetails = v;
        // let {version, subVersion, patch} = data.versionL;

        return {
            versionStr: selectedV.v2,//isForceSelected ? selectedV.v2 : [get('version'), get('subVersion'), get('patch')].join('.'),
            // isEqual: diff.isEqual,
            isForceSelected,
            // changeKey: changeKey,
            // info: {
            //   version: vl.version,
            //   subVersion: vl.version,
            //   patch: vl.patch
            // }
        }
    }

    let dataLTS = (data.versionLTS || {}).data;
    let dataLTP = (data.versionLTP || {}).data;

    function toggleCB({versionDB, questionDB, isFromToggle}) {
        let version = versionDB;
        let versionStr = version.versionStr;
        data.versionLTP = {...version};
        data.versionLTS = {...version};
        data.versionL = {...version};
        data.qData = {...data.qData, ...questionDB}
        isFromToggle = data.allVersions.find(it => it.versionStr == versionDB.versionStr);

        data.allVersions = isFromToggle
            ? [...data.allVersions.map(it => {
                return {...it, isLTS: versionStr == it.versionStr}
            })]
            : [{versionStr: version.versionStr, isLTS: true}, ...data.allVersions.map(it => {
                return {...it, isLTS: false}
            }).filter(it => it.versionStr != '0.0.0')];
        data.allVersionsObj = {...data.allVersionsObj, [version.versionStr]: version}
        setSelectedV({...selectedV, v1: version.versionStr, v2: version.versionStr})
        setData({...data})
    }

    function skipOrDecline(action) {
        let versionStr = data.versionLTP.versionStr;
        global.http.get('/skip-or-decline', {question: _id, versionStr, action}).then(({version}) => {
            //console.log("skipped or declined", version)
            data.versionLTP = {...version};
            data.allVersionsObj = {...data.allVersionsObj, [version.versionStr]: version}
            setData({...data})
        })
    }

    function getLTS() {
        return (data.versionLTS || {}).versionStr || '';
    }

    function getDD(__id) {
        __id = __id || _id;

        let DItemsList = CustomStorage.get('customEditArr') || [];
        let DItem = null
        DItemsList = DItemsList.filter((it, ind) => {
            DItem = DItem || (it.questionId == __id ? it : DItem);
            return (ind < 30) && (it.questionId != __id)
        });

        return {DItemsList, DItem}

    }

    function extendLTP(vv) {
        data.versionLTP = data.versionLTP || {};
        data.versionLTP.data = {...data.versionLTP.data || {}, ...vv}
        onSetCustomEdit({data: data.versionLTP.data})
        setData({...data})
        setSelectedV({...selectedV, v2: CustomKey})
    }

    function onSetCustomEdit(data) {
        data = data || {}
        delete (data.jsDetails || {}).v
        delete ((data.data || {}).jsDetails || {}).v

        setCustomEdit(data)

        let {DItemsList, DItem} = getDD()
        //console.log("DItem", _id, DItem, DItemsList)
        DItemsList.unshift({data, version: selectedV.v1, questionId: _id, cd: new Date().getTime()})
        CustomStorage.set('customEditArr', DItemsList)
    }

    // function onOpenNext(scb) {}

    let vv = getNewVersion();
    let isV2Numbers = /[0-9]\./gi.test(selectedV.v2)
    let lastMyAction = isV2Numbers ? getLastUserAction(data.versionLTP) : '';
    let isLTP0 = selectedV.v2 === '0.0.0'

    function getLastV() {
        let [version, subVersion, patch] = (((data.allVersions || [])[0] || {}).versionStr || '').split('.')
        return {version: +version, subVersion: +subVersion, patch: +patch}
    }

    // question = question || 1007
    let versionKeyId = ((data.versionLTP || {}).data || {}).versionKeyId || '-1';
    let keyId = _id + '___' + selectedV.v2 + (versionKeyId);
    let lastInfo = getLastV()
    let duplId = data?.qData?.data?.originalDuplicateId;
//console.log("RENDER!!! 1.0", timer(cd), keyId, { versionKeyId })
    return <div className='row'>
        <div className='col-sm-2 np'>
            Текущая LTS:
            <div></div><strong>{selectedV.v1 || 'Отсутсвует'}</strong>

            <hr/>
            <small><b>Версии</b></small>
            <VersionList
                loadVersion={loadVersion}
                allVersionsObj={data.allVersionsObj}
                allVersions={data.allVersions}
                selected={selectedV.v2} onChange={(versionLTP, v2) => {
                //console.log("xxxxx")
                setSelectedV({...selectedV, v2})
                setData({...data, versionLTP})
                global.resetMemo('editor')
            }}>
            </VersionList>
            <hr/>
            <div className='pull-left2' style={{marginRight: '10px'}}>
                <small>Все Контрибьютеры</small>
                <ContributorsList size={30} item={data.qData}></ContributorsList>
                <hr/>
                <small>Последний релиз</small>
                <div></div>
                <ContributorsList size={45} version={data.qData}></ContributorsList>

            </div>
            {/* </div> */}
        </div>

        <div className='col-sm-10 approveList'>


            <div style={{marginBottom: '15px'}}>
                <button className='btn btn-xs btn-default' onClick={() => {
                    //console.log('history')
                    window.history.back();
                }}>
                    <i className='fa fa-angle-left'></i> Вернуться
                    <div>
                        <small>По истории</small>
                    </div>
                </button>
                {<div className='ib' style={{opacity: !isV2Numbers ? '.3' : '1'}}>
                    <button
                        disabled={!isV2Numbers}
                        className={'btn btn-xs btn-default ' + (lastMyAction === 'contribute' ? 'btn-success active' : '')}
                        onClick={() => {
                            global.http.get("/like-by-any", {
                                question: _id,
                                versionStr: selectedV.v2
                            }).then(({versionDB}) => {
                                let version = versionDB;
                                //console.log("likeed", version)
                                data.versionLTP = {...version};
                                data.allVersionsObj = {...data.allVersionsObj, [version.versionStr]: version}
                                setData({...data})
                            })
                        }}

                    >Версия Нравится
                        <div><small>Нечего дополнить</small></div>
                    </button>
                    <button
                        disabled={!isV2Numbers}
                        className={'btn btn-xs btn-default ' + (lastMyAction === 'skip' ? 'active' : '')}
                        onClick={() => skipOrDecline('skip')}>
                        Не моя тема
                        <div><small>Пропустить</small></div>

                    </button>
                    <button
                        disabled={!isV2Numbers}
                        className={'btn btn-xs btn-default ' + (lastMyAction === 'decline' ? ' btn-danger active' : '')}
                        onClick={() => skipOrDecline('decline')}>
                        Версия не Нравится
                        <div><small>Но нет времени поправить</small></div>

                    </button>
                </div>}
                <Button
                    color={1}
                    onClick={(scb) => {
                        global.http.post('/get-next-url', {ignoreIds: getLastIds()}).then(({questionId}) => {
                            global.navigate('/theme-question/' + questionId)
                        })
                    }}
                    className='btn btn-xs btn-default'>
                    Далее <i className='fa fa-angle-right'></i>
                    <div>
                        <small>К след вопросу</small>
                    </div>
                </Button>
                {isV2Numbers && <div className='ib' style={{marginLeft: '20px'}}>
                    Автор релиза версии
                    <div><ContributorsList left={0} size={20} version={data.versionLTP}></ContributorsList></div>
                </div>}
                <div className="pull-right">
                    <small>Комментарий (к вопросу не к версии)</small>
                    <Textarea value={(data.qData || {}).comment} onChange={(v) => {
                        //console.log('ddzxczxcvzxcvzxcvzxcv', data, data.qData)
                        data.qData = data.qData || {};
                        data.qData.comment = v;
                        setData({...data})
                        global.http.post("/update-comment", {_id: data.qData.sourceId, comment: v}).then();
                    }
                    }></Textarea>
                </div>
                {/* {isV2Numbers && <div className='row'>
          <pre className='col-sm-4'>{JSON.stringify(data.versionLTP.decliners, null, 2)}</pre>
          <pre className='col-sm-4'>{JSON.stringify(data.versionLTP.contributors, null, 2)}</pre>
          <pre className='col-sm-4'>{JSON.stringify(lastMyAction, null, 2)}</pre>
        </div>} */}
            </div>

            {!lock && <>

                {<div className='ib actionsQ'>
                    {!vv.isForceSelected && <>
                        {['version', 'subVersion', 'patch'].map((changeKey) => {
                            let v = increaseVersion(lastInfo, changeKey);

                            return <button
                                style={{opacity: equalScore ? .3 : 1}}
                                disabled={equalScore}
                                className={"btn btn-xs btn-success " + (vv.changeKey === changeKey ? 'recommended' : '')}
                                onClick={() => {
                                    //console.log("make version LTS", data.versionLTP)
                                    global.http.post("/create-lts", {
                                        currentLTS: getLTS(),
                                        changeKey, question: _id, LTP: data.versionLTP, versionDetails: v
                                    })
                                        .then(toggleCB)
                                }}>Выпустить: {v.versionStr}</button>
                        })}
                        {equalScore && <div>
                            Нет диффренсов с текущей LTS, невозможно ничего применить. <div></div>
                        </div>}
                    </>}


                </div>}
                {selectedV.v2 === '0.0.0' && <button className='btn btn-xs btn-danger' onClick={() => {
                    //console.log("reject LTP", data)
                    global.http.get('/reject-ltp', {currentLTS: getLTS(), question: _id}).then(r => {
                        data.allVersions = data.allVersions.filter(it => it.versionStr !== '0.0.0')
                        let it = data.allVersions[0];
                        loadVersion(it.versionStr, (versionLTS) => {
                            data.versionLTP = versionLTS;
                            setData({...data})
                            setSelectedV({...selectedV, v2: versionLTS.versionStr})
                        })

                    })
                }}>Отменить Реквест v0.0.0</button>}

                {vv.isForceSelected && <button className="btn btn-xs btn-success" onClick={() => {
                    //console.log("make version LTS", data.versionLTP)
                    global.http.get("/toggle-lts", {currentLTS: getLTS(), question: _id, versionStr: selectedV.v2})
                        .then(toggleCB)
                }}>{equalScore ? 'Переустановить' : 'Установить'} LTS: {vv.versionStr}</button>}
            </>}

            {/* {vv.isEqual ? 'YES' : 'NO'}
      {vv.isForceSelected ? 'F_YES' : 'F_NO'} */}
            {/* <div className='ib ml15'>Номер новой стабильной версии будет: <strong>{vv.versionStr}</strong> */}
            {duplId && <div className="col-sm-12 error" style={{padding: '20px'}}>
                <div>!!! Этот вопрос помечен как дубликат: <Link to={'/theme-question/' + duplId}>#{duplId}</Link></div>
                {console.log("dat4444a", data?.qData?.data?.originalDuplicateId || '-')}
            </div>}
            <div>
                <ContributorsList top={10} size={30} item={data.allVersionsObj[selectedV.v2]}></ContributorsList>
            </div>
            {/* </div> */}

            <hr/>

            {lock && <>
                <Lockuser locker={data.locker}></Lockuser>
                {/* <label className='label label-warning'>Data is Locked</label> */}
                <div style={{paddingTop: '20px'}} className={'animChild'}>
                    {/* <QuestionDetails
          woSuggestions={true}
          showName={true} question={data.versionLTP.data} withoutShow={true} ></QuestionDetails> */}
                </div>
            </>}
            {/* {!lock && <> */}
            {open && <div className='col-sm-4'>
                <DiffSmart
                    onChangeTotalEqual={(isEqual, size, v) => {
                        if (isEqual !== equalScore) {
                            setEqualScore(isEqual)
                        }
                        //console.log("is Equal44444575757575757755", isEqual, size, v)
                    }}
                    localItem={{lts: dataLTS, ltp: dataLTP}} key1={'lts'} key2={'ltp'}
                    onChange={(v, key) => {
                        //console.log('on chagne12412414', v, key)
                        if (key === 'ltp') {
                            data.versionLTP.data = {...v, versionKeyId: new Date().getTime()};
                        } else {
                            data.versionLTS.data = {...v};
                        }
                        setSelectedV({...selectedV, v2: CustomKey})
                        setData({...data})
                    }}></DiffSmart>

                {/* <pre>{JSON.stringify(dataLTP, null, 4)}</pre>
      <hr />
      <pre>{JSON.stringify(data, null, 4)}</pre> */}
            </div>}

            <div className={open ? 'col-sm-8' : 'col-sm-12'}>
                <MyTabs
                    top={'-20'}
                    defIndex={1}
                    items={[
                        {
                            preventSelect: true,
                            nameComp: () => {
                                return <a className={"nav-link "} onClick={() => {
                                    setOpen(!open)
                                }}>
                                    <i className='fa fa-bars'></i>
                                </a>
                            }
                        },
                        {
                            name: 'Вопрос (описание)', Component: <>
                                <Smart
                                    obj={{...(data.versionLTP || {}).data, ...{questionId: _id}}}
                                    items={editQuestion}
                                    versionStr={(data.versionLTP || {}).versionStr}
                                    onChange={(v) => {
                                        //console.log("vvvvvvvvvvv7777", v)
                                        // setMigrateQuestion({...v});
                                        data.versionLTP = data.versionLTP || {};
                                        data.versionLTP.data = v;
                                        delete v.v;
                                        onSetCustomEdit({data: v})
                                        setData({...data, versionLTP: data.versionLTP})
                                        setSelectedV({...selectedV, v2: CustomKey})
                                    }
                                    }
                                ></Smart></>
                        }, {
                            name: 'Задача (запуск)',
                            Component: <JSDetails
                                customData={{
                                    keyId,
                                    questionId: _id,
                                    jsDetails: {
                                        details: ((data.versionLTP || {}).data || {}).jsDetails
                                    },
                                    onChangeDetails: (jsDetails) => {
                                        //console.log('on Change jS Details', jsDetails)
                                        extendLTP({jsDetails: jsDetails.details})
                                    }
                                }}
                            >
                            </JSDetails>
                        }]}
                >

                </MyTabs>
            </div>
            {/* </>} */}
        </div>
    </div>
}

function MyTabs(props) {
    let {items, top = '0', defIndex} = props
    let [selectedInd, setSelectedInd] = useState(defIndex || 0);

    let it = items[selectedInd]


    return <>
        <ul className="nav nav-tabs" style={{marginTop: top + 'px'}}>
            {items.map((it, ind) => {
                let Comp = it.nameComp;
                return <li className="nav-item" key={ind} onClick={() => {
                    !it.preventSelect && setSelectedInd(ind)
                }}>
                    {it.nameComp ? <Comp></Comp> :
                        <a className={"nav-link " + (ind == selectedInd ? 'active' : '')}>{it.name}</a>}
                </li>
            })}
        </ul>

        <div style={{width: '100%', float: 'left', paddingTop: '30px'}}>{it.Component}</div>
    </>
}

function Lockuser({locker,}) {
    let {user = {}, lockData = {}} = locker || {};

    function date(cd) {
        function addLeadingZero(number) {
            return number < 10 ? `0${number}` : number;
        }

        const date = new Date(cd); // Get the current date and time

        const hours = addLeadingZero(date.getHours());       // Extract the hours
        const minutes = addLeadingZero(date.getMinutes());   // Extract the minutes
        const seconds = addLeadingZero(date.getSeconds());   // Extract the seconds

        return `${hours}:${minutes}:${seconds}`
    }

    return <div className="alert alert-warning" role="alert">
        Вы не можете править этот вопрос потому что его уже правят в текущий момент времени
        <div>Пользователь: <Link to={'/user/' + user._id}>#{user._id}</Link> {user.name}</div>
        <div>Дата старта лока: {date(lockData.cd)}</div>
        <div>Дата старта последнего лока: {date(lockData.startCd)}</div>


    </div>
}

export default Layout2
