import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Table from '../Suggest/Table'
import m from 'libs/m/m';

import {
    Link, Outlet
} from "react-router-dom";
import './UserStats.css'
import UserSelector from 'libs/UserSelector';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Smart from "libs/Smart";


function toOdb(cd) {
    let _cd = new Date(cd);
    return [_cd.getFullYear(), pub(_cd.getMonth() + 1), pub(_cd.getDate())].join('-')
}

function pub(v) {
    return v < 10 ? '0' + v : v;
}

let days = 24 * 3600 * 1000;

let names = [
    {
        name: 'Время в работе (непосредственно)', key: 'work_time'
    }, {
        name: 'Среднее активных сек в рабочие мин', key: 'work_time_quality'
    }, {
        name: '% изучения (В спринте)', key: 'sprint_perc'
    }, {
        name: 'Задач в спринте', key: 'sprint_total_tasks'
    }, {
        name: 'Задач на отл (В спринте)', key: 'sprint_very_good'
    }, {
        name: 'Задач на отл + хор (В спринте)', key: 'sprint_very_good_and_good'
    }, {
        name: 'Задач отл + хор + норм (В спринте)', key: 'sprint_touched'
    }, {
        name: 'Задач повторено (В спринте)', key: 'sprint_repeat'
    }, {
        name: 'Задач изучено (В спринте)', key: 'sprint_new'
    }, {
        name: 'Кол-во переключений', key: 'pause'
    }, {
        name: 'Задач на отл (всего)', key: 'very_good'
    }, {
        name: 'Задач на отл + хор (всего)', key: 'very_good_and_good'
    }, {
        name: 'Задач на отл + хор + норм (всего)', key: 'touched'
    },
];

let namesObj = names.reduce((acc, {key, name}) => {
    return {...acc, [key]: name}
}, {});

function EmptyMessage() {
    return <div>Выберите юзера</div>
}

function StatsTableBlock(props) {
    let {start, end, userId, matchPeriods} = props;
    let periods = matchPeriods;

    let [parsedStats, setParsedStats] = useState({});
    useEffect(loadData, [start, end, userId])
    useEffect(loadData, [matchPeriods])


    if (!userId) {
        return <EmptyMessage></EmptyMessage>
    }


    function loadData() {
        let _start = toOdb(start);
        let _end = toOdb(end);

        if (!matchPeriods && !matchPeriods.length) {
            return;
        }

        global.http.get('/get-user-stats', {start: _start, end: _end, user: userId})
            .then(r => {
                let stats = {}
                let leng = periods.length;

                stats.activeQuestions = r.sprints.reduce((acc, it) => {
                    _.each(it.questions, (_id, ind) => {
                        acc[_id] = true;
                    })
                    return acc;
                }, {})
                stats.statsByDays = r.stats.reduce((acc, it) => {
                    return {...acc, [it.odb]: it.stats}
                }, {});

                stats.fullHistory = {}

                _.each(r.history, (hist, ind) => {

                    let histArr = hist.history || [];

                    let histIndex = 0;
                    let histOdb = getOdb()
                    let info = {};
                    for (let i = leng - 1; i >= 0; i--) {
                        let {cd, name} = periods[i] || {}
                        info[name] ??= {}
                        info[name].status = getActiveHistStatus(cd);
                        if (name === histOdb) {
                            info[name][histIndex === histArr.length - 1 ? 'isNew' : 'isRepeat'] = true
                        }
                    }

                    stats.fullHistory[hist.question] = info;

                    function getActiveHistStatus(periodCd) {
                        while (histArr[histIndex] && histArr[histIndex].cd > periodCd) {
                            histOdb = getOdb(++histIndex)
                        }
                        return (histArr[histIndex] || {}).status || ''
                    }

                    function getOdb() {
                        let cd = ((histArr || [])[histIndex] || {}).cd
                        if (!cd) {
                            return ''
                        }
                        return toOdb(cd)
                    }

                })

                stats.grouppedCounts = {};
                stats.grouppedActiveCounts = {};

                function increaseCounts(key, odb, {status, isNew, isRepeat}) {
                    stats[key][odb] ??= {new: 0, repeat: 0};
                    stats[key][odb][status] ??= 0;
                    stats[key][odb][status]++;
                    stats[key][odb].new += isNew ? 1 : 0;
                    stats[key][odb].repeat += isRepeat ? 1 : 0;
                }

                _.each(stats.fullHistory, (item, questionId) => {
                    _.each(item, (it, odb) => {
                        increaseCounts('grouppedCounts', odb, it)
                        if (stats.activeQuestions[questionId]) {
                            increaseCounts('grouppedActiveCounts', odb, it)
                        }
                    })
                })

                let {grouppedActiveCounts, statsByDays, activeQuestions, grouppedCounts} = stats;

                let parsedStats = matchPeriods.map(({name}) => {
                    let act = grouppedActiveCounts[name] || {}
                    let total = grouppedCounts[name] || {}
                    let sprint_total_tasks = _.size(activeQuestions)

                    let stats = ((statsByDays || {})[name] || {}).stats || {}
                    let isStats = stats.total > 0;

                    function wrap(v) {
                        return !isStats ? 'No Data' : v
                    }

                    return {
                        sprint_total_tasks,
                        sprint_very_good: act.very_good,
                        sprint_very_good_and_good: (act.very_good || 0) + (act.good || 0),
                        sprint_touched: (act.very_good || 0) + (act.good || 0) + (act.norm || 0),
                        sprint_repeat: act.repeat,
                        sprint_new: act.new,
                        sprint_perc: Math.round(100 * ((act.very_good || 0) * 3 + (act.good || 0) * 2 + (act.norm || 0)) / (3 * (sprint_total_tasks || 1))) + '%',
                        pause: wrap(stats.pause),
                        work_time: wrap((stats.total / 3600).toFixed(1) + 'ч'),
                        work_time_quality: wrap((stats.total / (stats.totalMinutes || 1)).toFixed(0)),
                        very_good: total.very_good,
                        very_good_and_good: (total.very_good || 0) + (total.good || 0),
                        touched: (total.very_good || 0) + (total.good || 0) + (total.norm || 0),
                    }
                })

                console.log("qqqqq .....................................................", r, parsedStats, matchPeriods);
                // window.stats = stats
                setParsedStats({...parsedStats})
            });
    }


    function getOptions(key) {
        function getMax(max) {
            return {
                scales: {
                    y: {
                        min: 0,
                        max
                    },
                }
            }
        }


        if (/sprint_very_good|sprint_touched/gi.test(key)) {
            return getMax(sprint_total_tasks)
        }

        if (/sprint_perc/gi.test(key)) {
            return getMax(100)
        }
        if (/work_time_quality/gi.test(key)) {
            return getMax(60)
        }
        if (/work_time/gi.test(key)) {
            return getMax(10)
        }

        return {}
    }

    let sprint_total_tasks = (parsedStats[0] || {}).sprint_total_tasks

    return <div>
        <div className="row">
            <div className="col-sm-12">
                <div className="table-responsive">
                    <table className={'table table-bordered table-striped '}>
                        <thead>
                        <tr>
                            <th>

                            </th>
                            {(matchPeriods || []).map((it, ind) => {
                                return (<th className="th-name" key={ind}>
                                    <span>{it.name}</span>
                                </th>)
                            })}
                        </tr>
                        </thead>
                        {(names || []).map(({name, key}, ind) => {
                            return (<tbody key={ind}>
                            <tr>
                                <td>{name}</td>
                                {(matchPeriods || []).map((it, periodInd) => {
                                    return (<td key={periodInd}>
                                        <span>{(parsedStats[periodInd] || {})[key]}</span>
                                    </td>)
                                })}

                            </tr>
                            </tbody>)
                        })}


                    </table>
                </div>
            </div>
        </div>

        <div className="row">
            {(names || []).map(({name, key}, ind) => {
                if (key === 'sprint_total_tasks') {
                    return <></>
                }
                return (<div className="col-sm-4" key={ind}>
                    <Graphic
                        labels={matchPeriods.map(it => it.name)}
                        options={getOptions(key)}
                        datasets={[{
                            label: name,
                            data: matchPeriods.map((it, periodInd) => {
                                return parseFloat((parsedStats[periodInd] || {})[key] || '');
                                // return (parsedStats[periodInd] || {})[key] || 0
                            }),
                            borderColor: key === 'pause' ? 'red' : 'rgb(53, 162, 235)',
                            backgroundColor: 'transparent'
                        }]}
                    ></Graphic>

                </div>)
            })}

        </div>
    </div>
}


function UserStatsOld(props) {
    let cd = new Date().getTime();


    let [stats, setStats] = useState({})
    let [tabName, setTabName] = useState(getHash('tabName') || 'agr')
    let [daysCount, setDaysCount] = useState(7)
    let [matchPeriods, setMatchPeriods] = useState([])
    let [parsedStats, setParsedStats] = useState({})
    let [userId, setUserId] = useState(getHash('userId'))
    let [gapSize, setGapSize] = useState(10)
    let [_cd, setCd] = useState(0)
    let [day, setDay] = useState(cd)
    let [start, setStart] = useState(cd - 7 * days)
    let [end, setEnd] = useState(cd)

    function getHash(key) {
        let str = decodeURI(window.location.hash || '').replace('#', '');
        let hashObj;
        try {
            hashObj = JSON.parse(str) || {};
            // hashObj.tabName && setTabName(hashObj.tabName)
            // hashObj.userId && setUserId(hashObj.userId)
            return hashObj[key]
        } catch (e) {
        }
    }

    useEffect(() => {
        console.log("qqqqq USE EFFECT TO RELOAD",);
        let _start = toOdb(start);
        let _end = toOdb(end);
        let daysCount = Math.round(1 + (new Date(_end).getTime() - new Date(_start).getTime()) / (1 * days));
        setDaysCount(daysCount);
        let startCd = new Date(_start).getTime();
        let periods = m.from_to(0, daysCount - 1).map(it => {
            let cd = new Date(startCd + it * days)
            let name = toOdb(cd);
            return {name, odb: name, cd: new Date(name).getTime() + 1 * days - 3 * 3600 * 1000 - 1}
        })
        setMatchPeriods(periods);
    }, [start, end, userId])
    useEffect(() => {
        window.location.hash = JSON.stringify({tabName, userId})
    }, [tabName, userId])

    let periods = [{name: 'd', time: 0}, {name: '3d', time: 2}, {name: 'w', time: 6}, {
        name: '2w',
        time: 13
    }, {name: 'm', time: 30},]
    let {} = stats || {};


    console.log("qqqqq parsed ::::: ", parsedStats, matchPeriods);
    return <div>
        <div className='row'>
            <div className='col-sm-12 interviews-group'>
                <div className="ib" style={{marginTop: '20px'}}>
                    <Smart
                        obj={{tabName}}
                        items={[{
                            key: 'tabName',
                            type: 'group',
                            list: ['history', 'graphics', 'daily', 'agr', 'by_user_new'],
                            size: 12
                        }]}
                        onChange={(v) => {
                            console.log("qqqqq vvvvv", v);
                            setTabName(v.tabName)
                        }
                        }
                    ></Smart>
                </div>

                <div className="ib" style={{marginLeft: '15px', marginRight: '15px'}}>
                    <UserSelector user={userId} onChange={(userId) => {
                        setUserId(userId)
                    }
                    }></UserSelector>
                </div>
                {tabName == 'daily' && <div className="ib">
                    <DayPicker title="Дата" value={day} onChange={setDay}></DayPicker>
                </div>}
                {tabName == 'by_user_new' && <div className="ib">
                    <DayPicker title="Дата" value={day} onChange={setDay}></DayPicker>
                </div>}

                {!/daily|by_user_new/gi.test(tabName) && <>
                    <div className="ib">
                        <DayPicker title="Старт" value={start} onChange={setStart}></DayPicker>
                    </div>
                    <div className="ib">
                        <DayPicker title="Конец" value={end} onChange={(cd) => {
                            setEnd(cd)
                            setStart(cd - 7 * days)
                        }
                        }></DayPicker>

                        {/*<small>End</small>*/}
                        {/*<input type="date" value={toOdb(end)} onChange={(e) => {*/}
                        {/*    let cd = new Date(e.target.value).getTime();*/}
                        {/*    setEnd(cd)*/}
                        {/*    setStart(cd - 7 * days)*/}
                        {/*}}></input>*/}
                    </div>
                    <div className="ib">
                        <div className='periods-wrap'>
                            {periods.map(it => {
                                return <span onClick={(e) => {
                                    setStart(end - it.time * days)
                                }}>{it.name}</span>
                            })}
                        </div>
                    </div>
                </>}
                <div className="ib">
                    <small>Gap Size</small>
                    <div><input type="number" style={{width: '100px'}} value={gapSize} onChange={(e) => {
                        setGapSize(e.target.value)
                    }
                    }/></div>
                </div>
                <div className="col-sm-12">
                    <hr/>
                </div>

                {tabName === 'history' && !userId && <div>Выберите юзера</div>}
                {tabName === 'history' && userId && <>
                    <div className="col-sm-12">
                        <Table user={userId}></Table>
                    </div>
                </>}
                {tabName === 'agr' && <>
                    <div className="col-sm-12">
                        <Aggregation
                            matchPeriods={matchPeriods}
                            start={start} end={end}></Aggregation>
                    </div>
                </>}
                {tabName === 'daily' && <>
                    <div className="col-sm-12">
                        <Daily userId={userId} odb={toOdb(day)}></Daily>
                    </div>
                </>}
                {tabName === 'graphics' && <>
                    <StatsTableBlock start={start} end={end} userId={userId}
                                     matchPeriods={matchPeriods}></StatsTableBlock>
                </>}


            </div>
        </div>
    </div>
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function Aggregation(props) {
    let {start, end, matchPeriods} = props;
    let periods = matchPeriods;
    let [data, setData] = useState({});
    let [parsedStats, setParsedStats] = useState({});
    useEffect(() => {
        if (!matchPeriods.length) {
            return;
        }
        global.http.get('/get-all-stats', {start, end})
            .then(r => {

                let stats = {}
                let leng = periods.length;

                stats.activeQuestions = r.sprints.reduce((acc, {user, questions}) => {
                    acc[user] = acc[user] || {};
                    _.each(questions, (_id, ind) => {
                        acc[user][_id] = true;
                    })
                    return acc;
                }, {});

                stats.statsByDays = r.stats.reduce((acc, {odb, stats, user}) => {
                    acc[user] ??= {};
                    acc[user][odb] = stats;
                    return acc;
                }, {});

                stats.fullHistory = {}

                _.each(r.history, (hist, ind) => {

                    let histArr = hist.history || [];

                    let histIndex = 0;
                    let histOdb = getOdb()
                    let info = {};
                    for (let i = leng - 1; i >= 0; i--) {
                        let {cd, name} = periods[i] || {}
                        info[name] ??= {}
                        info[name].status = getActiveHistStatus(cd);
                        if (name === histOdb) {
                            info[name][histIndex === histArr.length - 1 ? 'isNew' : 'isRepeat'] = true
                        }
                    }

                    stats.fullHistory[hist.user] ??= {}
                    stats.fullHistory[hist.user][hist.question] = info;

                    function getActiveHistStatus(periodCd) {
                        while (histArr[histIndex] && histArr[histIndex].cd > periodCd) {
                            histOdb = getOdb(++histIndex)
                        }
                        return (histArr[histIndex] || {}).status || ''
                    }

                    function getOdb() {
                        let cd = ((histArr || [])[histIndex] || {}).cd
                        if (!cd) {
                            return ''
                        }
                        return toOdb(cd)
                    }

                })

                console.log("qqqqq xxxxxx ststats 3.0", stats.fullHistory);


                stats.grouppedCounts = {};
                stats.grouppedActiveCounts = {};

                function increaseCounts(key, odb, {status, isNew, isRepeat}, userId) {
                    stats[key] ??= {};
                    stats[key][userId] ??= {}
                    stats[key][userId][odb] ??= {new: 0, repeat: 0};
                    stats[key][userId][odb][status] ??= 0;
                    stats[key][userId][odb][status]++;
                    stats[key][userId][odb].new += isNew ? 1 : 0;
                    stats[key][userId][odb].repeat += isRepeat ? 1 : 0;
                }

                console.log("xxxahahahahhahahahah xxxxxx 4.9", stats.fullHistory)

                _.each(stats.fullHistory, (st, userId) => {
                    _.each(st, (item, questionId) => {
                        _.each(item, (it, odb) => {
                            // console.log("qqqqq itititititititi xxxxxxx 6.0",odb, it, userId, (stats.activeQuestions[userId] || {})[questionId] );
                            increaseCounts('grouppedCounts', odb, it, userId)
                            if ((stats.activeQuestions[userId] || {})[questionId]) {
                                increaseCounts('grouppedActiveCounts', odb, it, userId)
                            }
                        })
                    })

                })

                console.log("xxxahahahahhahahahah xxxxxx 7.9", stats)

                let {grouppedActiveCounts, statsByDays, activeQuestions, grouppedCounts} = stats;

                let parsedStats = matchPeriods.map(({name}) => {

                    let res = {};

                    _.each(r.users, (userId, ind) => {
                        res[userId] = res[userId] || {};
                        grouppedActiveCounts[userId] ??= {};
                        grouppedActiveCounts[userId][name] ??= {};
                        let act = grouppedActiveCounts[userId][name] || {};
                        let total = (grouppedCounts[userId] || {})[name] || {};
                        let sprint_total_tasks = _.size(activeQuestions[userId])

                        let stats = (((statsByDays || {})[userId] || {})[name] || {}).stats || {}

                        let isStats = stats.total > 0;

                        function wrap(v) {
                            return !isStats ? 'No Data' : v
                        }

                        res[userId] = {
                            sprint_total_tasks,
                            sprint_very_good: act.very_good,
                            sprint_very_good_and_good: (act.very_good || 0) + (act.good || 0),
                            sprint_touched: (act.very_good || 0) + (act.good || 0) + (act.norm || 0),
                            sprint_repeat: act.repeat,
                            sprint_new: act.new,
                            sprint_perc: Math.round(100 * ((act.very_good || 0) * 3 + (act.good || 0) * 2 + (act.norm || 0)) / (3 * (sprint_total_tasks || 1))) + '%',
                            pause: wrap(stats.pause),
                            work_time: wrap((stats.total / 3600).toFixed(1) + 'ч'),
                            work_time_quality: wrap((stats.total / (stats.totalMinutes || 1)).toFixed(0)),
                            very_good: total.very_good,
                            very_good_and_good: (total.very_good || 0) + (total.good || 0),
                            touched: (total.very_good || 0) + (total.good || 0) + (total.norm || 0),
                        }

                    })

                    return res;

                })

                console.log("xxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxx 100.0", r, parsedStats, matchPeriods);
                // window.stats = stats
                setParsedStats({...parsedStats})
                console.log("qqqqq load data", r);
                setData({...r})
            })
    }, [start, end, matchPeriods.length])
    data.dbUsers = data.dbUsers || [];
    const beautifulColors = [
        '#1f77b4', // Blue
        '#ff7f0e', // Orange
        '#2ca02c', // Green
        '#d62728', // Red
        '#9467bd', // Purple
        '#8c564b', // Brown
        '#e377c2', // Pink
        '#7f7f7f', // Gray
        '#bcbd22', // Olive
        '#17becf', // Cyan
        '#1a9850', // Dark Green
        '#66c2a5', // Light Green
        '#fdae61', // Light Orange
        '#b2182b', // Dark Red
        '#a6cee3', // Light Blue
        '#6a3d9a', // Dark Purple
        '#b15928', // Dark Brown
        '#fb9a99', // Light Pink
        '#e41a1c', // Bright Red
        '#fdbf6f', // Bright Yellow
    ];

    function RenderFields(props) {
        let {field, stats} = props;
        const labels = (matchPeriods || []).map(it => it.name);

        const __data = {
            labels,

            datasets: (data.dbUsers || []).map(({username, _id}, userInd) => {
                let userId = _id;
                return {
                    label: username,
                    data: labels.map((odb, periodInd) => {
                        return parseFloat(((parsedStats[periodInd] || {})[userId] || {})[field] || 0) || 0
                    }),
                    borderColor: beautifulColors[userInd],//'rgb(53, 162, 235)',
                    // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            })
        }

        return <>
            <thead>
            <tr>
                <td colSpan={data.dbUsers.length + 1}>
                    <strong>{namesObj[field]}</strong>
                    <Line
                        options={props.options || {}} data={__data}/>;
                </td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>
                </th>
                {(data.dbUsers || []).map((item, ind) => {
                    return (<th className="th-name" key={ind}>
                        <td>{item.username}<br/>#{item._id}
                            <br/>
                            <div style={{width: '15px', height: '15px', background: beautifulColors[ind]}}></div>
                        </td>
                    </th>)
                })}
            </tr>
            </tbody>
            {(matchPeriods || []).map((it, periodInd) => {

                return (<tbody key={periodInd}>
                <tr>
                    <td>
                        <span>{it.name}</span>
                    </td>
                    {(data.dbUsers || []).map((it, ind) => {
                        let userId = it._id;
                        return (<td key={ind}>
                            <span>{((parsedStats[periodInd] || {})[userId] || {})[field]}</span>
                        </td>)
                    })}

                </tr>
                </tbody>)
            })}
        </>
    }

    return <div>
        <div className="table-responsive">
            <table className={'table table-bordered table-striped '}>
                {(names || []).map((it, ind) => {
                    return (<>
                        <RenderFields stats={parsedStats} field={it.key}></RenderFields>
                    </>)
                })}
            </table>
        </div>
    </div>
}


function DayPicker(props) {
    let {value, title, onChange} = props

    let days = 24 * 3600 * 1000;

    function _addDay(delta) {
        onChange(new Date(value).getTime() + delta * days)
    }


    return <>
        <div className="ib btn-day" onClick={() => {
            _addDay(-1)
        }}>
            <div className="fa fa-arrow-left"></div>
        </div>
        <div className="ib">
            <small>{title || 'Day'}</small>

            <input type="date" value={toOdb(value)} onChange={(e) => {
                onChange(new Date(e.target.value).getTime())
            }}></input>
        </div>
        <div className="ib btn-day" onClick={() => {
            _addDay(1)
        }}>
            <div className="fa fa-arrow-right"></div>
        </div>
    </>

}


function Graphic(props) {
    const labels = props.labels || ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,

        datasets: props.datasets || [
            {
                label: 'Label 1',
                data: labels.map(() => 100 * Math.round(Math.random())),
                borderColor: 'rgb(53, 162, 235)',
                // backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Label 2',
                data: labels.map(() => 30),
                borderColor: 'red',
                // backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ],
    };

    return <Line
        options={props.options || {}} data={data}/>;
}


function Daily(props) {
    let [data, setData] = useState({})
    let {userId, odb} = props

    let hours = m.from_to(0, 23)
    useEffect(() => {
        global.http.get('/load-stats', {user: userId, odb})
            .then(r => {
                setData(r.stats)
            })
    }, [userId, odb])


    if (!userId) {
        return <div>Выберите Пользователя</div>
    }

    let {stats = {}, details = {}, statsByHours = {}} = data || {};

    function RenderStats(props) {
        let {stats = {}} = props;
        return <>
            <div>{stats.pause || '-'} : Переключений окна</div>
            <div>{stats.total} сек [ {((stats.total || 0) / 3600).toFixed(1)}ч ] : Тотал рабочих секунд</div>
            <div>{stats.mouseClick} / {stats.mouseMove} сек активности (Клик / Мышь)</div>
            <div>{stats.keyDown} сек активности (Клавиатура)</div>
        </>
    }

    let HOURS = m.from_to(0, 23);
    let MAX_60 = (max = 60) => {
        return {
            scales: {
                y:
                    {
                        min: 0,
                        max: max || 60,
                        stepSize: max / 60,
                    },
            }
        }
    }

    return <div className={'row'}>
        <div className="col-sm-12">
            {/*<div className="ib graphIt" style={{width: '300px'}}>*/}

            {/*    <div>Devices: {_.size(data.devices)}</div>*/}
            {/*    <RenderStats stats={stats}></RenderStats>*/}
            {/*</div>*/}
            <div className="ib graphIt">
                <Graphic labels={HOURS}
                         options={MAX_60()}
                         datasets={[
                             {
                                 label: 'Тотал Рабочих минут (эни экшн)',
                                 data: HOURS.map(it => (statsByHours[it] || {}).totalMinutes || 0),
                                 borderColor: 'rgb(53, 162, 235)',
                                 backgroundColor: 'transparent',
                                 // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                             },
                         ]}>
                </Graphic>
            </div>
            <div className="ib graphIt">

                <Graphic labels={HOURS}
                         options={MAX_60()}
                         datasets={[
                             {
                                 label: 'Тотал Рабочих минут (включая гэп)',
                                 data: HOURS.map(it => (statsByHours[it] || {}).totalMinutes || 0),
                                 borderColor: 'rgb(53, 162, 235)',
                                 backgroundColor: 'transparent',
                                 // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                             },
                         ]}></Graphic>
            </div>
            <div className="ib graphIt">

                <Graphic labels={HOURS} options={MAX_60(3600)} datasets={[
                    {

                        label: 'Тотал Рабочих сек',
                        data: HOURS.map(it => ((statsByHours[it] || {}).total || 0)),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'transparent',
                        // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ]}></Graphic>
            </div>
            <div className="ib graphIt">

                <Graphic labels={HOURS} datasets={[
                    {

                        label: 'Кол-во переключений окна',
                        data: HOURS.map(it => ((statsByHours[it] || {}).pause || 0)),
                        borderColor: '#f25656',
                        backgroundColor: 'transparent',
                        // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ]}></Graphic>
            </div>
        </div>
        <div className="col-sm-12">
            <hr/>
            {(hours || []).map((hour, ind) => {
                let detailsHours = details[hour];
                let width = (100 / 60) + '%'
                return (
                    <div className={"row hoursWrap " + (details[hour] ? ' activeHours' : ' passiveHours')}
                         key={ind}>
                        <div className="col-sm-1">
                            <div className={'flex-bot-date'}>{pub(hour)}:00</div>
                        </div>
                        <div className="col-sm-5">
                            {!detailsHours && <div className="passiveGraph"></div>}
                            {detailsHours && <div className="statsGraph">
                                {(m.from_to(0, 59)).map((minute, ind) => {
                                    let {total = 0} = detailsHours[minute] || {};
                                    let height = 100 / 60 * Math.min(100, total) + 'px';

                                    return (<div key={ind} className={'minutesWrap'}>
                                        <div className={'statMinuteBlock ' + (!total ? 'emptyMinute' : '')}
                                             style={{width, height}}>
                                        </div>
                                    </div>)
                                })}
                            </div>}
                        </div>
                        <div className="col-sm-6" style={{fontSize: '13px', fontWeight: 'normal'}}>
                            <div className={'flex-bot-stats'}>
                                {statsByHours[hour] && <RenderStats stats={statsByHours[hour]}></RenderStats>}
                            </div>

                        </div>
                    </div>)
            })}
        </div>
    </div>
}

export {DayPicker, Daily}

export default UserStatsOld
