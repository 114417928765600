import React, {useEffect, useState} from 'react';

let {get, post, put} = global.http;

let fn = (url) => {
  return (query) =>{
    return get(url, query)
  }
}
let methods = {
  getLastAudio: fn('/load-hist'),
  getLastFeedbacks: fn('/load-feed'),
  getLastAQH: fn('/load-aqh'),
  updateQuizHistory: fn('/set-quiz-history-fbaq'),
  updateQuizHistory2: fn('/set-quiz-history-fbaq2'),
  getLastAQ: fn('/load-aq'),
  getUserStats: fn('/user-stats'),
  sendFB: fn('/send-fb'),
  sendAQ: fn('/send-aq'),
}

export default methods;
