import React, {useEffect, useState} from 'react';
import QHAdmin, {QHParentsAdminId} from "../QHAdmin";
import {getActiveClass} from "../../Suggest/SuggestionItem";
import {getDayFilterQuery} from "../CoursesList";
import NFN from "../../../NFN";

function Layout2(props) {
    let [items, setItems] = useState([]);
    let [item, setItem] = useState({});
    let [isAutoPlay, setAutoPlay] = useState(true);
    let [filterRate, setFilterRate] = useState(0);
    let [type, setType] = useState('all');
    let [total, setTotal] = useState(0);
    console.log('*........ ## ROOT RENDER', props);

    useEffect(() => {
        let query = type === 'interview' ? {autoInterview: {$exists: true}}
            : type === 'exam' ? {exam: {$gt: 1}} : {}

        console.log("qqqqq queryqueryquery", query);
        let subQuery = {}
        if (filterRate) {
            subQuery['answerDetails.rate'] = filterRate;
        }
        global.http.get('/quiz-history', {
            filter: {
                ...getDayFilterQuery(props.dayFilter),
                answerType: {$ne: 'quiz'}, user: props.user, ...query || {}, ...subQuery || {}},
            per_page: 1000
        }).then((r) => {
            console.log("qqqqq aaaaa4444", r);
            setTotal(r.total)
            setItems(r.items)
            setItem(r.items[0])
        })
    }, [props.dayFilter, props.user, type, filterRate])

    // let v = useActionData();
    return <div>
        <div className="pr">Селектор дня</div>
        Рабочие Сессии ({total})
        <div></div>
        <div className="pull-right">
            {([0, 1, 2, 3, 4, 5] || []).map((it, ind) => {
                return (<div key={ind} className={'ib ' + getActiveClass(it, filterRate)}
                             onClick={() => {setFilterRate(it)}}
                             style={{padding: '3px'}}>
                    {it}
                </div>)
            })}
        </div>


        {(['all', 'exam', 'interview'] || []).map((it, ind) => {
            return (<div key={ind} className={'ib ' + getActiveClass(it, type)} style={{marginRight: '5px'}}
                         onClick={() => {
                             setType(it)
                         }}>
                <NFN>{it}</NFN>
            </div>)
        })}


        <hr/>
        <div className="row ">
            <div className="col-sm-6">
                <div className="fbList">
                    {(items || []).map((it, ind) => {
                        let {answerDetails = {}, adminDetails = {}, reviewDetails = {}} = it;
                        let {localAttempt} = answerDetails;
                        let {titleInfo = {}} = it
                        ind < 1 && console.log("qqqqq ititititititi", it);
                        return (<div key={ind} className={''} onClick={() => {
                            setItem(it)
                            setAutoPlay(true)
                        }}>

                            <div>
                                <div className="ib">
                                    <div className="pointer">
                                        <small
                                            className="label2 label-default2">{answerDetails.rate || '-'}&nbsp;{adminDetails.rate || '-'}&nbsp;{reviewDetails.rate || '-'}</small>
                                    </div>
                                </div>
                                {localAttempt > 1 && <div className="label label-default">x{localAttempt || 0}</div>}
                                <div
                                    className="label label-default">Speed: {it.recognition.recognizedSpeed} (x{it.recognition.recognizedTextSize})
                                </div>
                                <div className="label label-default">#{it._id}</div>

                            </div>
                            <div className={'ellipse w100'}>
                                {titleInfo.title || titleInfo.smallTitle || titleInfo.desc}
                            </div>

                        </div>)
                    })}
                </div>
            </div>
            {item && <div className="col-sm-6">
                <QHAdmin item={item} autoPlay={isAutoPlay}></QHAdmin>
                <hr/>
                <QHParentsAdminId histId={item?._id}></QHParentsAdminId>
            </div>}


            {/*<div className="col-sm-4">*/}
            {/*    Курсы*/}
            {/*</div>*/}
            {/*<div className="col-sm-4">*/}
            {/*    Модули*/}
            {/*</div>*/}
            {/*<div className="col-sm-4">*/}
            {/*    Вопросы*/}
            {/*</div>*/}
            {/*<div className="col-sm-5">*/}
            {/*  Квизы с прокруткой*/}
            {/*  <hr/>*/}
            {/*  Сессии*/}
            {/*</div>*/}
        </div>
        {/*<small className="row">*/}
        {/*    <div className="col-sm-2">Date</div>*/}
        {/*    <div className="col-sm-2">Exam</div>*/}
        {/*    <div className="col-sm-2">ExamC</div>*/}
        {/*    <div className="col-sm-2">TotalQH</div>*/}
        {/*    <div className="col-sm-2">RecSize</div>*/}
        {/*    <div className="col-sm-2">RecSp</div>*/}
        {/*</small>*/}

    </div>
}

export default Layout2
