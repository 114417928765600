import React, {useEffect, useState} from 'react';
import './CoursesStats.css';
import Textarea from "../../libs/Textarea";

function Layout2(props) {
    let {user = 1000} = props;
    let [info, setInfo] = useState({})
    let [hist, setHist] = useState([])
    console.log('*........ ## ROOT RENDER', props);

    useEffect(() => {
        user && global.http.get('/load-courses-sadmin', {user: user}).then(r => {
            console.log("qqqqq rrrr", r);
            setInfo(r)
        })
        loadUserHist()
    }, [user])

    function loadUserHist() {
        user && global.http.get('/load-hist', {user, total: 10}).then(r => {
            console.log("qqqqq rrrr", r);
            setHist(r)
        })
    }

    function pub(d) {
        const date = new Date(d);

        const formattedTime = date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'});
        return formattedTime;
    }


    let statuses = [{name: 'Модулей изучено'}, {name: 'Аудио сделано'}, {name: 'Минут на портале'}, {name: 'Средняя активность на портале'}]
    let {courses, userCourses} = info || {};
    return <div className={'row mt20'}>
        <div className="col-sm-12">
            filter page
            <hr/>
        </div>
        <div className="col-sm-4">
            <table className={'animChild mt-10 table table-bordered table-striped '}>
                <thead>
                <tr>
                    <th></th>
                    <th>1d</th>
                    <th>3d</th>
                    <th>7d</th>
                    <th>30d</th>
                </tr>
                </thead>
                <tbody>
                {(statuses || []).map((it, ind) => {
                    return (<tr key={ind}>
                        <td>{it.name}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>)
                })}
                </tbody>
            </table>

            Courses List:
            <hr/>

            {(courses || []).map((it, ind) => {
                let userCourse = userCourses.find(c => c.course == it._id)
                return (<div key={ind}>
                    {it.name} {_.size(userCourse.qHistory)} / {_.size(userCourse.questions)}
                </div>)
            })}
        </div>
        <div className="col-sm-4">
            Voices he reviews wait my review
            <hr/>
            Voices on Review
            <hr/>
            Last Voices
        </div>
        <div className="col-sm-4">
            Activity?
            {(hist || []).map((it, ind) => {
                let {
                    answerType,
                    hash,
                    spentTime,
                    timePerc,
                    rate,
                    growTags,
                    growComment,
                    userRate,
                    localAttempt,
                    question,
                    quiz,
                    name,
                    correctAnswer,
                    selectedAnswer,
                    isCorrect
                } = it;
                return (<div key={ind} className={'historyItem'}>
                    {userRate} <i className="fa fa-star"></i> {pub(it.cd)} {spentTime}s {timePerc}% {it._id}
                    <div>{name}</div>
                    {answerType === 'audio' && <PreviewAudio it={it}></PreviewAudio>}
                    {answerType === 'quiz' && <PreviewQuiz it={it}></PreviewQuiz>}
                    {/*<pre>{JSON.stringify(it, null, 4)}</pre>*/}
                </div>)
            })}


        </div>
    </div>
}

export function Feedback(props) {
    let {filter, isCode, url = '/rate-admin', it, onChangeExam} = props;
    let {_id,} = it;
    let adminFeedback = it.adminFeedback || {};
    let [growComment, setAdminGrowComment] = useState('');
    let [rate, setRate] = useState();
    let [codeRate, setCodeRate] = useState();
    let [hist, setHist] = useState({});
    let names = {
        confidence: 'Уверенности',
        undrstanding: 'Понимания вопроса',
        practic: 'Практич примеров',
        structure: 'Структурованности ответа',
        misclick: 'Сбился при надиктовке',
        other: 'Другое'
    }

    useEffect(() => {
        let adminFeedback = it.adminFeedback || {};

        setAdminGrowComment(adminFeedback.growComment)
        setRate(adminFeedback.rate || it.rate);
        setCodeRate(adminFeedback.codeRate || it.codeRate);
        setHist((adminFeedback.growTags || []).reduce((acc, it) => {
            return {...acc, [it]: true}
        }, {}));
    }, [it])

    function onSetRate(rate) {
        postHttp({adminRate: rate})
        setRate(rate)
    }

    function onSetCodeRate(rate) {
        postHttp({adminCodeRate: rate})
        setCodeRate(rate)
    }

    function onSetPoints(key) {
        hist[key] = !hist[key];
        setHist({...hist})
        postHttp({hist})

    }

    function onSetComment(adminGrowComment) {
        setAdminGrowComment(adminGrowComment)
        postHttp({adminGrowComment})

    }

    function postHttp(opts) {
        console.log("qqqqq filterfilterfilterfilter", filter);
        global.http.post(url, {filter, _id, adminRate: rate, codeRate, hist, ...opts}).then((r) => {
            console.log("qqqqq rateddd admin", it, '@@@', r.adminRate);
            onChangeExam && onChangeExam(r)
        })
    }


    console.log("qqqqq ittttt", it);

    return <>
        {([1, 2, 3, 4, 5] || []).map((it, ind) => {
            return (<span className={'ib rate_admin ' + (rate == it ? 'selected' : '')} key={ind} onClick={() => {
                onSetRate(it)
            }}>
                {it}
            </span>)
        })}

        {isCode && <div>
            <small>CodeRate:</small>
            <div></div>
            {isCode && ([1, 2, 3, 4, 5] || []).map((it, ind) => {
                return (
                    <span className={'ib rate_admin ' + (codeRate == it ? 'selected' : '')} key={ind} onClick={() => {
                        onSetCodeRate(it)
                    }}>
                {it}
            </span>)
            })}</div>}


        <div></div>
        {!props?.woNames && (Object.keys(names) || []).map((it, ind) => {
            return (<div className={'ib rate_admin ' + (hist[it] ? 'selected' : '')} key={ind} onClick={() => {
                onSetPoints(it)
            }}>
                #{names[it]}
            </div>)
        })}

        {hist.other && <Textarea value={growComment} onChange={onSetComment} autoFocus={true}></Textarea>}

    </>
}

function PreviewAudio({it}) {
    let {
        _id,
        answerType,
        hash,
        spentTime,
        timePerc,
        rate,
        growTags,
        recognizedText,
        recognizedSpeed,
        growComment,
        localAttempt,
        question,
        user,
        quiz,
        name,
        correctAnswer,
        selectedAnswer,
        childSubQuestions,
        isCorrect
    } = it;

    let [_addQuestions, setAddQuestions] = useState(childSubQuestions || [])
    let [open, isOpen] = useState(false)
    let [text, setText] = useState('')

    function addAditionalQuestion() {
        console.log("qqqqq add Additional question",);

        global.http.get('/create-additional-question', {_id, name: text}).then(r => {

            console.log("qqqqq rrrrrr", r);
            // _addQuestions.unshift(r)
            setAddQuestions(r.childSubQuestions)
            setText('')
        })
    }


    _id == 1178 && console.log("qqqqq _addQuestions", it, _addQuestions, childSubQuestions);
    return <>
        <div>
            {(growTags || []).map((it, ind) => {
                return (<span className={'label label-success'} key={ind} style={{marginRight: '2px'}}>
                                {it}
                            </span>)
            })}
        </div>
        <div>
            GrowComment: {growComment}
        </div>
        <div onClick={() => {
            myPlayer({src: env.video_domain + '/' + user + '/' + hash + '.wav'})
        }}>
            <div className="fa fa-play" style={{fontSize: '20px'}}></div>
            <small title={'LocalAttempt'}>x{localAttempt}</small>
            <small>{+(recognizedSpeed || 0).toFixed(2)} letters/s</small>
        </div>
        <div>{recognizedText}</div>
        <Feedback it={it}></Feedback>
        <div>
            <small>Уточняющий вопрос</small>
        </div>
        {(_addQuestions || []).map((it, ind) => {
            return (<div key={ind}>
                {it.name}
            </div>)
        })}


        <Textarea value={text} onChange={v => setText(v)}></Textarea>
        <button onClick={() => {
            addAditionalQuestion()
        }}>Задать уточняющий вопрос
        </button>
    </>
}

function PreviewQuiz({it}) {
    let {
        answerType,
        hash,
        spentTime,
        timePerc,
        rate,
        growTags,
        growComment,
        localAttempt,
        question,
        quiz,
        name,
        correctAnswer,
        selectedAnswer,
        isCorrect
    } = it;

    return <>

        <small>
            Правильно: {correctAnswer}
        </small>
        <div></div>
        <small>Выбранный: {selectedAnswer}</small>

    </>
}


export default Layout2
