import React, {useEffect, useState} from 'react';
import QHAdmin, {QHParentsAdminId} from "./QHAdmin";

function QuizByQuestion(props) {
  let [info, setInfo] = useState({})
  let [train, setTrain] = useState([])
  let [exam, setExam] = useState([])
  let {_id, quiz, user} = props || ''

  useEffect(() => {
    _id && global.http.get('/admin/load-sessions-by-question', {question: _id, quiz, user}).then(r => {
      console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrr3333", r);
      setTrain(r.train)
      setExam(r.exam)
      // setInfo(r)
    })
  }, [_id, quiz])
  console.log('*........ ## ROOT RENDER infoinfoinfoinfo ', info);


  // let v = useActionData();
  return <div>
    <div className="row">
      <div className="col-sm-6">
        Экзамен
        {(exam || []).map((it, ind) => {
            return (<div key={ind}>
              <QHAdmin item={it}></QHAdmin>
              <QHParentsAdminId histId={it._id}></QHParentsAdminId>
              <hr/>
            </div>)
        })}


      </div>
      <div className="col-sm-6">
        Тренировка
        {(train || []).map((it, ind) => {
            return (<div key={ind}>
              <QHAdmin item={it}></QHAdmin>
              <QHParentsAdminId histId={it._id}></QHParentsAdminId>
              <hr/>
            </div>)
        })}


      </div>
    </div>
  </div>
}

export default QuizByQuestion
