import React, {useState} from 'react';
import Train from "./Train";

function Layout2(props) {
    let opts = props.opts;
    let {loading, quizes} = opts;
    console.log('*........ ## ROOT RENDER', props);

    if (loading) {
        return <div>Loading ...</div>
    }
    console.log("qqqqq quizes44441111", props);
    let Res = props.Result;
    // let v = useActionData();
    return <div>
        <Train
            isExam={opts.isExam}
            quizOpenNextIfCorrectMs={600}
            quizOpenNextIfIncorrectMs={5000}
            maxAttemptsCount={5}
            opts={opts}
            // url={'/exam-load'}
            getItems={async () => {
                return quizes
            }}
            Result={Res || Result2}
            onChange={(v) => {
                // setTrainHist(v)
            }}
        >
        </Train>
    </div>
}


function Result2(props) {
    return <>
        <hr/>
        Result pge 2</>
}

export default Layout2
