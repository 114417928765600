import React, {useEffect, useRef, useState} from 'react';
import _ from 'underscore';
import './Course.css';
import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../libs/Smart";
import Textarea from "../../libs/Textarea";
import MDEditorComp from "../Suggest/MDEditorComp";
import Input from "../../libs/Input";
import MyModal from "../../libs/MyModal";
import Button from "../../libs/Button";
import QuizPreview from "../Suggest/QuizPreview";
import {QuizEditFields} from "../Suggest/SuggestionItem";
import PreviewCourseModule from "../Suggest/PreviewCourseModule";
import PreviewCourseModuleById from "../CourseModule/PreviewCourseModuleById";
import NFN from "../../NFN";


function Course({props}) {
    let [selectedModuleInd, setSelectedModuleInd] = useState(0)
    let {item, onGlobalChange, autoSaveFn, onChange} = props || {};
    item ??= {};
    let quizesModal = useRef(null);
    let selectedModule = (item.modules || {})[selectedModuleInd] || {};
    console.log("qqqqq selectedModule", props, item);


    return <div className={'row'}>
        <div className="col-sm-3">
            <Smart
                obj={item}
                items={[{
                    size: 12, key: 'modules',
                    sortable: true,
                    notFoundText: ' ',
                    addName: '+ Модуль ',
                    each: [
                        {
                            size: 12,
                            Component: ({ind, localItem}) => {
                                return <div
                                    className={'ib courseModuleItem w100 ' + (selectedModuleInd === ind ? 'active' : '')}
                                    onClick={() => setSelectedModuleInd(ind)}>{localItem.name || '-'}</div>
                            },
                        },
                    ]
                }]}
                onChange={(v) => {
                    item.modules = v.modules;
                    onGlobalChange(item)
                }
                }
            ></Smart>
        </div>
        <div className="col-sm-9">

            {/**/}
            <Smart
                obj={(item.modules || [])[selectedModuleInd] || {}}
                items={[
                    {
                        Component: ({item}) => {
                            return <Link to={'/course-module/' + item.module}><NFN>Edit Module</NFN> #{item.module}</Link>
                        }
                    },
                    {
                        name: 'name', key: 'name', size: 12
                    }, {
                        name: 'module', key: 'module', size: 12
                    }, {
                        type: 'HR', size: 12
                    }]}
                onChange={(v) => {
                    item.modules[selectedModuleInd] = v
                    onGlobalChange(item)
                }
                }
            ></Smart>
            <PreviewCourseModuleById props={{item: {_id: selectedModule.module}}}></PreviewCourseModuleById>
        </div>
    </div>
}


// function SelectedBlock(props) {
//     let [selectedBlock, setSelectedBlock] = useState({})
//
//     useEffect(() => {
//         setSelectedBlock(props.it)
//     }, [props.blockInd, props.selectedModuleInd])
//
//     let localItem = {};
//
//     return <div>
//         {localItem.type}
//         <Textarea value={selectedBlock.name} onChange={(v2) => {
//             // onChange(v2, 'name')
//             selectedBlock.name = v2
//             console.log("qqqqq pppp", props, selectedBlock.name);
//             setSelectedBlock({...selectedBlock})
//         }
//         }></Textarea>
//     </div>
// }


export default Course
