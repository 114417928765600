import React, {useState} from 'react';
import CircularProgress2 from "./CircularProgress2";
import FeedbackReview from "../FeedbackReview";

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);


    let getPercNewCount = () => {
        let wrongCount = 0;
        let openCount = 0;
        let time = 0;
        let cd = new Date().getTime();
        let count = (res.fb || []).length || 0;

        let timeCount = 0;


        _.each(res.fb, (item, ind) => {
            let {isOpen, answerDetails = {}, stats = {}, adminDetails = {}, reviewDetails = {}} = item;


            if (adminDetails.rate != 5) {
                console.log("qqqqq item", item);
                time += (reviewDetails.cd || cd) - adminDetails.cd
                console.log("qqqqq time", time);
                timeCount++;
            }

            if (isOpen) {
                openCount++;
            }


            if (stats?.answerVSadmin !== 'equal') {
                wrongCount++;
            }
            //
        })

        return {
            totalCount: count, openCount, perc: Math.round(100 * (count - wrongCount) / (count || 1)),
            time: Math.round((time / (timeCount || 1)))
        }

    }

    let {onClick, fb, onTrain, onChangeFb, res} = props;
    let {perc, totalCount, time, openCount} = getPercNewCount()

    let isAdmin;
    // let v = useActionData();
    return <div>
        <div>Кол-во открытых фидбеков: {openCount} из {totalCount}</div>
        <div>% соответствия оценок: {perc}%;
            <div className="ib mlcircle">
                <CircularProgress2
                    title={""} value={perc} size={20}></CircularProgress2>
            </div>
        </div>
        {!!time && <div>Время обработки ОС: {Math.round(time / (60 * 1000)) || '> 1'}мин</div>}
        {/*<Select items={['new', 'open', 'all']}></Select>*/}
        <hr/>
        <div className="row">
            <div className={isAdmin ? "col-sm-12" : "col-sm-5"}>
                <div className={'fbList animChild'}>
                    {(res.fb || []).map((it, ind) => {
                        let {
                            odb, hist1, isAdmin, stats = {},
                            name, _id,
                            isOpen, isViewed,
                            adminReviewOnReviewDetails = {},
                            answerDetails = {}, adminDetails = {}, reviewDetails = {}
                        } = it || {};
                        let {answerVSadmin, rate} = adminDetails;
                        let {isReview, isReview5, reviewTime} = stats;
                        answerDetails = answerDetails || {}
                        console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrr", it.parents);
                        let count = it.parents.length;
                        return (<div key={ind} onClick={() => {
                            onClick(it)
                        }}>
                            <div>
                                {!it.adminReviewOnReviewDetails?.rate && reviewDetails?.rate == 5 && <div className="label label-primary">АДМИН ПОСМОТРИ</div>}

                                {/*{!isOpen && <div className="label label-success">ok</div>}*/}
                                {!!count && adminDetails?.rate != 5 && <div className="label label-danger">ПОВТОРНЫЙ !!</div>}
                                {isOpen && <div className="label label-danger">open</div>}
                                {!isViewed && <div className="label label-success">new</div>}
                                <div className="label label-default">#{_id}</div>
                            </div>
                            <div className="ellipse">
                                {name || '-'}
                            </div>
                            <div></div>
                            <div className="pointer">
                                <small className="label2 label-default2">Старт: {answerDetails.rate || '-'}</small>
                                <small className="label2 label-default2">A1: {adminDetails.rate || '-'}</small>
                                <small className="label2 label-default2">Ревью: {reviewDetails.rate || '-'}</small>
                                {reviewDetails.rate && <small className="label2 label-default2">А2: {adminReviewOnReviewDetails?.rate || '-'}</small>}
                            </div>
                            {/*<div>Reviw: {isReview ? 'R' : 'NotR'}</div>*/}
                            {/*<div>IsReview5: {isReview5 ? 'R5' : 'NotR5'}</div>*/}

                            {/*<div>{isReview5 ? 'r5' : 'not5'}*/}
                            {/*</div>*/}
                            <hr/>
                        </div>)
                    })}
                </div>
            </div>
            {!isAdmin && <div className="col-sm-7">
                <FeedbackReview fb={fb} onTrain={(v) => {
                    console.log("qqqqq on Train", v);
                    onTrain(v)
                }}></FeedbackReview>
            </div>}
        </div>

    </div>
}

export default Layout2
