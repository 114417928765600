import _ from 'underscore';

let timer;
let isImported;
let api_domain = `http://212.8.247.141ongo:6057`;

let categoriesObj = {};
let categoriesArr = [];
let users = [];
let users_obj = {};
const Storage = {
    setImport(v) {
        // users = v.users;
        categoriesObj = v.categoriesObj;
        skills = v.skills;
        // users_obj = Storage.objectifyUsers()

        Storage.set('categoriesObj', categoriesObj);
        Storage.set('skills', skills);
        Storage.set('users', users);


    },
    get(key) {
        let value = localStorage.getItem(key);
        try {
            value = JSON.parse(value)
        } catch (e) {

        }
        return value;
    },

    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
        if (/skills|users|categoriesObj/gi.test(key) && isImported) {

            clearTimeout(timer);
            timer = setTimeout(() => {
                Storage.exportObj(this.exportAnimation)
            }, 700)
        }

    },

    exportAnimation() {
        let el = window.$('#export');
        el.removeClass('active');
        setTimeout(() => {
            el.addClass('active')
        }, 10)
    },

    timeId(delta = 0) {
        let cd = new Date().getTime();
        return cd + delta;
    },
    addCategory(obj, cb) {
        let parentId = obj.parentId;

        global.http.post('/hash-tag', obj)
            .then(r => {
                let _category = {_id: r._id, title: r.title, parentId};
                Storage.setCategory(_category._id, _category);
                cb && cb(_category)
            })
    },
    getUserId() {
        return 1000;
    },

    getCategoryId() {
        let userId = this.getUserId() || 1000;
        return `cat_${userId}_${this.timeId()}`
    },

    getSkillId() {
        let userId = this.getUserId() || 1000;
        return `skill_${userId}_${this.timeId()}`
    },
    loadQuestions(hashTags, cb) {
        if (!hashTags || hashTags == -1) {
            return cb && cb([])
        }
        let filter = {hashTags: hashTags.length ? {$in: hashTags} : hashTags, isDuplicated: false};

        global.http.get('/theme-question', {filter, per_page: 100000}).then(r => {
            cb && cb(r.items)
        })
    },
    loadAllQuestions(cb) {
        global.http.get('/theme-question', {filter: {isDuplicated: false}, per_page: 100000}).then(r => {
            cb && cb(r.items)
        })
    },
    loadInterviewQuestions(hashTags, cb) {
        if (!hashTags || hashTags == -1) {
            return cb && cb([])
        }
        let filter = {hashTags};

        global.http.get('/interview-question', {filter, per_page: 100000}).then(r => {
            cb && cb(r.items)
        })
    },
    loadInterviewQuestionsByTheme(themeId, cb) {

        let filter = {
            themeQuestionId: themeId
        }
        global.http.get('/interview-question', {filter, per_page: 99999}).then(r => {
            cb && cb(r.items)
        })
    },
    getCategoriesPlain() {
        return Object.keys(categoriesObj).map(it => {
            return categoriesObj[it]
        })
    },
    getParentCategory(selectedTag, deep = 0) {
        let categories = Storage.getCategories()

        let parent = (categories[selectedTag] || {}).parentId
        let parent1 = (categories[parent] || {}).parentId

        // console.log("qqqqq 22222222222222222222222222", selectedTag, parent, parent1, deep, categories[selectedTag], {categories}, {categoriesObj});
        if (parent == -1) {
            return selectedTag
        }

        if (parent1 == -1) {
            return parent
        }
        if (deep > 15) {
            return selectedTag;
        }

        return Storage.getParentCategory(parent1, deep + 1)
    },
    getCategories() {
        return categoriesObj;
    },

    syncCategories(cb) {
        global.http.get('/hash-tag', {per_page: 10000}).then((r) => {
            categoriesObj = {};
            _.each(r.items, it => {
                it.parentId = it.parentId || -1;
                categoriesObj[it._id] = it;
                Storage.calcCategoriesChilds();

            })
            cb && cb();
        })
    },
    calcCategoriesChilds() {
        _.each(categoriesObj, (it, key) => {
            it.childs = [];
        });

        _.each(categoriesObj, (it, _id) => {
            let parent = categoriesObj[it.parentId];
            if (parent && parent.childs) {
                parent.childs.push(_id)
            }
        })
    },

    setCategories(v) {
        categoriesObj = v || categoriesObj;
        Storage.calcCategoriesChilds();

        Storage.set('categoriesObj', categoriesObj)
    },

    deleteCategory(id) {
        let ids = Storage.getAllChildIds(id);

        _.each(ids, (_id, ind) => {
            delete categoriesObj[_id]
        })

        Storage.set('categoriesObj', categoriesObj);
        skills = _.filter(skills, it => {
            return ids.indexOf(it.category) < 0
        });

        global.http.get('/delete-hash-tag', {_id: id, ids}).then(r => {
        })
        Storage.set('skills', skills);
    },
    getObj() {
        let obj = {
            users: (Storage.getUsers() || {}).users,
            categoriesObj: Storage.getCategories(),
            skills: Storage.getAllSkills()
        };
        return obj;
    },
    exportObj(cb) {
        let obj = Storage.getObj();

        global.http.post('/save_skills', obj, {domain: api_domain})
            .then(r => {
              //console.log('*........ ## rrrr', r);
                cb && cb();

            })
            .catch(e => {
                alert(JSON.stringify(e));
            })
    },

    importObj(cb) {

        global.http.get('/get_skills', {}, {domain: api_domain})
            .then(r => {
                console.log('*........ ## rrrr', r);
                // window.notify.success('Imported ')

                Storage.setImport(r);
                cb && cb(r);
                isImported = true;
            })
    },
    setCategory(id, obj) {
        categoriesObj[id] = obj;
        Storage.calcCategoriesChilds();

        Storage.set('categoriesObj', categoriesObj)
        global.http.put('/hash-tag', obj);
    },
    getUsers() {
        return {users, users_obj}
    },

    getSkills(categoryId, filter, onlyThatCategory) {
        console.log('*........ ## categoryId', categoryId);
        filter = filter || {};
        let searchReg = filter.search ? new RegExp(filter.search, 'gi') : null;


        if (filter.skill_id) {
            return _.sortBy(_.filter(skills, it => {
                return it._id == filter.skill_id
            }), it => {
                return it.isPin2 ? 3 : (it.isPin ? 2 : 1)
            })
        }

        let _ids = onlyThatCategory ? [categoryId] : this.getAllChildIds(categoryId);

        return _.sortBy(
            _.filter(skills,
                skill => {
                    if (searchReg && !searchReg.test(skill.title)) {
                        return false;
                    }
                    return (categoryId == -1) || (_ids.indexOf(skill.category) > -1)// == categoryId;
                })
            , it => {
                return (it.isPin2 ? 1 : (it.isPin ? 2 : 3)) //* (((categoryId === -1) || (it.category === categoryId)) ? 1 : -1)
            })
    },
    getAllSkills() {
        return skills;
    },

    getAllChildIds(categoryId) {
        let arr = [categoryId];

        _.each((categoriesObj[categoryId] || {}).childs, (childId, ind) => {
            arr = arr.concat(this.getAllChildIds(childId))
        });

        return _.uniq(arr);
    },

    getHash(key, defValue) {

        let arr = (window.location.hash || '').replace('#', '').split('&');
        _.each(arr, (it, ind) => {
            let _arr = it.split("=")
            if (_arr[0] == key) {
                defValue = _arr[1]
            }
        })
        return defValue;

    },

    getAuthorsId() {
        let userId = this.getUserId() || 1000;
        return `user_${userId}_${this.timeId()}`
    },
    objectifyUsers() {
        let obj = {};
        _.each(users, (it, ind) => {
            if (!it._id) {
                it._id = this.getAuthorsId();
            }
            obj[it._id] = it;
        })
        return obj;
    },
    filterSkills() {
        _.each(skills, (skill, ind) => {
            this.skillCalcUpdate(skill);
            _.each(skill, (it, key) => {
                if (/[а-я]/gi.test(key)) {
                    // console.log('*........ ## key', key);
                    delete skill[key];
                }
            })
            // console.log('*........ ## skill ', skill);
        });
        Storage.set('skills', skills)

    },
    updateThemeQuestion(question, cb) {
        global.http.put('/theme-question', question).then(r => {
            cb && cb()
        })
    },
    tryRemoveQuestion(question, cb) {
        global.http.get('/try-remove-question', {_id: question._id}).then(r => {
            cb && cb()
        })
    },
    createThemeQuestion(query, cb) {
        global.http.post('/theme-question', query).then(r => {
            cb && cb(r)
        })
    },
    skillCalcUpdate(skill) {

        let delta_counts = {
            '-1': -2,
            0: 1,
            1: 3
        };
        let delta_counts0 = {
            '-1': -6,
            0: 1,
            1: 3
        };
        let MAX_LENG = 10;
        let scores = [1, 2, 4, 7, 14, 21, 30, 45, 60, 90, 90];
        skill.history = (skill.history || []).slice(0, MAX_LENG);
        let {history = []} = skill;
        let score10 = 0;
        let score3 = 0;

        let is_ok = null;
        _.each(history, (it, ind) => {
            let value = it.value;
            is_ok = is_ok || (value > -1);
            let score_v = (is_ok ? delta_counts : delta_counts0)[value];
            score10 += score_v;
            if (ind < 3) {
                score3 += score_v;
            }
            console.log('*........ ## score_v', {is_ok, score_v, value, score10, score3});

        });

        skill.score10 = score10;
        skill.score3 = score3;
        let vv = history.length;
        if (score3 < 0) {
            vv = Math.max(Math.round(vv * 4 / 7), 0);
        }
        // else if (score3 > 0) {
        //   vv = Math.min(MAX_LENG, vv + 1)
        // }

        skill.scoreInd = vv;
        skill.nextCd = new Date().getTime() + (scores[vv] || 360) * 24 * 3600 * 1000;
    },
    // deleteSkill(skill) {
    //   skills = _.filter(skills, it => {
    //     return it._id != skill._id
    //   })
    //   Storage.set('skills', skills)
    // },
    // skillUpdate(skill) {
    //   let _id = skill._id;
    //   let _skill = _.filter(skills, it => {
    //     return it._id == _id
    //   })
    //   _skill.cd = _skill.cd || new Date().getTime();
    //   _skill = _.extend(_skill, skill);
    //   Storage.set('skills', skills);
    // },
    // updateUsers(_users) {
    //   users = _users;
    //   users_obj = Storage.objectifyUsers();
    //   Storage.set('users', users)
    // }

};

let skills = Storage.get('skills') || [];

window.Storage = Storage;

export default Storage;
