import React, {useState, useEffect} from 'react';
import CircularProgress2 from "./Comps/CircularProgress2";
import Button from "../../libs/Button";
import {getCoursePerc, getPercByIds} from "./Comps/mainMethods";
import QuizByQuestion from "./QuizByQuestion";
import {getActiveClass} from "../Suggest/SuggestionItem";
import {getQuizAnyName} from "../Suggest/RenderQuizResults";
import DebugLogs from "./DebugLogs";

function CoursesAndQuestions(props) {
    let [courseInd, setCourseInd] = useState(0)
    let [moduleInd, setModuleInd] = useState(0)
    let [questionInd, setQuestionInd] = useState(0)
    let {res, info, user, history, courses} = props


    let active = (ind1, ind2) => {
        return ind1 == ind2 ? 'selectedTag' : ''
    }

    let getModules = () => {
        return (courses[courseInd] || {}).modules || []
    }

    let getQuestionsCourseModule = (moduleInd) => {
        return ((histCourse.modules || [])[moduleInd] || {}).questions || []
    }
    let getQHistory = (_courseInd = courseInd) => {
        return (history[(courses[_courseInd] || {})._id] || {}).qHistory || {}
    }
    let getHistPerc = (ids) => {
        let cur = 0;
        let total = 0;
        _.each(ids, (_id, ind) => {
            total += 1;
            cur += (qHist[_id] || {}).status == 'ok' ? 100 : 0

        })

        console.log("qqqqq _ids", ids, qHist, cur, total);
        return Math.round(cur / total)
    }

    let onSetModuleInd = (ind) => {
        setModuleInd(ind)
        setQuestionInd(0)
    }

    let {calcQuestion, calcQuiz, questionsWithQuizes} = props?.res?.result || {}
    let modules = getModules();
    let histCourse = (history || {})[(courses[courseInd] || {})._id] || {};
    let questions = getQuestionsCourseModule(moduleInd)
    let qHist = getQHistory(courseInd)

    let question = (questions || [])[questionInd] || {}

    console.log('*........ ## ROOT RENDER4444444444444', questionsWithQuizes, props?.res?.result, question, props, {
        calcQuestion,
        calcQuiz
    });

    // let v = useActionData();
    return <div className={'row'}>
        <div className="col-sm-2 fbList2">
            Курсы ({courses.length})

            {/*<div className={"fbList " + active(courseInd, -1)}>All</div>*/}
            {(courses || []).map((it, ind) => {
                let perc = getCoursePerc(it, history);
                let hist = (history || {})[it._id] || {};


                let count = getCountByQuestions(hist.questions, calcQuestion, true)

                let {train, exam} = getPercByIds(hist.questions, res)
                perc = perc || 0;
                return (
                    <div
                        to={"/courses/" + it._id}
                        key={ind}
                        className={"fbList " + active(courseInd, ind)}
                        onClick={() => {
                            // onClick && onClick(hist.questions)
                            setCourseInd(ind)
                            onSetModuleInd(0)
                        }}
                    >
                        <div className="text-left ellipse w100 pointer"
                             style={{padding: '6px 0'}}
                        >
                            <div className="ib coursesProgress"
                                 style={{width: '45px', marginRight: '7px'}}>
                                <div className="ib" style={{width: '33%'}}>
                                    <CircularProgress2
                                        zoom={.7}
                                        title={"Теория"} value={perc} size={20}></CircularProgress2>
                                </div>
                                <div className="ib" style={{width: '33%'}}>
                                    <CircularProgress2
                                        zoom={.7}
                                        title={"Практ"} value={train} size={20}></CircularProgress2>
                                </div>
                                <div className="ib" style={{width: '33%'}}>
                                    <CircularProgress2
                                        zoom={.7}
                                        title={"Экзамен"} value={exam} size={20}></CircularProgress2>
                                </div>
                            </div>
                            <div className="courseTitle ib" style={{}}>
                                <Count count={count}></Count>
                                {it.name}</div>
                            <div className="icons">
                                <Button size={'xs'} color={4}>
                                    <div className="fa fa-book"></div>
                                </Button>
                                <Button size={'xs'} color={4}>
                                    <div className="fa fa-play"></div>
                                </Button>
                            </div>
                            {/*<small>Кол-во модулей: {(hist.modules || []).length}</small>*/}
                        </div>
                    </div>
                );
            })}


        </div>
        <div className="col-sm-2 fbList2">
            Модули ({modules.length})
            {(modules || []).map((it, ind) => {
                let questions = getQuestionsCourseModule(ind)
                // let train = 0;
                // let exam = 0;
                console.log("qqqqq itttttttt55555555555555", questions, it);
                let {train, exam} = getPercByIds(questions, res)
                let count = getCountByQuestions(questions, calcQuestion)

                console.log("qqqqq getCountByQuestionsg", count, questions, calcQuestion);
                return (<div key={ind}
                             className={'fbList ' + active(moduleInd, ind)}
                             onClick={() => {
                                 onSetModuleInd(ind)
                             }}
                >
                    <div className="text-left ellipse w100 pointer"
                         style={{padding: '6px 0'}}
                    >
                        <div className="ib coursesProgress"
                             style={{width: '45px', marginRight: '7px'}}>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Практ"} value={getHistPerc(questions)} size={20}></CircularProgress2>
                            </div>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Практ"} value={train} size={20}></CircularProgress2>
                            </div>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Экзамен"} value={exam} size={20}></CircularProgress2>
                            </div>
                        </div>
                        <div className="courseTitle ib" style={{}}><Count count={count}></Count>{it.name}</div>
                    </div>
                </div>)
            })}


        </div>
        <div className="col-sm-3 fbList2">
            Вопросы ({questions.length})
            <div></div>
            {(questions || []).map((it, ind) => {
                let q = (props.questionsObj[it] || {})
                let {title, _id, train, exam} = q || {};
                console.log("qqqqq qqq444444222124124", q, props);
                return (<div key={ind}
                             className={'fbList questionsList ' + active(questionInd, ind)}
                             onClick={() => {
                                 setQuestionInd(ind)
                             }}
                >
                    <div className="text-left  w100 pointer"
                         style={{padding: '6px 0'}}
                    >
                        <span className="ib coursesProgress"
                              style={{width: '45px', marginRight: '7px'}}>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Практ"} value={getHistPerc([_id])} size={20}></CircularProgress2>
                            </div>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Практ"} value={train} size={20}></CircularProgress2>
                            </div>
                            <div className="ib" style={{width: '33%'}}>
                                <CircularProgress2
                                    zoom={.7}
                                    title={"Экзамен"} value={exam} size={20}></CircularProgress2>
                            </div>
                        </span>
                        <div className="courseTitle ellipse ib" style={{}}>
                            <Count count={q?.totalSessions}></Count>
                            <DebugLogs>#{q._id}</DebugLogs> {q.title}</div>
                    </div>
                </div>)
            })}


        </div>
        <div className="col-sm-5">
            <QuizesListByQuestion
                user={user}
                questionsWithQuizes={(questionsWithQuizes || {})[question]}
                calcQuiz={calcQuiz}
                calcQuestion={calcQuestion}
                question={question}>
            </QuizesListByQuestion>
            {/*<div>Quizses With Stats </div>*/}

        </div>
    </div>
}

function getCountByQuestions(questions, calcQuestion, logs) {
    let count = 0;
    let res = {}
    let _ids = {}
    _.each(questions, (item, ind) => {

        let _id = item?._id || item

        if (_ids[_id]) {
            return;
        }
        let dc = +((calcQuestion || {})[_id] || {}).totalSessions || 0;
        count += dc
        res[_id] = dc
        _ids[_id] = true;
    })

    logs && console.log("qqqqq COUTasdfasdfasdf", {count, res}, questions, calcQuestion);

    return Math.min(count, 999);

}


function QuizesListByQuestion(props) {
    let {question, calcQuiz, user, questionsWithQuizes, calcQuestion} = props;
    let [quizes, setQuizes] = useState([])
    let [selectedQuiz, setSelectedQuiz] = useState(null)

    useEffect(() => {
        let _ids = (questionsWithQuizes || []).map(it => it._id);
        console.log("qqqqq _ids", _ids);
        if (_ids && _ids.length) {
            global.http.get('/quiz', {filter: {_id: {$in: _ids}}}).then(r => {
                console.log("qqqqq rrrrrrrrrrrr", r);
                setQuizes(r.items)
                setSelectedQuiz(0)
            })
        } else {
            setQuizes([]);
        }
    }, [questionsWithQuizes])


    console.log("qqqqq questionsWithQuizes444", questionsWithQuizes, calcQuiz, quizes);
    return <div>

        <div className="fbList2">
        {([{name: 'all', _id: 0}, ...quizes || []]).map((it, ind) => {
            let quizId = it._id;
            let {
                exam = 0,
                train = 0
            } = quizId ? ((calcQuiz || {})[quizId] || {}) : ((calcQuestion || {})[question] || {})
            let count = quizId ? ((calcQuiz || {})[quizId] || {}).sessions || 0 : +((calcQuestion || {})[question] || {}).totalSessions
            console.log("qqqqq countttttttttttt", count);
            return (<div key={ind} onClick={() => {
                setSelectedQuiz(it._id)
            }} className={getActiveClass(it._id, selectedQuiz)}>
                <div className="ellipse" title={it._id}>
                    {<div className="ib" style={{marginTop: '3px'}}>

                        <div className="ib" style={{width: '20px'}}>
                            <CircularProgress2
                                zoom={.7}
                                title={"Практ"} value={train} size={20}></CircularProgress2>
                        </div>
                        <div className="ib" style={{width: '20px'}}>
                            <CircularProgress2
                                zoom={.7}
                                title={"Exam"} value={exam} size={20}></CircularProgress2>
                        </div>
                    </div>}
                    <Count count={count}></Count>{!!quizId && <DebugLogs>#{it._id}</DebugLogs>} {getQuizAnyName(it)}
                </div>
            </div>)
        })}
        </div>
        <hr/>
        <div className="fbList2">
            <QuizByQuestion _id={question} user={user} quiz={selectedQuiz}></QuizByQuestion>
        </div>
    </div>
}

function Count(props) {
    let {count} = props;
    if (!count || !+count) {
        return null;
    }
    if (count > 999) {
        return 999
    }
    return <strong>x{+count} </strong>
}

export default CoursesAndQuestions
