import * as React from 'react';
import {useEffect, useState} from 'react';
import './tree.css';
import Storage from './Storage';
import _ from 'underscore';


function TreeIt(props) {
    let trees = Storage.getCategories();
    let {parentIds, searchReg, categoriesCount} = props //|| [1, 2];

    return (parentIds || []).map((id, ind) => {
        let it = trees[id];
        let isOk = searchReg ? searchReg.test(it.title) : true;

        if (!it) {
            return null;
        }
        let childs = _.sortBy(it.childs, child => {
            return (trees[child] || {}).title || '-'
        });

        let isSelected = (id) => {
            return (props.selectedId == id) || (props.selectedIds || {})[id]
        }

        return ((!it) ? <div>-- {id}</div> : <div
            className={'tree-child '} onClick={(e) => {
            props.onClick && props.onClick(id);
            e.preventDefault();
            e.stopPropagation();
            return true;
        }}>
            {isOk ? <div className={'tt2 tree-title ' + (isSelected(id) ? 'selected' : '')}>{it.title || '---'}
                {((categoriesCount || {}).themeQuestions || {})[it._id] &&
                    <small> [x{categoriesCount.themeQuestions[it._id]} /
                        x{categoriesCount.interviewQuestions[it._id]} {categoriesCount.emptyInterviewQuestions[it._id] &&
                            <span> / x{categoriesCount.emptyInterviewQuestions[it._id]}</span>}]</small>
                }</div> : null}
            {(childs || []).map((childId, ind) => {
                // console.log('*........ ## childid', id, childId, parentIds, it);
                return (<div key={childId}>
                    <TreeIt parentIds={[childId]}
                            categoriesCount={categoriesCount}
                            searchReg={searchReg}
                            selectedId={props.selectedId}
                            selectedIds={props.selectedIds}
                            onClick={props.onClick}
                    ></TreeIt>
                </div>)
            })}
        </div>)
    })
}

export default function Tree(props) {
    let [search, setSearch] = useState('');
    let [cd, setCd] = useState(0);
    let trees = Storage.getCategories();
    let {selectedId, categoriesCount, parentCategory, parentCategoryIds, onSearch, selectedIds} = props;

    useEffect(() => {
        if (props.sync) {
            Storage.syncCategories(() => {
                setCd(new Date().getTime())
            });
        }
    })

    let parent_1 = _.map(Object.keys(trees), (_id) => {
        return (trees[_id] || {}).parentId == -1 ? _id : null
    }).filter(it => it);
    if (props.shortTree) {
        let cat = Storage.getParentCategory(selectedId)
        parentCategoryIds = cat ? [cat] : null
    }
    let parentItems = parentCategoryIds ? parentCategoryIds : parentCategory ? [parentCategory] : parent_1
    let parentIds = _.sortBy(_.filter(parentItems, it => it), v => {
        return (trees[v] || {}).title
    });
    let searchReg = search ? new RegExp(search, 'gi') : null;

    // console.log("qqqqq searchsearchsearchsearch", search, parent_1, parentIds);
    return (
        <div className={'trees'}>
            <div className={props.defClassInput}>
                <input placeholder={'Поиск по категориям'} value={search} onChange={(e) => {
                    onSearch && onSearch(e.target.value)
                    setSearch(e.target.value)
                }}/>
            </div>

            <div className={props.defClass}>
                {<div
                    onClick={(e) => {
                        props.onClick && props.onClick(-1, 'Parent -1')
                    }}
                    className={'tree-title ' + (props.selectedId == -1 ? 'selected' : '')}>Все</div>}

                <TreeIt
                    categoriesCount={categoriesCount}
                    searchReg={searchReg}
                    selectedId={selectedId}
                    selectedIds={selectedIds}
                    parentIds={!search ? parentIds : parent_1}
                    onClick={(id) => {
                        props.onClick && props.onClick(id, trees[id]?.title)
                    }}/>
            </div>
        </div>
    );
}
