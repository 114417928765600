import React, {useEffect, useState} from 'react';
import {isDebugLogsFn} from "../Suggest/RenderQuizResults";

function getCurFiles(items, time) {

}


function SmartPlayer(props) {
    let [activeTime, setActiveTime] = useState(0)
    let [files, setFiles] = useState({})
    let [lastFiles, setLastFiles] = useState({})
    let [info, setInfo] = useState({})
    let {user, hash, autoPlay} = props;
    let step = 2;
    let toFix = 1 / step;

    useEffect(() => {
        global.http.get("/quiz-type-history", {filter: {hash, user}}).then(r => {
            let items = ((r?.items || {})[0] || {}).items || []
            let files = {}
            let curFiles = {};
            let prevTime = 0;


            _.each(items.reverse(), (item, ind) => {
                let time = Math.round(step * item.time) / step;
                let {activeFileName, code} = item;
                curFiles[activeFileName] = {code};

                let copiedObj = {...curFiles}
                for (let i = prevTime; i <= time; i = i + toFix) {
                    files[time + i * step] = copiedObj
                }
            })
            setFiles(files)
            setLastFiles(curFiles)
            setInfo(r.items[0])
        })
    }, [hash])

    useEffect(() => {
        props.autoPlay && onClick()
    }, [props.autoPlay, hash])

    function onClick () {
        myPlayer({
            onChange(time, v) {
                console.log("qqqqq on CHange ", time);
                setActiveTime(time)
            },
            path: `/audio/${user}/${hash}.wav`
        })
    }



    let isDebugLogs = isDebugLogsFn();
    let _files = files[Math.round(activeTime * step) / step] || lastFiles || {}

    return <div>
        {isDebugLogs && <div className={'debugLogs'}>
            Smart Player
            <div>
                AcytiveTime: {activeTime}
            </div>
            <div>
                User: {user}
            </div>
            Hash: {hash}
        </div>}
        <div onClick={() => {
            console.log("qqqqq con click playerrrrrrrrrrrr", user, hash);
            onClick()
        }}>
            {!props?.woPlayIcon && <a
                style={{marginRight: '5px'}}>
                <span className="fa fa-play-circle"></span></a>}

            {(Object.keys(_files || {}).map((ind) => {
                let it = _files[ind]
                return <div key={ind}>
                    <strong>{ind}</strong>
                    <div></div>
                    <pre>{it.code}</pre>
                </div>
            }))}

            {props.children}
        </div>

    </div>
}

export default SmartPlayer
