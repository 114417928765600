global.is_local = /localhost/.test(window.location.host) ? 1 : 0;
let is_local = global.is_local;


let local = 'http://localhost:6057';
let multerDomain = 'http://localhost:1111';
// local = 'http://212.8.247.141:6057'
let isAqa = /aqa-admin/gi.test(window.location.hostname)
let isDemo = window.location.href.indexOf('admin-demo.') > -1;
let isJavacode = window.location.href.indexOf('razvitie.javacode') > -1;

// VIDEO_UPLOAD_DOMAIN: 'http://uploads/itconsult-web.ru',
//     VIDEO_DOMAIN: 'http://uploads/itconsult-web.ru',
let VIDEO_DOMAIN = !!global.is_local ? multerDomain : 'https://uploader.itconsult-web.ru';
let STATIC_DOMAIN = !!global.is_local ? multerDomain : 'https://static.itconsult-web.ru';



let servers = {
    local: local,
    aqa: 'https://aqa-api.javacode.ru',
    demo: 'https://demo-api.itk.academy',
    academy:  'https://api-razvitie.itk.academy',
    def:  'https://api-razvitie.itrum.ru'
}

let Demo =  {
    login: <>
        <img src={'/logos/academy/logo_hor.png'} height={25} style={{opacity: .8}}/>
        <div style={{marginTop: '15px'}}></div>
        <div style={{opacity: .7, fontSize: '12px'}}>Панель администратора</div>
    </>,
    main: <img src={'/logos/academy/logo_vert.png'} height={100} />,
}


let logoImgs = {
    def: Demo,
    aqa: Demo,
    demo: Demo,
    academy: Demo,
}

// servers.local = servers.demo;
let serverKey = global.is_local ? 'local' : isAqa ? 'aqa' : isDemo ? 'demo' : 'def'

if (global?.is_local) {
    serverKey = 'academy'
}

window.env = {
    domain: servers[serverKey] || servers.def,
    isDemo,
    serverKey,
    logoImg: logoImgs[serverKey] || logoImgs.def,

    // domain: global.is_local ? local : isAqa ? 'https://aqa-api.javacode.ru' :  'https://api-razvitie.itrum.ru',
    VIDEO_DOMAIN_PLAYER: STATIC_DOMAIN,
    VIDEO_DOMAIN: VIDEO_DOMAIN,
    video_domain: VIDEO_DOMAIN,
    title: 'Панель администратора',
    login_title: 'Панель администратора',
    wo_token: false,
    redirect_after_login: '/admin/users',
    isAdmin: true,
    is_local: global.is_local
}

document.title = window.env.title;
