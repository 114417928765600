import React, {useState} from 'react';
import CircularProgress2 from "./CircularProgress2";
import Button from "../../../libs/Button";

function QuestionsList(props) {
  console.log('*........ ## ROOT RENDER', props);

  let {questions, onClick} = props;

  // let v = useActionData();
  return <div>
    <div className="pr">Фильтр</div>
    <div>
      Вопросы
    </div>
    <hr/>
    <div className={'fbList animChild'}>
      {(questions || []).map((v, ind) => {
        return (<div key={ind} className={'w100 rel'}
                     onClick={() => onClick && onClick(v)}
                     style={{width: '100%'}}>
          <div className="ib w100 ellipse pointer">
            <div className="ib coursesProgress "
                 style={{width: '60px', marginRight: '7px'}}>
              <div className="ib" style={{width: '50%'}}>
                <CircularProgress2
                    zoom={.7}
                    title={"Практ"} value={v.train} size={20}></CircularProgress2>

              </div>
              <div className="ib" style={{width: '50%'}}>
                <CircularProgress2
                    zoom={.7}
                    title={"Экзамен"} value={v.exam} size={20}></CircularProgress2>
              </div>

            </div>
            {v.title}
            <div className="icons">
              <Button size={'xs'} color={4}>
                <div className="fa fa-book"></div>
              </Button>
              <Button size={'xs'} color={4}>
                <div className="fa fa-play"></div>
              </Button>
            </div>
          </div>

        </div>)
      })}
    </div>
  </div>
}

export default QuestionsList
