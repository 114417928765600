import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import './PreviewCourseModule.css'
import QuestionDetails from "./QuestionDetails";
import MdPreview from "./MdPreview";
import MDEditor from "@uiw/react-md-editor";
import Button from "../../libs/Button";
import QuizTraining from "./QuizTraining";
import RunQuiz from "./RunQuiz";
import MyModal from "../../libs/MyModal";
import {Link, useHistory} from "react-router-dom";
import {generateSuggestion} from "./SuggestionItem";
import CustomStorage from "./CustomStorage";

function CourseQuiz(props) {
    let {onAction, isLastModule, title, onSuccess, questionId, moduleId} = props;

    let [loading, setLoading] = useState(false);
    let [open, setOpen] = useState(false);
    let [opts, setOpts] = useState({});
    let [quizResults, setQuizResults] = useState(false);
    let [quizes, setQuizes] = useState([]);
    let [quizPerc, setQuizPerc] = useState(0);

    useEffect(() => {
        loadQuiz()
    }, [questionId])

    useEffect(() => {
        if (!quizResults) {
            return;
        }
        saveResults(quizPerc)
    }, [quizResults])


    function getQuizPerc(quizHistory) {
        let corTotal = 0;
        let total = 0;
        _.each(quizHistory.history, (item, ind) => {
            total++;
            if (item && item.isCorrect) {
                corTotal++
            }

        })
        return {quizPerc: Math.round(100 * (corTotal / (quizes.length || 1))), total};

    }

    function saveResults(quizPerc, cb) {
        let total = (quizes || []).length;
        onAction('complete_quiz', quizPerc)


        if (questionId) {
            let opts = {
                quizPerc,
                question: questionId || '',
                total,
                courseUserId: props.courseUserId,
                status: getIsQuizOk(quizPerc) ? 'ok' : 'error',
            }

            global.http.post('/save-course-module-question-results', opts)
                .then(r => {
                    setOpts(opts)
                    cb && cb();
                })
        } else {
            let opts = {
                quizPerc,
                total,
                courseModule: moduleId,
                courseUserId: props.courseUserId,
                status: getIsQuizOk(quizPerc) ? 'ok' : 'error',
            };
            global.http.post('/save-course-module-results', opts)
                .then(r => {
                    setOpts(opts)
                    cb && cb();
                })
        }
    }

    function loadQuiz() {
        setLoading(true);
        setQuizResults(false);
        let opts = {question: questionId || 0, _id: moduleId};
        global.http.get('/load-quizes-by-module', opts).then((r) => {
            let items = [...r.quizes];
            setLoading(false)
            setQuizes(items)
        })
    }

    function simpleGenerateQuiz(scb, ecb) {
        setLoading(false)
        if (!quizes.length) {
            setQuizPerc(100);
            setQuizResults(true);
            onClose();
        }
    }

    function reGenerateQuiz(scb, ecb) {
        console.log("qqqqq regenerate QUIZ", );
        setLoading(true)
        setOpen(true)
        setQuizResults(false);
        setOpts({});

        let opts = {question: questionId || 0, _id: moduleId};
        global.http.get('/load-quizes-by-module', opts).then((r) => {
            console.log("qqqqq rrrr", r);
            let items = [...r.quizes];
            setQuizes(items)
            scb && scb()
            setLoading(false)
            if (!items.length) {
                setQuizPerc(100);
                setQuizResults(true);
            }
        })
    }

    function getIsQuizOk(quizPerc) {
        return quizPerc >= QUIZ_NORMAL
    }

    function onClose() {
        hideModal()
        opts && opts.status === 'ok' && onSuccess && onSuccess(opts)
    }

    function hideModal() {
        setOpen(false)
    }


    let QUIZ_NORMAL = 85;
    let isQuizOk = getIsQuizOk(quizPerc);
    console.log("qqqqq quizesquizesquizes", {quizes, isLastModule, loading});
    return <div>
        {!!loading && <button className={'btn btn-sm btn-primary'} style={{opacity: 0}}  disabled={true}>Проверяем результаты ...</button>}
        {!loading && <>
            {!!quizes.length && <Button className={'btn btn-sm btn-primary'} onClick={(scb) => {
                reGenerateQuiz(scb)
            }}>{title || 'Проверить знания'}</Button>}
            {!isLastModule && !quizes.length && <Button className={'btn btn-sm btn-primary'} onClick={(scb) => {
                saveResults(100, () => {
                })
                onSuccess && onSuccess({status: 'ok'}, () => {
                    scb && scb()
                })

            }}>{'Следующий модуль'}</Button>}
                {isLastModule && !quizes.length && <Link to='/courses' className={'btn btn-sm btn-primary'} onClick={(scb) => {
                saveResults(100, () => {
                })
                onSuccess && onSuccess({status: 'ok'}, () => {
                    scb && scb()
                })

            }}>{'Завершить курс и вернуться в главное меню'}</Link>}
        </>}

        <MyModal
            woClose={true}
            isOpen={open}
            onClose={onClose}
        >
            {!quizResults && <>
                <div className="pull-right">
                    <Button
                        onClick={(scb) => {
                            scb && scb()
                            setQuizResults(true)

                        }}
                        color={0}>Завершить квиз</Button>
                </div>
                {!loading && <><RunQuiz
                    items={quizes}
                    onAnswer={(r) => {
                        onAction('quiz_answer', r)
                    }}
                    onChange={(v) => {
                        let {quizPerc, total} = getQuizPerc(v)
                        console.log("qqqqq onChange RUN QUIZ", v, quizPerc, v);
                        setQuizPerc(quizPerc);

                        let quizId = v.quizId;

                        if ((((v.history || {})[quizId] || {}).isCorrect) && total && total === quizes.length) {
                            setQuizResults(true)
                        }

                    }
                    }></RunQuiz>

                </>}
                {loading && <div>Загружаем квиз</div>}
            </>}

            {quizResults && <>
                <div>
                    <div className={'quiz-result-title'}>
                        {isQuizOk ? 'Поздравляем вы можете переходить к след блоку!' : 'Нужно еще немного поработать, тест не пройден!'}
                    </div>
                    <div></div>
                    <hr/>
                    <div></div>
                    {!quizes.length && <>
                        <strong style={{marginBottom: '10px', display: 'inline-block'}}>Уверен, ты действительно изучил
                            эту тему хорошо</strong>
                        <div>
                            Поэтому можешь переходить к следующему модулю
                        </div>
                    </>}
                    {!!quizes.length && <>
                        <strong style={{marginBottom: '10px', display: 'inline-block'}}>Результаты Квиза</strong>
                        <div></div>
                        Набранный балл: {quizPerc}%
                        <div></div>
                        Необходимый минимум: {QUIZ_NORMAL}%+
                    </>}
                    <hr/>
                    {!isQuizOk && <>
                        <Button color={0} onClick={(scb) => {
                            setOpen(false)
                            scb && scb()
                        }}>Изучать материалы</Button>
                        <Button color={4} onClick={(scb) => {
                            reGenerateQuiz(scb)
                        }}>Перезапустить квиз</Button>
                    </>

                    }

                    {isQuizOk && !isLastModule && <Button color={0} onClick={(scb) => {
                        onClose()
                        scb && scb()
                    }
                    }>Отлично, перейти к след модулю</Button>}
                    {isQuizOk && isLastModule && <Button color={0} onClick={(scb) => {
                        onClose()
                        scb && scb()
                    }
                    }>Отлично, ты прошел курс! Молодец!</Button>}


                </div>
            </>}

        </MyModal>
    </div>
}


export default CourseQuiz
