import React, {useEffect, useState} from 'react';
import CoursesList from "./CoursesList";

function Layout2(props) {
  let [fb, setFb] = useState({})
  let [histId, setHistId] = useState(null)

  useEffect(() => {

  }, [histId])
  console.log('*........ ## ROOT RENDER', props);


  // let v = useActionData();
  return <div>
        <CoursesList isAdmin={true}
                     user={props.user || 1002}
                     onSelectFb={(fb) => setFb(fb)}></CoursesList>
  </div>
}

export default Layout2
