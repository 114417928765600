import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Select from 'libs/Select'
import Smart from 'libs/Smart'
import Textarea from 'libs/Textarea'
import Input from 'libs/Input'
import MDEditorComp from '../Suggest/MDEditorComp'
import TagSelector from '../Suggest/TagSelector'
import Questions from '../Suggest/QuestionsSelection'
import {ReactSortable} from "react-sortablejs";
import Storage from "../Suggest/CustomStorage";
import UserNewSel from "../Suggest/UserNewSel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Box} from "@mui/material";
import MyModal from "../../libs/MyModal";

let isAdmin = Storage.isAdmin()
let pubName = Storage.pubName;

function InsertFromExel({localItem}) {
    function text(it) {
        return it ? it.innerHTML : it;
    }

    return <div className="row contenteditable-input o4">
        <div className="col-sm-6">
            <small onClick={() => {
                global.document.querySelector('#table1').innerHTML = ``
            }}>Вставьте блок с вопросами</small>
            <div contentEditable={true} id="table1" onInput={(e) => {
                let el = e.currentTarget.querySelector('table')
                if (!el) {
                    return;
                }
                let categories = Storage.getCategoriesPlain();
                let rows = el.querySelectorAll('tbody tr')
                let questions = [];

                rows.forEach((it, ind) => {
                    let [question, theme, additionalQuestions, time, level, answer] = it.querySelectorAll('td');
                    let name = text(question);
                    let themePlain = text(theme);

                    ((name || '').trim() !== 'Вопрос') && questions.push({
                        name,
                        themePlain,
                        hashTags: _.filter(categories, it => {
                            // console.log("qqqqq category", it);
                            return it.title === themePlain
                        }).map(it => it._id),
                        additionalQuestions: text(additionalQuestions),
                        answerLevel: text(level),
                        timePlain: text(time),
                        detailedAnswer: text(level),
                    })


                })
                localItem.questions = questions;
                global.onChangeCount(new Date().getTime());
                // console.log("qqqqq vvv!!!!", questions);
            }
            }>
            </div>
        </div>
        <div className="col-sm-6">
            <small onClick={() => {
                global.document.querySelector('#table2').innerHTML = ''
            }}>Блок Шапки</small>
            <div contentEditable={true} id="table2" onInput={(e) => {
                console.log("qqqqq vvвввv", e.currentTarget.innerHTML);
                let el = e.currentTarget.querySelector('table')
                if (!el) {
                    return;
                }
                let row = el.querySelectorAll('tbody tr')[1].querySelectorAll('td');

                // let localItem = {}

                localItem.name = text(row[0])
                localItem.client = text(row[1])
                localItem.position = text(row[2])
                localItem.partner = text(row[3])
                localItem.video = row[4].querySelector('[href]').getAttribute('href')
                localItem.stack = text(row[5])

                global.onChangeCount(new Date().getTime());
                // global.UpdateRootFn();
                console.log("qqqqq localItem", localItem);
            }
            }>
            </div>
        </div>
        <hr/>
    </div>
}


function Interview({props}) {
    const [count, setCount] = useState(0);
    console.log('*.....Interview Render', props);
    let {item, onChange} = props

    global.onChangeCount = setCount;
    let user = global.user;
    let isSale = user.is_role('sale');
    let isAdmin = user.is_role('admin');
    console.log("qqqqq isSale is Admin", isSale, isAdmin);
    return <div>

        <div className="row interview-item">
            <div className="col-sm-12">
                <Smart
                    items={[
                        {
                            size: 12,
                            defSize: 3,
                            childs: [

                                {
                                    size: 6, defSize: 6, childs: [
                                        {name: 'Название', key: 'name', type: 'input'},
                                        {name: 'Дата', key: 'date', type: 'date', size: 3,},

                                        {name: 'Тип', key: 'type', type: 'select', items: ['', 'HR', 'tech'], size: 3},
                                        {name: 'Видео ссылка', size: 12, key: 'video', type: 'input'},


                                    ]
                                },
                                {
                                    name: 'Собственная оценка интервью',
                                    key: 'growPoints',
                                    minRows: 4,
                                    type: 'textarea',
                                    size: 6
                                },


                                {
                                    isVisible: () => isSale || isAdmin,
                                    size: 12,
                                    defSize: 2,
                                    childs: [
                                        {type: 'HR', size: 12,},
                                        {
                                            size: 3,
                                            childs: [{
                                                size: 12,
                                                Component: () => <small>Кто проходил</small>
                                            }, {key: 'user', size: 12, Component: UserSaleSel}]
                                        },

                                        {
                                          size: 3,
                                          childs: [{
                                              size: 12,
                                              Component: () => <small>Кто может посмотреть</small>
                                          }, {key: 'user2', size: 12, Component: UserSaleSel}]
                                      },
                                      {
                                            size: 6, Component: InsertFromExel
                                        },
                                        {type: 'HR', size: 12,},

                                        {
                                            defSize: 4,
                                            size: 6, childs: [

                                                {
                                                    name: 'Статус',
                                                    key: 'status',
                                                    type: 'select',
                                                    items: ['waiting', 'next_stage', 'offer', 'declined']
                                                },
                                                // {name: 'Рейт', key: 'rate', type: 'number'},
                                                {name: 'Позиция', key: 'position', type: 'input'},
                                                {
                                                    name: 'Рейт', key: 'rate', type: 'select',
                                                    items: ['0', '1', '2', '3', '4', '5']
                                                },
                                                {name: 'Дата пред этапа', key: 'datePrev', size: 4, type: 'date'},
                                                {type: 'HR', size: 12},
                                                {name: 'Партнер', key: 'partner', Component: PartnerSelector},
                                                {name: 'Компания', key: 'client', Component: PartnerSelector},
                                                // {name: 'Стек', key: 'stack', type: 'input'},

                                                {
                                                    size: 4,
                                                    childs: [{
                                                        size: 12,
                                                        Component: () => <small>{global.nameFn('Sale manager')}</small>
                                                    }, {key: 'sale', size: 12, Component: UserSaleSel}]
                                                },


                                            ]


                                        },
                                        {
                                            size: 6, childs: [
                                                {
                                                    name: 'Фидбек Клиента',
                                                    key: 'feedback',
                                                    type: 'textarea',
                                                    minRows: 4,
                                                    size: 12
                                                },
                                            ]
                                        }


                                    ]
                                },
                            ]
                        }


                    ]}
                    defSize={6}
                    obj={item}
                    onChange={(v, _id) => {
                        console.log("qqqqqon Change", v, _id);
                        setCount(new Date().getTime())
                    }
                    }></Smart>
                <hr/>
            </div>
            <div className="col-sm-12">
                <Sort {...props} onChange={(item) => {
                    console.log("qqqqq item ON CHAGNSE", item);
                    item ? onChange(item) : onChange()
                }
                } item={item}></Sort>

            </div>
        </div>
    </div>
}

function PartnerSelector({item, field}) {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const key = field.key;
    const value = item[key];

    useEffect(() => {
        global.http.get('/my-' + key, {per_page: 1000}).then((r) => {
            setItems(r.items);
        });
    }, [key]); // Added key to the dependencies

    function onAdd () {
        setLoading(true)
        global.http.post('/my-' + key, {name}).then(r => {
            setItems([...items, r])
            item[key] = r._id;
            global.onGlobalChange({...item})
            setOpen(false)
            setLoading(false)
        })

    }


    const selectedValue = {...items.filter((it) => it._id == value)[0]};

    return (
        <div>
            <small>{global?.nameFn(key)}</small>
            <Autocomplete
                openOnFocus={true}
                sx={{width: '100%'}}
                options={items || []}
                value={selectedValue} // Directly use the filtered value
                onChange={(e, el) => {
                    item[key] = el ? el._id : el;
                    global.onGlobalChange({...item});
                }}
                getOptionLabel={(option) => {
                    return option.name || 'Select ' + key
                }}
                renderOption={(props, option = {}) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <span>{option.name}</span>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'My ' + key, // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <small onClick={() => {
                setOpen(true)
            }}><a>+ {key}</a></small>
            <MyModal isOpen={open} onClose={() => {
                setOpen(false)
            }}>
                <Input
                    autofocus={true}
                    value={name} onChange={(v) => {
                    setName(v)
                }} onEnter={onAdd}></Input>
                <button disabled={loading} className="btn btn-xs btn-success" onClick={onAdd}>Добавить</button>

            </MyModal>
        </div>
    );
}

function UserSaleSel({item, field}) {
    let {key} = field;
    console.log("qqqqq field4444", field, item);

    return <UserNewSel userId={item[key]} onChange={(v, _id) => {
        console.log("qqqqq iddddd", item);
        item[key] = _id
        global.onGlobalChange({...item})
        // onChange(item)
    }
    }></UserNewSel>
}


class Sort extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        let {
            field = {},
            activeInd,
            autoSave,
            localItem,
            onChange,
        } = this.props;

        // let onChange = () => {
        //     localItem.questions = value;
        //     // global.onChangeCount(new Date().getTime());
        //     this.setState({cd: new Date()})
        // }

        let value = localItem.questions || [];
        this.arr = value;

        function onAdd(cmd) {
            global.http.post('/interview-question', {interview: localItem._id})
                .then(r => {
                    value[cmd](r);
                    onChange();
                })
        }


        let {ind = 0} = this.state;
        let item = value[ind];

        return <div className={'row'}>
            <div className="col-sm-4">
                <div className="col-sm-12">
                    <ReactSortable
                        handle={".drag-handle"}
                        animation={200}
                        list={value || []}
                        onEnd={(evt) => {
                            value = this.arr;
                            onChange()
                        }}

                        setList={(_value) => {
                            value = _value;
                            this.arr = _value;
                            // onChange()
                        }}>

                        {value.map((item, _ind) => {
                                let tags = (item.hashTags || []).length;
                                let themeQuestionId = item.themeQuestionId;
                                let isError = !tags || !themeQuestionId;
                                item.answerLevel = item.answerLevel || '5'
                                return <div key={'value_wrap' + '_' + _ind}
                                            onClick={() => this.setState({item, ind: _ind})}
                                            className={'row sort-item sort-questions '
                                                + (_ind === ind ? 'active' : '')
                                                + (isError ? ' error-wrap' : '')}>
                                    <div className={'ib col-sm-12 draggable-cont'}>
                                        <div className={'arr-item ' + field.defClass} onClick={() => {
                                        }}>
                                            <div className={'drag-handle'} onClick={(e) => {
                                                e.stopPropagation();
                                                return true;
                                            }}>
                                                <i className={'fa fa-bars'}></i>
                                            </div>
                                            <div>
                                                {pubName(item.name || '-')}
                                            </div>
                                            <i className="fa fa-close" onClick={(e) => {
                                                let remItem = value[_ind];
                                                console.log("qqqqq remImte", remItem, _ind, value,);
                                                value = _.filter(value, (it, ind2) => {
                                                    return ind2 !== _ind;
                                                });
                                                onChange();
                                                this.setState({ind: 0})
                                                e.stopPropagation();
                                                e.preventDefault();
                                                return true;
                                            }}></i>
                                        </div>
                                    </div>
                                </div>
                            }
                        )}

                    </ReactSortable>
                    <button className={'btn btn-xs btn-default btn-push-add'}
                            style={{marginTop: '10px'}}

                            onClick={(e) => {
                                onAdd('push')
                            }}> + Новый вопрос
                    </button>
                </div>
            </div>
            {item && <div className="col-sm-8">
                <div className="row">

                    <div className="col-sm-3">
                        <Input placeholder="Время" value={item.timePlain} onChange={(v) => {
                            item.timePlain = v;
                            onChange(item)
                        }}></Input>
                    </div>

                    <div className="col-sm-3 padd0">
                        <small>Ответ этот вопрос на</small>
                        <Select
                            defClass={''}
                            items={[{name: 'Никак', value: '1'}, {name: 'Плохо', value: '2'}, {
                                name: 'Не очень',
                                value: '3'
                            }, {name: 'Норм', value: '4'}, {name: 'Отлично', value: '5'}]}
                            value={item.answerLevel}
                            onChange={v => {
                                item.answerLevel = v;
                                onChange(item);
                            }}></Select>
                    </div>
                    <div className="sol-sm-6">
                        <div className="pull-right" style={{marginTop: '0', textAlign: 'right', marginRight: '15px'}}>
                            <small>&nbsp;</small>
                            <Smart items={
                                [
                                    {
                                        size: 12,
                                        name: 'Type',
                                        key: 'type',
                                        type: 'group',
                                        list: ['question', 'task', 'js-task']
                                    }
                                ]
                            }
                                   obj={item}
                                   onChange={(vv) => {
                                       item.type = vv.type;
                                       onChange(item)
                                   }
                                   }
                            ></Smart>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {item.answerLevel !== '5' && <Textarea value={item.detailedAnswerIfIsNotGreat} label=""
                                                               placeholder="Работа над ошибками"
                                                               onChange={(e) => {
                                                                   item.detailedAnswerIfIsNotGreat = e;
                                                                   onChange(item)
                                                               }
                                                               }/>}
                        <hr/>
                    </div>
                    <div className="col-sm-12">

                        <div style={{width: '100%'}}>
                            <small className="pull-left">Редактировать вопрос
                                {/*{!isAdmin && <>*/}
                                {/*{item._id && <a href={'/interview-question/' + item._id} className="fa fa-pencil"></a>}*/}
                                {/*/!*{item.date && <a href={'/interview-question/' + item.date}>{item.date}</a>}*!/*/}
                                {/*</>}*/}
                            </small>
                        </div>


                        <MDEditorComp
                            preview={'edit'}
                            value={item.name}
                            onChange={(v) => {
                                item.name = v;
                                onChange(item)
                            }}></MDEditorComp>

                    </div>
                    <div className="col-sm-12">
                        <Textarea minRows="1" placeholder="Уточняющие вопросы"
                                  value={item.additionalQuestions}
                                  onChange={(e) => {
                                      item.additionalQuestions = e;
                                      onChange(item)
                                  }
                                  }/>
                    </div>


                    <div className="col-sm-12">
                        <TagSelector
                            props={{
                                localItem: item,
                                onChange: (value, key) => {
                                    item[key] = value;
                                    onChange(item)
                                }
                            }}

                        ></TagSelector>
                        {(!item.hashTags || !item.hashTags.length) && <small>** {item.themePlain}</small>}
                    </div>

                    <div className="col-sm-12">
                        <hr/>

                        <Questions
                            themeQuestionId={item.themeQuestionId}
                            forceLoad={this.state.forceLoad}
                            setForceLoad={() => {
                                this.setState({forceLoad: new Date().getTime()})
                            }
                            }
                            hashTags={item.hashTags}
                            onChange={(themeQuestionId, question) => {
                                console.log("qqqqq question444", question, onChange);
                                console.log("qqqqq question444", question, autoSave, this.props);

                                item.themeQuestionId = themeQuestionId;
                                item.hashTags = (item.hashTags || []).length ? item.hashTags : (question || {}).hashTags;
                                onChange(item)
                                if (!themeQuestionId) {
                                    Storage.createThemeQuestion({
                                        hashTags: item.hashTags,
                                        name: item.name,
                                        isForModeratorApprove: !isAdmin
                                    }, (question) => {
                                        item.themeQuestionId = question._id;
                                        onChange(item)
                                        this.setState({forceLoad: new Date().getTime()})
                                    })
                                } else {

                                }

                            }
                            }></Questions>
                        {/* <hr/> */}

                    </div>
                </div>


            </div>}
            {!item && <div className="col-sm-8 tc">
                Выберите вопрос
            </div>}

        </div>

        // </div >
    }
}

export {PartnerSelector, UserSaleSel};
export default Interview;
