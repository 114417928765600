import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import './st.css'
import {
    Link
} from "react-router-dom";
import {toOdb} from "../Stats";
import {generatePeriods, pubDate} from "./GeneratePrognozFactPeriods";
import CalendarView from "../Stats/CalendarView";
import MonthView from "../Stats/MonthView";
import {TimePicker} from "../Stats/UserStats";
import Perc from "../Suggest/Perc";
import Input from 'libs/Input';
import CircleIndicator from "../Stats/CircleIndicator";


function getIndicators(vit, keyItem) {
    let perc = keyItem.perc || 15;

    vit.plan = Number(vit.plan)
    vit.prognoz = Number(vit.prognoz)

    function getCl(plan, fact) {
        let k = (100 - Number(perc)) / 100
        console.log("qqqqq kdddd", k, plan, fact, Number(plan) * k);
        if (Number(plan) * k > Number(fact)) {
            return 'pf-red'
        }
        return 'pf-orange'
    }


    if (!vit.plan) {
        return [{key: 'pf-noplan'}]
    }
    if (!vit.fact && !vit.prognoz) {
        return [{key: 'pf-empty'}]

    }

    if (vit.fact) {
        console.log("qqqqq vittttt77", vit);
        if (vit.plan > vit.fact) {
            return [{key: getCl(vit.plan, vit.fact)}]
        }
    } else {
        console.log("qqqqq vittttt55", vit);

        if (vit.plan > vit.prognoz) {
            return [{key: getCl(vit.plan, vit.prognoz)}]
        }
    }

    return [{key: 'pf-green'}];
}


function Layout2(props) {
    props = props.props || props;
    let today = new Date();
    let _days = 1000 * 24 * 3600;
    let _cd = new Date().getTime();

    let [data, setData] = useState({});
    let [loading, setLoading] = useState(true);
    let [dailyCd, setDailyCd] = useState({start: _cd - 30 * _days, end: _cd + 14 * _days});
    let [weeklyCd, setWeeklyCd] = useState({start: _cd - 45 * _days, end: _cd + 14 * _days});
    let [monthlyCd, setMonthlyCd] = useState({start: _cd - 180 * _days, end: _cd + 14 * _days});
    let [cd, setCd] = useState({
        start: toOdb(today.setMonth(today.getMonth() - 3)),
        end: toOdb(today.setMonth(today.getMonth() + 6))
    });

    let {start, end} = cd;
    let {item} = props;
    let keysArr = item.items.map(it => it.keyId)

    useEffect(() => {
        let {start, end} = cd;
        setLoading(true)
        global.http.get('/full-stats', {start, end, keys: keysArr}).then(data => {
            setLoading(false)
            let keys = data.keys.reduce((acc, it) => ({
                ...acc, [it._id]: {
                    pfSubKey: it.pfSubKey || 'main',
                    pfKey: it.pfKey,
                    name: it.name,
                    prognozDelta: it.prognozDelta,
                    factDelta: it.factDelta,
                }
            }), {});
            let values = data.values.reduce((acc, it) => {
                let {pfKeyId, pfSubKey, odbKey, odb, plan, fact, prognoz, prognoz1, prognozHist} = it;
                let keyItem = keys[pfKeyId]
                keyItem ??= {};
                if (!pfKeyId) {
                    console.log("qqqqq accccccc EMPTYYYYYYYYYYYYYYYYYY", it);

                    return acc;
                }
                odbKey = odbKey || odb;
                pfSubKey = pfSubKey || keyItem.pfSubKey;
                acc[pfKeyId] ??= {};
                acc[pfKeyId][pfSubKey] ??= {}
                acc[pfKeyId][pfSubKey][odbKey] = {plan, fact, prognoz, prognoz1, prognozHist}
                console.log("qqqqq accccccc", acc, it, pfKeyId, pfSubKey);
                return acc;
            }, {})
            console.log("qqqqq acccccc", keys, values);
            setData({keys, values})
        })
    }, [keysArr.join('_')])

    if (loading) {
        return <div>Loading ...</div>
    }
    let periods = {
        quarters: [],
        monthly: generatePeriods({startCd: start, endCd: end, type: 'monthly'}),
        weekly: generatePeriods({startCd: start, endCd: end, type: 'weekly'}),
        daily: generatePeriods({startCd: start, endCd: end, type: 'daily'}),
    }

    console.log("qqqqq ittmtmtmtmmadfasdfasdf", data);

    let {values, keys} = data;
    let filteredItems = item.items || [];

    function onSetValue (value, {key = 'plan', odb, pfKey, pfSubKey, pfKeyId, type}) {
      values ??= {};
      values[pfKeyId] ??= {};
      values[pfKeyId][pfSubKey] ??= {};
      values[pfKeyId][pfSubKey][odb] ??= {};
      values[pfKeyId][pfSubKey][odb][key] = value;

      setData({...data, values})

      // global.http.post('/plan-one-value', {
      //     odb, pfKey, pfSubKey, pfKeyId, value, type
      // }).then(r => {
      // })
  }

    return <div>
        {cd.start}
        <div></div>
        {cd.end}
        <div></div>
        <hr/>
        <TimePicker value={monthlyCd} onChange={setMonthlyCd}></TimePicker>
        <table border="1" className={'table table-bordered'}>
         <td></td>
                            {(item.items || []).map((it, ind) => {
                                let _key = (keys || {})[it.keyId] || {};

                                return (<td key={ind} >
                                    {_key.name}
                                </td>)
                            })}
        <MonthView
            start={monthlyCd.start} end={monthlyCd.end} Component={({date, name, ind, odb}) => {
            let isSt = true;
            return <tr key={ind} onClick={() => {
                // setSelected({odb: date, type: 'interview', data: st})
            }
            }>

                    <td>{name}</td>
                    {(filteredItems || []).map((it, ind) => {
                        let odbKey = odb;
                        let _values = (values || {})[it.keyId] || {}
                        let _key = (keys || {})[it.keyId] || {};
                        let vit = (_values[_key.pfSubKey || ''] || {})[odbKey] || {};
                        // console.log("ititititiitit", it, vit, _values)
                        return (<td key={ind} >
                            {vit.plan} {vit.fact} {vit.prognoz}
                            <Input value={vit.plan} onChange={(v) => {
                              console.log("change", v)
                              onSetValue(v, {odb: odbKey, pfKeyId: it.keyId, pfKey: _key.pfSubKey, type: 'monthly' })
                            }}/>
                             <Input value={vit.fact} onChange={(v) => {
                              console.log("change", v)
                            }}/>
                            <Input value={vit.prognoz} onChange={(v) => {
                              console.log("change", v)
                            }}/>
                        </td>)
                    })}
            </tr>
        }
        }></MonthView>
        </table>

        <TimePicker value={dailyCd} onChange={setDailyCd}></TimePicker>
        <CalendarView
            start={dailyCd.start} end={dailyCd.end} Component={({date, datePub}) => {
            let isSt = true;
            return <div className={isSt ? 'stOk' : 'stEmpty'} onClick={() => {
                // setSelected({odb: date, type: 'interview', data: st})
            }
            }>
                <small>{datePub}</small>
                <div></div>
            </div>
        }
        }></CalendarView>

        {(item.items || []).map((it, ind) => {
            let key = 'monthly'
            let _values = (values || {})[it.keyId] || {}
            let _key = (keys || {})[it.keyId] || {};
            return (<div key={ind}>
                {/*<Link to={'/p-f-keys/' + it.}*/}
                <Link to={'/p-f-keys/' + it.keyId}>#{ind} {it.keyId}</Link>
                <div></div>
                {(periods[key] || []).map((period, ind) => {
                    let odbKey = period.odbKey || period.odb;
                    let vit = (_values[_key.pfSubKey || ''] || {})[odbKey] || {};
                    let indicators = getIndicators(vit, _key);

                    return (<div key={ind} className={'ib ' + (indicators.map(it => it.key).join(' '))}
                                 style={{padding: '5px'}}>
                        {pubDate(period, key)}
                        {vit && <div>
                            {vit.plan}
                            <div></div>
                            {vit.fact && <small>
                                {vit.fact} **
                            </small>}
                            {!vit.fact && <small>
                                {vit.prognoz}
                            </small>}
                        </div>}
                    </div>)
                })}
            </div>)
        })}
    </div>
}

export default Layout2
