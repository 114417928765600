import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import Storage from './Storage'
import {
    Link, Outlet
} from "react-router-dom";
import QuestionsListWithSelect from "./Suggest/QuestionsListWithSelect";
import TagSelector from "./Suggest/TagSelector";
import MyModal from "libs/MyModal";
import Smart from "../libs/Smart";
import {editQuestion} from './Suggest/SuggestionItem'
import QuestionDetails from "./Suggest/QuestionDetails";
import DuplicatePreview from "./Suggest/DuplicatePreview";

function Duplicates(props) {
    let [isModal, setIsModal] = useState(false)
    let [questions, setQuestions] = useState([])
    let [migrateQuestion, setMigrateQuestion] = useState({})
    let [question1, setQuestion1] = useState(props.question1 || {})
    let [question2, setQuestion2] = useState(props.question2 || {})
    let [hashTags1, setHashTags1] = useState([])
    let [hashTags2, setHashTags2] = useState([])


    function getQuestionId() {

        const query = window.location.href.split(/\?|\#/gi)[1]
        const params = Object.fromEntries(new URLSearchParams(query))

      //console.log(params)
        return [params.question1, params.question2]
    }


    function onChange(id, ind) {
        let item = questions.filter(it => it._id == id)[0];
        if (ind == 1) {
            question1 = item;
            setQuestion1(item)
        } else {
            question2 = item;
            setQuestion2(item)
        }

      //console.log('queyest1', question1, question2)
        if (question1 && question2 && question1._id && question2._id) {
            setIsModal(true)
            setMigrateQuestion(smartCombine(question1, question2))
        }


    }

    function loadAndSet(setFakes, cb) {
        Storage.loadAllQuestions((items) => {
            let [q1id, q2id] = getQuestionId();
            setQuestions(items)
            let q1 = (items.filter(it => it._id == q1id)[0] || {});
            let q2 = (items.filter(it => it._id == q2id)[0] || {});
            setQuestion1(q1)
            setQuestion2(q2)

          //console.log("qqqqq q1, q2", q1, q2, {q1id, q2id});
            if (setFakes && q1._id && q2._id) {
                let migratedQuestion = smartCombine(q1, q2);
                setMigrateQuestion(migratedQuestion)
                setIsModal(setFakes)

            }


        });
    }


    useEffect(() => loadAndSet(true), [])
  //console.log('*........ ## ROOT question1', question1, questions);

    function smartCombine(obj1, obj2, depth = 0) {
        if (depth > 2) {
            return obj2;
        }
        if (depth === 0) {
            obj1.hashTags = _.uniq([].concat(obj1.hashTags || [], obj2.hashTags || []));
            obj2.hashTags = obj1.hashTags;
        }

        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            return obj1.length >= obj2.length ? obj1 : obj2;
        }

        if (typeof obj1 === 'string' && typeof obj2 === 'string') {
            return obj1.length >= obj2.length ? obj1 : obj2;
        }

        if (typeof obj1 === 'object' && typeof obj2 === 'object') {
            const combined = {};

            for (const key in obj1) {
                combined[key] = smartCombine(obj1[key], obj2[key], depth + 1);
            }

            for (const key in obj2) {
                if (!(key in obj1)) {
                    combined[key] = obj2[key];
                }
            }

            return combined;
        }

        return obj2; // Use obj2 if types don't match or aren't array/string/object
    }

    let isMore = (question1 || {})._id > (question2 || {})._id

    return <div className={"row"}>
        <div className="col-sm-6">
            {isModal && <MyModal
                size={'full'}
                isOpen={isModal}
                onClose={() => {
                    setIsModal(false)
                }
                }
            >
                <div className={'modalPreview'}>
                    <DuplicatePreview
                        question1={!isMore ? question1 : question2}
                        question2={!isMore ? question2 : question1}
                        onClose={() => {
                          //console.log("qqqqq close",);
                            setIsModal(false);
                            loadAndSet(true);
                        }}></DuplicatePreview>
                </div>

            </MyModal>}
            <TagSelector
                props={{
                    localItem: {hashTags: hashTags1},
                    onChange: setHashTags1
                }}

            ></TagSelector>
            <hr/>
            <QuestionsListWithSelect
                selectedQuestionId={question1._id}
                urlPrefix={'/theme-question'}
                onChange={(id) => onChange(id, 1)}
                hashTags={hashTags1}
                questions={questions}></QuestionsListWithSelect>
        </div>
        <div className="col-sm-6">

            <TagSelector
                props={{
                    localItem: {hashTags: hashTags2},
                    onChange: setHashTags2
                }}

            ></TagSelector>
            <hr/>
            <QuestionsListWithSelect
                selectedQuestionId={question2._id}
                urlPrefix={'/theme-question'}
                onChange={(id) => onChange(id, 2)}
                hashTags={hashTags2}
                questions={questions}></QuestionsListWithSelect>
        </div>
    </div>
}

export default Duplicates
