import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {toOdb} from "../Stats";

function generateWeeks(start, end) {
    let startDate = new Date(start);
    let endDate = new Date(end);

    // Если начальная дата не понедельник, установить начальную дату на предыдущий понедельник
    while (startDate.getDay() !== 1) {
        startDate.setDate(startDate.getDate() - 1);
    }

    // Если конечная дата не воскресенье, установить конечную дату на следующее воскресенье
    while (endDate.getDay() !== 0) {
        endDate.setDate(endDate.getDate() + 1);
    }

    let weeks = [];
    let currentWeek = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
        currentWeek.push(toOdb(currentDate));

        if (currentDate.getDay() === 0) {
            weeks.push(currentWeek);
            currentWeek = [];
        }

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return weeks;
}


function CircleIndicator(props) {
    let {start, end, Component} = props;
    let startOdb = toOdb(start)
    let endOdb = toOdb(end)
    let weeks = generateWeeks(start, end)
    return <div style={{maxHeight: '500px', overflowY: 'auto'}}>
        {(weeks || []).map((it, ind) => {
            return (<div key={ind} className={'ib2'}>
                {(it || []).map((it, ind) => {
                    return (<div key={ind}
                                 className={'ib ' + (startOdb > it || endOdb < it ? 'emptyCalendar' : '')} style={{padding: '10px',}}>
                        <Component date={it} datePub={it.substring(5, 12)}></Component>
                    </div>)
                })}

            </div>)
        })}
    </div>
}

export default CircleIndicator
