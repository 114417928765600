import React, {useEffect, useState} from 'react';

function Layout2(props) {
//console.log('*........ ## ROOT RENDER', props);
   useEffect(() => {
       global?.user?.logout()
   })
    return <div className={'tc'} style={{padding: '10%'}}>
        Выход из системы ...
    </div>
}

export default Layout2
