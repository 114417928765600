import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";


function Layout2(props) {
    let [value, setValue] = useState('')
    let {odb, pfKey, pfSubKey, period, pfKeyId, type} = props || {};
    type = type || 'plan'

    useEffect(() => {

        !props.value && !props.isValue && global.http.get('/plan-one-value', {
            odb, pfKey, pfSubKey, pfKeyId
        }).then(r => {
            setValue(r[type])
        })

    }, [odb, pfKey, pfSubKey, pfKeyId])
    useEffect(() => {
        props.isValue && setValue(props.value)
    }, [props.value])

    function onSetValue (value) {
        global.http.post('/plan-one-value', {
            odb, pfKey, pfSubKey, pfKeyId, value, type
        }).then(r => {
        })
        setValue(value)
    }



    console.log("qqqqq ittttttttttttttttttttttt", props);
    return <div>
        {/*Plan Fact value*/}
        {/*<div></div>*/}
        {/*{odb}*/}
        {/*<div>*/}
        {/*    {pfKey} - {pfSubKey}*/}
        {/*</div>*/}
        {/*{period}*/}
        <Input value={value} onChange={(v) => {
            onSetValue(v)
        }
        }/>
    </div>
}

export default Layout2
