
import React, {useState} from 'react';
import _ from 'underscore';
import './CircleIndicator.css';

function CircleIndicator(props) {
    console.log('*........ ## ROOT RENDER', props);


    let {value, title = '', borders = [], width = 7, margin = '1px'} = props || {};
    value = +value || 0;

    width = width + 'px'
    let clName = 'circleGreen';
    let border0 = borders[0]
    let border1 = borders[1]
    if (!value) {
        clName = 'circleEmpty'
    } else if (value >= border0 && value < border1) {
        clName = 'circleOrange'
    } else if (value < border0) {
        clName = 'circleRed'
    }


    clName = props.clName || clName || ''
    return <div
        title={title + ' :: ' + value}
        style={{width, height: width, margin}} className={'circleIndicator ' + clName}>
    </div>
}

export default CircleIndicator
