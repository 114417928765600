import React, {useState} from "react";
// let changeFavorite = Storage.changeFavorite;

function IsFavorite(props) {
    let [count, setCount] = useState(0);
    let {item, onChange, size} = props;
    // global.historyObj = global.historyObj || {};
    //
    // let histObj = (global.historyObj || {})[question._id] || {};
    item = item || {}
    return <div className={'ib'} style={{marginRight: '5px'}} onClick={(e) => {


        // changeFavorite && changeFavorite(question, histObj.isFavorite, () => {
        //     setCount(new Date().getTime())
        // });
        onChange && onChange(!item.isFavorite)
        e.stopPropagation();
        return e.preventDefault();
    }}>
        <div className={"fa fa-star" + (!item.isFavorite ? '-o' : '')} style={{fontSize: (size || 15) + 'px'}}>
        </div>
    </div>
}

export default IsFavorite
