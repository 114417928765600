import React, {lazy, Suspense, useState} from 'react';
import {MonacoDiffEditor} from "react-monaco-editor";

// function LazyEditorDirect2(props) {
//     // return <div>Diff Editor is temporary disabled</div>
//     return <MonacoDiffEditor {...props} />

// }


function LazyEditorDirect(props) {
    // return <>{new Date().getTime()}
    {/* <Editor {...props}></Editor></>; */}
    return <Comp2 {...props}></Comp2>
}
const Comp2 = React.memo(function LazyEditorDirect2(props) {
    console.log("v1, v2 2222", props)
    // 
    return <><MonacoDiffEditor {...props} /></>;
  }, (v1, v2) => {
    console.log('v11, v22', v1, v2, pub(v1), pub(v2), pub(v1) == pub(v2))
    function pub(v1) {
        return (v1.original + '___' + v1.value)
    }
    return pub(v1) == pub(v2)
  });



export default LazyEditorDirect
