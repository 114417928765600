import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import React, {lazy, useEffect, useState, Suspense} from 'react';
import _ from 'underscore';

import env from './admin_env';
import nameFn from './nameFn';
import CustomStorage from "./comps/Suggest/CustomStorage";
import Smart from './libs/Smart'
import Table from './libs/Table'
import Skeleton from './libs/Skeleton'
import TagsComp from './comps/TagsComp'
import Input from './libs/Input'
import Login from './libs/Login/Login'
import DefOne from 'libs/DefOne'
import DefList from 'libs/DefList'
import Fetcher from './comps/methods/Fetcher'
import UserSelector from './libs/UserSelector/UserSelector'
import './comps/Suggest/MeterFn'
import Train from './comps/Stats/Train'
import {
    createBrowserRouter,
    RouterProvider,
    redirect,
    useLocation,
    useNavigate,
    useLoaderData,
    useParams,
    // useHistory,
    useActionData,
    Link, Outlet
} from "react-router-dom";
import {QuestionTabs} from './comps/ForApprove';
import Storage from "./comps/Storage";

import {editQuestion, QuizEditFields} from "./comps/Suggest/SuggestionItem"
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {USER_SPRINT_STATUSES} from "./comps/UserSprint";
import IsFavorite from "./comps/IsFavorite";
import DynamicStyle from "./comps/Suggest/DynamicStyle";
import notify from "./libs/Notify/Notify";
import {PartnerSelector, UserSaleSel} from "./comps/Interview/Interview";
import Button from "./libs/Button";
import {considerationEdit} from "./comps/Considerations";
import {UserPreview} from './comps/Suggest/UserNewSel';
import MainAdmin from "./comps/TrainMethods/MainAdmin";
import Player from "./comps/TrainMethods/AudioShort/Player";
import TagSelector from "./comps/Suggest/TagSelector";
import Tree from "./comps/Tree";
import NFN from "./NFN";
import Logout from "./comps/Logout";

let user = window.user;
let files = require.context('./comps', true, /\.(js|jsx)$/).keys();
global.Fetcher = Fetcher;
let CourseStatuses = [{
    key: 'active',
    name: 'Актив'
}, {
    name: 'Не актив',
    key: 'non_active'
}];

global.question_statuses = [
    {name: 'Новый', status: '', desc: '1-2 дня'},
    {name: 'Плохо', status: 'bad', desc: '1-2 дня'},
    {name: 'Норм', status: 'norm', desc: '3-4 дня'},
    {name: 'Хорошо', status: 'good', desc: '8-10 дней'},
    {name: 'Очень хорошо', status: 'very_good', desc: '30 дней'}
]

function sync_components(cb) {
    Storage.syncCategories(() => {
        // global.UpdateRootFn('categories');
        cb && cb();
    });

}

// sync_components();

function getter(opts) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({_id: 1003, name: '124xzcv'})
        }, 5000)
    })
}

function pub_menu(arr) {
    return arr.filter(it => it).map(it => {

        return it.name ? it : {name: it, url: '/' + it.toLowerCase()}
    })
}

function to_url_arr(obj) {
    let arr = [];
    _.each(obj, (item, ind) => {
        let url = item.admin_url || ind;

        arr.push({
            path: url,
            element: <DefList props={item}>
            </DefList>

        })

        arr.push({
            path: url + "/:id",
            element: <DefOne props={item} _ind={ind}>
            </DefOne>

        })
    })
    return arr;
}

let comp_statuses = [{
    name: 'Актив', value: 'active'
}, {
    name: 'Не актив', value: 'non_active'
}]

let tagFields = [
    {key: 'name', type: 'String'},
    {key: 'user', type: 'Number'},
    {key: 'priority', type: 'Number'},
    {key: 'what', type: 'String'},
    {key: 'problemDesc', type: 'String'},
    {key: 'details', type: 'String', isArr: true},
    {key: 'practicalUsage', type: 'String'},
    {key: 'practicalNotUsage', type: 'String'}
]
let roles = user.get_my_roles();
let isDemo = window.location.href.indexOf('demo.') > -1
global.CONFIG = {
    menu: pub_menu([
        {name: 'Профиль', url: 'profile'},
        {name: 'Тэги', url: 'tags'},
        {name: 'Вопросы статистика', url: 'stats'},
        {name: 'Логи', url: 'log-approve'},
        {name: 'Дубликаты', url: 'find-duplicates-new'},
        {name: 'Стеки', url: 'stack'},
        {name: 'Шорт Фидбеки', url: 'user-history'},
        {name: 'Версии контента', url: 'versions'},
        {name: 'Для тех лида', url: 'mtl-question'},
        {name: 'Заявки', url: 'requests'},

        'HR', {name: 'Выход', url: 'logout'}]),
    header: pub_menu(
        user.is_role('sale') ? [
                isDemo ? null :  {name: 'Интервью', url: 'interviews'},'',
                {name: 'Considerations', url: 'considerations-new'},

                {name: 'Clients', url: 'clients'},
                {name: 'Partners', url: 'partners'},
                {name: 'Roadmaps', url: 'roadmaps'},
                {name: 'Exams', url: 'exams'},

                {name: 'Users', url: 'users'},
                {name: 'User.Templ', url: 'users-templates'},
            ] :
            [
                isDemo ? null :  {name: 'Интервью', url: 'interviews'},
                {name: 'Вопросы', url: 'theme-question'},
                {name: 'Доп. вопросы', url: 'interview-question'},
                {name: 'Группы', url: 'preview'},

                {name: 'Для апрува', url: 'for-approve'},

                // {name: 'U.St', url: 'user-stats'},
                {name: 'Статистика', url: 'user-stats-new'},

                {name: 'Пользователи', url: 'users'},
                {name: 'Шаблоны пользоват.', url: 'users-templates'},
                {name: 'Фидбеки', url: 'suggestions'},

                // {name: 'M', url: 'stats-by-seconds'},
                // {name: 'M.D', url: 'stats-by-days'},


                // {name: 'QuizH', url: 'quiz-history-plain'},
                {name: 'Курсы', url: 'course'},
                {name: 'Модули', url: 'course-module'},
                // {name: 'U.Sprints', url: 'user-sprint'},
                {name: 'Экзамены', url: 'exams'},
                {name: 'Квизы', url: 'quizes'},

                // {name: 'PFK', url: 'p-f-keys'},
                // {name: 'PFV', url: 'p-f-values'},
                // {name: 'PFP', url: 'p-f-prognoz'},
                // {name: 'PFG', url: 'p-f-group'},
                // {name: 'Planing', url: 'planing'},
            ]
    ),

    urls: {
        'user-history': {
            woModal: true,
            // woAdd: true,
            url: '/question-user-history',
            autoSave: 500,
            top_filters: [
                {
                    key: 'rating.type',
                    title: 'Тип',
                    def_name: 'Все',
                    def_value: {$exists: true},
                    arr: [{
                        value: {$exists: true},
                        name: 'Не пустой тип'
                    },

                    ]
                },

                {
                    key: 'rating.score',
                    def_name: 'Все',
                    arr: [
                        {
                            name: 'Не пустые оценки',
                            value: {$exists: true},
                        },
                    ]
                }

            ],
            edit: [
                // {name: '', key: 'name', size: 4},
            ],
            tabs: [
                {
                    name: 'QuestionId', key: 'question', component: ({item}) => {
                        return <a onClick={e => {
                            e.stopPropagation();
                            return false;
                        }} href={'/theme-question/' + item.question}># {item.question}</a>
                    }
                },
                {name: 'Feedback.Score', key: 'rating.score'},
                {name: 'Feedback.Date', key: 'rating.cd', type: 'date'},
                {name: 'Feedback.Type', key: 'rating.type'},
                {name: 'Feedback.Desc', key: 'rating.desc'},

                {name: '_id', key: '_id'},

            ]
        },
        stack: {
            url: '/stack',
            woModal: true,
            autoSave: 500,
            top_filters: [],
            useEffect: (cb) => {
                Storage.syncCategories(cb)
            },
            create: [  {name: 'Name', key: 'name', size: 12,},],
            edit: [
                // {name: '', key: 'name', size: 4},
                {name: 'Name', key: 'name'},
                {name: 'Status', key: 'status', type: 'select', items: ['', 'active', 'unactive']},
                {
                    size: 6, Component({item}) {
                        return <>{(item.hashTags || []).map((it, ind) => {
                            return (<div key={ind}>
                                {it.title}
                            </div>)
                        })}</>
                    }
                },
                {
                    size: 12,
                    addName: '+ Родительский хэш тэг',
                    key: 'hashTags', each: [{
                        size: 12,
                        key: 'name', name: 'NameChild',
                        Component(_props) {
                            let [cd, setCd] = useState(0)
                            useEffect(() => {
                            }, [])
                            let {item, ind, onChange, localItem} = _props || {};
                            return <>
                                {localItem.title} {localItem._id}
                                <div className={'fbList2'}>
                                    <Tree
                                        selectedId={localItem._id}
                                        parentCategory={Storage.getParentCategory(localItem._id)}
                                        onClick={(_id, title) => {
                                            item.hashTags[ind] = {...item.hashTags[ind] || {}, ...{_id, title}}

                                            autoSaveFn && autoSaveFn(item)
                                            console.log("qqqqq clickc id", _id, title);
                                        }}
                                    ></Tree>
                                </div>
                            </>
                        }
                    }]
                },
                {size: 12, type: 'HR'}
            ],
            tabs: [

                {name: 'Name', key: 'name'},
                {name: 'Status', key: 'status'},
                {name: 'HashTags', key: 'hashTags'},
                {name: '_id', key: '_id'},

            ]
        },
        'p-f-group': {
            woModal: true,
            // woAdd: true,
            url: '/p-f-group',
            autoSave: 500,
            edit: [
                {name: 'Name', key: 'name', size: 4},
                {
                    name: 'items',
                    key: 'items',
                    sortable: true, size: 8, each: [
                        {name: 'keyId', key: 'keyId', size: 6},
                        {name: 'name', key: 'name', size: 6},
                    ]
                },
                {type: 'HR', size: 12,},
                {path: 'PlanFact/St', size: 12,}

            ],

            tabs: [
                {name: 'Name', key: 'name'},
                {name: 'Items', key: 'items'}
            ]
        }, 'log-approve': {
            // woModal: true,
            // woAdd: true,
            url: '/log-approve',
            autoSave: 500,
            edit: [
                {name: 'Type', key: 'type', size: 12},
                {size: 12, Component({item}) {
                    return <pre>{JSON.stringify(item.details, null, 4)}</pre>
                    }}, {size: 12, Component({item}) {
                    return <pre>{JSON.stringify(item.info, null, 4)}</pre>
                    }}

            ],

            tabs: [
                {name: 'Type', key: 'type'},
                {name: 'User', key: 'user'},
                {name: 'Info', key: 'info'},
                {name: 'Details', key: 'details'},
                {name: 'Cd', key: 'cd'},
            ]
        }, 'p-f-values': {
            woModal: true,
            // woAdd: true,
            url: '/p-f-value',
            autoSave: 500,
            edit: [
                {name: 'Name', key: 'name'},
                {name: 'value', key: 'value'},
                {name: 'pfKey', key: 'pfKey'},
                {name: 'pfKeyId', key: 'pfKeyId'},
                {name: 'pfSubKey', key: 'pfSubKey'},
                {name: 'User', key: 'user'},
                {name: 'Value', key: 'value'}
            ],

            tabs: [
                {name: 'Name', key: 'name'},
                {name: 'plan', key: 'plan'},
                {name: 'fact', key: 'fact'},
                {name: 'prognoz', key: 'prognoz'},
                {name: 'odb', key: 'odb'},
                {name: 'odbKey', key: 'odbKey'},
                {name: 'pfKey', key: 'pfKey'},
                {name: 'pfKeyId', key: 'pfKeyId'},
                {name: 'pfSubKey', key: 'pfSubKey'},
                {name: 'User', key: 'user'},
                {name: 'Value', key: 'value'}
            ]
        }, 'p-f-prognoz': {
            woModal: true,
            // woAdd: true,
            url: '/p-f-prognoz',
            autoSave: 500,
            edit: [
                {name: 'Name', key: 'name'},
                {name: 'value', key: 'value'},
                {name: 'pfKey', key: 'pfKey'},
                {name: 'pfKeyId', key: 'pfKeyId'},
                {name: 'pfSubKey', key: 'pfSubKey'},
                {name: 'Value', key: 'values', type: 'pre'},
            ],

            tabs: [
                {name: 'odb', key: 'odb'},
                {name: 'pfKey', key: 'pfKey'},
                {name: 'pfKeyId', key: 'pfKeyId'},
                {name: 'pfSubKey', key: 'pfSubKey'},
                {name: 'User', key: 'user'},
                {name: 'Values', key: 'values'}
            ]
        },
        'p-f-keys': {
            woModal: true,
            // woAdd: true,
            url: '/p-f-key',
            autoSave: 500,
            edit: [
                {name: 'Name', key: 'name'},
                {name: 'pfKey', key: 'pfKey'},

                {name: 'pfSubKey', key: 'pfSubKey'},
                {name: 'pfKeyFormula', key: 'pfKeyFormula', type: 'textarea'},
                {name: 'pfBuitify', key: 'pfBuitify', type: 'select', items: ['num', '%']},
                {name: 'User', key: 'user'},
                {name: 'TotalType', type: 'select', key: 'totalType', items: ['total', 'avg']},

                {name: 'googleDetails', key: 'googleDetails', type: 'textarea'},
                {name: 'isFavorite', key: 'isFavorite', type: 'checkbox'},

                {type: 'HR', size: 12,},
                {name: 'PrognozPeriod', key: 'prognozPeriod', type: 'select', items: ['weekly', 'monthly', 'daily']},
                {name: 'prognozStartCd', key: 'prognozStartCd', type: 'Date'},
                {name: 'prognozEndCd', key: 'prognozEndCd', type: 'Date'},

                {name: 'PrognozDay', key: 'prognozDay',},
                {name: 'PrognozTime', key: 'prognozTime',},
                {name: 'PrognozCount', key: 'prognozCount',},
                {name: 'PrognozDelta', key: 'prognozDelta',},

                {type: 'HR', size: 12,},
                {name: 'PlanPeriod', key: 'planPeriod', type: 'select', items: ['weekly', 'monthly', 'daily']},
                {name: 'factDelta', key: 'factDelta'},
                {name: 'startCd', key: 'startCd', type: 'Date'},
                {name: 'endCd', key: 'endCd', type: 'Date'},
                {type: 'HR', size: 12,},
                // {
                //     key: 'fields', size: 6, sortable: true,
                //     addName: '+ Добавить поле',
                //     each: [{
                //         name: 'Name', key: 'name', size: 6,
                //     }, {
                //         name: 'Key (для центр статистики)', key: 'key', size: 6,
                //     }]
                // },
                {path: 'PlanFact/GeneratePrognozFactPeriods', size: 12,}
            ],

            tabs: [
                {name: 'Name', key: 'name'},
                {name: 'pfKey', key: 'pfKey'},
                {name: 'User', key: 'user'},
                {name: 'PlanPeriod', key: 'planPeriod'},
                {name: 'PrognozPeriod', key: 'prognozPeriod',},
                {name: 'startCd', type: 'Date'},
                {name: 'endCd', type: 'Date'},
                {name: 'cd', key: 'cd', type: 'date'},
            ]
        },
        'versions': {
            woModal: true,
            woAdd: true,
            url: '/version',
            autoSave: 500,
            edit: [
                {name: 'Name', key: 'name'},
                {name: 'Source', key: 'source'},
                {name: 'SourceId', key: 'sourceId'},
                {name: 'contributesScore', key: 'contributesScore'},

                {name: 'VersionStr', key: 'versionStr', type: 'text'},
                {name: 'ForkVersion', key: 'forkVersionStr', type: 'text'},
                {
                    name: 'suggests', size: 12, Component: ({item, onGlobalChange}) => {
                        item.suggests = item.suggests || [];
                        return <>
                            {item.versionStr !== '0.0.0' && !item.isLTS &&
                                <button className='btn btn-xs btn-default' onClick={() => {
                                    global.http.get('/set-lts', {_id: item._id}).then(r => {
                                        item.isLTS = true;
                                        global.onGlobalChange({...item})
                                    })
                                }}>SetLTS</button>}
                            {item.isLTS && <div><NFN>LTS version</NFN></div>}
                            <div></div>

                            <NFN>SUGGESTIONS</NFN>: x{item.suggests.length}: {item.suggests.join(', ')}</>
                    }
                },

                {
                    size: 12, Component: ({item}) => {
                        return <pre>{JSON.stringify(item.data, null, 4)}</pre>
                    }
                }
            ],
            top_filters: [
                {
                    key: 'source',
                    title: 'Source',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'ThemeQuestion',
                        name: 'Вопросы'
                    }, {
                        name: 'Квизы',
                        value: 'Quiz'
                    }
                    ]
                },
            ],
            tabs: [
                {name: 'Name', key: 'name'},
                {name: 'Source', key: 'source'},
                {name: 'SourceId', key: 'sourceId'},
                {name: 'VersionStr', key: 'versionStr'},
                {name: 'ForkVersion', key: 'forkVersionStr'},
                {name: 'suggests', key: 'suggests', type: 'text'},
                {name: 'isLTS', key: 'isLTS'},
                {name: 'contributorsScore', key: 'contributorsScore', type: 'text'},
                {name: 'contributors', key: 'contributors', type: 'text'},


                {name: 'cd', key: 'cd'},
            ]
        },
        requests: {
            // woModal: true,
            // woAdd: true,
            url: "/client-req",
            autoSave: 500,
            edit: [
                {size: 12, type: 'select', key: 'status', name: 'Статус', items: ['open', 'answered', 'closed']},
                {size: 12, type: 'md', key: 'answer', name: 'Ответ'},
                {size: '12', type: 'HR'},
                {size: 12, key: 'type', type: 'text', prefix: 'Срочность: ',
                    items: ['-', 'norm', 'urgent']},

                {size: 12, key: 'name', type: 'text', prefix: 'Тайтл: '},
                {size: 12, key: 'desc',
                    type: 'text',
                    rows: 10,
                    preview: 'edit', prefix: 'Описание: '},

                {size: '12', type: 'HR'},

                // {size: 12, Component: () => {
                //         return <>
                //             <small>Если менеджер плохо обработал заявку, то для отправки претензии к качеству используейте пожалуйста почту из публичной оферты <a href={"mailto:hello@itk.academy"}>hello@itk.academy</a> или почту указанную в договоре</small>
                //             <div></div>
                //             <small>Мы каждый день усердно работаем чтобы ИТК Академия предоставляла лучшее качество услуг в РФ и мире</small>
                //         </>
                //     }
                // },
            ],
            top_filters: [
                {
                    key: "status",
                    title: "Status",
                    def_name: "Все",
                    def_value: "",
                    arr: [
                        // {
                        //     value: "edit",
                        //     name: "Редактирую",
                        // },
                        {
                            name: "Открытые",
                            value: "open",
                        },
                        {
                            name: "Ответ",
                            value: "answered",
                        },{
                            name: "Закрытые",
                            value: "closed",
                        },
                        // {
                        //     name: "Отменено",
                        //     value: "canceled",
                        // },
                    ],
                },
            ],
            tabsTitle: "",
            tabs: [
                {
                    name: "Имя", key: "name",
                    component({item}) {
                        let name = item.name || '';
                        let N = 100;
                        if (name.length > N) {
                            name = name.substring(0, N) + ' ...'
                        }
                        return <div className={'ellipse'}>{name}</div>
                    }
                },
                {name: "Статус", key: "status"},
                // {name: 'Вопрос', key: 'question'},
                // {name: 'Пользователь', key: 'user'},
            ],
        },
        'users-templates': {
            url: '/user-hr-template',
            woModal: true,
            defSize: 4,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                {
                    size: 12, Component: ({item, localItem}) => {
                        useEffect(() => {
                            const url = new URL(window.location.href);

                            const params = new URLSearchParams(url.search);
                            const obj = {};

                            for (const [key, value] of params.entries()) {
                                obj[decodeURIComponent(key)] = decodeURIComponent(value);
                            }
                            let vv = {first_name: obj.name, surname: obj.surName};
                            vv.login = getLogin(vv)
                            setData(vv);
                            setCustomData({hrData: obj})
                        }, [])


                        function transform(text) {
                            const transliterationMap = {
                                'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
                                'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
                                'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
                                'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ъ': '',
                                'ы': 'y', 'ь': "", 'э': 'e', 'ю': 'yu', 'я': 'ya', '_': '_', '-': '-'
                            };
                            '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').forEach((item) => {
                                transliterationMap[item] = item;
                            })


                            return text.split('').map(char => transliterationMap[char]).join('');
                        }

                        function getLogin(v) {
                            return transform(((v.first_name || '') + '_' + (v.surname || '')).toLowerCase())
                        }

                        // console.log(obj);
                        let [data, setData] = useState({});
                        let [user, setUser] = useState({});
                        let [customData, setCustomData] = useState({});

                        function getMsg() {
                            return (localItem.desc || '')
                                .replace(/\#NAME\#/gi, user.first_name)
                                .replace(/\#LOGIN\#/gi, user.username)
                                .replace(/\#PASS\#/gi, data.pass)

                        }

                        return <div>
                            <Smart
                                obj={data}
                                items={[{
                                    key: 'first_name', name: 'Имя',
                                    onChange(v) {
                                        data.first_name = v;
                                        data.login = getLogin(data)
                                        setData({...data})
                                        setUser({})
                                    }
                                },
                                    {
                                        key: 'surname', name: 'Фамилия',
                                        onChange(v) {
                                            data.surname = v;
                                            data.login = getLogin(data)
                                            setData({...data})
                                            setUser({})
                                        }
                                    }, {
                                        key: 'login', name: 'Логин'
                                    },
                                    // {
                                    //     key: 'pass', name: 'Пароль'
                                    // }
                                ]}
                                onChange={(v) => {
                                    setData({...v})
                                    setUser({})
                                }}
                            ></Smart>
                            <button className='btn btn-sm btn-success' onClick={() => {
                                function generateRandomString(length) {
                                    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                                    let result = '';
                                    for (let i = 0; i < length; i++) {
                                        const randomIndex = Math.floor(Math.random() * charset.length);
                                        result += charset.charAt(randomIndex);
                                    }
                                    return result;
                                }

                                data.pass = generateRandomString(15)
                                setData({...data})

                                global.http.post('/create-user-from-template', {
                                    customData,
                                    localItem,
                                    item,
                                    data
                                }).then(({
                                             user,
                                             error
                                         }) => {
                                    //console.log("qqqqq rrrrrrr", user);
                                    setUser(user)
                                    setCustomData({});
                                })
                            }}>Создать пользователя
                            </button>

                            {user && user._id && <>
                                <div></div>
                                <a href={'/users/' + user._id}>#{user._id} {user.username}</a>
                                <div></div>
                                <pre>{getMsg()}</pre>
                            </>}
                            <pre>{JSON.stringify(customData, null, 4)}</pre>
                        </div>
                    }
                },

                {type: 'HR', size: 12},

                {name: 'name', key: 'name'},
                {name: 'desc', key: 'desc', type: 'textarea', size: 6},


                {type: 'HR', size: 12},
                {
                    key: 'exams',
                    addName: '+ Экзамен',
                    name: 'Экзамен',
                    size: 4,
                    each: [{
                        name: 'examId', key: 'sourceId', size: 6,
                    }, {
                        Component: ({localItem}) => {
                            return <a href={'/exams/' + localItem.sourceId}>{localItem.name} #{localItem.sourceId}</a>
                        }, size: 6
                    }]
                },
                {
                    key: 'courses',
                    addName: '+ Курсы',
                    name: 'Курсы',
                    size: 4,
                    each: [{
                        name: 'courseId', key: 'sourceId', size: 6,
                    }, {
                        Component: ({localItem}) => {
                            return <a href={'/course/' + localItem.sourceId}>{localItem.name} #{localItem.sourceId}</a>
                        }, size: 6
                    }]
                },
                {
                    key: 'sprints',
                    addName: '+ Спринты',
                    name: 'Спринт',
                    size: 4,
                    each: [{
                        name: 'sprintId', key: 'sourceId', size: 6,
                    }, {
                        Component: ({localItem}) => {
                            return <a
                                href={'/user-sprint/' + localItem.sourceId}>{localItem.name} #{localItem.sourceId}</a>
                        }, size: 6
                    }]
                },
                {type: 'HR', size: 12},
                {
                    size: 12, path: 'UserHrTemplateAssign'
                },


            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: '_id', key: '_id'},
            ]
        },
        quizes: {
            url: '/quiz',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                }, {
                    key: 'answerType',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'audio', value: 'audio'}, {name: 'quiz', value: 'quiz'}, {name: 'code', value: 'code'}]
                }, {
                    key: 'isValid',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Валидные', value: true}, {name: 'Не валидные + не активные', value: 'false'}]
                },
            ],
            edit: QuizEditFields,
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'cd', key: 'cd'},
                {name: 'question', key: 'question'},
                {name: 'specialType', key: 'specialType'},
                {name: 'isValid', key: 'isValid'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: 'status', key: 'status'},
                {name: '_id', key: '_id'},
            ]
        },
        clients: {
            url: '/my-client',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Active', value: 'active'}, {name: 'Unactive', value: 'unactive'}]
                },
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                {name: 'name', size: 10, key: 'name'},
                {name: 'isFavorite', size: 2, key: 'isFavorite', type: 'checkbox'},
                {name: 'status', key: 'status', type: 'group', list: ['', 'active', 'unactive']},
                {type: 'HR', size: 12,},
                {
                    name: 'contacts', key: 'contacts',
                    size: 12,
                    addName: '+ Добавить контакт',
                    sortable: true,
                    each: [
                        {
                            name: 'TG', key: 'tg',
                        }, {
                            name: 'Имя', key: 'name',
                        }, {
                            name: 'Должность', key: 'role',
                        }
                    ]
                },
                {type: 'HR', size: 12,},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'cd', key: 'cd'},
                {name: 'status', key: 'status'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: '_id', key: '_id'},
            ]
        },
        partners: {
            url: '/my-partner',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Active', value: 'active'}, {name: 'Unactive', value: 'unactive'}]
                },
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                {name: 'name', size: 10, key: 'name'},
                {name: 'isFavorite', size: 2, key: 'isFavorite', type: 'checkbox'},

                {name: 'status', key: 'status', type: 'group', list: ['', 'active', 'unactive']},
                {type: 'HR', size: 12,},
                {
                    name: 'contacts', key: 'contacts',
                    size: 12,
                    addName: '+ Добавить контакт',
                    sortable: true,
                    each: [
                        {
                            name: 'TG', key: 'tg',
                        }, {
                            name: 'Имя', key: 'name',
                        }, {
                            name: 'Должность', key: 'role',
                        }
                    ]
                },
                {type: 'HR', size: 12,},
                {
                    size: 12,
                    addName: '+ Добавить джоб урл',
                    sortable: true,
                    name: 'jobUrls', key: 'jobUrls', each: [
                        {
                            name: 'УРЛ', key: 'url',
                        }, {
                            name: 'TG', key: 'tg',
                        }, {
                            name: 'Контакт', key: 'name',
                        }
                    ]
                },

                {type: 'HR', size: 12,},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'cd', key: 'cd'},
                {name: 'status', key: 'status'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: '_id', key: '_id'},
            ]
        },
        considerations: {
            url: '/my-consideration',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Active', value: 'active'}, {name: 'Unactive', value: 'unactive'}]
                },
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: considerationEdit,
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'cd', key: 'cd'},
                {name: 'status', key: 'status'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: '_id', key: '_id'},
            ]
        },
        tags: {
            path: 'TagsComp'
            // url: '/hash-tag',
            // // admin_url: '/users',
            // edit: tagFields,
            // tabs: [
            //     {name: 'username', key: 'username'},
            //     {name: 'roles', key: 'roles'},
            //     {name: '_id', key: '_id'},
            // ]
        },
        'interview-question': {
            woModal: true,
            url: '/interview-question',
            // admin_url: '/users',
            top_filters: [
                // {
                //     key: 'name',
                //     def_name: 'Все',
                //     def_value: '',
                //     arr: [
                //         {name: 'SOFFICE', value: 'SOFFICE'},
                //         {name: 'DOWNLOAD', value: 'DOWNLOAD'},
                //         {name: 'INN', value: 'INN'},
                //         {name: 'doc_ids', value: 'doc_ids'},
                //         {name: 'doc_download', value: 'doc_download'},
                //         {name: 'doc_html', value: 'doc_html'},
                //         {name: 'doc_inn', value: 'doc_inn'}
                //     ]
                // },
                {
                    key: 'themeQuestionId',
                    title: 'Status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'пустые', value: {$not: {$exists: true}}},
                    ]
                }
            ],
            edit: [
                {name: 'Type', key: 'type', type: 'group', list: ['', 'question', 'task', 'js-task'], size: 12},
                {name: 'Вопрос', key: 'name', type: 'md', size: 12,},
                {name: 'Уточняющие вопросы', key: 'additionalQuestions', type: 'textarea', size: 12},
                {path: 'InterviewTagsSel', size: 12},
            ],
            tabs: [
                {
                    name: 'Вопрос', key: 'name', component: ({item}) => {
                        return <a href={"/interview-question/" + item._id}>{item.name}</a>
                    }
                },
                {name: 'Тип', key: 'type'},
                {name: 'Уточн вопросы', key: 'additionalQuestions'},
                {name: 'Вопрос ИД', key: 'themeQuestionId'},
                {name: 'СтрТема', key: 'themePlain'},
            ]
        },
        'suggestions': {
            woModal: true,
            woAdd: true,
            // modalSize: 'full',
            newPage: true,
            autoSave: 500,
            url: '/suggestion',
            edit: [

                {size: 12, path: 'Suggest/SuggestionItem'},

            ],
            class_fn: (item) => {
                return item.status == 'sent' ? 'error' : ''
            },
            top_filters: [
                {
                    key: 'status',
                    title: 'Status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'edit',
                        name: 'Редактирую'
                    }, {
                        name: 'Отправлено',
                        value: 'sent'
                    }, {
                        name: 'Проверено',
                        value: 'approved'
                    }, {
                        name: 'Отменено',
                        value: 'canceled'
                    }]
                },
                {
                    type: 'UserSelector'
                },
            ],
            tabs: [
                {name: 'Name', key: 'name'},
                {name: 'Status', key: 'status'},

                {name: 'Question', key: 'question'},
                {name: 'User', key: 'user'},

                // { name: 'Facts', key: 'factsLength' },
                // { name: 'UseCases / LC solutions', key: 'useCasesLength' },
                // { name: 'InterviewsCount', key: 'interviewsCount' },
            ]
        },
        'theme-question': {
            woModal: true,
            url: '/theme-question',
            // admin_url: '/users',
            autoSave: 200,
            top_filters: [

                {
                    key: 'type',
                    title: 'Status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Вопрос', value: 'question'},
                        {name: 'Задача', value: 'task'},
                        {name: 'JS Задача', value: 'js-task'},
                        {name: 'Пусто', value: {$exists: false}},
                    ]
                },

                {
                    key: 'contributorsScore', title: 'Score',
                    def_name: 'Все',
                    def_value: '', arr: [
                        {name: 'Все', value: 'all'},
                        {name: 'Пусто', value: {$exists: false}},
                        {
                            name: '5-',
                            value: {$lte: 5}
                        },
                        {name: '500-', value: {$lte: 500}},
                        {name: '500+', value: {$gte: 500}}
                    ]
                },
                {
                    key: 'answerProgressCount',
                    title: 'Count',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Ничего', value: '0'},
                        {name: 'Что-то', value: 1},
                        {name: 'Хорошо', value: 2},
                        {name: 'Все', value: 3},
                    ]
                },
                {
                    key: 'isForModeratorApprove',
                    title: 'Count',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Не модерированные', value: true},
                    ]
                },
                {
                    key: 'originalDuplcateId',
                    title: 'Count',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Дубли', value: {$exists: true}},
                    ]
                },
                {
                    key: 'quizCount',
                    title: '',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Без квизов', value: {$exists: false}},
                        {name: '0', value: '0'},
                        {name: '1', value: '1'},
                        {name: '2', value: '2'},
                        {name: '3', value: '3'},
                        {name: '4', value: '4'},
                    ]
                },
            ],
            edit: [
                ...editQuestion,
                // {type: 'HR', size: 12},
                // {path: 'ListOfInterviewQuestions', size: 12},
            ],
            class_fn: (item) => {
                return item.originalDuplicateId ? 'removed' : item.isForModeratorApprove ? 'error' : 'ok'
            },
            create: [
                {type: 'md', key: 'name', size: 12,}
            ],
            tabs: QuestionTabs
        },
        users: {
            url: '/user-auth1',
            // admin_url: '/users',
            woModal: true,
            autoSave: 200,
            defSize: 4,
            top_filters: [
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: {$ne: 'unactive'},
                    arr: [{name: 'active', value: {$ne: 'unactive'}}, {name: 'unactive', value: 'unactive'}]
                }, {
                    key: 'roles',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Админ', value: 'admin'}]
                },
            ],
            edit: [
                {name: 'status', key: 'status', size: 12, type: 'group', list: ['', 'active', 'unactive']},

                {type: 'HR', size: 12},
                {type: 'input', key: 'first_name', label: 'Имя', size: 4},
                {type: 'input', key: 'surname', label: 'Фамилия', size: 4},
                {type: 'input', key: 'email', label: 'Эл. почта', size: 4},
                {name: 'username', key: 'username', size: 4},
                {name: 'plain_password', key: 'plain_password', size: 4},
                {name: 'roles', key: 'roles', size: 2},
                {name: 'isCV?', key: 'customData.isCV', size: 2, type: 'checkbox'},
                {
                    name: 'Открытие поиска (передали в саилз отдел)',
                    key: 'customData.salesOpenTime',
                    size: 4,
                    type: 'date'
                },
                {
                    label: 'Статус поиска',
                    key: 'customData.salesStatus',
                    size: 4,
                    type: 'group',
                    list: ['', 'active_search', 'pause_search', 'on_project']
                },
                {name: 'cd', key: 'cd', type: 'text'},
                {type: 'HR', size: 12},
                {path: 'CourseUserSelector', size: 12},
                {type: 'HR', size: 12},
                {path: 'Approve/ApproveTags', size: 12},
                {type: 'HR', size: 12},
                {type: 'text', isJSON: true, key: 'customData', name: 'CustomData', size: 12},
                // {name: 'roles', key: 'roles'},
            ],
            tabs: [
                {name: 'status', key: 'status'},
                {name: 'username', key: 'username'},
                {name: 'name', key: 'first_name'},
                {name: 'surname', key: 'surname'},
                {name: 'userGrade', key: 'customData.userGrade'},
                {name: 'roles', key: 'roles'},

                {name: 'approvedTags', key: 'customData.approvedTags'},
                {name: 'ignoreTags', key: 'customData.ignoreTags'},
                {name: 'isApprovedAll', key: 'customData.isApprovedAll'},

                {name: '_id', key: '_id'},
            ]
        },
        exams: {
            url: '/exam',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                // {path: 'Exam'},
                // {size: 12, type: 'hr'},
                // {key: 'minutesStr', size: 4, name: 'minutesStr', type: 'textarea', minRows: 1},
                {
                    size: 12, Component: ({item}) => {
                        return <div className={'fbList2'}>
                            <pre>{JSON.stringify(item.scoreDetails, null, 4)}</pre>
                        </div>
                    }
                },
                {path: 'Exam', size: 12},
                {name: 'name', key: 'name', size: 12},
                {type: 'HR', size: 12}
                // {name: 'roles', key: 'roles'},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'perc', key: 'perc'},
                {name: 'quizPerc', key: 'quizPerc'},
                {name: 'startCd', key: 'startCd', type: 'date'},
                {name: 'submitCd', key: 'submitCd', type: 'date'},
                {name: 'user', key: 'user', component: UserPreview},
                {name: 'status', key: 'status'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: '_id', key: '_id'},
            ]
        },
        'exam-templates': {
            url: '/exam-template',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {type: 'UserSelector'},
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                {path: 'ExamTemplate', size: 12},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'perc', key: 'perc'},
                {name: 'quizPerc', key: 'quizPerc'},
                {name: 'startCd', key: 'startCd', type: 'date'},
                {name: 'submitCd', key: 'submitCd', type: 'date'},
                {name: 'user', key: 'user', component: UserPreview},
                {name: 'status', key: 'status'},
                {name: 'isFavorite', key: 'isFavorite'},
                {name: '_id', key: '_id'},
            ]
        },
        course: {
            url: '/course',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
                {
                    key: 'status',
                    title: 'Status',
                    def_name: 'Все',
                    def_value: '',
                    arr: CourseStatuses.map(({name, value, key}) => ({key: key, value: value, name: name}))
                },
            ],
            edit: [
                {name: 'Status', key: 'status', type: 'group', list: CourseStatuses, size: 3},

                {name: 'name', key: 'name', size: 4},
                {name: 'Time', key: 'time', size: 2},
                {name: 'inviteId', key: 'inviteId', size: 3},
                {type: 'HR', size: 12},
                {path: 'Course/Course', size: 12},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'status', key: 'status'},
                {name: '_id', key: '_id'},
            ]
        },
        'course-module': {
            url: '/course-module',
            woModal: true,
            defSize: 12,
            autoSave: 200,
            top_filters: [
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
            ],
            edit: [
                {size: 6, name: 'name', key: 'name'},
                {size: 3, name: 'quizExamSize', key: 'quizExamSize'},
                {size: 3, name: 'examSize', key: 'examSize'},
                {type: 'HR', size: 12},
                {path: 'CourseModule/CourseModule', size: 12},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: '_id', key: '_id'},
            ]
        },
        'mtl-question': {
            woModal: true,
            url: '/mtl-question',
            // admin_url: '/users',
            top_filters: [
                {type: 'UserSelector'},
            ],
            edit: [
                // {type: 'text', value: 'Question', size: 12,},

                {name: 'type', key: 'type', type: 'group', list: ['', 'refactor', 'problem', 'discussion'], size: 12},
                {type: 'HR', size: 12},
                {name: 'name', key: 'name', type: 'md', size: 12},
                {name: 'hashTags', path: 'Suggest/TagSelector', size: 12},
                {name: 'Время решения (мин)', key: 'time', size: 12, type: 'number'},
                {type: 'HR', size: 12},
                // {type: 'text', value: 'Answer'},
                {name: 'answer', key: 'answer', type: 'md', defClass: 'md-answer', size: 12},

            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'type', key: 'type'},
                {name: 'time', key: 'time'},
                {name: 'user', key: 'user'},
                {name: 'cd', key: 'cd'},

            ]
        },
        'user-sprint': {
            woModal: true,
            url: '/user-sprint',
            // admin_url: '/users',
            autoSave: 200,
            top_filters: [
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: USER_SPRINT_STATUSES.filter(it => it)
                },
                {
                    key: 'isFavorite',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{name: 'Favorite', value: true}, {name: 'NotFavorite', value: 'false'}]
                },
                {type: 'UserSelector'},

            ],
            create: [
                {size: 12, name: 'name', key: 'name'},
                {size: 12, type: 'HR'},
            ],
            edit: [
                {path: 'UserSprint', size: 12}
            ],
            tabs: [
                {name: 'star?', key: 'isFavorite'},
                {name: 'name', key: 'name'},
                {name: 'user', key: 'user'},
                {name: 'questionsCount', key: 'questionsCount'},
                {name: 'status', key: 'status'},
                {name: 'type', key: 'type'},
                // {name: 'time', key: 'time'},
                {name: 'cd', key: 'cd'},

            ]
        },
        interviews: {
            woModal: true,
            modalSize: 'small',
            autoSave: 200,
            // refreshOnSave: true,
            url: '/interview',
            class_fn: (item) => {
                return !(item.questions || []).length ? 'error' : 'ok'
            },
            top_filters: [
                {
                    key: 'type',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'HR', value: 'HR'},
                        {name: 'tech', value: 'tech'},
                    ]
                },
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Ожидает', value: 'waiting'},
                        {name: 'Оффер', value: 'offer'},
                        {name: 'След этам', value: 'next_stage'},
                        {name: 'Отказ', value: 'reject'},
                    ]
                },
                {type: 'UserSelector'},

            ],
            // admin_url: '/users',
            create: [
                {size: 12, name: 'name', key: 'name'},
                {size: 12, type: 'HR'},
            ],
            edit: [
                {
                    path: 'Interview/Interview',
                    size: 12,
                }
            ],

            tabs: [
                {name: 'name', key: 'name'},
                {name: 'problemQuestions', key: 'problemQuestions'},
                {name: 'questions', key: 'questionsSize'},
                {name: 'client', key: 'client'},
                {name: 'type', key: 'type'},
                {name: 'status', key: 'status'},
                {name: 'date', key: 'date'},


            ]
        },
        'stats-by-seconds': {
            modalSize: 'full',
            top_filters: [{
                type: 'UserSelector'
            },
            ],
            url: '/stats-by-second',
            edit: [
                {
                    Component: ({item}) => {
                        return <pre>{JSON.stringify(item.stats, null, 4)}</pre>
                    },
                    size: 12,
                }, {
                    Component: ({item}) => {
                        return <>
                            <hr/>
                            <pre>{JSON.stringify(item, null, 4)}</pre>
                        </>
                    },
                    size: 12,
                }
            ],
            tabs: [
                {name: '_id', key: '_id'},
                {name: 'user', key: 'user'},
                {name: 'hours', key: 'hours'},
                {name: 'minutes', key: 'minutes'},
                {name: 'seconds', key: 'seconds'},
                {name: 'odb', key: 'odb'},
                {name: 'date', key: 'cd', type: 'date'},

            ]
        },

        'stats-by-days': {
            modalSize: 'full',
            top_filters: [{
                type: 'UserSelector'
            },
            ],
            url: '/stats-by-day',
            edit: [
                {
                    Component: ({item}) => {

                        return <pre>{JSON.stringify((item.stats || {}).stats, null, 4)}</pre>
                    },
                    size: 12,
                },
                // {size: 12, type: 'HR'},
                {
                    Component: ({item}) => {
                        return <>
                            <hr/>
                            <pre>{JSON.stringify(item, null, 4)}</pre>
                        </>
                    },
                    size: 12,
                }
            ],
            tabs: [
                {name: '_id', key: '_id'},
                {name: 'user', key: 'user'},
                {name: 'total', key: 'stats.stats.total'},
                {name: 'totalMinutes', key: 'stats.stats.totalMinutes'},

                {name: 'odb', key: 'odb'},
                {name: 'date', key: 'cd', type: 'date'},

            ]
        },
        'exam-history': {
            modalSize: 'full',
            top_filters: [{
                type: 'UserSelector'
            },

            ],
            url: '/exam-history',
            edit: [
                {
                    Component: ({item}) => {
                        return <pre>{JSON.stringify(item.stats, null, 4)}</pre>
                    },
                    size: 12,
                }, {
                    Component: ({item}) => {
                        return <>
                            <hr/>
                            <pre>{JSON.stringify(item, null, 4)}</pre>
                        </>
                    },
                    size: 12,
                }
            ],
            tabs: [
                {name: '_id', key: '_id'},
                {name: 'user', key: 'user'},
                {name: 'exam', key: 'exam'},
                {name: 'size', key: 'size'},
                {name: 'question', key: 'question'},
                {
                    name: 'code', component: ({item}) => {
                        //console.log('item444444444444', item, item.files)
                        return (item.files || {})['']
                    }
                },
                {name: 'curCasesStr', key: 'curCasesStr'},
                {name: 'date', key: 'cd', type: 'date'},

            ]
        },

        'quiz-history-plain': {
            modalSize: 'full',
            top_filters: [{
                type: 'UserSelector'
            },
                {
                    key: 'isCorrect',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Ok', value: true},
                        {name: 'Err', value: 'false'},
                    ]
                },

            ],
            url: '/quiz-history-plain',
            edit: [
                {name: 'quiz', type: 'Number'},
                {name: 'question', type: 'Number'},
                {name: 'user', type: 'Number'},
                {name: 'quizStatus', type: 'String'},
                {name: 'isCorrect', type: 'Boolean'},
                {name: 'cd', type: 'Date'},
            ],
            tabs: [
                {name: 'quiz', key: 'quiz'},
                {name: 'question', key: 'question'},
                {name: 'user', key: 'user'},
                {name: 'quizStatus', key: 'quizStatus'},
                {name: 'isCorrect', key: 'isCorrect'},
                {name: 'cd', key: 'cd'},

            ]
        },
        'video': {
            modalSize: 'full',
            top_filters: [{
                type: 'UserSelector'
            },
                {
                    key: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [
                        {name: 'Loading', value: 'loading'},
                        {name: 'Ok', value: 'ok'},
                        {name: 'Err', value: 'err'},
                    ]
                },

            ],
            url: '/upload-video',
            edit: [
                {name: 'quiz', type: 'Number'},
                {name: 'question', type: 'Number'},
                {name: 'user', type: 'Number'},
                {name: 'quizStatus', type: 'String'},
                {name: 'isCorrect', type: 'Boolean'},
                {name: 'cd', type: 'Date'},
            ],
            tabs: [
                {name: 'user', key: 'user'},
                {name: 'type', key: 'type'},
                {
                    name: 'link', component({item}) {
                        let link = global.env.VIDEO_DOMAIN_PLAYER + '/' +(item.type === 'img' ? 'file' : 'video')+ '/' + item.user + '/' + item?.info?.fileName
                        return <a onClick={(e) => {
                            e.stopPropagation();
                            return null;
                        }} href={link} target={"_blank"}>{link}</a>
                    }
                },
                // {name: 'status', key: 'status'},
                {name: 'comment', key: 'comment'},
                {name: 'hash', key: 'hash'},
                {
                    name: 'size', key: 'info.fileSize', component: ({value}) => {
                        return <>{Math.round(value / (1000 * 1000)).toFixed(1)} Mb</>
                    }
                },
                {name: 'fileName', key: 'info.fileName'},
                {name: 'originalName', key: 'info.name'},
                {
                    name: 'duration', key: 'info.duration', component: ({value}) => {
                        let v = +value;
                        //console.log("qqqqq value3333", value);

                        return <>
                            {v && <>{v.toFixed(1)} мин</>}
                            {!v && <>--</>}
                        </>
                    }
                },
                {name: 'hostname', key: 'hostname'},
                {name: 'cd', key: 'cd'},

            ]
        },
    }
}

let admin_urls = to_url_arr(global.CONFIG.urls);
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                path: "profile",
                element: Loader('Profile')()
            }, {
                path: "find-duplicates",
                element: Loader('Duplicates')()

            }, {
                path: "find-duplicates-new",
                element: Loader('DuplicatesNew')()

            },
            {
                path: "planing",
                element: Loader('Planing')()
            }, {
                path: "stats",
                element: Loader('Stats')()
            }, {
                path: "user-stats",
                element: Loader('Stats/UserStatsOld')()
            },
            {
                path: "preview",
                element: Loader('Preview')()
            }, {
                path: "tags",
                element: Loader('TagsComp')()
            },
            {
                path: "exam-admin-history/:id",
                element: Loader('ExamAdminHistory')()
            },
            {
                path: "theme-question/:id",
                element: Loader('ApproveQuestion')()
            },
            {
                path: "for-approve",
                element: Loader('ForApprove')()
            },
            {
                path: "quiz-editor/:id",
                element: Loader('Suggest/QuizEditor')()
            },
            {
                path: "users-templates-generate-from-hr",
                element: Loader('FromHrAdminInterface')()
            },
            {
                path: "considerations-new",
                element: Loader('Considerations')()
            },
            {
                path: "user-stats-new",
                element: Loader('Stats/UserStats')()
            }, {
                path: 'train',
                element: <Train></Train>
            }, {
                path: 'train2',
                element: <MainAdmin></MainAdmin>
            },


        ].concat(admin_urls),
    }, {
        path: '404',
        element: <div>404</div>
    }, {
        path: 'login',
        element: <Login/>
    }, {
        path: 'logout',
        element: <Logout/>
    }
]);


function Loader(path) {

    function def() {
        return function (props) {
            return <Skeleton label={path}></Skeleton>
        }
    }

    try {
        let _path = './' + path.replace(".js", '').replace('./', '').replace(/^\//gi, '') + '.js';

        if (files.indexOf(_path) > -1) {
            let Comp = require('./comps/' + path).default
            return function (props) {
                return <Comp props={props}></Comp>
            }
        } else {
            //console.log('*........ ## AA FALSE', files);
            return def()

        }

    } catch (e) {
        //console.log('*........ ## root eee', e);
        return def()
    }
}

global.Loader = Loader;

function Root() {
    let [count, setCount] = useState(0)

    // useEffect(() => {
    //     sync_components(() => {
    //         setData({})
    //     })
    // }, [])
    // let go = () => {
    //   //console.log("qqqqq  agogogogogo", );
    //     global.http.get('/interview-question?search=&per_page=10000&page=1&sort=_id&direction=desc&&sub_id=&filters%5B0%5D=name&filters%5B1%5D=type&filters%5B2%5D=additionalQuestions&filters%5B3%5D=themeQuestionId&filters%5B4%5D=themePlain').then(r => {
    //       //console.log("qqqqq agogogogogo3", r.items.map(it => it.name));
    //     })
    // }
    // go()

    global.UpdateRootFn = (key) => {
        //console.log('updateROotFn', key)
        setCount(new Date().getTime());
    }
    let location = useLocation();
    const navigate = useNavigate();

    global.navigate = navigate;
    global.redirect = redirect;

    // React.useEffect(() => {
    //     // console.log('*........ ## location changed');
    // }, [location]);

    let path = /team/gi.test(window.location.pathname)
        ? 'Layouts/Layout2'
        : 'Layouts/Layout1';
    let Item = Loader(path);

    // if (!data) {
    //     return <div className='tc' style={{marginTop: '20px'}}>Loading ...</div>
    // }

    if (window.location.pathname == '/') {
        setTimeout(() => {
            navigate('/theme-question')
        }, 100)
    }


    return <div>
        <Player></Player>
        <DynamicStyle></DynamicStyle>
        <Item></Item>
        <div className={'tc'} style={{padding: '20px'}}>
            <small>
                <a href={'https://itk.academy/files/personal.pdf'} target={"_blank"} style={{marginRight: '20px'}}>
                    <span className="mr-5 fa fa-external-link"></span>
                    Политика конфиденциальности
                </a>
                <a href={'https://itk.academy/files/agreement.pdf'} target={"_blank"}>
                    <span className="mr-5 fa fa-external-link"></span>
                    Пользовательское соглашение
                </a>
            </small>
        </div>
    </div>
}


// console.log('*........ ## router', router);
createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function Team(props) {
    return <div>Commented</div>
}

