import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Tree from './Tree'
import Storage from './Storage';
import Smart from 'libs/Smart';
import MyModal from 'libs/MyModal'
import Textarea from 'libs/Textarea'
import Input from 'libs/Input'
import UserSelector from "./UserSelector";

let importObj = Storage.importObj;

function SkillEdit(opts) {
    let {localItem} = opts;

    return <div className={'row'}>
        {localItem.name || '---'}
    </div>
}

function Home(props) {

    const [selectedId, setSelectedId] = useState(Storage.getHash('category') || -1);
    const [search, setSearch] = useState(Storage.getHash('search'));
    const [treeSearch, setTreeSearch] = useState('');
    const [count, setCount] = useState(-1);
    const [categoriesCount, setCategoriesCount] = useState({});
    const [questions, setQuestions] = useState([]);
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [toggle, setToggle] = useState(Storage.get('toggle'));
    let categoriesObj = Storage.getCategories();
    let category = categoriesObj[selectedId];
    let modal, modalFn, modalSelectedId, authorModal;

    useEffect(() => {
        Storage.syncCategories(() => {
            setCount(new Date().getTime())
        })
        global.http.get('/aggregateCount', {}).then((r) => {
            setCategoriesCount(r);
        })
        
      //console.log("qqqqq EFFECT", );
    }, [])

    useEffect(() => {
        Storage.loadQuestions(category._id, (r) => {
           setQuestions(_.sortBy(r, it => (-1 ) * it.interviewsCount));
        })

        Storage.loadInterviewQuestions(category._id, (r) => {
           setInterviewQuestions(_.sortBy(r, it => it.themeQuestionId ? 1 : 0))
        })
    }, [category])

        if (selectedId === -1) {
            category = {_id: -1}
        }

        let addCategory = (parentId) => {
           Storage.addCategory({parentId, title: treeSearch || 'Category'}, (_category) => {
               setSelectedId(_category._id)
           })
        };

        category = category || {};

        return <div className={''}>
            <div className="row">
                {!toggle && <div className="col-sm-3">
                    <div className="ff">
                        <button className={'btn btn-xs btn-default'} onClick={(e) => {
                            addCategory(-1)
                        }}>+ Тэг (в корень)
                        </button>
                        <button className={'btn btn-xs btn-default'} onClick={(e) => {
                            addCategory(selectedId)
                        }}>+ Тэг (в выбранную)
                        </button>
                        <button className={'btn btn-xs btn-default'} onClick={(e) => {
                          //console.log("qqqqq sync is clickced", );
                            global.http.get('/sync-all', {})
                                .then(() => {
                                  //console.log("qqqqq synced", );
                                })
                        }}>Sync
                        </button>
                    </div>

                    <Tree
                        categoriesCount={categoriesCount}
                        defClassInput={"tree-wrap-input"}
                        defClass={"tree-wrap"}
                        selectedId={selectedId}
                        onSearch={(v) => {
                          //console.log("qqqqq vvv", v);
                            setTreeSearch(v)
                        }
                        }
                        onClick={(id) => {
                            setSelectedId(id)
                            window.location.hash = 'category=' + id;
                        }}></Tree>


                </div>}

                <div className={toggle ? "col-sm-12" : 'col-sm-9'}>
                    {category && <div>
                        <MyModal
                            ref={(_this) => {
                                modal = _this;
                            }}
                        >
                            <b>Выберите родительскую категорию для {category.title}</b>
                            <hr/>
                            <Tree
                                categoriesCount={categoriesCount}
                                selectedId={modalSelectedId}
                                onClick={(id) => {
                                    modalFn(id)
                                }}
                            ></Tree>
                        </MyModal>
                        <Smart
                            obj={category}
                            items={[
                                {
                                    size: 4,
                                    key: 'title',
                                },
                                {
                                    size: 7,
                                    Component: () => {
                                        return <div>

                                            <button className={'pull-right btn btn-md'} onClick={(e) => {
                                                // console.log('*........ ## remove');
                                                if (window.confirm('Are you sure to delete with all tasks?')) {
                                                    Storage.deleteCategory(selectedId);
                                                    setSelectedId(-1)
                                                }
                                            }}>
                                                удалить
                                            </button>

                                            <a className={'mr10 pull-right'} style={{
                                                marginTop: '6px'
                                            }}><a onClick={(e) => {
                                                modal.show();
                                                modalSelectedId = selectedId;
                                                modalFn = (id) => {
                                                    category.parentId = id;
                                                    Storage.setCategory(selectedId, category);
                                                    setCount(count + 1);
                                                    modal.hide();
                                                }
                                            }}>Родитель: {(categoriesObj[category.parentId] || {}).title || '--'}</a></a>

                                            {/*<div className="ib pull-right" title={'Объединить в скилы? (для тасков)'}*/}
                                            {/*     style={{marginRight: '14px', marginTop: '3px'}}*/}
                                            {/*>*/}
                                            {/*    <Input type={"checkbox"} value={category.isSkill} onChange={(v) => {*/}
                                            {/*        category.isSkill = !category.isSkill;*/}
                                            {/*        Storage.setCategory(selectedId, category);*/}
                                            {/*        setCount(count + 1);*/}
                                            {/*    }}/>*/}
                                            {/*</div>*/}
                                        </div>
                                    }

                                }]}
                            onChange={(v) => {
                              //console.log('*........ ## on change', v);

                                Storage.setCategory(selectedId, v);
                                setCount(count + 1);
                            }}
                        ></Smart>

                        <hr/>
                        {/*<b style={{marginBottom: '10px'}}>Skills</b>*/}


                        <div className="tasks">
                            <div className="" style={{marginBottom: '15px'}}>
                                <div className="ib">
                                    <input type="text" placeholder={'Поиск по вопросам'} value={search}
                                           onChange={(e) => {
                                               setSearch(e.target.value)
                                           }}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 questionsTagsList">
                                    {(questions || []).map((it, ind) => {
                                        return (<div key={ind}>
                                            <a href={'/theme-question/' + it._id} >[x{it.interviewsCount || 0}] {it.name} </a>
                                        </div>)
                                    })}
                                </div>
                                <div className="col-sm-6 questionsTagsList">
                                    {(interviewQuestions || []).map((it, ind) => {
                                        return (<div key={ind}>
                                            <a href={'/interview-question/' + it._id} >
                                                {!it.themeQuestionId && `[ ** ]`} {it.name} ({it.interview})</a>
                                        </div>)
                                    })}
                                </div>
                            </div>



                            {/*<Smart*/}
                            {/*    obj={{skills}}*/}
                            {/*    items={[{*/}
                            {/*        size: 12,*/}
                            {/*        key: 'skills',*/}
                            {/*        sortable: true,*/}
                            {/*        defObj: {isNew: true},*/}
                            {/*        each: [{*/}
                            {/*            size: 12,*/}
                            {/*            Component: SkillEdit,*/}
                            {/*        }]*/}
                            {/*    }]}*/}
                            {/*    onChange={(v) => {*/}
                            {/*      //console.log('*........ ## ON CHANGEEEEEE', v);*/}
                            {/*        // clearTimeout(timer)*/}
                            {/*        // timer = setTimeout(() => {*/}
                            {/*        // }, 10)*/}
                            {/*    }}*/}
                            {/*></Smart>*/}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    }

    export default Home
