import React, {useState} from 'react';
import _ from 'underscore';
import TagSelector from './../Suggest/TagSelector'
import Select from 'libs/Select';
import {
    Link, Outlet
} from "react-router-dom";
import NFN from "../../NFN";


function ApproveTags({props}) {
    let {localItem, autoSaveFn, onGlobalChange} = props;
    localItem.customData = localItem.customData || {}
    console.log('*........ ## APPROVE TAGS RENDER', props, localItem);

    // let {customData = {}} = localItem

    // let v = useActionData();
    let onChange = (localItem) => {
        onGlobalChange && onGlobalChange({...localItem})
        autoSaveFn({...localItem})
    }
    return <div className='row'>
        <div className='col-sm-2'>
            <small><NFN>User Grade</NFN></small>
            <Select items={[0, 1, 2, 3]} value={localItem.customData.userGrade} onChange={(v) => {
                localItem.customData.userGrade = v;
                onChange(localItem)
            }}></Select>
        </div>
        <div className='col-sm-5'>
            <NFN>Approved Tags</NFN>
            {!localItem.customData.isApprovedAll && <TagSelector props={{
                localItem: {hashTags: localItem.customData.approvedTags || []},
                onChange: (v) => {
                    localItem.customData.approvedTags = v;
                    onChange(localItem)

                }
            }}></TagSelector>}
            <div style={{marginTop: '20px'}}></div>
            <label onClick={(e) => {
                localItem.customData.isApprovedAll = !localItem.customData.isApprovedAll;
                onChange(localItem)

            }}>
                {/* <input key={'checkbox'} type="checkbox" checked={Boolean(localItem.customData.isApprovedAll)} >

            </input> */}
                <NFN>Approve All Tags?</NFN>: {localItem.customData.isApprovedAll ? 'YES' : "NO"}

            </label>

        </div>
        <div className='col-sm-5'>
            <NFN>Ignore Tags</NFN>
            <TagSelector props={{
                localItem: {hashTags: localItem.customData.ignoreTags || []},
                onChange: (v) => {
                    localItem.customData.ignoreTags = v;
                    onChange(localItem)
                }
            }}></TagSelector>
        </div>


    </div>
}

export default ApproveTags
