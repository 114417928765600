import React, {useEffect, useState} from "react";
import _ from "underscore";
import "./CoursesList.css";
import {Link, Outlet} from "react-router-dom";
import Button from "libs/Button";
import TrainPageCourse from "./TrainPageCourse";
import m from "../../libs/m/m";
import AutoInterview from "./AutoInterview";
import FeedbackReview from "./FeedbackReview";
import Courses from "./Comps/Courses";
import CircularProgress2 from "./Comps/CircularProgress2";
import QuestionsList from "./Comps/QuestionList";
import FeedbacksList from "./Comps/FeedbacksList";
import TopStats from "./Comps/TopStats";
import Interviews from "./Comps/Interviews";
import WorkSessions from "./Comps/WorkSessions";
import Statistics from "./Comps/Statistics";
import {getAllQuestions, getDBQuizes, getSortedQuizesByQuestion, getTotalStats, sortFn} from "./Comps/mainMethods";
import Input from "../../libs/Input";
import CoursesAndQuestions from "./CoursesAndQuestions";
import {getActiveClass} from "../Suggest/SuggestionItem";
import NFN from "../../NFN";


function days(delta) {
    return new Date().getTime() + delta * 1000 * 24 * 3600;
}

function hours(hours) {
    return days(hours / 24)
}

let queryVariants = {
    '1 день': {$gt: days(-1)},
    '2 дня': {$gt: days(-2)},
    '3 дня': {$gt: days(-3)},
    '7 дней': {$gt: days(-7)},
    '14 дней': {$gt: days(-14)},
    '1 час': {$gt: hours(-1)},
    '2 часа': {$gt: hours(-2)},
    '4 часа': {$gt: hours(-4)},
    '8 часов': {$gt: hours(-8)},
    '-1 день': {$gt: days(-1), $lt: days(0)},
    '-2 дня': {$gt: days(-2), $lt: days(-1)},
    '-3 дня': {$gt: days(-3), $lt: days(-2)},
    '--1 день': {$lt: days(-1)},
    '--2 дня': {$lt: days(-2)},
    '--3 дня': {$lt: days(-3)},
    '--5 дней': {$lt: days(-5)},
    '--7 дней': {$lt: days(-7)},
    '--14 дней': {$lt: days(-14)},
    '--30 дней': {$lt: days(-30)},
}

export function getDayFilterQuery(dayFilter) {
    let q = queryVariants[dayFilter];
    return q ? {cd: q} : {}
}

function Layout2(props) {
    let [courses, setCourses] = useState([]);
    let [interview, setInterview] = useState(null);
    let [fb, setFb] = useState(null);
    let [user, setUser] = useState(+props.user || 999);
    let [res, setRes] = useState({});
    let [open, setOpen] = useState(false);
    let [history, setHistory] = useState({});
    let [modalOpts, setModalOpts] = useState({});
    let [questions, setQuestions] = useState([]);
    let [questionsObj, setQuestionsObj] = useState({});
    let [loading, setLoading] = useState(false);
    let [dayFilter, setDayFilter] = useState(0);

    let {isAdmin, onSelectFb} = props;

    useEffect(() => {
        fullLoad()
    }, [dayFilter]);

    useEffect(() => {
        setUser(+props.user || 0)
    }, [props.user]);
    useEffect(() => {
        user && fullLoad()
    }, [user]);

    function fullLoad() {
        setLoading(true)
        global.http.get("/load-my-courses-q-admin", {
            user,
            filter: getDayFilterQuery(dayFilter)
        })
            .then((r) => {
                setLoading(false)
                let {courses, userCourses, questionIds} = r;
                console.log("qqqqq aaaaaaaaaaaaaaaaa", r, r.result);
                setRes(r);
                setCourses(courses);
                setHistory(
                    userCourses.reduce((acc, it) => ({...acc, [it.course]: it}), {})
                );
                let {calcQuestion = {}} = r.result || {};
                let questions = ((r.result || {}).questions || []).map(it => {
                    return {...it, ...calcQuestion[it._id] || {}}
                });
                setQuestions(questions)
                setQuestionsObj(questions.reduce((acc, it) => ({...acc, [it._id]: it}), {}))
            });
    }


    function clickInterview(interview) {
        setInterview(interview)
        console.log("qqqqq interivew", interview);
    }

    function clickFeedback(fb) {
        setFb(fb)
        onSelectFb(fb)
        console.log("qqqqq click fb", fb);
        // global.http

    }

    async function clickExam(questions = [], size = 7) {
        clickCourse(questions || getAllQuestions(), 'exam', size || 10)
    }

    async function clickQuestion(item, key) {
        setOpen(true);
        setModalOpts({loading: true, quizes: []})
        let {_id} = item || {};
        let {quizes, generalQuiz} = getSortedQuizesByQuestion(res, _id, key, 3);
        let dbQuizes = await getDBQuizes(quizes)
        setModalOpts({loading: false, quizes: dbQuizes})

        console.log("qqqqq quizes!! 1", quizes.map(it => it?.calc));
        console.log("qqqqq quizes!! 2", dbQuizes);
        // let gQuiz = generalQuiz || await loadGeneralQuiz({_id});
    }


    async function clickCourse(questions = [], key = 'train', size = 7) {
        setModalOpts({loading: true, quizes: []})
        setOpen(true)
        let isExam = key == 'exam';

        // setOpen(true);
        let v = questions.map(it => {
            return getSortedQuizesByQuestion(res, it, key, 3)
        })
        let quizesPlain = _.sortBy(v.reduce((acc, it) => {
            return [...acc, ...it.quizes]
        }, []), sortFn(key)).splice(0, size)

        console.log("qqqqq quizes Plain 1", questions);
        console.log("qqqqq quizes Plain 2", quizesPlain.map(sortFn(key)), quizesPlain, key, v);
        let dbQuizes = await getDBQuizes(quizesPlain)
        let autoInterview;

        if (isExam) {
            autoInterview = await createAutoInterview({
                quizes: dbQuizes.map(it => it?.item?._id),
                questions: dbQuizes.map(it => it?.opts?.question),
            })
            _.each(dbQuizes, (item, ind) => {
                item.opts = item.opts || {};
                item.opts.autoInterview = autoInterview._id
            })

            console.log("qqqqq auto Interivew", autoInterview);
        }
        console.log("qqqqq quizes Plain dbQuizes", dbQuizes);

        setModalOpts({loading: false, quizes: dbQuizes, isExam: key == 'exam', autoInterview})


    }


    let onTrainFeedback = async ({fb, hist}) => {
        let dbQuizes = await getDBQuizes([{_id: hist.quiz}])
        _.each(dbQuizes, (item, ind) => {
            item.opts = item.opts || {};
            item.opts.parentFB = fb._id;
        })
        setModalOpts({loading: false, quizes: dbQuizes, isExam: false})
        setOpen(true)

        console.log("qqqqq db Quizes44444", dbQuizes);

    }


    let AutoInterviewWrap = (props) => {
        let _interview = props?.opts?.autoInterview || interview;
        return <AutoInterview interview={_interview}></AutoInterview>
    }
    let totalStats = getTotalStats({res, history});

    interview = interview || (res.interviews || {})[0];
    fb = fb || (res.fb || {})[0];

    return (
        <div style={{margin: "0", opacity: loading ? .4 : 1}}>
            {(['', ...Object.keys(queryVariants)] || []).map((it, ind) => {
                return (<div key={ind}
                             onClick={() => {
                                 setDayFilter(it)
                             }}
                             style={{padding: '3px 5px'}}
                             className={'ib pointer ' + getActiveClass(it, dayFilter)}>
                    <NFN>{it || 'all'}</NFN>
                </div>)
            })}

            <MyModal
                isOpen={open}
                onClose={() => setOpen(false)}
                size={'full'}>
                <TrainPageCourse
                    Result={modalOpts.autoInterview ? AutoInterviewWrap : null}
                    // Result={modalOpts.autoInterview ? <AutoInterview interview={interview}></AutoInterview> : null}
                    opts={modalOpts}
                />
            </MyModal>
            <div className="row">
                {/*<Input value={user} onChange={(v) => setUser(v)} placeholder={'User'}></Input>*/}
                <TopStats
                    onClickExam={() => clickExam(getAllQuestions(history), 7)}
                    onClickTrain={() => clickCourse(getAllQuestions(history))}
                    totalStats={totalStats}
                ></TopStats>
                <div className="col-sm-12">
                    <div className="card2">
                        <NFN>Courses & Questions</NFN>
                        <CoursesAndQuestions
                            res={res}
                            courses={courses}
                            user={user}
                            history={history}
                            questions={questions}
                            questionsObj={questionsObj}
                        ></CoursesAndQuestions>

                    </div>
                </div>

                <div className="col-sm-6">

                    <Interviews
                        isAdmin={isAdmin}
                        res={res}
                        onClick={() => {
                            console.log("qqqqq nothing here",);
                        }}></Interviews>
                </div>
                <div className="col-sm-6">
                    <div className="card2 w100">
                        <WorkSessions
                            dayFilter={dayFilter}
                            user={user}
                        ></WorkSessions>
                    </div>
                </div>

                <div className="col-sm-12"></div>
                <div className="col-sm-6">
                    <div className="card2 animChild">
                        <FeedbacksList
                            isAdmin={isAdmin}
                            onClick={clickFeedback}
                            onTrain={onTrainFeedback}
                            fb={fb}
                            res={res}
                        ></FeedbacksList>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card2 w100">
                        <Statistics
                            res={res}
                        ></Statistics>
                    </div>

                </div>



            </div>
            {/*<div*/}
            {/*  className="afade coursePreview coursePreviewTitle"*/}
            {/*>*/}
            {/*  <strong className="tabsTitle">*/}
            {/*    Список доступных курсов ({courses.length}):*/}
            {/*  </strong>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  data-courses={(courses || []).length}*/}
            {/*  className="animChild "*/}
            {/*  style={{*/}
            {/*    display: "block",*/}
            {/*    gap: "10px",*/}
            {/*    textAlign: "center",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {(courses || []).map((it, ind) => {*/}
            {/*    let perc = getCoursePerc(it, history);*/}
            {/*    let hist = (history || {})[it._id] || {};*/}
            {/*    perc = perc || 0;*/}
            {/*    return (*/}
            {/*      <Link*/}
            {/*        to={"/courses/" + it._id}*/}
            {/*        key={ind}*/}
            {/*        className={"mainCont3 coursePreview"}*/}
            {/*      >*/}
            {/*        <div className="courseTitle">{it.name}</div>*/}
            {/*        <div>*/}
            {/*          <small>Кол-во модулей: {(hist.modules || []).length}</small>*/}
            {/*        </div>*/}
            {/*        <small>Процент изучения: {perc}%</small>*/}
            {/*        <Perc top={15} value={perc}></Perc>*/}
            {/*      </Link>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</div>*/}
        </div>
    );
}

export default Layout2;
