import Smart from "../../libs/Smart";
// import MonacoEditor from "react-monaco-editor";
import React, {useEffect, useState} from "react";
import CustomStorage from "./CustomStorage";
import LazyEditor from "../LazyEditor/LazyEditor";
import {Editor} from "@monaco-editor/react";
import Select from "../../libs/Select";
import {getActiveClass} from "./SuggestionItem";
import DebugLogs from "../TrainMethods/DebugLogs";
import Button from "../../libs/Button";
import NFN from "../../NFN";

const SmartMemo = React.memo(function (props) {
    return <Smart {...props}></Smart>
}, (v1, v2) => {
    console.log('SMART MEMEO', v1.memo, v2.memo, v2.name)
    return v1.memo == v2.memo;
})

let isFilesFn = (codeType) => {
    return !/multiRun|promise|async|logreader/gi.test(codeType)
}
let getStarterCode = (details, lng) => {
    let {fnName, returnType, returnArrType, files, fields} = details;
    _.each(files, (item, ind) => {
        let {name, returnType, returnArrType} = fields
    })

    console.log("qqqqq detailsssss", details);
    let codes = {
        'js': ``,
        'ts': ``,
        'py': ``,
        'ja': ``,
    }
    return lng
}

let allLngs = [
    {name: 'js', value: 'js'}, {name: 'ts', value: 'ts'}, {name: 'python', value: 'py'}, {
        name: 'java',
        value: 'ja'
    }
]

let jsShort = [{name: 'js', value: 'js'}, {name: 'ts', value: 'ts'}]

let lngsByCodeType = {
    'js': jsShort,
    'ts': jsShort,
    'react': jsShort,
    'vue': jsShort,
    'html': jsShort,
    'promise': jsShort,
    'async': jsShort,
}

let isHTMLSpecialFn = (type) => {
    return /react|vue|html|logreader/gi.test(type)
}

function JSDetails(props) {
    props = props?.props || props;

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState({})
    let {localItem = {}, item, customData = {}} = props || {};
    let question = (localItem || {})._id || props.questionId;
    let isCustom = Boolean(customData.onChangeDetails);
    let keyId = (customData || {}).keyId;
    let quizId = (localItem || {})._id || props.quiz;
    console.log("qqqqq set JS DETAILS", data, props, quizId);

    // let setData = () =>{
    //
    // }
    window.setJsDetailsData = (_data) => {
        data = {...data, details: {...data.details || {}, ..._data.details || {}}}
        console.log("qqqqq datatatatat", data);
        setData(data);
        autoSave(data)

    }
    useEffect(() => {
        if (!isCustom && question) {
            setLoading(true)
            global.http.get('/get-js-details', {quiz: quizId})
                .then(it => {
                    setLoading(false)
                    it.details = it.details || {};
                    setData({...it})

                })
        }

    }, [quizId])

    useEffect(() => {
        let startFiles = {
            multiRun: [],
            js: [{name: 'main.js'}],
            ts: [{name: 'main.ts'}],
            promise: [{name: 'main.js'}],
            async: [{name: 'main.js'}],
            react: [{name: 'App.js'}, {name: 'App.css'}],
            vue: [{name: 'App.js'}, {name: 'App.css'}],
            html: [{name: 'index.html'}],
            css: [{name: 'main.css'}],
            logreader: [{name: 'main.js'}],
            custom: [],
        }
        let unitTestsTypes = {
            react: 'html', vue: 'html', html: 'html',
        }
        let codeType = data?.details?.codeType;
        if (codeType) {
            console.log("qqqqq CODE TYPE IS CHANGED", codeType);
            // data.details.files = startFiles[codeType] || []
            data.details.unitTestsType = unitTestsTypes[codeType] || 'js'
            setData({...data})
        }
    }, [data?.details?.codeType || ''])

    useEffect(() => {
        isCustom && keyId && setData({...customData.jsDetails})
        // setLoading(false)
    }, [keyId])

    function autoSave(data) {
        customData.onChangeDetails && customData.onChangeDetails(data)
        console.log("qqqqq  quizzzzzzzzzzzzzzzzid", data, quizId, props);
        !isCustom && global.http.put('/js-details', data).then(it => {
            // console.log("qqqqq PROPS JS DETAILS PUT DATA", it);
        })

    }

    console.log('jsdetails props4444', data)

    if (loading) {
        return <div>Loading ...</div>
    }

    function getId() {
        return localItem._id || CustomStorage.getId();
    }

    let isJsType = true || localItem.type == 'js-task'

    let returnTypesAndCheckbox = [{
        key: 'returnType',
        name: '',
        size: 5,
        type: 'select',
        items: ['', 'string', 'int', 'bigint', 'boolean', 'object', 'symbol']
    }, {
        key: 'returnArrType', name: '', size: 2, type: 'select', items: ['', 'plain', '[]', '[][]']
    }, // {key: 'isArr', name: 'isArr', size: 2, type: 'checkbox'},
    ]

    let _setData = (data) => {
        // data.details = {...data.details, v};
        setData(data)
        autoSave(data);
    }
    let lng = 'js'
    let isHTMLSpecial = isHTMLSpecialFn(data?.details?.codeType)
    console.log("qqqqq dataaaaaaaaaaaaaaaaaaaaaaaaaaa", data.details);

    return !isJsType ? null : <div className={loading ? 'o5' : ''}>
        <div className="pull-right">
            <a href={'https://razvitie.itrum.ru/run-by-quiz?quiz=' + getId()} target="_blank"
               style={{marginRight: '10px'}}>https://razvitie.xxx</a>
            <a href={'http://localhost:3001/run-by-quiz?quiz=' + getId()} target="_blank">http://localhost</a>
        </div>

        <Smart
            onChange={(v) => {
                console.log("qqqqq vvvvvv", v);
                data.details = {...data.details, v};
                setData({...data})
                autoSave(data);
            }}
            obj={data.details}
            items={[{size: 12, type: 'HR'}, {
                size: 2,
                type: 'select',
                key: 'codeType',
                name: global.nameFn('CodeType'),
                // items: ['', 'multiRun', 'promise', 'js', 'ts', 'async', 'react', 'vue', 'html', 'logreader', 'custom']
                items: ['', 'multiRun', 'promise',
                    // 'js', 'ts',
                    'async', 'react', 'vue', 'html', 'logreader', 'custom']
            }, {
                size: 2, key: 'timeout', name:  global.nameFn('Timeout (to wait async)'), onChange: () => {
                    global.resetMemo('editor')
                }
            }, {
                size: 2, type: 'select', key: 'hideRunStatus', name: global.nameFn('hideRunStatus'), items: ['', 'hidden']
            }, {
                size: 2,
                type: 'select',
                key: 'originalSolutionStatus',
                name: global.nameFn('originalSolutionStatus'),
                items: ['', 'shown']
            }, {
                size: 12,
                Component: () => <div></div>
            }, {
                size: 6, childs: [
                    {
                        size: 12,
                        defSize: 12,
                        isVisible({item}) {
                            return !isHTMLSpecial
                        },
                        childs: [
                            {
                                Component: ({item}) => {
                                    return <>
                                        <div className={'title-use-case'}>Название функции</div>
                                    </>
                                },
                            },
                            {
                                size: 12, Component: () => <Button
                                    color={4}
                                    onClick={() => {
                                        let details = data.details;

                                        details.starterFilesObj = details.starterFilesObj || {}
                                        _.each(lngs, (item, ind) => {
                                            let lng = item.value;
                                            let fileName = '';

                                            details.starterFilesObj[lng] = details.starterFilesObj[lng] || {};
                                            details.starterFilesObj[lng][fileName] = details.starterFilesObj[lng][fileName] || {};
                                            details.starterFilesObj[lng][fileName].code = getStarterCode(details, lng)
                                            console.log("qqqqq itemmmmmm", lng);
                                        })


                                        data.details = details;
                                        setData({...data})
                                        autoSave(data)
                                    }}
                                >Generate Names</Button>
                            },

                            {key: 'fnName', name: ' ', size: 5}, ...returnTypesAndCheckbox,

                            {


                                size: 12,
                                childs: [
                                    {
                                        Component: ({item}) => {
                                            return <>
                                                <div className={'title-use-case'}>Филды</div>
                                            </>
                                        },
                                    },

                                    {
                                        name: 'Филды',
                                        key: 'fields',
                                        notFoundText: 'Добавьте поле',
                                        addName: '++ Добавить поле ++',
                                        sortable: true, // showTopAdd: true,

                                        // defClass: 'video-list-parent',
                                        each: [{
                                            key: 'name',
                                            name: '',
                                            type: 'input',
                                            minRows: 1,
                                            size: 5
                                        }, ...returnTypesAndCheckbox,
                                        ]
                                    },
                                ]
                            },

                            // {
                            //     name: 'Тест Кейсы',
                            //     key: 'pubTestCases',
                            //     notFoundText: 'Добавьте тест кейс',
                            //     addName: '++ Добавить тест кейс ++',
                            //     sortable: true,
                            //     showTopAdd: true,
                            //
                            //     // defClass: 'video-list-parent',
                            //     each: [
                            //         {key: 'params', name: '', type: 'textarea', minRows: 1, size: 7},
                            //         {key: 'res', name: '', type: 'textarea', minRows: 1, size: 5},
                            //         // {key: 'res', name: 'Ожид результат', type: 'textarea', minRows: 1, size: 6},
                            //
                            //     ]
                            // },
                        ]
                    },


                    {
                        isVisible({item}) {
                            return !isHTMLSpecial
                        },
                        size: 12, childs: [
                            {
                                size: 12, Component: ({item}) => {
                                    return <>
                                        <div className={'title-use-case'}>Публичные Тест кейсы</div>
                                    </>
                                },
                            }, {
                                size: 12, key: 'pubCasesStr', type: 'textarea'
                            }, {
                                size: 12,
                                Component: (props) => <ExecTests fieldKey={'pubCasesStr'} {...props}></ExecTests>
                            }, {size: 12, type: 'hr'}, {
                                size: 12, Component: ({item}) => {
                                    return <>
                                        <div className={'title-use-case'}>Все Тест кейсы</div>
                                    </>
                                },

                            }, {
                                key: 'testCasesStr', size: 12, type: 'textarea'
                            }, {
                                size: 12,
                                Component: (props) => <ExecTests fieldKey={'testCasesStr'} {...props}></ExecTests>
                            },


                        ]
                    },

                    {
                        size: 12, Component({item}) {
                            return <>
                                <label className={'w100'}>
                                    {data?.details?.unitTests?.isActive && <span className="pull-right">
                                     <span className="ib">
                                     <Select items={['', 'html', 'js']}
                                             value={data?.details?.unitTests?.type}
                                             onChange={(v) => {
                                                 data.details.unitTests.type = v;
                                                 _setData({...data})
                                             }}></Select>
                                     </span>
                                </span>}
                                    Юнит Тесты
                                    <span className="ib" style={{marginLeft: '10px'}}>
                                                <input
                                                    type={"checkbox"} checked={data?.details?.unitTests?.isActive}
                                                    onChange={(e) => {
                                                        console.log("qqqqq eeeeeeeeeeeeeeeeeeeeeeeee", data.details, e.target.checked);
                                                        data.details.unitTests.isActive = e.target.checked;
                                                        // resetMemo && resetMemo();
                                                        _setData({...data})
                                                        setTimeout(() => {
                                                            _setData({...data})
                                                        }, 10)

                                                    }}/>
                                            </span>
                                </label>

                                {data?.details?.unitTests.isActive &&
                                    <LazyEditor
                                        height="500px"
                                        defaultLanguage="javascript"
                                        language="javascript"
                                        value={(data.details || {}).unitTests?.code || ''}
                                        // value={}
                                        onChange={(solution) => {
                                            data.details.unitTests.code = solution;
                                            _setData(data)
                                        }}
                                    />}
                            </>

                        }
                    }


                ]
            },


                {
                    size: 6, Component: ({item}) => {
                        return <>
                            <div className={'title-use-case'}></div>
                        </>
                    },

                }, // ]},
                {
                    size: 6, // memoKey: 'editor',
                    // path: 'Suggest/EditorViewJsDetails'
                    Component: EditorView
                    // (props) =>{
                    //     return  <EditorView {...props} setData={setData} autoSave={autoSave}></EditorView>
                    // },
                }]}>
        </Smart>
    </div>
}

export function getFileExt(name, lng) {
    let arr = (name || '').split('.')
    let last = lng || arr[arr.length - 1];
    let ext = last === 'css' ? 'css' : last === 'html' ? 'html' : last === 'ts' ? 'typescript' : last === 'py' ? 'python' : last == 'ja' ? 'java' : 'javascript';

    return ext;
}

function EditorView(props) {
    let {item, resetMemo} = props;
    // function autoSave() {

    // }
    function setData(data) {
        console.log("qqqqq EDITOR VIEW", props);

        let _data = {
            details: {}
        }
        _.each(['starterFilesObj', 'solutionFilesObj', 'unitTests'], (key, ind) => {
            _data.details[key] = data.details[key]
        })

        window.setJsDetailsData({..._data})
    }

    console.log('propsssssssVIEW', props)
    let [selectedFileInd, setSelectedFileInd] = useState(0)
    let [selectedSolutionFileInd, setSelectedSolutionFileInd] = useState(0)
    let [lng, setLng] = useState('js')
    let data = {details: item}


    data.details = data.details || {};
    data.details.starterFilesObj = data.details.starterFilesObj || {};
    data.details.starterFilesObj[lng] = data.details.starterFilesObj[lng] || {};
    let codeType = data?.details?.codeType;
    let isFilesByType = isFilesFn(codeType);
    // let {files} = item || {};
    let files = !isFilesByType ? [] : (data.details.starterFilesObj[lng] || {}).files || [];
    let selectedFileName = (files[selectedFileInd] || {}).name || ''
    let selectedSolutionFileName = (files[selectedSolutionFileInd] || {}).name || ''

    selectedFileName = selectedFileName || ''


    data.details.starterFilesObj[lng][selectedFileName] = typeof data.details.starterFilesObj[lng][selectedFileName] != 'object' ? {} : data.details.starterFilesObj[lng][selectedFileName] || {};

    data.details.solutionFilesObj = data.details.solutionFilesObj || {};
    data.details.solutionFilesObj[lng] = data.details.solutionFilesObj[lng] || {};
    data.details.unitTests = data.details.unitTests || {}

    console.log("qqqqq FILESSSSSSSSSSSSSSSSSSSSSSS", data.details, files);
    let isFiles = files && files.length > 0;


    let onUpdate = ({opts, lng, selectedFileName}) => {
        data.details.starterFilesObj[lng][selectedFileName] = {...data.details.starterFilesObj[lng][selectedFileName] || {}, ...opts}
        console.log("qqqqq data44444444444444444 CUSTOM", data.details.starterFilesObj, opts);

        setData({...data})
    }
    let onUpdateSolution = ({opts, lng, selectedSolutionFileName}) => {
        data.details.solutionFilesObj[lng][selectedSolutionFileName] = {...data.details.solutionFilesObj[lng][selectedSolutionFileName] || {}, ...opts || {}}
        console.log("qqqqq solutionFilesObjsolutionFilesObjsolutionFilesObj", data.details.starterFilesObj);
        setData({...data})
    }

    let isUnactive = ({lng, selectedFileName}) => {
        return ((data.details.starterFilesObj[lng] || {})[selectedFileName || ''] || {}).status == 'unactive'
    }
    let setAllStatus = (status, activeLng) => {
        let file = ''
        _.each(lngs, (item, ind) => {
            let lng = item.value;
            data.details.starterFilesObj[lng] = data.details.starterFilesObj[lng] || {};
            data.details.starterFilesObj[lng][file] = data.details.starterFilesObj[lng][file] || {};
            data.details.starterFilesObj[lng][file].status = status;

        })
        if (activeLng) {
            data.details.starterFilesObj[activeLng] = data.details.starterFilesObj[activeLng] || {}
            data.details.starterFilesObj[activeLng][file] = data.details.starterFilesObj[activeLng][file] || {}
            data.details.starterFilesObj[activeLng][file].status = 'active';
        }

        console.log("qqqqq data44444444444444444", data.details.starterFilesObj);
        setData({...data})

    }


    let lngs = lngsByCodeType[codeType] || allLngs;
    let isLngSelect = !isHTMLSpecialFn(codeType)
    console.log("qqqqq isLngSelect", isLngSelect, data);
    // console.log("RENDER EDITOR", data)
    return <>
        {/*{isLngSelect && <>*/}
        <div>Языки программировния
            <div className="ib">
                <a><small className="ml-10" onClick={() => {
                    setAllStatus('active')
                }}>SelectAll</small>
                </a>
                <a>
                    <small className="ml-10" onClick={() => {
                        setAllStatus('unactive')
                    }}>UnSelectAll</small>
                </a>
                <a>
                    <small className="ml-10" onClick={() => {
                        setAllStatus('unactive', lng)
                    }}>AllExceptCurrent</small>
                </a>
            </div>
            <div></div>
            {(lngs || []).map((it, ind) => {
                let currentLng = it.value;
                let fileName = '';
                let _isUnactive = isUnactive({lng: currentLng, selectedFileName: ''})
                return (<div
                    onClick={() => {
                        setLng(it.value)
                        setSelectedFileInd(0)
                    }}
                    key={ind} className={'ib mr-5 ' + getActiveClass(it.value, lng) + (_isUnactive ? ' o3' : '')}>
                    {it.name}
                </div>)
            })}


        </div>
        {isFilesByType && <>
            <hr/>

            <Smart
                onChange={(v) => {
                    onUpdate({opts: {files: v.files}, lng, selectedFileName})
                }}
                obj={data.details.starterFilesObj[lng]}
                items={[

                    {
                        size: 4,
                        Component: ({item}) => {
                            return <>
                                <div className={'title-use-case'}>Файлы</div>
                            </>
                        },
                    },

                    {
                        size: 8,
                        name: 'Файлы',
                        key: `files`,
                        notFoundText: 'Добавьте файл',
                        addName: '++ Добавить файл ++',
                        sortable: true, // showTopAdd: true,

                        each: [{
                            key: 'name',
                            name: '',
                            type: 'input',
                            minRows: 1,
                            size: 12
                        }, // {key: 'returnType', name: '', size: 5},
                            // {key: 'res', name: 'Ожид результат', type: 'textarea', minRows: 1, size: 6},
                        ]
                    }
                ]}
            >

            </Smart>
        </>}
        <hr/>
        {/*</>}*/}
        <div>Показ для запуска</div>
        <div className="ib pull-right">
            <Select items={['', 'active', 'unactive']}
                    value={data.details.starterFilesObj[lng][selectedFileName].status}
                    onChange={(v) => {
                        onUpdate({opts: {status: v}, lng, selectedFileName})

                    }}>

            </Select>
        </div>
        {isFiles && (files || []).map((it, ind) => {
            return <div
                onClick={() => {
                    setSelectedFileInd(ind)
                }}
                className={'ib filesItem ' + (ind === selectedFileInd ? 'correct' : '') + (isUnactive({
                    lng, selectedFileName: it.name
                }) ? ' o3' : '')}>{it.name || '-'}</div>
        })}
        <div>
            <LazyEditor
                height="150px"
                defaultLanguage={getFileExt(selectedFileName, lng)}
                language={getFileExt(selectedFileName, lng)}
                value={data.details.starterFilesObj[lng][selectedFileName].code || ''}

                onChange={(solution) => {
                    // localItem.jsDetails ??= {}
                    // props.onChange({...props.localItem, solution})
                    // data.details.starterFilesObj[lng][selectedFileName] = data.details.starterFilesObj[lng][selectedFileName] || {}
                    data.details.starterFilesObj[lng][selectedFileName].code = solution;
                    setData({...data})
                    // autoSave(data)
                }}
            />
        </div>
        <div>Правильное решение {lng} {selectedSolutionFileName}</div>
        {isFiles && (files || []).map((it, ind) => {
            return <div
                onClick={() => {
                    setSelectedSolutionFileInd(ind)
                }}
                className={'ib filesItem ' + (ind === selectedSolutionFileInd ? 'correct' : '')}>{it.name || '-'}</div>
        })}
        <LazyEditor
            height="250px"
            defaultLanguage={getFileExt(selectedSolutionFileName, lng)}
            language={getFileExt(selectedSolutionFileName, lng)}
            value={(data.details.solutionFilesObj[lng][selectedSolutionFileName] || {}).code || ''}
            // value={(data.details || {}).correctSolution || ''}
            // value={}
            onChange={(code) => {
                onUpdateSolution({opts: {code}, lng, selectedSolutionFileName})
            }}
        />

    </>
}

function ExecTests(props) {
    let [results, setResults] = useState(null)

    return <div>
        <hr/>
        <button className={'btn btn-xs btn-default'} onClick={() => {
            let casesStr = props.localItem[props.fieldKey];
            let {fields, fnName, correctSolution} = props.localItem;
            global.http.post('/admin-get-responses', {
                casesStr, fields, FN_NAME: fnName, codeStr: correctSolution, questionId: CustomStorage.getId()
            })
                .then(r => {
                    setResults(r)
                    console.log("qqqqq rrrr", r);
                })

            console.log("qqqqq on run tests", props);
        }}><NFN>Get DebugLogs</NFN>
        </button>
        {results && <>
            <div></div>
            <small>Results</small>
            <pre>{results.RESULTS}</pre>
            <small>Run FN</small>
            <pre>{results.RUN_FILE}</pre>
            <small>Cases File</small>
            <pre>{results.CASES_FILE}</pre>
            <small>FOLDER</small>
            <pre>{results.FOLDER}</pre>
        </>}
    </div>
}


function MonWrap(props) {
    let {localItem, item} = props;

    useEffect(() => {

    }, [localItem])
    if (localItem.reactLoading) return <div>Loading ...</div>
    let solution = (localItem || {}).solution;
    console.log("localItem444", solution, localItem)
    // localItem.jsDetails = {}
    return <div>
        <LazyEditor
            height="500px"
            defaultLanguage="javascript"
            language="javascript"
            value={solution}
            // value={}
            onChange={(solution) => {
                // localItem.jsDetails ??= {}
                console.log("JS DETAILS qqqqq vvvvvvvvv localItem444 Props", props, localItem)
                props.onChange({...props.localItem, solution})
            }}
        />
    </div>
}

export default JSDetails;