import React, {useEffect, useState} from 'react';
import {QHAdminId, QHParentsAdminId} from "./QHAdmin";
import Button from "../../libs/Button";

function Layout2(props) {
    let [info, setInfo] = useState({});

    console.log('*........ ## Auto Interview !!!!!!!!!!!!!!!!!!', props);

    let {fb = {}} = props || {};

    useEffect(() => {
        loadInterviewDetails();
    }, [fb?._id])

    let loadInterviewDetails = () => {
        fb._id && global.http.get('/quiz-history/' + fb.hist1, {_id: fb.hist1}).then(r => {
            setInfo(r)
        })
    }
    let onSetInfo = (_id, obj) => {
        // let stat = info[_id] || {}
        //
        // setInfo({...info, [_id]: {...info[_id] || {}, ...obj || {}}})
        // global.http.get('/set-quiz-answer-details', {_id: stat._id, answerDetails: obj.answerDetails || {}})
        console.log("qqqqq on SET INFO", _id);
    }
    // let v = useActionData();
    let {answerDetails = {}, adminDetails = {}, reviewDetails = {}, stats = {}} = fb;
    console.log("qqqqq feedback detailssssss", answerDetails, adminDetails, 'ffff', fb);

    return <div>
        <div></div>
        <div className="h100">
            #{fb.hist1} {info.name}
        </div>


        <hr/>
        <div></div>
        <QHAdminId deep={-1} histId={fb.hist1}></QHAdminId>
        <div>
            <QHParentsAdminId histId={fb.hist1}></QHParentsAdminId>
        </div>
        {/*Изначальная оценка: {answerDetails.rate}*/}
        {/*<div></div>*/}
        {/*Админ оценка: {adminDetails.rate}*/}
        {/*<div></div>*/}
        {/*Ревью оценка: {reviewDetails.rate}*/}
        {/*<hr/>*/}
        {/*<div></div>*/}
        {/*Текст: {info.recognition?.recognizedText || ''}*/}

        <div>
            <hr/>
        </div>

        {/*<div>*/}
        {/*    <hr/>*/}
        {/*</div>*/}
        {/*<div></div>*/}
        {/*<HashPlayer item={info}></HashPlayer>*/}

    </div>
}

export const HashPlayer = (props) => {
    let {hash, user} = props.item || {};
    return <div className="fa fa-play-circle" onClick={() => {
        myPlayer({path: `/${user}/${hash}.wav`})
    }}></div>
}
export default Layout2

