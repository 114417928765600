import React, {lazy, Suspense, useState} from 'react';
import {Editor} from "@monaco-editor/react";

function LazyEditorDirect(props) {
    // return <>{new Date().getTime()}
    {/* <Editor {...props}></Editor></>; */}
    return <Comp {...props}></Comp>
}
const Comp = React.memo(function LazyEditorDirect(props) {
    return <><Editor {...props}></Editor></>;
  }, (v1, v2) => {
    console.log('v1, v2', v1.value, v2.value)
    return v1.value == v2.value;
  });

export default LazyEditorDirect
