import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './Player.css'
import {useEffect, useState,useRef} from "react";
import DebugLogs from "../DebugLogs";
import React, { Component } from 'react'
import ReactHowler from 'react-howler'


// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

let onChange = {
    fn: null,
    src: ''
};
let prevSrc;
export default function Player(props) {

    let [src, setSrc] = useState('')
    let [open, setOpen] = useState(false)
    let player = useRef()

    let onChangeSrc = (src) => {
        console.log("qqqqq CHNAT SRC", src);
        setSrc('')
        let isOk = src && !/undefined/gi.test(src);
        setTimeout(() => {
            if (isOk) {
                setSrc(src)
            }
        })

        if (!isOk) {
            setOpen(false)
            return;
        } else {

        }
        setOpen(true)
    }
    useEffect(() =>{
        $('body').toggleClass('playing', open)
    }, [open])
    useEffect(() => {
        onChangeSrc(props.src)
    }, [props.src])

    window.myPlayer = (props) => {
        let _src = props.src || props.path;
        if (props.onChange) {
            onChange = {
                fn: props.onChange,
                src: _src
            }
        }

        if (onChange?.src != _src) {
            onChange = {}
        }
        let src =  global.env.VIDEO_DOMAIN_PLAYER + props.path || props.src;

        if (!src) {
            setOpen(false)
            setSrc('')
            return;
        }

        if (src == prevSrc) {
            console.log("qqqqq player", player);
            $('.rhap_play-pause-button').click();
            // player?.current?.togglePlay && player?.current?.togglePlay()
        } else {
            prevSrc = src;
            onChangeSrc(src)
        }

        // if (props.path) {
        //     let src = global.env.VIDEO_DOMAIN + props.path;
        //     console.log("qqqqq srccccccccc", src);
        //     onChangeSrc(src)
        // }
        // if (props.src) {
        //     onChangeSrc(props.src)
        // }
        //
        // if (!props.src && !props.path) {
        //     setOpen(false)
        //     setSrc('')
        // }
    }
    window.pp = player;


    return <div className={'player '  + (open ? ' opened' : '')}>
        <div className="fa fa-times player-close" onClick={() => {
            setSrc('')
            setOpen(false)
        }}></div>
        <DebugLogs>
            {src}
        </DebugLogs>


        <AudioPlayer
            autoPlay
            loop={false}
            ref={player}
            autoPlayAfterSrcChange={true}
            src={src}
            onListen={(v) => {
                console.log("qqqqq conChange", v);
                onChange?.fn && onChange?.fn(v.target.currentTime, v)
            }}
            onPlay={e => console.log("onPlay")}
            onClickNext={e => console.log("next")}
        />
    </div>
};

// async function fetchAudioAndEncodeBase64(remoteUrl) {
//     try {
//         // Fetch the audio file
//         const response = await fetch(remoteUrl);
//
//         // Get the audio data as a Blob
//         const audioBlob = await response.blob();
//
//         // Read the audio data as a data URL
//         const reader = new FileReader();
//         reader.readAsDataURL(audioBlob);
//
//         // Convert the audio data URL to Base64
//         reader.onloadend = function() {
//             const base64Data = reader.result.split(',')[1];
//             console.log(base64Data); // This is the Base64-encoded audio data
//         };
//     } catch (error) {
//         console.error('Error:', error);
//     }
// }

// window.fetchAudioAndEncodeBase64 = fetchAudioAndEncodeBase64;
//
// // Replace 'http://example.com/audio.mp3' with the URL of the audio file you want to fetch
// fetchAudioAndEncodeBase64('http://example.com/audio.mp3');



// class PlayerHowler extends Component {
//     // This sound file may not work due to cross-origin setting
//     render () {
//         return (
//             <ReactHowler
//                 src='http://goldfirestudios.com/proj/howlerjs/sound.ogg'
//                 playing={true}
//             />
//         )
//     }
// }