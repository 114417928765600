import React, {useEffect, useState} from "react";
import Storage from "./Storage";

function Questions({themeQuestionId, forceLoad, setForceLoad, onSave, hashTags, onChange}) {
    let [allQusetions, setAllQuestions] = useState([])
    let [questions, setQuestions] = useState([])
    let [search, setSearch] = useState('')
    useEffect(() => {
        if (hashTags && hashTags.length) {
            Storage.loadQuestions(hashTags, (r) => {
                setQuestions(r)
            })
        } else {

        }
    }, [hashTags, forceLoad])
    useEffect(() => {
        Storage.loadAllQuestions(r => {
          //console.log("qqqqq load ALL QUWTRIONS",r );
            setAllQuestions(r)
        })
    }, [])

    function filterQuestions(questions) {
        let reg = new RegExp(search, 'gi')
        return search ? questions.filter(it => {
            return reg.test(it.name || it.title)
        }) : questions;
    }
    return <div>
         <button className={'btn btn-xs btn-default'} onClick={() => {
            if (!hashTags || !hashTags.length) {
                return alert('Заполните в начале хэштег')
            }
            onChange(0)
        }
        }>Это новый вопрос
        </button>
        <input className="pull-right input-xs" style={{width: '200px'}} 
        value={search}
        placeholder="Поиск по вопросам"
            onChange={(e) => {
                setSearch(e.target.value)
            }}
        ></input>
        <div className="questions-wrap">
            {filterQuestions(questions || []).map((it, ind) => {
                return (<div key={ind} onClick={() => {
                    onChange(it._id)
                }} className={themeQuestionId == it._id ? 'activeQuestion' : ''}>
                    <small onClick={(e) => {
                        Storage.tryRemoveQuestion(it, () => {
                            setForceLoad(new Date().getTime())
                        })
                        return e.stopPropagation();
                    }} className={'close-icon'}>
                        <span className="fa fa-times"></span>
                    </small>
                    <a href={'/theme-question/' + it._id}  onClick={(e) => {
                        return e.stopPropagation();
                    }}>
                        <span className="close-icon fa fa-pencil"></span>
                    </a>
                    {/*<Textarea value={it.name} minRows={1} onChange={(v) => {*/}
                    {/*    questions[ind].name = v;*/}
                    {/*    setQuestions([...questions]);*/}
                    {/*  //console.log("qqqqq render quest PREEEEEEE", v);*/}
                    {/*    // Storage.updateThemeQuestion(it, () => {*/}
                    {/*    //*/}
                    {/*    // })*/}
                    {/*}}></Textarea>*/}
                    {it.title || it.name || '-----'}
                    {/*{themeQuestionId} {it._id}*/}
                </div>)
            })}
        </div>

        <button className={'btn btn-xs btn-default'} onClick={() => {
            if (!hashTags || !hashTags.length) {
                return alert('Заполните в начале хэштег')
            }
            onChange(0)
        }
        }>Это новый вопрос
        </button>


    </div>
}

export default Questions;