import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function Layout2(props) {
//console.log('*........ ## ROOT RENDER', props);

  useEffect(() => {
    window.location = '/users-templates/1000' + window.location.search
  }, [])
  // for (const [key, value] of params.entries()) {
  // //console.log(`${key}: ${value}`);
  // }

  // let v = useActionData();
  return <div>
    Redirecting ...
  </div>
}

export default Layout2
