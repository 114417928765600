import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {toOdb} from "../Stats";

function getMonthsBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const months = [];

    const monthNames = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];

    while (start <= end) {
        let date = new Date(start).setDate(1)
        months.push({name: monthNames[start.getMonth()] + " " + start.getFullYear(), date, odb: toOdb(date)});
        if (start.getMonth() === 11) {
            start.setFullYear(start.getFullYear() + 1);
            start.setMonth(0);
        } else {
            start.setMonth(start.getMonth() + 1);
        }
    }

    return months.reverse();
}


function MonthView(props) {
    let {start, end, Component} = props;
    let startOdb = toOdb(start)
    let endOdb = toOdb(end)
    let months = getMonthsBetweenDates(start, end)
    console.log("qqqqq months", months);
    return <>
        {(months || []).map((item, ind) => {
            let odb = item.odb;
            return (<Component {...item} ind={ind}></Component>)
        })}
    </>
}

export default MonthView
