import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from 'libs/Button'
import Input from 'libs/Input'
import IsFavorite from './IsFavorite'
import {
    Link, Outlet
} from "react-router-dom";
import CustomStorage from "./Suggest/CustomStorage";
import TagSelector from "./Suggest/TagSelector";
import Smart from 'libs/Smart';
import UserSprint from "./UserSprint";
import MyModal from "../libs/MyModal";
import './Exam.css';
import UserNewSel from "./Suggest/UserNewSel";

const USER_SPRINT_STATUSES = ['', {name: 'Актив', key: 'active'}, {name: 'Закрыто', key: 'closed'}];


let names = {
    good: {name: 'Хорошо', cl: 'label-success'},
    very_good: {name: 'Оч. хорошо', cl: 'label-success'},
    bad: {name: 'Норм', cl: 'label-warning'},
    norm: {name: 'Плохо', cl: 'label-danger'},
    '': {name: 'Новый', cl: 'label-info'},
    'all': {name: 'Все', cl: 'label-info'}
}
let timer;

function autoSave(item, delay) {
    // clearTimeout(timer)
    // timer = setTimeout(() => {
    //   global.http.put('/user-sprint', item)
    // }, delay || 300)
    console.log("qqqqq auto save", item, delay);
}

function SourcePreview({item, field = 'questions'}) {
    let [open, setOpen] = useState(false)
    let urls = {
        exam: '/exam',
        course: '/course',
        module: '/course-module'
    }
    return <div className={'row'}>
        <div className="col-sm-3">
            <div className="xQuizCount">
                x{item.minCount}
            </div>
        </div>
        <div className="col-sm-9">
            {item.source === 'current' && <div>It's Current item</div>}
            {item.source !== 'current' && <><a target={'_blank'}
                                               href={`${urls[item.source]}/${item.sourceId}`}>SourceName: {item.sourceName}</a></>}
            <div></div>
            <a onClick={() => {
                console.log("qqqqq open current", item);
                setOpen(true)
            }}>{field === 'questions' ? 'Questions' : 'Quizes'} List: x{(item[field] || []).length}</a>
        </div>

        <MyModal
            isOpen={open}
            onClose={() => setOpen(false)}
        >
            {(item[field] || [] || []).map((it, ind) => {
                return (<div key={ind}>
                    <a href={`/${field === 'questions' ? 'theme-question' : 'quizes'}/${it._id}`}>{it.name}</a>
                </div>)
            })}

        </MyModal>
    </div>

}

const ACTIVE_STATUSES = [
    '',
    {name: 'Актив', key: 'active'},
    {name: 'Не актив', key: 'unactive'},
]

function UserPreviewComponent(props) {
    let [loading, setLoading] = useState(false)
    let [items, setItems] = useState([])
    let [user, setUser] = useState(null)
    let {item} = props;
    let {_id} = item;

    useEffect(() => {
        user && loadExams()
        if (!user) {
            setItems([])
        }
    }, [user])

    function loadExams() {
        global.http.get('/exam', {sort: '-_id', per_page: 10, filter: {user, examTemplate: _id}}).then(r => {
            setItems(r.items)
        })
    }

    function generateExam() {
        console.log("qqqqq generate Exam",);
        let info = {...item || {}, user, examTemplate: _id, status: 'active'}
        delete info._id;
        delete info.cd;

        console.log("qqqqq infofoofoff", info);
        global.http.post('/exam', info).then(r => {
            console.log("qqqqq rrrr", r);
            loadExams();
        })

    }


    return <div>
        {loading ? 'loading ... ' : ''}
        <Button onClick={(scb) => {
            scb && scb();
            generateExam();

        }}>Generate Exam</Button>
        <div className="ib" style={{marginTop: '-15px', marginLeft: '20px'}}>
            <UserNewSel userId={user} onChange={(v) => {
                console.log("qqqqq change user", v);
                setUser(v?._id || null)
            }}></UserNewSel>
        </div>
        <hr/>

        {(items || []).map((it, ind) => {
            return (<Link to={'/exam/' + it._id} key={ind} className={'w100'}>
                <div className="ib">{m.date_time_short(it.cd)}</div>
                <div className="label label-info">{it.status}</div>
                {it.name}
                <div></div>
                <small>
                    Perc: {it.perc || '-'} QuizPerc: {it.quizPerc || '-'}

                </small>
            </Link>)
        })}
    </div>
}


function Layout2({props, httpSaveFn}) {
    let localItem = props.localItem || {};
    console.log("qqqqq W#################", props);
    return <div>
        <div className="col-sm-12">
            <UserSprint
                {...props}
                props={props}
                isExam={true}
                statuses={ACTIVE_STATUSES}
                UserPreviewComponent={UserPreviewComponent}
                opts={{
                    fieldKey: 'currentPotQuestions',
                    name: 'Exam',
                    cloneUrl: '/clone-exam-to-template2',
                    redirectUrl: '/exam-template'
                }}></UserSprint>
        </div>
        <div className="col-sm-12">
            <hr/>
        </div>
        <a style={{marginTop: '20px', marginRight: '10px', display: 'inline-block'}}
           onClick={() => {
               console.log("qqqqq localItemlocalItemlocalItemlocalItem", localItem);
               global.http.post('/clone-exam-to-template',
                   {
                       item: {
                           ...localItem || {},
                           status: 'active'
                       }
                   })
                   .then(r => {
                       window.location.pathname = '/exam-templates/' + r._id
                   })
           }}
        >Save To Another Template</a>
        <hr/>
        <div className={'row'}>

            <div className="col-sm-6">
                <div className="xQuizCount">Total Quizes: x{localItem.potQuizesCount}
                </div>
            </div>
            <div className="col-sm-6">
                <div className="xQuizCount">Total Tasks: x{localItem.potQuestionsCount}
                </div>
            </div>

            <div className="col-sm-6">
                <Smart
                    items={[

                        {
                            key: 'potQuizes',
                            size: 12,
                            notFoundText: 'Добавьте сорс квиза',
                            addName: '+ Сорс Квиза',
                            name: 'Potential Quizes',
                            sortable: true,
                            each: [
                                {
                                    type: 'group',
                                    key: 'source',
                                    name: 'Source',
                                    list: ['exam', 'module', 'course', 'current'],
                                    size: 6
                                }, {
                                    type: 'group',
                                    key: 'type',
                                    list: ['', 'easy', 'medium', 'hard'],
                                    size: 6
                                },
                                {size: 12, Component: () => <div></div>},
                                {
                                    label: 'sourceId',
                                    type: 'input',
                                    key: 'sourceId',
                                    size: 2
                                }, {
                                    type: 'number',
                                    key: 'count',
                                    label: 'Count',
                                    size: 2
                                }, {
                                    size: 8,
                                    Component: ({localItem, item}) => {
                                        console.log("qqqqq localItem potQiuzes", localItem);
                                        return <SourcePreview field={'quizes'} item={localItem}></SourcePreview>
                                    }
                                }
                            ]
                        },
                    ]}
                    obj={props.item}
                    onChange={(v) => {
                        if (v._id) {
                            console.log("qqqqq props are changed", v);
                            window.onGlobalChange && window.onGlobalChange(v, {woHttp: true});

                            CustomStorage.debounce(() => {
                                global.http.post('/potential-quiz-changed', {exam: v})
                                    .then(r => {
                                        console.log("qqqqq pot quizes", r);
                                        window.onGlobalChange && window.onGlobalChange(r, {woHttp: true});
                                    })
                            }, 1000, 'setSourceId')
                        }
                    }}
                >
                </Smart>
            </div>
            <div className="col-sm-6">
                <Smart
                    items={[

                        {
                            key: 'potQuestions',
                            size: 12,
                            notFoundText: 'Добавьте сорс Вопроса',
                            addName: '+ Сорс Вопроса',
                            name: 'Potential Question',
                            sortable: true,
                            each: [
                                {
                                    type: 'group',
                                    key: 'source',
                                    name: 'Source',
                                    list: ['exam', 'module', 'course', 'current'],
                                    size: 6
                                }, {
                                    type: 'group',
                                    key: 'type',
                                    list: ['', 'easy', 'medium', 'hard'],
                                    size: 6
                                },
                                {size: 12, Component: () => <div></div>},
                                {
                                    label: 'sourceId',
                                    type: 'input',
                                    key: 'sourceId',
                                    size: 2
                                }, {
                                    type: 'number',
                                    key: 'count',
                                    label: 'Count',
                                    size: 2
                                }, {
                                    size: 8,
                                    Component: ({localItem, item}) => {
                                        console.log("qqqqq localItem potQiuzes", localItem);
                                        return <SourcePreview item={localItem}></SourcePreview>
                                    }
                                }
                            ]
                        },

                    ]}
                    obj={props.item}
                    onChange={(v) => {
                        if (v._id) {
                            window.onGlobalChange && window.onGlobalChange(v, {woHttp: true});

                            CustomStorage.debounce(() => {
                                global.http.post('/potential-questions-changed', {exam: v})
                                    .then(r => {
                                        window.onGlobalChange && window.onGlobalChange(r, {woHttp: true});
                                    })
                            }, 1000, 'setSourceId')
                        }
                        //
                        // console.log("qqqqq vvvvv", v);
                    }}
                >

                </Smart>
            </div>
            <div className="col-sm-12">
                <hr/>
            </div>
        </div>
    </div>
}


export default Layout2
export {USER_SPRINT_STATUSES}