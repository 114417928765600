import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import _ from "underscore";

import {Link, Outlet} from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import "./QuizPreview.css";
import {CountDownSecs} from "./../TrainMethods/CoundDownSec";
import SmartPlayer from "../TrainMethods/SmartPlayer";
import {getQuizAnyName, isDebugLogsFn} from "./RenderQuizResults";

let onNextTimer;
global._setAdminMode = (v) => {
    localStorage.setItem("adminMode", v);
};

let Layout2 = forwardRef((props, ref) => {
    let [cdPerc, setCDPerc] = useState(0);
    let [disabledMs, setDisabled] = useState({});
    let [activeCodeFiles, setActiveCodeFiles] = useState([]);
    let [chosen, setChosen] = useState({});
    let [isSubmit, setIsSubmit] = useState(false);
    let {quiz, onSubmit, dbHist, isExam, onClick} = props;
    console.log("qqqqq dbHisttttt", dbHist);
    quiz ??= {};

    useEffect(() => {
        let hist = props.history || {};
        setChosen({...(hist.chosen || {})});
        setIsSubmit(hist.isSubmit);
        !hist.isSubmit && props.onStart && props.onStart()
    }, [quiz._id]);

    // useEffect(() => {
    //     if (props.forceHistory) {
    //         let hist = props.history || {};
    //         setChosen({...(hist.chosen || {})});
    //         setIsSubmit(hist.isSubmit);
    //     }
    // }, [quiz.history]);

    function pubName(it) {
        return it ? it.name || '' : ''
    }


    function _onSubmit(chosen, opts) {
        let {variations = []} = quiz || {};
        opts.correctAnswer = pubName(variations.find(it => it.isCorrect))
        opts.selectedAnswer = pubName(variations[Object.keys(chosen).filter(it => chosen[it])[0]])
        onSubmit && onSubmit(chosen, opts);
        //console.log("qqqqq SUBMITTTTT CLICK VARIANT3", quiz, opts, chosen);

        let isCorrect = opts?.isCorrect;

        let {quizOpenNextIfCorrectMs, quizOpenNextIfIncorrectMs, examOnNextMs} = props;
        let time = isExam ? examOnNextMs || 100 : isCorrect ? quizOpenNextIfCorrectMs : quizOpenNextIfIncorrectMs;

        setDisabled(time)
        setCDPerc(time)

        onNextTimer = time && setTimeout(() => {
            props.onNext && props.onNext();
        }, time)


    }

    function onClickVariant(ind) {
        let it = (quiz?.variations || [])[ind] || {}
        //  chosen[ind] = !chosen[ind];
        //console.log("qqqqq SUBMITTTTT CLICK VARIANT", ind);
        chosen = {[ind]: true};
        setChosen({...chosen});
        setIsSubmit(true);
        _onSubmit && _onSubmit(chosen, {isCorrect: it.isCorrect});

    }

    function timeOut() {
        //console.log("qqqqq click inside child",);
        let ind = 999;
        chosen = {[ind]: true};
        setChosen({...chosen});
        setIsSubmit(true);
        _onSubmit && _onSubmit(chosen, {
            isCorrect: false, isTimeout: true
        });
    }

    useImperativeHandle(ref, () => ({
        timeOut
    }));

    let {skipBottomOpenText} = props || {};
    let isAdminMode = !isExam && localStorage.getItem("adminMode") == "1";
    let {answerType} = quiz

    let isCode = answerType == 'code'
    let isAudio = answerType == 'audio'
    let isQuiz = answerType == 'quiz'
    let isAnyAudio = isAudio || isCode;

    let {history} = props;
    let {files, codeRate, answerRate, rate, compareRate} = history || {};
    let {hash} = history || {};
    console.log("qqqqq propssssssssss", quiz, {files, codeRate, answerRate, rate, compareRate}, history);
    // let timer = props.timer || 3;
    // let v = useActionData();
    let isDebugIds = isDebugLogsFn()

    let quizFiles = quiz.files || []
    history = history || {}
    // hash = '1706981918'
    return (<div
            className={
                "quiz-preview animChilds " + (!isExam && isSubmit ? "submitted" : "nonSubmitted")
            }
        >

            {isDebugIds && <div className={'debugLogs'}>
                Quiz: {quiz._id}
            </div>}
            <div className="quiz-submit-title no-select">
                <MDEditor.Markdown source={getQuizAnyName(quiz)}/>
            </div>

            <div className="row">
                {!!hash && <div className={'col-xs-12'}><SmartPlayer
                    user={props?.exam?.user}
                    hash={hash}
                >
                </SmartPlayer></div>}
                {isAnyAudio && <div className={'col-xs-12'}>
                    {history.recognizedText || '--'}
                    <div></div>
                    <div>Продолжительность: {history.duration}</div>
                    <div>Скорость: {history.recognizedSpeed}</div>

                </div>}
            </div>
            {!!quizFiles && !!quizFiles.length && <div className="row">
                <div className="col-sm-12">
                    {isCode && <>
                        <hr/>
                        {((history || {}).files || []).map((file, ind) => {
                            return (<div key={ind}>
                                <strong>{file.name}</strong>
                                <div>{file.code}</div>
                            </div>)
                        })}
                    </>}

                </div>
            </div>}


            <div
                style={{
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                    borderBottom: "1px solid #efefef",
                }}
            ></div>
            {isQuiz && (quiz.variations || []).map((it, ind) => {
                let clName;
                if (chosen[ind]) {
                    clName = isExam ? "unknown" : it.isCorrect ? "correct" : "incorrect";
                }
                if (!isExam && isSubmit && it.isCorrect) {
                    clName = props.isGreyForCorrect ? "unknown" : "correct";
                }
                return (
                    <div
                        key={ind}
                        className={"quiz-answer-it no-select " + clName}
                        onClick={() => {
                            if (!isExam && isSubmit) {
                                return;
                            }
                            onClickVariant(ind)
                        }}
                    >
                        <div className="quiz-answer-it-radio"></div>
                        <MDEditor.Markdown
                            source={(isAdminMode && it.isCorrect ? "** " : " ") + it.name}
                        />
                    </div>
                );
            })}
            {!disabledMs && isSubmit && <>
                <hr/>
                <Button color={4} onClick={(cb) => {
                    cb && cb()
                    props.onNext && props.onNext()
                }}>Идти дальше</Button>
            </>}
            {!skipBottomOpenText && isSubmit && disabledMs && <div>
                <hr/>
                След квиз откроется автоматически через {Math.round(disabledMs / 1000)}с. <a onClick={() => {
                clearTimeout(onNextTimer)
                props.onNext && props.onNext();

            }}>Открыть сйечас</a>
            </div>}

        </div>
    );
})

export default Layout2;
